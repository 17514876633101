<div class="row">
  <div class="col-sm-12">
    <!-- <div class="row">
			<div class="col-lg-9 col-md-9 col-12 form-group"></div>
			<div class="col-lg-3 col-md-3 col-12 form-group">
				<input type="search" class="form-control placehder" name="" placeholder="&#xF002; Search">
			</div>
		</div> -->
    <div class="clearfix"></div>
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                data-toggle="modal" data-original-title="Add" container="body" ngbTooltip="{{ 'Table_Columns.Detail_Tooltip' | translate }}"
                [class.disabled]="fieldsetDisabled" data-target="#exampleModal" (click)="open(content,'','')"><i
                  class="fa fa-plus"></i>
              </a></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'field_name')">
              {{ 'Table_Columns.FieldName_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'field_value')" style="width: 8%;">
              {{ 'Table_Columns.FieldValue_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'createby')">
              {{ 'Table_Columns.CreateBy_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'createdate')">
              {{ 'Table_Columns.CreateDate_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'updateby')">
              {{ 'Table_Columns.UpdateBy_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'updatedate')">
              {{ 'Table_Columns.UpdateDate_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of detailsList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="open(content,item,'view')">{{ 'Table_Actions.View_Action' | translate }}</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled"
                      (click)="open(content,item,'edit')">{{ 'Table_Actions.Edit_Action' | translate }}</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled" (click)="delete(item.id)">{{ 'Table_Actions.Delete_Action' | translate }}</span>
                  </li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.field_name}}</td>
            <td class="custom-td text-left">{{item.field_value}}</td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.createdate |
              date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.updatedate |
              date:userdata.displaydatetimeformat}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">{{ 'Modal_Title' | translate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation" name="from" #f="ngForm">
        <div class="form row">
          <div class="form-group col-md-6 col-sm-12">
            <label for="" class="mb-1">{{ 'Modal_Inputs.FieldName_Label' | translate }}</label>
            <input class="form-control" id="" type="text" name="field_name" [(ngModel)]="detailsData.field_name"
              #field_name="ngModel"
              [ngClass]="{ 'is-invalid': field_name.invalid && field_name.errors , 'is-valid': field_name.valid}"
              required>
            <div *ngIf="field_name.invalid && field_name.errors" class="invalid-feedback d-block">
              <div *ngIf="field_name.errors.required">
                {{ 'Modal_Inputs.FieldName_Required' | translate }}
              </div>
            </div>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="" class="mb-1">{{ 'Modal_Inputs.FieldValue_Label' | translate }}</label>
            <input class="form-control" id="" type="text" name="field_value" [(ngModel)]="detailsData.field_value"
              #field_value="ngModel"
              [ngClass]="{ 'is-invalid': field_value.invalid && field_value.errors , 'is-valid': field_value.valid}"
              required>
            <div *ngIf="field_value.invalid && field_value.errors" class="invalid-feedback d-block">
              <div *ngIf="field_value.errors.required">
                {{ 'Modal_Inputs.FieldValue_Required' | translate }}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">{{ 'Modal_Buttons.Close_Button' | translate }}</button>
      <button type="button" class="btn btn-primary" [disabled]="!f.valid || isView"
        (click)="postDetails()">{{ 'Modal_Buttons.Save_Button' | translate }}</button>
    </div>
  </resize-border>
</ng-template>