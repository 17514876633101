import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/shared/service/language.service';

@Component({
  selector: 'app-openingbalance',
  templateUrl: './openingbalance.component.html',
  styleUrls: ['./openingbalance.component.scss']
})
export class OpeningbalanceComponent implements OnInit {
  private langChangeSubscription: Subscription; 
  constructor(private activeModal: NgbActiveModal, private loader: NgxUiLoaderService,
    private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient, 
      ) { }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  ngOnInit(): void {
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
     
  }
  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/sales/customers/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
}
