import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { ItemanalysisComponent } from '../../inventory/products/modal/itemanalysis/itemanalysis.component';
import { PurchasehistoryComponent } from '../../inventory/products/modal/purchasehistory/purchasehistory.component';

@Component({
  selector: 'app-productcatlog4purchase',
  templateUrl: './productcatlog4purchase.component.html',
  styleUrls: ['./productcatlog4purchase.component.scss']
})
export class Productcatlog4purchaseComponent implements OnInit {
  fullScreen = false;
  @Output('purchaseItemList') purchaseItemList;
  @Output('supplierid') supplierid;
  appname = environment.APP_NAME;
  userdata: any;
  product: any = '';
  pcatg: any = {
    categoryid: '',
    deptid: '',
    subcategoryid: '',
    supplierid: ''
  }
  @ViewChild('myDrop') myDrop;
  assembliesList: any = [];
  // purchaseItemList: any = [];
  searchby: any = {
    ptype: '',
    pricingtype: '',
    productsearchby: '',
  }
  productList: any = [];
  categoryList: any = []
  supplierList: any = []
  deptList: any = []
  subCategoryList: any = []
  isShowtileView: boolean = false;
  isShowGridView: boolean = true;
  focusIn = false;
  myDropShow = true;
  @HostListener('scroll', ['$event'])
  onWindowScroll(event) {
    if (this.myDropShow == true) {
      this.myDropShow = false;
    }
  }
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private commoanService: CommonServices,
    private toastr: NotificationService,
    private datePipe: DatePipe,
    private loader: NgxUiLoaderService,
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));

    this.commoanService.getsuppliersList().subscribe(res => {
      this.supplierList = res
      environment.production ? '' : console.log('supplier list data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading supplier list ', err);
    });
    this.commoanService.getDepartmentList(false).subscribe(res => {
      this.deptList = res
      environment.production ? '' : console.log('category list data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading category list ', err);
    });
  }
  showDropdown() {
    this.myDropShow = true;
  }
  selectCategory() {
    this.commoanService.getSubCategory(this.pcatg.categoryid).subscribe(res => {
      this.subCategoryList = res
      environment.production ? '' : console.log('category list data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading category list ', err);
    });
  }
  selectDept() {
    this.pcatg.categoryid = '';
    this.pcatg.subcategoryid = ''
    this.commoanService.getCategory(this.pcatg.deptid, false).subscribe(res => {
      this.categoryList = res
      environment.production ? '' : console.log('category list data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading category list ', err);
    });
  }
  searchProduct() {
    this.loader.start();
    this.commoanService.getProductCatalogforPO(this.product, this.pcatg.supplierid, this.pcatg.categoryid, this.pcatg.deptid, this.pcatg.categoryid).subscribe(res => {
      this.loader.stop();
      this.productList = res;
      this.productList.forEach(element => {
        if (element.pocost != null && element.pocost != 0) {
          element.pocost = element.pocost.toFixed(this.userdata.decimals_allowed);
        }
        else {
          element.pocost = parseFloat("0").toFixed(this.userdata.decimals_allowed);
        }
        if (element.cost != null && element.cost != 0) {
          element.cost = element.cost.toFixed(this.userdata.decimals_allowed);
        }
        else {
          element.cost = parseFloat("0").toFixed(this.userdata.decimals_allowed);
        }
      });
      this.productList.forEach(item => {
        item.qty = 1;
        if (item.pic) {
          item.pic = environment.DOC_URL + '/' + this.userdata.companyid + '/' + item.pic;
        }

      })
      environment.production ? '' : console.log('product list data ', this.productList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading expType list ', err);
    });
  }

  purchasehistory(item) {
    var modalRef = null;
    modalRef = this.modalService.open(PurchasehistoryComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' })
    modalRef.componentInstance.fromParent = { id: item.prodid };
    environment.production ? '' : console.log('id', modalRef.componentInstance.id);
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  itemalysis(item) {
    var modalRef = null;
    modalRef = this.modalService.open(ItemanalysisComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    modalRef.componentInstance.fromParent = { id: item.prodid };
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  gridView() {
    this.isShowtileView = false;
    this.isShowGridView = true
  }
  tileView() {
    this.isShowtileView = true;
    this.isShowGridView = false
  }
  addproduct(data) {

  }
  closeModal() {
    this.activeModal.close(this.purchaseItemList);
  }
  ngOnInit(): void {
    this.pcatg.supplierid = this.supplierid ? this.supplierid : '';
    this.searchProduct();
  }
  onSortPro(item) {

  }

  selectEvent(item) {
    // do something with selected item
    this.toastr.Success("Item added into list")
    item.qty = item.qty ? (item.qty == 0 ? 1 : item.qty) : 1;
    var itmObj = {
      "prodid": item.prodid,
      "cost": item.pocost ? Number(item.pocost) : 0,
      "landedcost": item.cost ? Number(item.cost) : 0,
      "price": item.price ? Number(item.price).toFixed(this.userdata.decimals_allowed) : 0,
      "qty": item.qty ? item.qty : 1,
      "description": item.desc,
      "qtyrtd": item.qtyrtd ? item.qtyrtd : 0,
      "active": true,
      "ilc": item.ilc,
      "qtyonhand": item.qtyonhand,
      "discount": item.discount ? item.discount : 0,
      "tax": item.tax ? item.tax : 0,
      "bin": item.bin,
      "isservice": item.isservice,
      "returnable": item.returnable,
      "qtyreturn": item.qtyreturn ? item.qtyreturn : 0,
      "isserilized": item.isserilized,
      "tpcode": item.tpcode ? item.tpcode : null,
      "serial": item.serial ? item.serial : null,
      "grp": item.grp ? item.grp : null,
      "geid": item.geid ? item.geid : null,
      "deplete_qty": item.deplete_qty ? item.deplete_qty : 0,
      "lotid": item.lotid ? item.lotid : 0,
      "linetype": item.linetype ? item.linetype : null,
      "tech": item.tech ? item.tech : null,
      "datecreated": null,
      "line_total": 0,
      "deletedate": null,
      "createby": this.userdata.email,
      "createdate": null,
      "deleteby": null,
      "srs_id": item.srs_id ? item.srs_id : null,
      "taxid": item.taxid ? item.taxid : null,
      "discount_type": "percent",
      "dis_amt": 0,
      "tax_amt": 0,
      "uom": item.puom
    };
    if (this.purchaseItemList.length > 0) {
      var isItemExists = false;
      this.purchaseItemList.forEach(i => {
        if (i.prodid == item.prodid) {
          i.qty = Number(i.qty) + Number(item.qty);
          isItemExists = true;
        }
      })
      if (!isItemExists) {
        this.purchaseItemList.push(itmObj)
      }
    } else {
      this.purchaseItemList.push(itmObj);
    }
  }

  selectEvents(item) {
    for (var i = 0; i <= this.purchaseItemList.length; i++) {
      if (this.purchaseItemList[i].prodid == item.prodid) {
        this.purchaseItemList[i].qty = (this.purchaseItemList[i].qty ? Number(this.purchaseItemList[i].qty) : 0) + (item.qty ? item.qty : 1);
        // this.calculateLineItemTotal(this.purchaseItemList[i]);
        this.toastr.Success("Item added into list");

        break
      }
      if (this.purchaseItemList[i].prodid == '') {
        this.purchaseItemList[i].receivedqty = 0;
        this.purchaseItemList[i].damageqty = 0;
        this.purchaseItemList[i].overageqty = 0;
        this.purchaseItemList[i].shippedqty = 0;
        this.purchaseItemList[i].description = item.desc;
        this.purchaseItemList[i].active = true;
        this.purchaseItemList[i].qty = item.qty ? item.qty : 1;
        this.purchaseItemList[i].cost = item.pocost ? Number(item.pocost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        this.purchaseItemList[i].tax = item.tax ? Number(item.tax).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        this.purchaseItemList[i].taxid = null;
        this.purchaseItemList[i].tax_amt = Number(0).toFixed(this.userdata.decimals_allowed);
        this.purchaseItemList[i].discount = Number(0).toFixed(this.userdata.decimals_allowed);
        this.purchaseItemList[i].dis_amt = Number(0).toFixed(this.userdata.decimals_allowed);
        this.purchaseItemList[i].wo = '';
        this.purchaseItemList[i].uom = item.puom;
        this.purchaseItemList[i].discount_type = '';
        this.purchaseItemList[i].line_total = 0;
        this.purchaseItemList[i].prodid = item.prodid;
        this.purchaseItemList[i].ilc = item.ilc;
        this.purchaseItemList[i].pohdrid = 0;
        this.purchaseItemList[i].createby = null;
        this.purchaseItemList[i].createdate = null;
        this.purchaseItemList[i].milc = item.milc;
        this.purchaseItemList[i].tpcode = null;
        this.purchaseItemList[i].landedcost = item.cost ? Number(item.cost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        this.purchaseItemList[i].serials = null;
        this.toastr.Success("Item added into list");
        // this.calculateLineItemTotal(this.purchaseItemList[i]);
        // this.createROW();
        break
      }
      if (i == this.purchaseItemList.length - 1) {
        item.receivedqty = 0;
        item.damageqty = 0;
        item.overageqty = 0;
        item.shippedqty = 0;
        item.description = item.desc;
        item.active = true;
        item.qty = item.qty ? item.qty : 1;
        item.cost = item.pocost ? Number(item.pocost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        item.tax = Number(0).toFixed(this.userdata.decimals_allowed);
        item.taxid = null;
        item.tax_amt = 0;
        item.discount = 0;
        item.dis_amt = 0;
        item.wo = '';
        item.uom = item.puom;
        item.discount_type = 'percent';
        item.line_total = item.qty * item.cost;
        item.landedcost = item.cost ? Number(item.cost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        item.line_total = parseFloat(item.line_total).toFixed(this.userdata.decimals_allowed);
        this.purchaseItemList.push(item);
        // this.calculateLineItemTotal(this.purchaseItemList[this.purchaseItemList.length - 1]);
        this.toastr.Success("Item added into list");
        break
      }

    }
  }
}



