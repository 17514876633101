import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InventoryServices } from '../../../inventory.service';
import { AddProductComponent } from '../add-product.component';

@Component({
  selector: 'app-priceauditlogs',
  templateUrl: './priceauditlogs.component.html',
  styleUrls: ['./priceauditlogs.component.scss']
})
export class PriceauditlogsComponent implements OnInit {
  public closeResult: string;
  public categories = []
  lastaction: any;
  fieldsetDisabled = false;
  product:any;
  pricings:any=[];
  constructor(private modalService: NgbModal,
    private loader: NgxUiLoaderService,
    private maincomponent: AddProductComponent,
    private service: InventoryServices,
    private route: ActivatedRoute) {

    this.categories = categoryDB.category;
      this.product=maincomponent.product;
    this.lastaction = this.route.snapshot.queryParamMap.get('action');
    if (this.lastaction == 'view') {
      this.fieldsetDisabled = true;
    } else if (this.lastaction == 'edit') {
      this.fieldsetDisabled = false;
    }
    this.getProductPricings();
  }

  ngOnInit(): void {

  }

  sortDir = 1;//1= 'ASE' -1= DSC
onSortClick(event,colum) {
	let target = event.currentTarget,
		childtarget = target.querySelector('i'),
		classList = childtarget.classList;

	if (classList.contains('fa-chevron-up')) {
		classList.remove('fa-chevron-up');
		classList.add('fa-chevron-down');
		target.classList.add('Sorted');
		this.sortDir = -1;
	} else {
		classList.add('fa-chevron-up');
		classList.remove('fa-chevron-down');
		target.classList.remove('Sorted');
		this.sortDir = 1;
	}
	this.sortArr(colum);
}

sortArr(colName: any) {
	this.pricings.sort((a, b) => {
		let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
		let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

if (!valueA && !valueB) {
	return 0; 
}
if (!valueA) {
	return -this.sortDir; 
}
if (!valueB) {
	return this.sortDir; 
}

if (valueA > valueB) {
	return this.sortDir;
}
if (valueA < valueB) {
	return -this.sortDir;
}
return 0;
});
}

//#endregion Sorting Table Header

  getProductPricings() {
    this.loader.start();
    this.service.getProductPricing(this.product.id).subscribe(res => {
      this.loader.stop();
      this.pricings = res;
    }, err => {
      this.loader.stop();
    });
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
