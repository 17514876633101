import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InventoryServices } from 'src/app/components/inventory/inventory.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AddProductComponent } from '../add-product.component';

@Component({
	selector: 'app-suppliers',
	templateUrl: './suppliers.component.html',
	styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {
	searchText: string = '';
	public closeResult: string;
	public categories = []
	product: any;
	supplierList: any = [];
	tabsupplierList: any = []
	supplierData: any = {
		"id": null,
		"prodid": '',
		"supplierid": '',
		"active": true,
		"cost": 0,
		"isprimary": true,
		"companyid": '',
		"itemcode": "",
		"deletedate": "",
		"createby": "",
		"createdate": "",
		"updateby": "",
		"updatedate": "",
	}
	userdata: any;
	appname = environment.APP_NAME;
	isView: boolean = false;
	response: any;
	lastaction: any;
	fieldsetDisabled = false;
	constructor(private modalService: NgbModal,
		private datepipe: DatePipe,
		private route: ActivatedRoute,
		private maincomponent: AddProductComponent,
		private loader: NgxUiLoaderService,
		private service: InventoryServices,
		private toastr: NotificationService) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.product = maincomponent.product;
		this.lastaction = this.route.snapshot.queryParamMap.get('action');
		if (this.lastaction == 'view') {
			this.fieldsetDisabled = true;
		} else if (this.lastaction == 'edit') {
			this.fieldsetDisabled = false;
		}
		this.service.getProductSupplier().subscribe(res => {
			this.supplierList = res;
			environment.production ? '' : console.log(' supplier list data ', this.supplierList);
		}, err => {
			environment.production ? '' : console.log('Error : While loading supplier list ', err);
		});
		this.getProductSupliers();
	}


	getProductSupliers() {
		this.loader.start();
		this.service.getProductSupliers(this.product.id).subscribe(res => {
			this.loader.stop();
			this.tabsupplierList = res;
		}, err => {
			this.loader.stop();
		})
	}

	ngOnInit(): void {
	}

	open(content, item) {
		if (item == null) {
			this.supplierData = {
				"active": true,
				"cost": 0,
				"isprimary": true,
				"supplierid": ''
			};
			this.isView = false;
		}
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
	goToView(content, item) {
		this.isView = true;
		this.supplierData = item;
		this.open(content, item)
	}
	goToEdit(content, item) {
		this.isView = false;
		this.supplierData = item;
		environment.production ? '' : console.log('supplier edit data', this.supplierData);
		this.open(content, item)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	//#region Sorting Table Header
	sortDir = 1;//1= 'ASE' -1= DSC
	onSortClick(event,colum) {
		let target = event.currentTarget,
			childtarget = target.querySelector('i'),
			classList = childtarget.classList;

		if (classList.contains('fa-chevron-up')) {
			classList.remove('fa-chevron-up');
			classList.add('fa-chevron-down');
			target.classList.add('Sorted');
			this.sortDir = -1;
		} else {
			classList.add('fa-chevron-up');
			classList.remove('fa-chevron-down');
			target.classList.remove('Sorted');
			this.sortDir = 1;
		}
		this.sortArr(colum);
	}

	sortArr(colName: any) {
		this.tabsupplierList.sort((a, b) => {
			let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
			let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

	if (!valueA && !valueB) {
		return 0; 
	}
	if (!valueA) {
		return -this.sortDir; 
	}
	if (!valueB) {
		return this.sortDir; 
	}

	if (valueA > valueB) {
		return this.sortDir;
	}
	if (valueA < valueB) {
		return -this.sortDir;
	}
	return 0;
});
	}

	//#endregion Sorting Table Header

	postSupplier() {
		this.isView = true;
		this.supplierData.companyid = this.userdata.companyid;
		this.supplierData.prodid = this.product.id;
		if (this.supplierData.id) {
			this.supplierData.createby = this.supplierData.createby;
			this.supplierData.createdate = this.datepipe.transform(this.supplierData.createdate, this.userdata.postdatetimeformat);
			this.supplierData.updateby = this.userdata.email;
			this.supplierData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		}
		else {
			this.supplierData.createby = this.userdata.email;
			this.supplierData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		}
		environment.production ? '' : console.log('post alias datais', this.supplierData);
		this.loader.start();
		this.service.postItemSupplier(this.supplierData).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
					this.tabsupplierList=res.data;
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				this.closeModal();
			}
			environment.production ? '' : console.log('post product response ', res);
		}, err => {
			this.isView = false;
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading post product ', err);
		});
	}
	deleteSupplier(item) {
		Swal.fire({
			title: 'Are you sure want to delete?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				this.loader.start();
				this.service.deleteProductSupliers(item.id).subscribe((res: any) => {
					this.loader.stop();
					if (res != null) {
						if (res.status_code == "1") {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
						} else {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
							return;
						}
						this.getProductSupliers();
						this.modalService.dismissAll();
					}
				}, err => {
					this.loader.stop();
					environment.production ? '' : console.log('Error : While loading delete Currency', err);
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}
	closeModal() {
		this.modalService.dismissAll();
	}
}
