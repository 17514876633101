import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { SalesServices } from '../../Sales/sales.service';
import { DatePipe } from '@angular/common';
import { CommonServices } from 'src/app/shared/service/common.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { GenericprintComponent } from '../genericprint/genericprint.component';

@Component({
	selector: 'app-quotemodal',
	templateUrl: './quotemodal.component.html',
	styleUrls: ['./quotemodal.component.scss']
})
export class QuotemodalComponent implements OnInit {
	fullScreen = false;
	@ViewChild('Customer') Customer;

	//#region userdata block
	appname = environment.APP_NAME;
	userdata: any;
	companyLogo: any = 'http://ezybooks.net/docs/logos/';
	format: any;
	totalRows: any = 0;
	//#endregion userdata block
	qoute: any = {
		status: '',
		salesrep: '',
		quoteno: '',
		project: '',
		customer: '',
		tpsync: '',
		searchtxt: ''
	}
	statusList: any = [];
	estimateList: any = [];
	stringData: string;
	selectedQuotestatus: any = [];
	dropdownSettings: IDropdownSettings;
	customerList: any = [];
	keyword = 'name';
	totalPages: any = 0;
	currentPage: any = 1;
	hideRightNav: boolean = true;
	hideLeftNav: boolean = false;
	constructor(
		private toaster: NotificationService,
		private service: SalesServices,
		private datePipe: DatePipe,
		private commonService: CommonServices,
		private modalService: NgbModal,
		private activeModal: NgbActiveModal, private router: Router, private loader: NgxUiLoaderService,) {
		//#region userdata block
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.format = this.userdata.date_format
		this.companyLogo += this.userdata.logo;
		MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
		//#endregion userdata block

		this.qoute.fromdate = this.userdata.fromdate;
		this.qoute.enddate = this.userdata.enddate;
		this.getEstimateList();
		this.commonService.getComboEnums('quotestatus').subscribe(res => {
			this.statusList = res;
			environment.production ? '' : console.log(' status list data ', this.statusList);
		}, err => {
			environment.production ? '' : console.log('Error : While loading status list ', err);
		});
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'stringValue',
			textField: 'Text',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 2,
			allowSearchFilter: true
		}
	}

	getEstimateList() {
		this.loader.start();
		this.service.getQuoteList(1, this.qoute.searchtxt, this.datePipe.transform(this.qoute.fromdate, this.userdata.postdateformat),
			this.datePipe.transform(this.qoute.enddate, this.userdata.postdateformat),
			this.qoute.status, '').subscribe((res: any) => {
				this.loader.stop();
				if (res != null) {
					this.estimateList = res.data;
					this.totalPages = res.total_pages;
				}
				this.estimateList.forEach(element => {
					element.estimatedate = this.datePipe.transform(element.estimatedate, this.format);
					element.expirydate = this.datePipe.transform(element.expirydate, this.format);
					if (element.amt != null && element.amt != 0) {
						element.amt = element.amt.toFixed(this.userdata.decimals_allowed);
					}
					else {
						element.amt = parseFloat("0").toFixed(this.userdata.decimals_allowed);
					}
				});
				this.totalRows = this.estimateList.length;
				environment.production ? '' : console.log('estimate list data ', res);

			}, err => {
				this.loader.stop();
				environment.production ? '' : console.log('Error : While loading invoice list ', err);
			});
	}
	//#region NgAutocomplete
	onItemSelect(item: any) {
		let arrayData = [];
		this.selectedQuotestatus.forEach(data => {
			arrayData.push('' + data.stringValue);
		})
		arrayData = arrayData.filter((c, index) => {
			return arrayData.indexOf(c) === index;
		});
		this.stringData = arrayData.toString();
		this.qoute.status = this.stringData;
		environment.production ? '' : console.log(this.qoute.status);
		this.gotoPage('first');
	}
	onSelectAll(items: any) {
		let arrayData = [];
		items.forEach(data => {
			arrayData.push('' + data.stringValue);
		});
		this.stringData = arrayData.toString();
		this.qoute.status = this.stringData;
		this.gotoPage('first');
	}

	onItemDeSelect($event) {
		let arrayData = [];
		this.selectedQuotestatus.forEach(data => {
			arrayData.push('' + data.stringValue);
		})
		arrayData = arrayData.filter((c, index) => {
			return arrayData.indexOf(c) === index;
		});
		this.stringData = arrayData.toString();
		this.qoute.status = this.stringData;
		environment.production ? '' : console.log(this.qoute.status);
		this.gotoPage('first');
	}
	//#endregion

	onFocused(e) {
		this.Customer.clear();
		environment.production ? '' : console.log('select event onfocus', e);
	}
	selectEvent(item) {
		environment.production ? '' : console.log('select event', item);
		this.qoute.customer = parseFloat(item.id)
		this.gotoPage('first');
	}
	inputCustomerCleared() {
		this.qoute.customer = null;
		environment.production ? '' : console.log('Customer value cleared ', this.qoute.customer);
	}
	onChangeSearch(val: string) {
		environment.production ? '' : console.log('select event onchange', val);
	}
	printQuote(quoteid) {
		var modalRef = null;
		modalRef = this.modalService.open(GenericprintComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
		modalRef.componentInstance.id = quoteid;
		modalRef.componentInstance.name = 'printq';
		modalRef.result.then((result) => {
			environment.production ? '' : console.log(result);
		}).catch((error) => {
			environment.production ? '' : console.log(error);
		});
	}
	quote2SalesOrder(quoteid) {
		var createSoResponse
		this.service.createQoute2Salesorder(quoteid).subscribe(res => {
			createSoResponse = res;
			Swal.fire('', 'Sales order created!', 'success');
			// const encrptData = btoa(createSoResponse); 
			// const url = this.router.serializeUrl(this.router.createUrlTree(['/salesorders/newsales'], { queryParams: { id: encrptData, action: 'edit' } }));
			// window.open(url, '_blank');
			this.gotoPage('first');
		}, err => {
			environment.production ? '' : console.log('Error : While loading sales order data', err);
		});
	}
	ngOnInit(): void {
	}

	hideNavButtons(isright, isleft) {
		this.hideRightNav = isright;
		this.hideLeftNav = isleft;
	}
	gotoPage(page) {
		// this.hideNav = true
		if (page == 'first') {
			this.currentPage = 1;
			this.hideNavButtons(true, false);
		} else if (page == 'prev') {
			if (this.currentPage == '2') {
				this.currentPage = 1;
				this.hideNavButtons(true, false);
			} else {
				this.currentPage--;
				this.hideNavButtons(false, false);
			}
		} else if (page == 'next') {
			if (this.currentPage == this.totalPages - 1) {
				this.currentPage = this.totalPages;
				this.hideNavButtons(false, true);
			} else {
				this.currentPage++;
				this.hideNavButtons(false, false);
			}

		} else if (page == 'last') {
			this.currentPage = this.totalPages;
			this.hideRightNav = false
			this.hideLeftNav = true
		}
		this.getEstimateList();
	}

	closeModal() {
		this.activeModal.close('Modal Closed');
	}


}
