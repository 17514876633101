import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {
  fullScreen = false;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  userdata: any;
  appname = environment.APP_NAME;
  site: any = {
    name: '',
  }
  siteList: any = [];
  selectedSite: any;
  constructor(private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private commonService: CommonServices,
    private datepipe: DatePipe,) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.companyLogo += this.userdata.logo;
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block
    this.getSite();
  }
  getSite() {
    this.loader.start();
    this.commonService.getSite(this.site.name).subscribe(res => {
      this.loader.stop();
      this.siteList = res;
      environment.production ? '' : console.log('site list data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading site list data', err);
    });
  }

  ngOnInit(): void {
  }
  selectSite(item) {
    this.selectedSite = item;
    this.closeModal();
  }
  closeModal() {
    this.activeModal.close(this.selectedSite);
  }

}
