<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h4 class="modal-title">Batch Print</h4>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <div>
                <!-- <button >print</button> -->
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <button type="button" class="close" id="printButton">
                    <span aria-hidden="true" class="fa fa-print"></span>
                </button>
            </div>
        </div>
        <div class="modal-body" id="print-section">
            <div class="alert alert-warning noRecord" *ngIf="ticketsBatchPrintList.length == 0">No records found</div>
            <div class="TaskPrint" *ngFor="let ticket of ticketsBatchPrintList; let i = index;">
                <table class="topLogoBlock" #wrapper>
                    <tr>
                        <td>
                            <img *ngIf="isShowCopanyLogo" [src]="companyLogo">
                            <div class="clearfix"></div>
                        </td>
                        <td align="center">
                            <div class="Title">
                                <b>Service Ticket</b>
                                <div>{{ticket.tno}}</div>
                            </div>
                            <div class="clearfix"></div>
                        </td>
                    </tr>
                </table>
                <div class="clearfix"></div>

                <section class="TaskBasic">
                    <h4>Basic Information</h4>
                    <div class="clearfix"></div>
                    <div class="blocks">
                        <b>Subject/Title:</b> {{ticket.subject}}<br />
                        <b>Schedule Date :</b> {{ticket.dos}}<br />
                        <b>Schedule Time :</b>
                        {{ticket.scheduledtime}}<br />
                        <b>Expected/Due Date :</b>{{ticket.duedate}} <br />
                        <b>Cusotmer :</b>{{ticket.customerid}} <br />
                        <b>Category :</b> {{ticket.category}}<br />
                        <b>Project :</b>{{ticket.projid}} <br />
                        <b>Scope of Work :</b><br> <span [innerHtml]="ticket.sow"></span>
                        <b>Location of Work :</b>{{ticket.low}} <br />
                        <b>Customer Notice :</b><span [innerHtml]="ticket.customer_notice"></span>
                        <b>Directions to location :</b>{{ticket.directions}}
                        <br />
                        <b>Assignto :</b>{{ticket.assignto}} <br />
                        <b>Priority :</b> {{ticket.priority}}<br />
                        <b>Status :</b> {{ticket.status}}
                    </div>
                </section>
                <section class="TaskPrintLogs">
                    <h4>History</h4>
                    <div class="clearfix"></div>
                    <div class="blocks" *ngFor="let log of ticket.ticketLogs; let j = index;">
                        {{log.description}} {{log.createby}}
                        {{log.createdate}}
                    </div>
                </section>
                <section class="TaskIncidents">
                    <h4>Line Items</h4>
                    <div class="clearfix"></div>
                    <div class="blocks">
                        <table class="lineitemstbl ResGrid" #PrintTable>
                            <tr class="headerRow">
                                <th>ItemCode</th>
                                <th>Description</th>
                                <th class="text-center">Qty</th>
                                <th class="text-center">AfterHours</th>
                                <th class="text-center">Notes</th>
                            </tr>
                            <tr *ngFor="let item of ticket.ticketItems; let k = index;">
                                <td>{{item.ilc}}</td>
                                <td>{{item.description}}</td>
                                <td align="right">{{item.hrs}}</td>
                                <td align="center">{{item.worktype}}</td>
                                <td>{{item.notes}}</td>
                            </tr>
                        </table>
                        <div class="clearfix"></div>
                    </div>
                </section>
                <section class="TaskIncidents">
                    <h4>Checklist</h4>
                    <div class="clearfix"></div>
                    <div class="blocks">
                        <table class="lineitemstbl ResGrid">
                            <tr>
                                <th>Description</th>
                                <th>Device/Equip</th>
                                <th>Completed</th>
                                <th>Values</th>
                                <th>Notes</th>
                            </tr>
                            <tr *ngFor="let chklst of ticket.ticketChecklists; let k = index;">
                                <td>{{chklst.description}}</td>
                                <td>{{chklst.deviceid}}</td>
                                <td>{{chklst.completed}}</td>
                                <td>{{chklst.value}}</td>
                                <td>{{chklst.notes}}</td>
                            </tr>
                        </table>
                        <div class="clearfix"></div>
                    </div>
                </section>
                <br />
                <b>Customer feedback:</b><br>

                <div class="TicketSign">
                    <div class="clearfix"></div>
                    <b>Customer Signature</b>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Close</button>
        </div>
    </div>
</resize-border>