import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { purchaseServices } from '../../purchase/purchase.service';

@Component({
  selector: 'app-purchaserequestitemhistory',
  templateUrl: './purchaserequestitemhistory.component.html',
  styleUrls: ['./purchaserequestitemhistory.component.scss']
})
export class PurchaserequestitemhistoryComponent implements OnInit {
  @Input('poid') poid;
  codeList: any = []
  serialno: any
  fullScreen = false;
  constructor(
    private activeModal: NgbActiveModal,
    private datepipe: DatePipe,
    private loader: NgxUiLoaderService,
    private service: purchaseServices,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getHistoryList()
  }
  closeModal() {
    this.modalService.dismissAll('')
  }
  getHistoryList() {
    this.loader.start();
    this.service.getPurchaseRequestItemHistory(this.poid, '', '', '').subscribe(res => {
      this.loader.stop();
      this.codeList = res;
      environment.production ? '' : console.log('item history  ', this.codeList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }

}
function input(arg0: string) {
  throw new Error('Function not implemented.');
}

