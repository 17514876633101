<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h4 class="modal-title" *ngIf="showtitle">{{modalTitle}}</h4>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
              </button>
            <div>
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <button type="button" class="close"></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="">{{ 'Modal_Inputs.Code_Label' | translate }}</label>
                    <input type="text" class="form-control" placeholder="{{ 'Modal_Inputs.Code_Placeholder' | translate }}" name="code" [(ngModel)]="master.code">
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="">{{ 'Modal_Inputs.Name_Label' | translate }}</label>
                    <input type="text" class="form-control" placeholder="{{ 'Modal_Inputs.Name_Placeholder' | translate }}" name="name" [(ngModel)]="master.name">
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="">{{ 'Modal_Inputs.Seaport_Label' | translate }}</label>
                    <input type="text" class="form-control" placeholder="{{ 'Modal_Inputs.Seaport_Placeholder' | translate }}" name="seaport"
                        [(ngModel)]="master.seaport">
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="">{{ 'Modal_Inputs.Airport_Label' | translate }}</label>
                    <input type="text" class="form-control" placeholder="{{ 'Modal_Inputs.Airport_Placeholder' | translate }}" name="airport"
                        [(ngModel)]="master.airport">
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="">{{ 'Modal_Inputs.Country_Label' | translate }}</label>
                    <select id="" class="form-control" name="country" [(ngModel)]="master.country">
                        <option value="">{{ 'Modal_Inputs.Country_Placeholder' | translate }}</option>
                        <option [value]="n.Value" *ngFor="let n of countryList">{{n.Text}}</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">{{ 'Modal_buttons.Close_Button' | translate }}</button>
                <button type="button" class="btn btn-primary" (click)="validatePostValues()">{{ 'Modal_buttons.Save_Button' | translate }}</button>
            </div>
            <div class="category-table custom-datatable mb-3">
                <div class="table-responsive2">
                    <table class="custom-table">
                        <thead>
                            <tr class="custom-tr">
                                <th class="custom-th text-center">{{ 'Modal_Table.ID_Column' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Modal_Table.Code_Column' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Modal_Table.Name_Column' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Modal_Table.Seaport_Column' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Modal_Table.Airport_Column' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Modal_Table.Country_Column' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Modal_Table.Action_Column' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="custom-tr" *ngFor="let item of itemList">
                                <td class="custom-td text-center">{{item.id}}</td>
                                <td class="custom-td">{{item.code}}</td>
                                <td class="custom-td">{{item.name}}</td>
                                <td class="custom-td">{{item.seaport}}</td>
                                <td class="custom-td">{{item.airport}}</td>
                                <td class="custom-td">{{item.country}}</td>
                                <td class="custom-td text-center">
                                    <div class="modalActionBtn">
                                        <i class="eb-edit mr-1" (click)="editMasterData(item)"></i>
                                        <i class="eb-delete" (click)="deleteMasterData(item)"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</resize-border>