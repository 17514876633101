<resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Serial</h5>
    <button type="button" class="close" aria-label="Close" (click)="closemodal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" name="from" #f="ngForm">
      <div class="row">
        <div class="col-lg-12 form-group">
          <label for="">Serial</label>
          <input type="text" class="form-control" placeholder="" name="serial" [(ngModel)]="serialdata.serial"
            #serial="ngModel" [ngClass]="{ 'is-invalid': serial.invalid && serial.errors , 'is-valid': serial.valid}"
            required>
          <div *ngIf="serial.invalid && serial.errors" class="invalid-feedback d-block">
            <div *ngIf="serial.errors.required">
              Please enter serial no
            </div>
          </div>
        </div>
        <div class="col-lg-12 form-group">
          <label for="">Bin Location</label>
          <ng-select placeholder="Search Bin Locations" name="to bin location" [(ngModel)]="serialdata.binid"
            [clearable]="serialdata.binid" #binid="ngModel"
            [ngClass]="{ 'is-invalid': binid.invalid && binid.errors , 'is-valid': binid.valid}" required>
            <!-- <option value="">Select Bin</option> -->
            <ng-option *ngFor="let item of allBinList" [value]="item.Value">
              {{item.Text}}</ng-option>
          </ng-select>
          <div *ngIf="binid.invalid && binid.errors" class="invalid-feedback d-block">
            <div *ngIf="binid.errors.required">
              Please select bin
            </div>
          </div>
        </div>
        <div class="col-lg-12 form-group">
          <label for="">Purchase Order</label>
          <input type="text" class="form-control" placeholder="" name="pon" [(ngModel)]="serialdata.pon">

        </div>
        <div class="col-lg-12 form-group">
          <label for="">Purchase From</label>
          <input type="text" class="form-control" placeholder="" name="purchasefrom"
            [(ngModel)]="serialdata.purchasefrom">
        </div>
        <div class="col-lg-12 form-group">
          <label for="">Purchase Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="picker280" placeholder="Choose a date"
              [(ngModel)]="serialdata.purchasedate" name="purchasedate">
            <mat-datepicker-toggle matSuffix [for]="picker280"></mat-datepicker-toggle>
            <mat-datepicker #picker280></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-12 form-group">
          <label for="">Manufacturer</label>
          <input type="text" class="form-control" placeholder="" name="manufacture"
            [(ngModel)]="serialdata.manufacture">
        </div>
        <div class="col-lg-12 form-group">
          <label for="">Sale Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="picker281" placeholder="Choose a date" [(ngModel)]="serialdata.saledate"
              name="saledate">
            <mat-datepicker-toggle matSuffix [for]="picker281"></mat-datepicker-toggle>
            <mat-datepicker #picker281></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-12 form-group">
          <label for="">Order No</label>
          <input type="text" class="form-control" placeholder="" name="orderno" [(ngModel)]="serialdata.orderno">
        </div>
        <div class="col-lg-12 form-group">
          <label for="">Warranty Expiry Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="picker282" placeholder="Choose a date" [(ngModel)]="serialdata.wed"
              name="wed">
            <mat-datepicker-toggle matSuffix [for]="picker282"></mat-datepicker-toggle>
            <mat-datepicker #picker282></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-12 form-group">
          <label for="">Status</label>
          <select class="form-control" name="status" [(ngModel)]="serialdata.status" #status="ngModel"
            [ngClass]="{ 'is-invalid': status.invalid && status.errors , 'is-valid': status.valid}" required>
            <option value="">Select Status</option>
            <option *ngFor="let item of statusList" [value]="item.stringValue">{{item.Text}}</option>

          </select>
          <div *ngIf="status.invalid && status.errors" class="invalid-feedback d-block">
            <div *ngIf="status.errors.required">
              Please select status
            </div>
          </div>
        </div>
        <div class="col-lg-12 form-group">
          <label for="">Notes</label>
          <textarea name="notes" rows="2" [(ngModel)]="serialdata.notes" class="form-control"></textarea>
        </div>
      </div>
    </form>

  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closemodal()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!f.valid" *ngIf="action!='view'"
      (click)="postSerial()">Save</button>
  </div>
</resize-border>