import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppServices } from 'src/app/shared/service/app.services';
import { CommonServices } from 'src/app/shared/service/common.service';
import { ExcelService } from 'src/app/shared/service/excel.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { reportServices } from '../../reports/reports.service';
import { ReportgenericprintComponent } from '../reportgenericprint/reportgenericprint.component';
// import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-ledgers',
  templateUrl: './ledgers.component.html',
  styleUrls: ['./ledgers.component.scss']
})
export class LedgersComponent implements OnInit {
  fullScreen = false;
  @Input('coa_id') coa_id;
  @Input('coa_name') coa_name;
  //#region userdata block
  appname = environment.APP_NAME;
  userdata: any;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  totalRows: any = 0;
  //#endregion userdata block

  ledgerList: any;
  public isShow: boolean = false
  dataXLSX: any;
  formula: string = 'Ledger';
  ledg: any = {
    fromdate: '',
    enddate: '',
    coa: '',
    refno: ''
  };
  dropdownSettings = {};
  dropdownList: any = [];
  public isCollapsed = false;

  closeResult: string;
  public config = {
    printMode: 'template-popup',
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    styles: ['#print-section {width:100%;display: block !important; page-break-after: always;}', '.TaskPrint {width:100%;}', 'body { width:100%;}', 'table {width:100%}']
  }
  dropdowntoggle() {
    this.isShow = !this.isShow;
  }


  glAccList: any = [];
  accountGroupingList: any = []
  constructor(
    private loader: NgxUiLoaderService,
    private excel: ExcelService,
    private modalService: NgbModal,
    private service: reportServices,
    private datepipe: DatePipe,
    private appService: AppServices,
    private commonservice: CommonServices,
    private activeModal: NgbActiveModal

  ) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.companyLogo += this.userdata.logo;
    this.settings.pager.perPage = Number(this.userdata.grid_page_size ? this.userdata.grid_page_size : 10);
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block

    //added for delete for header title by mayur 23/04/23
    var editDataForHeader = ''
    this.commonservice.headerEditItemData.next(editDataForHeader);

    this.ledg.fromdate = this.userdata.fromdate;
    this.ledg.todate = this.datepipe.transform(new Date(), 'yyyy-MM-dd')
    environment.production ? '' : console.log('from date', this.ledg.fromdate);
    environment.production ? '' : console.log('end date', this.ledg.todate);
    this.getglaccountlist();
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any) {
    throw new Error('Method not implemented.');
  }
  getglaccountlist() {
    this.appService.getGlAccounts().subscribe(res => {
      this.glAccList = res;
      this.dropdownList = [];
      this.glAccList.forEach(element => {
        this.dropdownList.push({
          "id": Number(element.Value),
          "itemName": element.Text,
          "category": element.Value1,
        })
      });
      this.accountGroupingList = Array.from(new Set(this.glAccList.map(({ Value1 }) => Value1)));
      environment.production ? '' : console.log('list', this.accountGroupingList);
      environment.production ? '' : console.log('glAccount', res)
    })
  }
  ngOnInit() {
    //#region Grid Settings
    if (this.userdata.grid_inline_filter === false) {
      this.settings.hideSubHeader = true;
      environment.production ? '' : console.log('settings.hideSubHeader :', this.settings.hideSubHeader);
    } else {
      this.settings.hideSubHeader = false;
      environment.production ? '' : console.log('settings.hideSubHeader :', this.settings.hideSubHeader);
    }
    this.settings.pager.perPage = this.userdata.grid_page_size;
    environment.production ? '' : console.log('settings.perPage :', this.settings.pager.perPage);
    //#region Grid Settings

    this.getBalanceSheet();
    this.dropdownSettings = {
      disabled: false,
      itemDisabled: false,
      singleSelection: true,
      text: "Select GL Account",
      groupBy: 'category',
      enableSearchFilter: true,
      searchPlaceholderText: 'Search GL Acount'
    };
  }

  onItemSelect(item: any) {
    environment.production ? '' : console.log(item);
    this.ledg.coa = item.id;
    environment.production ? '' : console.log(this.ledg.coa);
  }
  OnItemDeSelect(item: any) {
    environment.production ? '' : console.log(item);
    this.ledg.coa = '';
    environment.production ? '' : console.log(this.ledg.coa);
  }

  getBalanceSheet() {
    this.loader.start();

    this.service.getAccountDetails(
      this.coa_id,
      this.datepipe.transform(this.ledg.fromdate, this.userdata.postdateformat),
      this.datepipe.transform(this.ledg.todate, this.userdata.postdateformat)
    ).subscribe(res => {
      this.loader.stop();

      this.ledgerList = res;
      this.ledgerList.forEach(element => {
        element.tran_date = element.tran_date ? this.datepipe.transform(new Date(element.tran_date), this.userdata.postdateformat) : null
        if (element.dr != null && element.dr != 0) {
          element.dr = element.dr.toFixed(this.userdata.decimals_allowed);
        }
        else {
          element.dr = parseFloat("0").toFixed(this.userdata.decimals_allowed);
        }

        if (element.cr != null && element.cr != 0) {
          element.cr = element.cr.toFixed(this.userdata.decimals_allowed);
        }
        else {
          element.cr = parseFloat("0").toFixed(this.userdata.decimals_allowed);
        }

        if (element.running_total != null && element.running_total != 0) {
          element.running_total = element.running_total.toFixed(this.userdata.decimals_allowed);
        }
        else {
          element.running_total = parseFloat("0").toFixed(this.userdata.decimals_allowed);
        }
      });

      environment.production ? '' : console.log('ledgerList  data ', res);

      //#region Get total rows
      this.totalRows = this.ledgerList != null ? this.ledgerList.length : null;
      environment.production ? '' : console.log('Total Rows', this.totalRows);
      //#region Get total rows

    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading ledgerList  ', err);
    });
  }

  public settings = {
    pager: {
      display: true,
      perPage: 0
    },
    hideSubHeader: true,
    actions: false,
    columns: {
      // sort:true,
      tran_date: {
        title: 'Date',
        sort: true,
      },
      narration: {
        title: 'Description',
        sort: true,
        type: 'html',
      },
      refno: {
        title: 'Refno',
        sort: true,
      },
      dr: {
        title: 'Debit',
        sort: true,
      },
      cr: {
        title: 'Credit',
        sort: true,
      },
      running_total: {
        title: 'Running Total',
        sort: true,
      },
    },
  };

  exportAsXLSX(): void {
    this.dataXLSX = this.ledgerList;
    this.excel.exportAsExcelFile(this.dataXLSX, this.formula);
  }
  exportAsCSV() {
    var reportColumnList = [];
    for (var property in this.ledgerList[0]) {
      reportColumnList.push(property)
    }
    var data = this.ledgerList;
    var options = {
      title: this.formula,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      headers: reportColumnList
    };
    new AngularCsv(data, this.formula, options);
  }

  print() {
    var modalRef = this.modalService.open(ReportgenericprintComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.params = { fromdate: this.datepipe.transform(this.ledg.fromdate, this.userdata.postdateformat), todate: this.datepipe.transform(this.ledg.todate, this.userdata.postdateformat), coa: this.coa_id };
    modalRef.componentInstance.typename = 'details';
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
}
