import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedModule } from '../../shared/shared.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
// import {
//   GoogleLoginProvider,
//   FacebookLoginProvider,
//   MicrosoftLoginProvider
// } from '@abacritt/angularx-social-login';
const googleLoginOptions = {
  scope: 'profile email',
  plugin_name: 'login' //you can use any name here
};
@NgModule({
  declarations: [LoginComponent, ForgotPassComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    SharedModule,
    FormsModule,
    NgxUiLoaderModule,
    HttpClientModule, 
TranslateModule.forRoot({ 
 loader: { 
 provide: TranslateLoader, 
 useFactory: HttpLoaderFactory, 
 deps: [HttpClient] 
 } 
 }) 
 
    // SocialLoginModule
  ],
  providers: [
  //   {
  //   provide: 'SocialAuthServiceConfig',
  //   useValue: {
  //     autoLogin: false,
  //     providers: [
  //       {
  //         id: GoogleLoginProvider.PROVIDER_ID,
  //         provider: new GoogleLoginProvider(
  //           '39955721963-4ekdn3e1j3trj23p3j7rgqqb2ibi3s7t.apps.googleusercontent.com',
  //           googleLoginOptions
  //         )
  //       },
  //       {
  //         id: FacebookLoginProvider.PROVIDER_ID,
  //         provider: new FacebookLoginProvider('576996704420232')
  //       },
  //       {
  //         id: MicrosoftLoginProvider.PROVIDER_ID,
  //         provider: new MicrosoftLoginProvider('e2dcae27-39a4-4b29-a59e-720c2774a857', {
  //           redirect_uri: 'https://ezybooks.net/login',
  //           logout_redirect_uri: 'https://ezybooks.net/login'
  //         })
  //       }
  //     ],
  //     onError: (err) => {
  //       console.error(err);
  //     }
  //   } as SocialAuthServiceConfig,
  // }
]
})
export class AuthModule {

}
export function HttpLoaderFactory(http: HttpClient) { 
  return new TranslateHttpLoader(http); 
 } 