import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';
import { InventoryServices } from '../../../inventory.service';
import { AddProductComponent } from '../add-product.component';

@Component({
  selector: 'app-auditlogs',
  templateUrl: './auditlogs.component.html',
  styleUrls: ['./auditlogs.component.scss']
})
export class AuditlogsComponent implements OnInit {
  userdata: any;
  product: any;
  appname: any = environment.APP_NAME;
  auditLogs: any = []
  constructor(private maincomponent: AddProductComponent,
    private loader: NgxUiLoaderService,
    private commonservice: CommonServices,
    private service: InventoryServices) {
    // this.product = JSON.parse(localStorage.getItem(this.appname + 'editproduct'));
    this.product = this.maincomponent.product
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    this.getProductAuditLogs();
  }

  getProductAuditLogs() {
    this.loader.start();
    this.commonservice.getAuditLogs(this.product.id, 'product').subscribe(res => {
      this.loader.stop();
      this.auditLogs = res;
    }, err => {
      this.loader.stop();
    })
  }

  ngOnInit(): void {

  }

}
