<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Asset Assignment</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation" name="form" #f="ngForm">
        <h4 class="text-center">Action : {{lastaction}}</h4>
        <div class="form row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1 required">To location</label>
            <ng-autocomplete [data]="locationList" [initialValue]="editSetLocation"
              [searchKeyword]="keywordLocationSearch" placeholder="Select location" (selected)='selectLocation($event)'
              (inputChanged)='onAnySearchChange($event)' [itemTemplate]="locationTemplate"
              (inputCleared)="inputLocationCleared()" [notFoundTemplate]="notFoundTemplate"
              [(ngModel)]="location_validation" name="location" #Location="ngModel"
              [ngClass]="{ 'is-invalid': Location.invalid && Location.errors , 'is-valid': Location.valid}" required>
            </ng-autocomplete>
            <ng-template #locationTemplate let-item>
              <a [innerHTML]="item.Text"></a>
            </ng-template>
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
            <div *ngIf="Location.invalid && Location.errors" class="invalid-feedback d-block">
              <div *ngIf="Location.errors.required">
                Assign to is required
              </div>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12" *ngIf="lastaction=='Assign'">
            <label for="" class="mb-1 required">Assign-to</label>
            <select class="form-control" [(ngModel)]="asset.assignto" name="assignto" #assignto="ngModel"
              placeholder="Name"
              [ngClass]="{ 'is-invalid': assignto.invalid && assignto.errors , 'is-valid': assignto.valid}" required>
              <option value="">Select assign to</option>
              <option *ngFor="let item of assignToList" [value]="item.Value">{{item.Text}}</option>
            </select>
            <div *ngIf="assignto.invalid && assignto.errors" class="invalid-feedback d-block">
              <div *ngIf="assignto.errors.required">
                Assign to is required
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label for="">Contract</label>

            <ng-select placeholder="Search Contract type" [(ngModel)]="asset.contractid" name="rpm"
              [clearable]="asset.contractid">
              <ng-option *ngFor="let item of legalTypeList" [value]="item.Value">
                {{item.Text}}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12" *ngIf="lastaction=='Assign'">
            <label for="" class="mb-1">Due date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker8" placeholder="Due date" name="due date"
                [(ngModel)]="asset.duedate" (dateChange)="checkDate()">
              <mat-datepicker-toggle matSuffix [for]="picker8"></mat-datepicker-toggle>
              <mat-datepicker #picker8></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1 required">Condition</label>
            <select class="form-control" [(ngModel)]="asset.condition" name="condition" #condition="ngModel"
              [ngClass]="{ 'is-invalid': condition.invalid && condition.errors , 'is-valid': condition.valid}" required>
              <option value="">Select condition</option>
              <option *ngFor="let item of conditionList" [value]="item.stringValue">{{item.Text}}</option>
            </select>
            <div *ngIf="condition.invalid && condition.errors" class="invalid-feedback d-block">
              <div *ngIf="condition.errors.required">
                Condition is required
              </div>
            </div>
          </div>
          <div class="form-group col-lg-12 col-md-6 col-sm-12">
            <label for="" class="mb-1">Reason / Notes</label>
            <textarea name="notes" [(ngModel)]="asset.notes" class="form-control" rows="3"></textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary" (click)="postAssignment()" [disabled]="!f.valid">Save</button>
    </div>
  </div>
</resize-border>