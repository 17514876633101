import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SalesServices } from '../../../sales.service';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SerialComponent } from '../serial/serial.component';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
  selector: 'app-pickitem',
  templateUrl: './pickitem.component.html',
  styleUrls: ['./pickitem.component.scss']
})
export class PickitemComponent implements OnInit {
  @ViewChild('customerid') customerid;
  @ViewChild('prodid') prodid;
  @ViewChild('orderid') orderid;
  @ViewChild('tiid') tiid;
  @ViewChild('binLocation') binLocation;
  @ViewChild('params') params;
  binlocation: any;
  fieldsetDisabled: boolean = false;
  keyword2 = 'Text';
  closeResult: string;
  pickItemDetails: any = {
    "createdate": null,
    "companyid": null,
    "prodid": null,
    "tiid": null,
    "module": null,
    "username": null,
    "createby": null,
    "tobinlocation": '',
    "frombinlocation": '',
    "qty": 0,
    "deplete_qty": 0,
    "qtycount": 0,
    "damageqty": 0,
    "overageqty": 0,
    "action": null,
    "msg": null,
    "ttype": null,
    "refid": null,
    "serial": '',
    "ilc": null,
    "description": null,
    "isserilized": false,
    "customerid": null,
    "adjusttype": null,
    "qty2pick": 0,
    "warrantyexpiry": null,
  };
  isView: boolean;
  userdata: any;
  appname = environment.APP_NAME;
  duplicustomerid: any;
  dupliprodid: any;
  dupliorderid: any;
  pickItemDetailsfrombins: any;
  pickupOrder: any;
  constructor(
    private service: SalesServices,
    private datepipe: DatePipe,
    private loader: NgxUiLoaderService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private toastr: NotificationService
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));

  }

  getPickItem() {
    this.loader.start();
    this.service.getPickItem(this.pickupOrder.refid, this.pickupOrder.refno, this.pickupOrder.customerid, this.pickupOrder.prodid, this.pickupOrder.qty2pick, this.pickupOrder.module).subscribe(res => {
      this.loader.stop();
      this.pickItemDetails = res;
      this.pickItemDetailsfrombins = this.pickItemDetails.frombins;
      this.pickItemDetails.frombinlocation = '';
      if (this.pickItemDetails.qty2pick == 0) {
        this.closeModal();
      }
      if (this.pickItemDetails.isserilized == true) {
        this.isView = false;
        this.pickItemDetails.qtycount = 1
        this.binlocation ? this.selectEvent(this.binlocation) : null;
      } else {
        this.isView = true;
      }
      environment.production ? '' : console.log(' get pick item list', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading pick item list ', err);
    });
  }
  savePickItem() {
    var picObj = {
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdateformat),
      "companyid": this.userdata.companyid,
      "prodid": this.pickItemDetails.prodid,
      "module": this.pickItemDetails.module,
      "createby": this.userdata.email,
      "tobinlocation": this.pickItemDetails.tobinlocation,
      "frombinlocation": this.pickItemDetails.frombinlocation,
      "qty": this.pickItemDetails.qty,
      "qty2pick": this.pickItemDetails.qty2pick,
      "qtycount": this.pickItemDetails.qtycount,
      "msg": this.pickItemDetails.msg,
      "uom": this.pickItemDetails.uom,
      "ttype": this.pickItemDetails.ttype,
      "refid": this.pickItemDetails.refid,
      "refno": this.pickItemDetails.orderno,
      "serial": this.pickItemDetails.serial,
      "ilc": this.pickItemDetails.ilc,
      "description": this.pickItemDetails.description,
      "isserilized": this.pickItemDetails.isserilized,
      "customerid": this.pickItemDetails.customerid,
      "adjusttype": this.pickItemDetails.adjusttype,
      "warrantyexpiry": this.pickItemDetails.warrantyexpiry,
      "frombins": this.pickItemDetails.frombinlocation,
      "serials": this.pickItemDetails.serial
    }

    this.loader.start();
    this.service.postPickItem(picObj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          // this.modalService.dismissAll();
          this.pickupOrder.qty2pick = (this.pickupOrder.qty2pick ? Number(this.pickupOrder.qty2pick) : 0) - (this.pickItemDetails.qtycount ? Number(this.pickItemDetails.qtycount) : 0);
          this.getPickItem();
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post pick item list ', err);
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  selectBinLocation() {
    let obj = this.pickItemDetails.frombins.find(o => parseFloat(o.Value) === parseFloat(this.pickItemDetails.frombinlocation));
    this.pickItemDetails.qty = obj.Value2;
  }
  checkQty() {
    if (Number(this.pickItemDetails.qtycount) > this.pickItemDetails.qty) {
      this.toastr.Error('Entered qty should be less than instock qty.')
      this.pickItemDetails.qtycount = 0;
      return false;
    }
    if (Number(this.pickItemDetails.qtycount) > this.pickItemDetails.qty2pick) {
      this.toastr.Error('Entered qty should not be larger than qty to be pick.')
      this.pickItemDetails.qtycount = 0;
      return false;
    }
  }
  openserial() {
    var modalRef = null;
    this.isView = true;
    // this.fieldsetDisabled = true;
    modalRef = this.modalService.open(SerialComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
    modalRef.componentInstance.fromParent = this.pickItemDetails;

    environment.production ? '' : console.log('id', modalRef.componentInstance.id);
    // this.getSerial();
    modalRef.result.then((result) => {
      console.log(result);
      this.binlocation = null;
      this.getPickItem();
      this.closeResult = `Closed with: ${result}`;
      environment.production ? '' : console.log('closed');
    }, (reason) => {
    });
  }
  ngOnInit(): void {
    this.pickupOrder = this.params;
    this.getPickItem();
  }

  selectEvent(item) {
    this.pickItemDetails.frombinlocation = item.Value;
    let obj = this.pickItemDetails.frombins.find(o => parseFloat(o.Value) === parseFloat(this.pickItemDetails.frombinlocation));
    this.pickItemDetails.qty = obj.Value2;
    this.pickItemDetails.serial = '';
  }

  onFocused($event) {

  }

}
