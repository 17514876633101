import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
// import { LocalStorageService } from '../localStorage.service';
import { catchError, tap } from 'rxjs/operators';
import { HttpCacheService } from './http-cache.service';
import { environment } from 'src/environments/environment';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
        userprofile: any;
        appname=environment.APP_NAME;
        constructor(
                // private localstorageservice: LocalStorageService
                private cacheService: HttpCacheService
        ) { }

        handleError(error: HttpErrorResponse) {
                environment.production ? '' : console.log('Error Occurred');

                let message;
                if (error.status === 429) {
                        message = 'High Traffic, Try after some time.';
                } else if (error.status === 500) {
                        message = 'We can not process this request, Try again after some time.';
                } else if (error.status === 400) {
                        environment.production ? '' : console.log('error helper', error);
                        message = error.error.message;
                } else {
                        message = "Error occurred try again"
                }


                return throwError(message);
        }

        intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
                // this.userprofile = localStorage.getItem('userprofile');
                this.userprofile = localStorage.getItem(this.appname + 'userprofile');

               // environment.production ? '' : console.log('dattttaaaa', localStorage.getItem(this.appname + 'userprofile'));
                let token = null;
                if (this.userprofile != null) {
                        this.userprofile = JSON.parse(this.userprofile);
                        token = this.userprofile.token;
                }
                if (token == null) {
                        const authReq = req.clone({ setHeaders: { 'Content-Type': 'application/json' } });
                        return next.handle(authReq).pipe(
                                catchError(this.handleError)
                        );
                }
                const authHeader = 'Bearer ' + token;
                const authReq = req.clone({ setHeaders: { 'Content-Type': 'application/json', Authorization: authHeader } });
                return next.handle(authReq);
        }
}

