import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'supplierfilter',
  pure: false
})
export class SupplierfilterPipe implements PipeTransform {

 
  transform(items: any[], term): any {
    if (items?.length === 0 || term == '') {
      return items;
    }
    const data = [];
     for(const item of items) {
            if( item['name']?.toLowerCase().includes(term.toLowerCase()) 
            || item.contact?.toLowerCase().includes(term.toLowerCase()) 
            || item.email?.toLowerCase().includes(term.toLowerCase()) 
           ) {
                data.push(item);
            }
        }
        return data;
  }

}
