<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Authorization Required.</h5>
      <button type="button" class="close" aria-label="Close" (click)="closeModal('close')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation" name="form" #f="ngForm">
        <fieldset>
          <div class="form row">
            <div class="form-group col-sm-12">
              <label for="authorizedby" class="required">Authorized by</label>
              <ng-select placeholder="Select authorizedby" name="authorizedby" [(ngModel)]="req.authorizedby"
                [clearable]="req.authorizedby" #authorizedby="ngModel" required=""
                [ngClass]="{ 'is-invalid': authorizedby.invalid && authorizedby?.errors , 'is-valid': authorizedby.valid}">
                <ng-option *ngFor="let item of employeeList" [value]="item.Value1">
                  {{item.Text}}</ng-option>
              </ng-select>
              <div *ngIf="authorizedby.invalid && authorizedby.errors" class="invalid-feedback d-block">
                <div *ngIf="authorizedby.errors.required">Please enter authorizedby
                </div>
              </div>
            </div>
            <div class="form-group col-sm-12">
              <label for="notes">Notes</label>
              <textarea class="form-control" rows="5" debounce="0" #authorizationnotes="ngModel"
                name="authorizationnotes" id="authorizationnotes" [(ngModel)]="req.authorizationnotes"></textarea>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
        (click)="closeModal('close')">Close</button>
      <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="!f.valid">Okay</button>
    </div>
  </div>
</resize-border>