<resize-border [dragHolder]="header">
    <div #header class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">{{ 'Modal_Title' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="closemodal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [style.pointer-events]="isView?'none':''">
        <form class="needs-validation" name="from" #f="ngForm">
            <div class="form row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">{{ 'Modal_Inputs.BinLocation_Label' | translate }}</label>
                    <ng-select required="" name="bintype" [(ngModel)]="binData.binid" [readonly]="setReadonly" #binid="ngModel"
                        [ngClass]="{ 'is-invalid': binid.invalid && binid.errors , 'is-valid': binid.valid}" required
                        placeholder="{{ 'Modal_Inputs.BinLocation_Placeholder' | translate }}" [clearable]="binData.binid">
                        <ng-option *ngFor="let item of binList" [value]="item.Value">
                            {{item.Text}}</ng-option>
                    </ng-select>
                    <div *ngIf="binid.invalid && binid.errors" class="invalid-feedback d-block">
                        <div *ngIf="binid.errors.required">
                            {{ 'Modal_Inputs.BinLocation_Required' | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">{{ 'Modal_Inputs.OnHand_Label' | translate }}</label>
                    <input class="form-control" type="number" name="cnt" readonly [(ngModel)]="binData.cnt">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">{{ 'Modal_Inputs.HoldCount_Label' | translate }}</label>
                    <input class="form-control" type="number" name="onhold" readonly [(ngModel)]="binData.onhold">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">{{ 'Modal_Inputs.SoldCount_Label' | translate }}</label>
                    <input class="form-control" type="number" name="sold" readonly [(ngModel)]="binData.sold"
                        readonly>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">{{ 'Modal_Inputs.AvailableCount_Label' | translate }}</label>
                    <input class="form-control" type="number" name="availableqty" readonly
                        [(ngModel)]="binData.availableqty">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">{{ 'Modal_Inputs.MinQty_Label' | translate }}</label>
                    <input class="form-control" type="number" name="minqty" [(ngModel)]="binData.minqty">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">{{ 'Modal_Inputs.MaxQty_Label' | translate }}</label>
                    <input class="form-control" type="number" name="maxqty" [(ngModel)]="binData.maxqty">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">{{ 'Modal_Inputs.ReorderPoint_Label' | translate }}</label>
                    <input class="form-control" type="number" name="reorderpoint" [(ngModel)]="binData.reorderpoint">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">{{ 'Modal_Inputs.ReorderQty_Label' | translate }}</label>
                    <input class="form-control" type="number" name="reorderqty" [(ngModel)]="binData.reorderqty">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">{{ 'Modal_Inputs.Notes_Label' | translate }}</label>
                    <textarea class="form-control" rows="1" name="notes" [(ngModel)]="binData.notes">
                  </textarea>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="Active1" class="mb-1">{{ 'Modal_Inputs.Active_Label' | translate }}</label>
                    <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                        <label class="d-block" for="Active1">
                            <input class="radio_animated" id="active" type="radio" [value]=true
                                [(ngModel)]="binData.active" name="active">
                                {{ 'Modal_Inputs.Active_Yes' | translate }}
                        </label>
                        <label class="d-block" for="Active2">
                            <input class="radio_animated" id="active1" [value]=false [(ngModel)]="binData.active"
                                name="active" type="radio">
                                {{ 'Modal_Inputs.Active_No' | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
            (click)="closemodal()">{{ 'Modal_Buttons.Close_Button' | translate }}</button>
        <button type="button" class="btn btn-primary" [disabled]="!f.valid || isView" (click)="addBin()">{{ 'Modal_Buttons.Save_Button' | translate }}</button>
    </div>
</resize-border>