
<div class="padding-div">
  <div class="form-row">
    <div class=" col-lg-12 col-md-12 form-group">
      <i class="fa fa-search hover-icon"></i><input #SearchElement style="padding-left:20px;" type="text" class="form-control" placeholder="Search by name, email" [(ngModel)]="searchtxt" (input)="getPartyList()">
    </div>
  </div>
  <div class="category-table custom-datatable mt-1">
  <div class="table-responsive">
    <table class="custom-table">
        <tr class="custom-tr">
          <th class="custom-th text-center">ID</th>
          <th class="custom-th text-center">Name</th>
          <th class="custom-th text-center">Email</th>
        </tr>
        <tr class="custom-tr" *ngFor="let item of customerList">
          <td class="custom-td text-center"><span class="hyperlink" (click)="selectCustomer(item)" ngbDropdownItem>{{item.Value}}</span>
          </td>
          <td class="custom-td text-left"><span class="hyperlink" (click)="selectCustomer(item)" ngbDropdownItem>{{item.Text}}</span>
          </td>
          <td class="custom-td text-left"><span class="hyperlink" (click)="selectCustomer(item)" ngbDropdownItem>{{item.Value1}}</span>
          </td>
        </tr>
    </table>
    <div class="no-results" *ngIf="customerList.length == 0">Your search result yielded no results</div>
  </div>
  </div>
</div>
