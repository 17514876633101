<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h4 class="modal-title" *ngIf="showtitle">{{modalTitle}}</h4>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <div>
        <button type="button" class="close" aria-label="Close" (click)="closeModal('closed')">
          <span aria-hidden="true">&times;</span>
        </button>
        <button type="button" class="close"></button>
      </div>
    </div>
    <div class="modal-body">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Add Description" aria-label="Search"
          [(ngModel)]="master.description" aria-describedby="basic-addon2" name="description">
        &nbsp;
        <select class="form-control" *ngIf="type=='devicetypes'" name="marker_type_id"
          [(ngModel)]="master.marker_type_id">
          <option value="">Select Marker</option>
          <option *ngFor="let marker of markerTypes" [value]="marker.Value">{{marker.Text}}</option>
        </select>
        <input type="text" *ngIf="isCodeFieldShow" class="form-control" placeholder="Code" aria-label="Search"
          [(ngModel)]="master.code" aria-describedby="basic-addon2" name="code">
        <div class="input-group-append">
          <span class="input-group-text btn-primary cursor-pointer" (click)="validatePostValues()"
            id="basic-addon2">Save</span>
        </div>
      </div>
      <div class="category-table custom-datatable mb-3">
        <div class="table-responsive2">
          <table class="custom-table">
            <thead>
              <tr class="custom-tr">
                <th class="custom-th text-center">{{ 'Modal_Table.Number_Column' | translate }}</th>
                <th class="custom-th text-center">{{ 'Modal_Table.Description_Column' | translate }}</th>
                <th class="custom-th text-center" *ngIf="isCodeFieldShow">{{ 'Modal_Table.Code_Column' | translate }}</th>
                <th class="custom-th text-center">{{ 'Modal_Table.Active_Column' | translate }}</th>
                <th class="custom-th text-center">{{ 'Modal_Table.Action_Column' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="custom-tr" *ngFor="let item of itemList">
                <td class="custom-td text-center">{{item.id}}</td>
                <td class="custom-td">{{item.description}}</td>
                <td class="custom-td text-center" *ngIf="isCodeFieldShow">{{item.code}}</td>
                <td class="custom-td text-center"><span *ngIf="item.active">Yes</span><span
                    *ngIf="!item.active">No</span>
                </td>
                <td class="custom-td text-center">
                  <div class="modalActionBtn">
                    <i class="eb-edit mr-1" (click)="editMasterData(item)"></i>
                    <i class="eb-delete" (click)="deleteMasterData(item)"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="closeModal('closed')">{{ 'Modal_Buttons.Close_Button_Label' | translate }}</button>
      <button type="button" class="btn btn-outline-primary" (click)="initializeValues()">{{ 'Modal_Buttons.Refresh_Button_Label' | translate }}</button>
    </div>
  </div>
</resize-border>