import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { accountServices } from '../../accounts/account.service';
import { GenericprintComponent } from '../genericprint/genericprint.component';

@Component({
  selector: 'app-apstatement',
  templateUrl: './apstatement.component.html',
  styleUrls: ['./apstatement.component.scss']
})
export class ApstatementComponent implements OnInit {
  //#region userdata block
  fullScreen = false;
  appname = environment.APP_NAME;
  userdata: any;
  format: any;
  //#endregion userdata block
  apstatement: any = {
    type: '0',
    template: '1'
  }
  apStMentList: any = []
  @Input('id') id;
  constructor(private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private datepipe: DatePipe,
    private loader: NgxUiLoaderService,
    private service: accountServices,
    private toastr: NotificationService) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.apstatement.fromdate = this.datepipe.transform(this.userdata.fromdate, this.userdata.postdateformat);
    this.apstatement.todate = this.datepipe.transform(this.userdata.enddate, this.userdata.postdateformat);

    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block
  }
  saveAPStatement() {
    //Service not available

    this.apstatement.companyid = this.userdata.companyid;
    this.apstatement.supplierid = [this.id]
    this.apstatement.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    this.apstatement.createby = this.userdata.email;
    this.apstatement.companyname = this.userdata.companyname;
    this.apstatement.fromdate = this.datepipe.transform(this.apstatement.fromdate, this.userdata.postdateformat);
    this.apstatement.todate = this.datepipe.transform(this.apstatement.todate, this.userdata.postdateformat);
    this.apstatement.sendinemail = false;
    this.apstatement.printonly = false;
    environment.production ? '' : console.log(this.apstatement);

    this.loader.start();
    this.service.postAPstatement(this.apstatement).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
      }
      this.apstatement = {}
      this.apStMentList = res.data;
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading Post AR statements', err);
    });
  }

  apstatementprint() {
    var modalRef = null
    modalRef = this.modalService.open(GenericprintComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.typename = "apstatement";
    modalRef.componentInstance.type = this.apstatement.type;
    modalRef.componentInstance.fromdate = this.datepipe.transform(this.apstatement.fromdate, this.userdata.date_format);
    modalRef.componentInstance.todate = this.datepipe.transform(this.apstatement.todate, this.userdata.date_format);
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  ngOnInit(): void {
  }
  download(element) {
    var printURL = environment.DOC_URL + '/' + this.userdata.companyid + '/' + element.doc_name
    window.open(printURL, '_blank');
    // this.closeModal();
  }

}
