import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppServices } from 'src/app/shared/service/app.services';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NavService } from 'src/app/shared/service/nav.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SalesServices } from '../../Sales/sales.service';
import { CreditComponent } from '../credit/credit.component';
@Component({
  selector: 'app-singlereceivepayment',
  templateUrl: './singlereceivepayment.component.html',
  styleUrls: ['./singlereceivepayment.component.scss']
})
export class SinglereceivepaymentComponent implements OnInit {
  fullScreen = false;
  @Input('params') params;
  payment: any = {
    amt: 0,
    looking: '',
    notify: '0',
    leave_credit: false,
    trandate: new Date(),
    mop: '',
    change: 0,
    payment_type: 'normal',
    bankid: '',
    cheque_details: '',
    number: '',
    cheque_date: new Date(),
    currency: '',
    roe: 0,
    creditavail: 0,
    tendered_amt: 0,
    fx_loss_gain: 0
  }
  baseTotal: any = 0;
  paymentMethodList: any = [];
  paymentTypeList: any = [];
  appname = environment.APP_NAME;
  isNumberShow = false;
  isBankAccShow = false;
  selectedMOP: any;
  userdata: any;
  duepayments: any = [];
  receivePayment: any;
  currencyList: any = [];
  isShowAvailableCredit = true;
  customer: any = {
    creditAvail: 0.0
  }
  companyBanks: any = [];
  paying_amt: any = 0;
  constructor(private activeModal: NgbActiveModal, private service: SalesServices, private toaster: ToastrService,
    private toastr: NotificationService,
    private datePipe: DatePipe, private appService: AppServices, private datepipe: DatePipe, private cd: ChangeDetectorRef, private router: Router, private modalService: NgbModal, private commoanService: CommonServices, private loader: NgxUiLoaderService, private navservice: NavService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.commoanService.getPaymentMethods().subscribe(res => {
      this.paymentMethodList = res;
      environment.production ? '' : console.log('payment method data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading payment method data', err);
    });
    this.commoanService.getComboEnums('paymenttype').subscribe(res => {
      this.paymentTypeList = res;
      environment.production ? '' : console.log('payment types data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading payment types data', err);
    });
    this.appService.getCurrency().subscribe(res => {
      this.currencyList = res;
      environment.production ? '' : console.log(' currency list data ', this.currencyList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading Currency list ', err);
    });
  }

  ngOnInit(): void {
    this.payment.tendered_amt = this.params.pay;
    this.paying_amt = this.params.pay;
    this.baseTotal = (this.params.pay ? this.params.pay : 0) * (this.params.roe ? this.params.roe : 0);
    this.baseTotal = this.baseTotal ? Number(this.baseTotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.getCustomerCredit();
  }
  getPaymentAccounts() {
    var type = '';
    this.paymentMethodList.forEach(item => {
      if (item.Value == this.payment.mop) {
        type = item.Value1 ? item.Value1 : '';
      }
    })
    this.commoanService.getCompBankList(type).subscribe(res => {
      this.companyBanks = res;
    }, err => {
    });
  }
  getCustomerCredit() {
    this.loader.start();
    this.commoanService.getCustomerCreditAvailable(this.params.customerid).subscribe(res => {
      this.loader.stop();
      this.customer = res;
      this.customer.availbalance = this.customer.availbalance ? Number(this.customer.availbalance).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    }, err => {
      this.loader.stop();
    })
  }


  selectROE() {
    this.currencyList.forEach(element => {
      if (this.payment.currency == element.Value) {
        this.payment.roe = Number(element.Value2).toFixed(this.userdata.decimals_allowed) ? Number(element.Value2).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      }
      else if (!this.payment.currency) {
        this.payment.roe = '';
      }
      this.updatePayAmt();
    })
  }

  applyCredit() {
    var modalRef = this.modalService.open(CreditComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg', windowClass: 'index-class', backdropClass: 'index-backdrop' });
    modalRef.componentInstance.customerid = this.params.customerid;
    modalRef.result.then((result) => {
    }).catch((error) => {
    });
  }

  selectPaymentType() {
    this.isNumberShow = false;
    this.paymentMethodList.forEach(element => {
      if (element.Value == this.payment.mop) {
        this.selectedMOP = element;
        if (element.Value1 == 'cheque') {
          this.isNumberShow = true;
          this.isBankAccShow = false;
        } else if (element.Value1 == 'online' || element.Value1 == 'banktransfer') {
          this.getPaymentAccounts();
          this.isNumberShow = false;
          this.isBankAccShow = true;
        } else {
          this.isNumberShow = false;
          this.isBankAccShow = false;
        }
      }
    });
  }
  updatePayAmt() {
    if (this.payment.tendered_amt != undefined || this.payment.tendered_amt != null || this.payment.tendered_amt != '') {
      if (Number(this.payment.tendered_amt) > Number(this.params.pay)) {
        this.payment.change = Number(this.payment.tendered_amt) - Number(this.params.pay);
        this.payment.change = this.payment.change.toFixed(this.userdata.decimals_allowed);
      } else {
        this.payment.change = 0;
      }
      Number(this.payment.tendered_amt).toFixed(this.userdata.decimals_allowed);
      Number(this.payment.change).toFixed(this.userdata.decimals_allowed);
    } else {
      this.payment.tendered_amt = Number(0).toFixed(this.userdata.decimals_allowed);
    }
    this.paying_amt = (Number(this.payment.tendered_amt) - Number(this.payment.change)).toFixed(this.userdata.decimals_allowed);
    var baseTotal = Number(this.params.bal ? this.params.bal : 0) * Number(this.params.roe ? this.params.roe : 0);

    this.payment.receivedbaseamount = ((this.paying_amt ? Number(this.paying_amt) : 0) * Number(this.payment.roe ? this.payment.roe : 0));
    this.payment.receivedbaseamount = this.payment.receivedbaseamount ? Number(this.payment.receivedbaseamount).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);

    this.payment.fx_loss_gain = ((this.paying_amt ? Number(this.paying_amt) : 0) * Number(this.params.roe ? this.params.roe : 0)) - Number(this.payment.receivedbaseamount);
    this.payment.fx_loss_gain = this.payment.fx_loss_gain ? Number(this.payment.fx_loss_gain).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
  }
  addPayment() {
    var dueList = [];
    if (!this.payment.mop) {
      this.toastr.Error("Please select method of payment");
      return;
    }
    if (this.selectedMOP.Value1 == 'cheque' && this.payment.number == '') {
      this.toastr.Error("Please enter cheque#");
      return;
    }
    if (this.selectedMOP.Value1 == 'online' && this.payment.bankid == '') {
      this.toastr.Error("Please select bank");
      return;
    }
    if (this.payment.tendered_amt == undefined || this.payment.tendered_amt == null || this.payment.tendered_amt <= 0) {
      this.toastr.Error("Please enter amount");
      return;
    }
    if (!this.payment.currency) {
      this.toastr.Error('Please select currency');
      return;
    }
    if (this.payment.memo == undefined || this.payment.memo == null || this.payment.memo == '') {
      this.toastr.Error("Please enter memo");
      return;
    }
    dueList.push({
      "companyname": this.params.companyname,
      "status": this.params.status,
      "refno": this.params.refno,
      "projref": this.params.projref,
      "currency": this.params.currency,
      "bal": this.params.pay,
      "pay": (this.payment.tendered_amt ? this.payment.tendered_amt : 0) > Number(this.params.pay) ? Number(this.params.pay) : (this.payment.tendered_amt ? this.payment.tendered_amt : 0),
      "refid": this.params.refid,
      "ttype": this.params.ttype,
      "invdate": this.params.invdate
    })

    var obj = {
      "notes": this.payment.notes,
      "memo": this.payment.memo,
      "change": Number(this.payment.change),
      "amt": this.payment.change > 0 ? Number(this.params.pay) : Number(this.payment.tendered_amt),
      "availbalance": Number(this.params.availbalance),
      "partyid": this.params.customerid,
      "party_type": 'customer',
      "party_name": this.params.companyname,
      "companyid": this.userdata.companyid,
      "notify": this.payment.notify,
      "currency": this.params.currency,
      "mop": Number(this.payment.mop),
      "leave_credit": this.payment.leave_credit,
      "trandate": this.datepipe.transform(new Date(this.payment.trandate), this.userdata.postdatetimeformat),
      "cheque_date": this.payment.cheque_date ? this.datepipe.transform(new Date(this.payment.cheque_date), this.userdata.postdatetimeformat) : null,
      "createby": this.userdata.email,
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "payment_type": this.payment.payment_type,
      "duepayments": dueList,
      "number": this.payment.number,
      "cheque_details": this.payment.cheque_details,
      "fx_loss_gain": this.payment.fx_loss_gain ? Number(this.payment.fx_loss_gain) : 0,
      "roe": this.payment.roe,
      "bankid": this.payment.bankid,
      "tendered_amt": Number(this.payment.tendered_amt)
    }
    environment.production ? '' : console.log('payment data', obj);
    this.loader.start();
    this.service.postPayment(obj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading device ', err);
    });
  }
  closeModal() {
    this.activeModal.close();
  }
  getCurrentROE() {
    if (!this.payment.currency) {
      this.toaster.error('Please select currency');
      return;
    }
    this.loader.start();
    this.commoanService.getExchangeRate(this.userdata.currency, this.payment.currency).subscribe(res => {
      this.loader.stop();
      this.payment.roe = res ? Number(res).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    }, err => {
      this.loader.stop();
    });
  }

}
