import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { SalesServices } from '../../Sales/sales.service';
import { PickitemComponent } from '../../Sales/salesorders/modal/pickitem/pickitem.component';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-pickuporder',
	templateUrl: './pickuporder.component.html',
	styleUrls: ['./pickuporder.component.scss']
})
export class PickuporderComponent implements OnInit {
	fullScreen = false;
	@ViewChild('id') id;

	//#region userdata block
	appname = environment.APP_NAME;
	userdata: any;
	companyLogo: any = 'http://ezybooks.net/docs/logos/';
	format: any;
	totalRows: any = 0;
	//#endregion userdata block
	buttonclicked = false;
	porder: any = {
		status: '',
		notes: ''
	};
	pickupOrder: any = {
		lstItem: [{
			"id": null,
			"prodid": null,
			"description": null,
			"ilc": null,
			"availableqty": 0,
			"receivedqty": 0,
			"qtyonorder": 0
		}]
	};
	itemBins: any[];
	isView: boolean;
	deliveryStatusList: any = [];
	orderid: any;
	pickupOrderItems: any = [];
	constructor(
		private toaster: NotificationService,
		private datePipe: DatePipe,
		private modalService: NgbModal,
		private service: SalesServices,
		private commonService: CommonServices,
		private toster: ToastrService,
		private activeModal: NgbActiveModal, private router: Router, private loader: NgxUiLoaderService,) {
		//#region userdata block
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.format = this.userdata.date_format
		this.companyLogo += this.userdata.logo;
		MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
		//#endregion userdata block
		this.commonService.getComboEnums('deliverystatus').subscribe(res => {
			this.deliveryStatusList = res;
			environment.production ? '' : console.log(' get deliveryStatus list', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading deliveryStatus list ', err);
		});
	}
	toggleClick(item) {
		this.itemBins = item.itemBins;
	}
	getPickupOrder(id) {
		this.loader.start();
		this.service.getPickupOrder(id).subscribe(res => {
			this.loader.stop();
			this.pickupOrder = res;
			environment.production ? '' : console.log(' get pickup order list', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading pickup order list ', err);
		});
	}
	updateOrderStatus() {
		if (this.porder.status == '' || this.porder.status == undefined) {
			this.toster.error('Please select status');
			return;
		}
		if (this.porder.notes == '' || this.porder.notes == undefined) {
			this.toster.error('Please enter notes');
			return;
		}
		this.loader.start();
		this.service.getUpdatePickupOrder(this.orderid, this.porder.status, this.porder.notes).subscribe(res => {
			this.loader.stop();
			this.toster.success('Order status updated!');
			this.modalService.dismissAll();
		}, err => {
			this.loader.stop();
		});
	}
	openPickItem(item) {
		var modalRef = null;
		this.isView = true;
		modalRef = this.modalService.open(PickitemComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
		modalRef.componentInstance.params = { refid: this.pickupOrder.orderid, refno: this.pickupOrder.orderno, customerid: this.pickupOrder.customerid, prodid: item.prodid, qty2pick: (item.qtyonorder - item.receivedqty), module: '' };
		modalRef.componentInstance.tiid = this.id;
		modalRef.componentInstance.customerid = this.pickupOrder.customerid;
		modalRef.componentInstance.prodid = item.prodid;
		modalRef.componentInstance.orderid = this.pickupOrder.orderid;

		modalRef.result.then((result) => {
			// this.closeResult = `Closed with: ${result}`;
			this.getPickupOrder(this.orderid);
			environment.production ? '' : console.log('closed');
		}, (reason) => {
			this.getPickupOrder(this.orderid);
		});
	}

	ngOnInit(): void {
		this.orderid = this.id;
		this.getPickupOrder(this.orderid)
	}

	closeModal() {
		this.activeModal.close('Modal Closed');
	}


}
