import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomepageRoutingModule } from './homepage-routing.module';
import { HomepageComponent } from './homepage.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateAccountComponent } from './createaccount/createaccount.component';
import { ChooseIndustryComponent } from './chooseindustry/chooseindustry.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerifyaccountComponent } from './verifyaccount/verifyaccount.component';
import { AccountdetailsComponent } from './accountdetails/accountdetails.component';
import { SystemmigrationComponent } from './systemmigration/systemmigration.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FeaturesComponent } from './features/features.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HomepageComponent, CreateAccountComponent, ChooseIndustryComponent, VerifyaccountComponent, AccountdetailsComponent, SystemmigrationComponent, FeaturesComponent],
  imports: [
    CommonModule,
    HomepageRoutingModule,
    CarouselModule,
    NgbModule,
    FormsModule,
    NgxUiLoaderModule,
    ReactiveFormsModule,
    HttpClientModule, 
TranslateModule.forRoot({ 
 loader: { 
 provide: TranslateLoader, 
 useFactory: HttpLoaderFactory, 
 deps: [HttpClient] 
 } 
 }) 
  ],
  providers: [
    NgbActiveModal,
    HomepageComponent
  ]
})
export class HomepageModule { }
export function HttpLoaderFactory(http: HttpClient) { 
  return new TranslateHttpLoader(http); 
 } 