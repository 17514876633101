<div class="custom-row">
  <div class="form-row">
    <div class="col-lg-3 col-md-6 form-group"> </div>
    <div class="col-lg-3 col-md-6 form-group">
      <label for="">{{ 'Form_Inputs.PO_Label' | translate }}</label>
      <input type="search" class="form-control" [(ngModel)]="filter.searchtxt" name="pon" placeholder="{{ 'Form_Inputs.PO_Placeholder' | translate }}">
    </div>
    <div class="col-lg-3 col-md-6 form-group pl-0">
      <label for="fromdate">{{ 'Form_Inputs.FromDate_Label' | translate }}</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker460" placeholder="{{ 'Form_Inputs.FromDate_Placeholder' | translate }}" name="fromdate"
          [(ngModel)]="filter.fromdate">
        <mat-datepicker-toggle matSuffix [for]="picker460"></mat-datepicker-toggle>
        <mat-datepicker #picker460></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-6 form-group pl-0">
      <label for="fromdate">{{ 'Form_Inputs.ToDate_Label' | translate }}</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker461" placeholder="{{ 'Form_Inputs.ToDate_Placeholder' | translate }}" name="todate"
          [(ngModel)]="filter.todate">
        <mat-datepicker-toggle matSuffix [for]="picker461"></mat-datepicker-toggle>
        <mat-datepicker #picker461></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="btn-group CsearchBtn" role="group">
    <button type="button" class="btn btn-primary" (click)="getProductOrders()">{{ 'Form_Inputs.Search_Button' | translate }}</button>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center" (click)="onSortClick($event,'ilc')">{{ 'Table_Columns.ILC_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'description')">{{ 'Table_Columns.Description_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'pon')">{{ 'Table_Columns.PON_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'podate')">{{ 'Table_Columns.PODate_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'potype')">{{ 'Table_Columns.Type_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'potitle')">{{ 'Table_Columns.POTitle_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'supplier')">{{ 'Table_Columns.Supplier_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'qty')">{{ 'Table_Columns.QtyOrdered_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'cost')">{{ 'Table_Columns.POCost_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'shippedqty')">{{ 'Table_Columns.QtyShipped_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'receivedqty')">{{ 'Table_Columns.QtyReceived_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'damageqty')">{{ 'Table_Columns.QtyDamage_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'overageqty')">{{ 'Table_Columns.QtyOverage_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'status')">{{ 'Table_Columns.Status_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'duedate')">{{ 'Table_Columns.DueDate_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'createby')">{{ 'Table_Columns.CreateBy_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'createdate')">{{ 'Table_Columns.CreateDate_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'updateby')">{{ 'Table_Columns.UpdateBy_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'updatedate')"> {{ 'Table_Columns.UpdateDate_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i> </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of historyList">
            <td class="custom-td text-left">{{item.ilc}}</td>
            <td class="custom-td text-left">{{item.description}}</td>
            <td class="custom-td text-left">{{item.pon}}</td>
            <td class="custom-td text-left text-nowrap">{{item.podate | date: userdata.date_format}}</td>
            <td class="custom-td text-left">{{item.potype}}</td>
            <td class="custom-td text-left">{{item.potitle}}</td>
            <td class="custom-td text-left">{{item.supplier}}</td>
            <td class="custom-td text-center">{{item.qty}}</td>
            <td class="custom-td text-right">{{item.cost}}</td>
            <td class="custom-td text-center">{{item.shippedqty}}</td>
            <td class="custom-td text-center">{{item.receivedqty}}</td>
            <td class="custom-td text-center">{{item.damageqty}}</td>
            <td class="custom-td text-center">{{item.overageqty}}</td>
            <td class="custom-td text-center"><span class="badge" title="{{item.status}}">{{item.status}}</span></td>
            <td class="custom-td text-left text-nowrap">{{item.duedate | date: userdata.date_format}}</td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.createdate | date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.updatedate | date:userdata.displaydatetimeformat}}</td>
          </tr>
          <tr class="custom-tr">
            <td class="custom-td text-right" colspan="7"><strong>Total</strong> </td>
            <td class="custom-td text-center"><strong>{{totalQty}}</strong></td>
            <td class="custom-td text-right"><strong>{{totalPOCost}}</strong></td>
            <td class="custom-td text-center"><strong>{{totalShippedQty}}</strong></td>
            <td class="custom-td text-center"><strong>{{totalReceivedqty}}</strong></td>
            <td class="custom-td text-center"><strong>{{totalDamageqty}}</strong></td>
            <td class="custom-td text-center"><strong>{{totalOverageqty}}</strong></td>
            <td class="custom-td text-right" colspan="5"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>