<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">{{ 'Modal_Title' | translate }}
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">{{ 'Modal_Inputs.ContactName_Label' | translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="compose.to_name" name="title" placeholder="{{ 'Modal_Inputs.ContactName_Placeholder' | translate }}">
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 form-group">
          <label>{{ 'Modal_Inputs.DefaultEmailTemplate_Label' | translate }}</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="defaulttemplate">
              <input class="radio_animated" [value]=true id="default_template" type="radio" name="default_template"
                [(ngModel)]="compose.default_template">
                {{ 'Modal_Inputs.DefaultEmailTemplate_Yes' | translate }}
            </label>
            <label class="d-block" for="defaulttemplate1">
              <input class="radio_animated" [value]=false id="default_template" type="radio" name="default_template"
                [(ngModel)]="compose.default_template">
                {{ 'Modal_Inputs.DefaultEmailTemplate_No' | translate }}
            </label>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 form-group" *ngIf="!compose.default_template">
          <label>{{ 'Modal_Inputs.EmailTemplate_Label' | translate }}</label>
          <select class="form-control" name="template_code" [(ngModel)]="compose.template_code">
            <option value="">{{ 'Modal_Inputs.EmailTemplate_Placeholder' | translate }}</option>
            <option *ngFor="let item of templateList" [value]="item.Value">{{item.Text}}</option>
          </select>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">{{ 'Modal_Inputs.EmailTo_Label' | translate }}</label>
          <textarea class="form-control" [(ngModel)]="compose.to" rows="2"></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">{{ 'Modal_Inputs.CC_Label' | translate }}</label>
          <textarea class="form-control" [(ngModel)]="compose.cc" rows="2"></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">{{ 'Modal_Inputs.BCC_Label' | translate }}</label>
          <textarea class="form-control" [(ngModel)]="compose.bcc" rows="2"></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">{{ 'Modal_Inputs.Subject_Label' | translate }}</label>
          <textarea class="form-control" [(ngModel)]="compose.subject" rows="2"></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">{{ 'Modal_Inputs.Message_Label' | translate }}</label>
          <textarea class="form-control" [(ngModel)]="compose.msg" rows="2"></textarea>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">{{ 'Modal_Inputs.Attachments_Label' | translate }}</label>
          <input class="form-control" name="attchemnt" type="file" ng2FileSelect [uploader]="uploader"
            (click)="cancelAttachment()" (change)="selectedFileOnChanged($event)" multiple
            accept="application/pdf, image/gif, image/jpeg, image/png" />
          <span *ngIf="multipleFiles.length>0">{{multipleFiles.length}} file attached</span>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 form-group">
          <label>{{ 'Modal_Inputs.UseTemplate_Label' | translate }}</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="usetemplate">
              <input class="radio_animated" [value]=true id="use_template" type="radio" name="use_template"
                [(ngModel)]="compose.use_template">
                {{ 'Modal_Inputs.UseTemplate_Yes' | translate }}
            </label>
            <label class="d-block" for="usetemplate1">
              <input class="radio_animated" [value]=false id="use_template" type="radio" name="use_template"
                [(ngModel)]="compose.use_template">
                {{ 'Modal_Inputs.UseTemplate_No' | translate }}
            </label>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 form-group">
          <label>{{ 'Modal_Inputs.DefaultEmailSender_Label' | translate }}</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="defaultsender">
              <input class="radio_animated" [value]=true id="default_sender" type="radio" name="default_sender"
                [(ngModel)]="compose.default_sender">
                {{ 'Modal_Inputs.DefaultEmailSender_Yes' | translate }}
            </label>
            <label class="d-block" for="defaultsender1">
              <input class="radio_animated" [value]=false id="default_sender" type="radio" name="default_sender"
                [(ngModel)]="compose.default_sender">
                {{ 'Modal_Inputs.DefaultEmailSender_No' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">{{ 'Modal_Buttons.Close_Button' | translate }}</button>
      <button class="btn btn-primary" type="button" (click)="checkUploadFiles()">{{ 'Modal_Buttons.Send_Button' | translate }}</button>
    </div>
  </div>
</resize-border>