<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Bill Payment</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-lg-3 col-md-3 form-group">
          <label for="">Invoice: <b>{{params.refno}}</b></label>
        </div>
        <div class="col-lg-3 col-md-3 form-group">
          <label for="">Supplier: <b>{{params.companyname}}</b></label>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <div class="available-credit" *ngIf="isShowAvailableCredit" [class.red]="supplier.availbalance <0"
            [class.green]="supplier.availbalance >0" (click)="applyCredit()">
            <div clas="hover-value">Available Credit: <span *ngIf="supplier">{{supplier.availbalance}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 form-group">
          <label for="">Total Due: <b>{{params.amt}} {{params.currency}}</b></label>
        </div>
        <div class="col-lg-3 col-md-3 form-group">
          <label for="">Base Total: <b>{{baseTotal}} {{userdata.currency}}</b></label>
        </div>
        <div class="col-lg-3 col-md-3 form-group">
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Date of Payment </label>
          <mat-form-field class="bg-white">
            <input matInput [matDatepicker]="picker26" [(ngModel)]="payBillData.trandate" placeholder="Choose a date"
              name="trandate">
            <mat-datepicker-toggle matSuffix [for]="picker26"></mat-datepicker-toggle>
            <mat-datepicker #picker26></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Payment Method </label>
            <div class="input-group mb-3">
              <select class="form-control" required="" name="mop" [(ngModel)]="payBillData.mop"
                (change)="getPaymentAccounts()" #paymentmethod="ngModel"
                [ngClass]="{ 'is-invalid': paymentmethod.invalid && paymentmethod.errors , 'is-valid': paymentmethod.valid}">
                <option value="">Select Payment Method</option>
                <option *ngFor="let item of paymentMethods" [value]="item.Value">{{item.Text}}
                </option>
              </select>
              <div *ngIf="paymentmethod.invalid && paymentmethod.errors" class="invalid-feedback d-block">
                <div *ngIf="paymentmethod.errors.required">Payment Method is
                required</div>
            </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Cash/Bank</label>
            <div class="input-group mb-3">
              <select class="form-control" required="" name="bankid" [(ngModel)]="payBillData.bankid"
                (ngModelChange)="selectCheckBooks()"#cashbank="ngModel"
                [ngClass]="{ 'is-invalid': cashbank.invalid && cashbank.errors , 'is-valid': cashbank.valid}">
                <option value="">Select Cash/Bank</option>
                <option *ngFor="let item of companyBanks" [value]="item.Value">{{item.Text}}
                </option>
              </select>
              <div *ngIf="cashbank.invalid && cashbank.errors" class="invalid-feedback d-block">
                <div *ngIf="cashbank.errors.required">Cash Bank is
                required</div>
            </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="isShowChequeFields">
          <div class="form-group">
            <label>Check Book</label>
            <select class="form-control" required="" name="checkbookid" [(ngModel)]="payBillData.checkbookid"
              (change)="getCheckBookNos()" #checkbook="ngModel"
              [ngClass]="{ 'is-invalid': checkbook.invalid && checkbook.errors , 'is-valid': checkbook.valid}">
              <option value="">Select Checkbook</option>
              <option *ngFor="let item of checkbookList" [value]="item.Value">{{item.Text}}
              </option>
            </select>
            <div *ngIf="checkbook.invalid && checkbook.errors" class="invalid-feedback d-block">
              <div *ngIf="checkbook.errors.required">Please select checkbook</div>
          </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 form-group" *ngIf="isShowChequeFields">
          <label for="">Number eg. Cheque</label>
          <input type="text" class="form-control" readonly [(ngModel)]="payBillData.number" placeholder="Cheque#">
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <div class="form-group">
            <label>Currency </label>
            <div class="input-group mb-3">
              <select class="form-control" id="currency" required="" (change)="selectROE()" name="currency"
                [(ngModel)]="payBillData.currency" #currencyname="ngModel"
                [ngClass]="{ 'is-invalid': currencyname.invalid && currencyname.errors , 'is-valid': currencyname.valid}">
                <option value="">Select currency</option>
                <option *ngFor="let item of currencyList" [value]="item.Value">
                  {{item.Text}}
                </option>
              </select>
              <div *ngIf="currencyname.invalid && currencyname.errors" class="invalid-feedback d-block">
                <div *ngIf="currencyname.errors.required">Currency is
                required</div>
            </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">XR</label>
          <div class="input-group">
          <input type="text" class="form-control text-right" placeholder="0" name="ROE" [(ngModel)]="payBillData.roe"
          #roe="ngModel" required=""  #exchnagerate="ngModel" (change)="updatePayAmt()"
          [ngClass]="{ 'is-invalid': exchnagerate.invalid && exchnagerate.errors , 'is-valid': exchnagerate.valid}">
          <div class="input-group-append">
            <span class="input-group-text btn-primary cursor-pointer" id="basic-addon2"
              (click)="getCurrentROE()"><i class="fa fa-refresh"></i>
            </span>
      </div>
        </div>
      </div>

        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Amount Paid</label>
          <input type="number" class="form-control" placeholder="Amount" name="amt" (change)="updatePayAmt()"
            [(ngModel)]="payBillData.tendered_amt" required=" " #amount="ngModel"
            [ngClass]="{ 'is-invalid': amount.invalid && amount.errors , 'is-valid': amount.valid}">
            <div *ngIf="amount.invalid && amount.errors" class="invalid-feedback d-block">
              <div *ngIf="amount.errors.required">Amount is
              required</div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Change/Overpayment</label>
          <input type="text" class="form-control" placeholder="Change/Overpayment" [(ngModel)]="payBillData.change"
            readonly>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Amount Paid ({{userdata.currency}})</label>
          <input type="number" readonly class="form-control" name="paying_amt" value="{{payBillData.receivedbaseamount}}">
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">FX Loss/Gain</label>
          <input type="number" class="form-control" name="amt" [(ngModel)]="payBillData.fx_loss_gain"
            (change)="updatePayAmt()">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Change on Account?</label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="showMultiple">
                <input class="radio_animated" [value]=true [(ngModel)]="payBillData.leave_debit" id="changeonaccount"
                  type="radio" name="rdo-changeonaccount">
                Yes
              </label>
              <label class="d-block" for="showMultiple1">
                <input class="radio_animated" [value]=false [(ngModel)]="payBillData.leave_debit" id="changeonaccount1"
                  type="radio" name="rdo-changeonaccount1">
                No
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Memo</label>
          <input type="text" class="form-control" required="" placeholder="Memo" name="memo" [(ngModel)]="payBillData.memo"
          #memo="ngModel"
            [ngClass]="{ 'is-invalid': memo.invalid && memo.errors , 'is-valid': memo.valid}">
            <div *ngIf="memo.invalid && memo.errors" class="invalid-feedback d-block">
              <div *ngIf="memo.errors.required">Memo is
              required</div>
          </div>
        </div>
        <div class="col-lg-12 form-group">
          <label for="">Notes </label>
          <textarea class="form-control" placeholder="Notes" name="notes" [(ngModel)]="payBillData.notes" rows="5">
            </textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary" (click)="billPayment()">Save</button>
    </div>
  </div>
</resize-border>