<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="productModalLabel">Item Analysis
            </h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <h5>Search by orders</h5>
            <div class="form-row">
                <div class="col-lg-10 col-md-8">
                    <input type="search" class="form-control" placeholder="Enter by orders" name="order"
                        [(ngModel)]="order">
                </div>
                <div class="col-lg-2 col-md-4">
                    <div class="btn btn-primary" (click)="getItemAnlysisList()">Show</div>
                </div>
            </div>
            <div class="category-table custom-datatable mt-3">
                <div class="table-responsive">
                    <table class="custom-table">
                        <tr class="custom-tr">
                            <th class="custom-th text-center">TranID</th>
                            <th class="custom-th text-center">Serial</th>
                            <th class="custom-th text-center">Last Location</th>
                            <th class="custom-th text-center">Qty</th>
                            <th class="custom-th text-center">New Location</th>
                            <th class="custom-th text-center">Qty</th>
                            <th class="custom-th text-center">Type</th>
                            <th class="custom-th text-center">Customer</th>
                            <th class="custom-th text-center">Receipt</th>
                            <th class="custom-th text-center">Txn Date</th>
                            <th class="custom-th text-center">Txn By</th>
                        </tr>
                        <tr class="custom-tr" *ngFor="let item of codeList">
                            <td class="custom-td text-left">{{item.tranid}}</td>
                            <td class="custom-td text-left">{{item.serial}}</td>
                            <td class="custom-td text-left">{{item.lastlocation}}</td>
                            <td class="custom-td text-left">{{item.qty}}</td>
                            <td class="custom-td text-left">{{item.newlocation}}</td>
                            <td class="custom-td text-left">{{item.qty}}</td>
                            <td class="custom-td text-left">{{item.TranType}}</td>
                            <td class="custom-td text-left">{{item.customer}}</td>
                            <td class="custom-td text-left">{{item.receipt}}</td>
                            <td class="custom-td text-left">{{item.dot | date: userdata.date_format_}}</td>
                            <td class="custom-td text-left">{{item.tranby}}</td>

                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
            <!-- <button class="btn btn-primary" type="button">Save</button> -->
        </div>
    </div>
</resize-border>