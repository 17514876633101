import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryServices } from 'src/app/components/inventory/inventory.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AddProductComponent } from '../add-product.component';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-lottracking',
	templateUrl: './lottracking.component.html',
	styleUrls: ['./lottracking.component.scss']
})
export class LottrackingComponent implements OnInit {
	LotTrackList: any = [];
	product: any = {};
	lotData: any = {}
	binList: any = []
	userdata: any;
	appname = environment.APP_NAME;

	constructor(private modalService: NgbModal,
		private activeModal: NgbActiveModal,
		private datepipe: DatePipe,
		private toastr: ToastrService,
		private route: ActivatedRoute,
		private maincomponent: AddProductComponent,
		private loader: NgxUiLoaderService,
		private service: InventoryServices) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));

		this.product = maincomponent.product;

		this.service.getBinLocationList().subscribe(res => {
			this.binList = res;
			environment.production ? '' : console.log(' bin list data ', this.binList);
		}, err => {
			environment.production ? '' : console.log('Error : While loading bin list ', err);
		});

	}
	ngOnInit(): void {
		this.getTrackingLot();

	}

	getTrackingLot() {
		this.loader.start();
		this.service.getLotTrackingData(this.product.id).subscribe(res => {
			this.loader.stop();
			this.LotTrackList = res
			this.LotTrackList.forEach(item => {
				item.sold = item.sold ? item.sold : 0;
				if (item.expirydate) {
					var dte = item.expirydate.split('T');
					item.expirydate = dte[0];
				}

			})
			console.log(res);
		})
	}




	//#region Sorting Table Header
	sortDir = 1;//1= 'ASE' -1= DSC
	onSortClick(event,colum) {
		let target = event.currentTarget,
			childtarget = target.querySelector('i'),
			classList = childtarget.classList;

		if (classList.contains('fa-chevron-up')) {
			classList.remove('fa-chevron-up');
			classList.add('fa-chevron-down');
			target.classList.add('Sorted');
			this.sortDir = -1;
		} else {
			classList.add('fa-chevron-up');
			classList.remove('fa-chevron-down');
			target.classList.remove('Sorted');
			this.sortDir = 1;
		}
		this.sortArr(colum);
	}

	sortArr(colName: any) {
		this.LotTrackList.sort((a, b) => {
			let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
			let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

	if (!valueA && !valueB) {
		return 0; 
	}
	if (!valueA) {
		return -this.sortDir; 
	}
	if (!valueB) {
		return this.sortDir; 
	}

	if (valueA > valueB) {
		return this.sortDir;
	}
	if (valueA < valueB) {
		return -this.sortDir;
	}
	return 0;
});
	}

	//#endregion Sorting Table Header
	closeModal() {
		this.modalService.dismissAll();
	}
	goToEdit(item, content) {
		this.loader.start();
		this.service.getLotDetailsById(item.id).subscribe(res => {
			this.loader.stop();
			this.lotData = res
			this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
				// this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				// this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
			console.log(res);
		})
	}
	postLot() {
		this.lotData.companyid = this.userdata.companyid;
		this.lotData.createby = this.lotData.createby;
		this.lotData.createdate = this.datepipe.transform(this.lotData.createdate, this.userdata.postdatetimeformat);
		this.lotData.updateby = this.userdata.email;
		this.lotData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);

		this.lotData.active = true;

		environment.production ? '' : console.log('post review data', this.lotData);
		this.loader.start();
		this.service.postLotDetails(this.lotData).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.success(res.message);

				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.error(res.message);
					return;
				}
				this.getTrackingLot()
				this.closeModal();
			}
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading post product ', err);
		});

	}
	postProductReview() {

	}

}
