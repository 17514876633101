<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="exampleModalLabel">Advance/Loan Application# {{loanapp.loanappno}}</h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="needs-validation" name="form" #f="ngForm">
                <div class="form row">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label class="required">Application Date</label>
                            <mat-form-field
                                [ngClass]="{ 'is-invalid': applicationdate.invalid && applicationdate.errors , 'is-valid': applicationdate.valid}"
                                [class.disabled]="fieldsetDisabled">
                                <input matInput [matDatepicker]="picker95" placeholder="Application date"
                                    [(ngModel)]="loanapp.applicationdate" #applicationdate="ngModel"
                                    name="applicationdate" required [disabled]="fieldsetDisabled">
                                <mat-datepicker-toggle matSuffix [for]="picker95">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker95></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="applicationdate.invalid && applicationdate.errors"
                                class="invalid-feedback d-block">
                                <div *ngIf="applicationdate.errors.required">
                                    Date is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label class="required" for="category">Loan Category</label>
                            <select class="form-control" name="category" [(ngModel)]="loanapp.category" required=""
                                #category="ngModel"
                                [ngClass]="{ 'is-invalid': category.invalid && category.errors , 'is-valid': category.valid}" (change)="getLoanType()">
                                <option value="">Select loan category</option>
                                <option *ngFor="let n of loanCatList" [value]="n.stringValue">{{n.Text}}
                                </option>
                            </select>
                            <div *ngIf="category.invalid && category.errors" class="invalid-feedback d-block">
                                <div *ngIf="category.errors.required">
                                    Please select loan type
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <label for="">Loan Type</label>
                        <select class="form-control" id="loantype" name="loantype" [(ngModel)]="loanapp.loantype"
                            #loantype="ngModel">
                            <option value="">Select Loan Type</option>
                            <option *ngFor="let item of loanTypes" [value]="item.Value">
                                {{item.Text}}</option>
                        </select>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="isShowFields">
                        <label for="">Party Type</label>
                        <select class="form-control" name="party_type" [(ngModel)]="loanapp.party_type"
                            (change)="selectPartyType()">
                            <option value="">Select Party Type</option>
                            <option *ngFor="let item of partyTypeList" [value]="item.stringValue">{{item.Text}}</option>
                        </select>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="loanapp.party_type=='other'">
                        <div class="form-group">
                            <label for="" class="">Party Name</label>
                            <input class="form-control" placeholder="Enter Party Name" id="" type="text" name="partnm"
                                [(ngModel)]="loanapp.party_name">
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="loanapp.party_type !=='other' && isShowFields">
                        <label for="" class="required">Party Name</label>
                        <div class="input-group" ngbDropdown>
                            <input class="form-control" type="text" name="party_name" readonly
                                [(ngModel)]="loanapp.party_name" placeholder="Party name" #partnme="ngModel"
                                [disabled]="fieldsetDisabled"
                                [ngClass]="{ 'is-invalid': partnme.invalid , 'is-valid': partnme.valid}"
                                ngbDropdownToggle>
                            <i class="fa fa-chevron-down right-hover" ngbDropdownToggle></i>
                            <ul ngbDropdownMenu class="main-dropdown ActionCell-List customer-dropdown"
                                aria-labelledby="dropdownBasic1">
                                <app-partysmodal (click)="selectedParty()" (keyup)="selectedParty()"></app-partysmodal>
                            </ul>
                        </div>
                        <div *ngIf="partnme.invalid && partnme.errors" class="invalid-feedback d-block">
                            <div *ngIf="partnme.errors.required">
                                Please select party name
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="isShowFields">
                        <div class="form-group">
                            <label class="required">Currency</label>
                            <select class="form-control" id="currency" name="currency" [(ngModel)]="loanapp.currency"
                                #currency="ngModel"
                                [ngClass]="{ 'is-invalid': currency.invalid && currency.errors , 'is-valid': currency.valid}">
                                <option value="">Select currency</option>
                                <option *ngFor="let item of currencyList" [value]="item.Value">
                                    {{item.Text}}
                                </option>
                            </select>
                            <div *ngIf="currency.invalid && currency.errors" class="invalid-feedback d-block">
                                <div *ngIf="currency.errors.required">
                                    Please select currency
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <label for="">Requested Amt</label>
                        <input type="number" class="form-control" name="requested_amt"
                            [(ngModel)]="loanapp.requested_amt" #requested_amt="ngModel"
                            [ngClass]="{ 'is-invalid': requested_amt.invalid && requested_amt.errors , 'is-valid': requested_amt.valid}"
                            required>
                        <div *ngIf="requested_amt.invalid && requested_amt.errors" class="invalid-feedback d-block">
                            <div *ngIf="requested_amt.errors.required">
                                Requested amt is required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label class="required">Expected Date</label>
                            <mat-form-field
                                [ngClass]="{ 'is-invalid': expected_date.invalid && expected_date.errors , 'is-valid': expected_date.valid}"
                                [class.disabled]="fieldsetDisabled">
                                <input matInput [matDatepicker]="picker97" placeholder="Expected date"
                                    [(ngModel)]="loanapp.expected_date" #expected_date="ngModel" name="expected_date"
                                    required [disabled]="fieldsetDisabled">
                                <mat-datepicker-toggle matSuffix [for]="picker97">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker97></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="expected_date.invalid && expected_date.errors" class="invalid-feedback d-block">
                                <div *ngIf="expected_date.errors.required">
                                    Expected date is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <label for="">Priority</label>
                        <select class="form-control" name="priority" [(ngModel)]="loanapp.priority">
                            <option *ngFor="let item of priorityList" [value]="item.stringValue">
                                {{item.Text}}</option>
                        </select>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="isShowFields">
                        <label for="">Approved Amt</label>
                        <input type="number" class="form-control" [readonly]="fieldsetDisabled" name="approved_amt"
                            [(ngModel)]="loanapp.approved_amt" #approved_amt="ngModel">
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="isShowFields">
                        <label for="">Approved By</label>
                        <input type="text" class="form-control" placeholder="Approved by" name="approveby"
                            [(ngModel)]="loanapp.approveby" #approveby="ngModel">
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="isShowFields">
                        <div class="form-group">
                            <label for="">Approved Date</label>
                            <mat-form-field [class.disabled]="fieldsetDisabled">
                                <input matInput [matDatepicker]="picker96" placeholder="Approved date"
                                    [(ngModel)]="loanapp.approvedate" #approvedate="ngModel" name="approvedate"
                                    [disabled]="fieldsetDisabled">
                                <mat-datepicker-toggle matSuffix [for]="picker96">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker96></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="status">Status</label>
                            <select class="form-control" id="status" name="status" [(ngModel)]="loanapp.status"
                                #status="ngModel">
                                <option value="">Select Status</option>
                                <option *ngFor="let item of loanAppStatus" [value]="item.stringValue">
                                    {{item.Text}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="">Attachments</label>
                            <input class="form-control" type="file" multiple ng2FileSelect [uploader]="uploader"
                                (click)="cancelAttachment()" (change)="selectedFileOnChanged($event)"
                                accept="application/pdf, image/gif, image/jpeg, image/png" />
                            <span *ngIf="t_loan_app_documents.length>0">{{t_loan_app_documents.length}} file
                                attached</span>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Uploaded Docs</label>
                            <br>
                            <span *ngFor="let item of uploadedFiles" (click)="openFile(item)">
                                <a class="small text-muted mb-0 text-right mr-2" target="_blank" [href]="item.file_url">
                                    {{item.file_name}}</a>
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
            <button type="button" class="btn btn-primary" (click)="saveloanapp()" [disabled]="!f.valid">Save</button>
        </div>
    </div>
</resize-border>