<div class="resizable" [class.onDrag]="this.onDrag" [ngStyle]="style">
    <ng-content></ng-content>
    <div class="cell-border-top"></div>
    <div class="cell-border-bottom"></div>
    <div class="cell-border-left"></div>
    <div class="cell-border-right"></div>
    <div class="cell-top-right"></div>
    <div class="cell-bottom-right"></div>
    <div class="cell-top-left"></div>
    <div class="cell-bottom-left"></div>
  </div>