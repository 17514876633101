import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { CommonServices } from 'src/app/shared/service/common.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shippingport',
  templateUrl: './shippingport.component.html',
  styleUrls: ['./shippingport.component.scss']
})
export class ShippingportComponent implements OnInit {
  fullScreen = false;
  @Input('shippingport') shippingport;
  @Input('dischargeport') dischargeport;

  userdata: any;
  appname = environment.APP_NAME;
  // printURL: any;
  itemList: any;
  modalTitle: any;
  master: any = {
    id: null,
    code: '',
    name: '',
    seaport: '',
    airport: '',
    country: '',
    createby: null,
    createdate: null,
    updateby: null,
    updatedate: null,
    active: true
  }
  showtitle: boolean;
  countryList:any = [];
  private langChangeSubscription: Subscription; 
  constructor(private cdRef: ChangeDetectorRef, 
    private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient, 
    private toastr: NotificationService,
    private commonService: CommonServices,
    private activeModal: NgbActiveModal, 
    private loader: NgxUiLoaderService, 
    private datepipe: DatePipe, 
    private service: CommonServices,) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.getCountries();
  }


  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  getCountries() {
		this.commonService.getCountries().subscribe(res => {
			this.countryList = res;
			environment.production ? '' : console.log('country list data ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading country list data', err);
		});
	}
  ngOnInit(): void {
    if (this.shippingport == 'shippingport') {
      this.modalTitle = 'Shipping Port';
      this.getShippingPortIndexValues();
    }
    else if (this.dischargeport == 'dischargeport') {
      this.modalTitle = 'Discharge Port';
      this.getShippingPortIndexValues();
    }
    // console.log('log for title', this.term);

    this.showtitle = true;
    this.cdRef.detectChanges();
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
  }


  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/purchase/customsdeclaration/basic/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 






  initializeValues() {
    this.master = {
      id: null,
      code: '',
      name: '',
      seaport: '',
      airport: '',
      country: '',
      createby: null,
      createdate: null,
      updateby: null,
      active: true
    };
  }
  editMasterData(item) {
    this.master = item;
  }
  deleteMasterData(item) {
    if (this.shippingport == 'shippingport') {
      this.deleteShippingPort(item);
    }
    else if (this.dischargeport == 'dischargeport') {
      this.deleteShippingPort(item);
    }
  }
  validatePostValues() {
    if (this.master.name == undefined || this.master.name == '') {
      Swal.fire('', 'Please enter description', 'warning');
      return;
    }
    if (this.master.id == undefined || this.master.id == null) {
      this.master.companyid = this.userdata.companyid;
      this.master.createby = this.userdata.email;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.master.updateby = this.userdata.email;
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    if (this.shippingport == 'shippingport') {
      this.postShippingPort();
    }
    else if (this.dischargeport == 'dischargeport') {
      this.postShippingPort();
    }
  }

  // ***** shipping port begin ***********
  getShippingPortIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getShippingPortIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postShippingPort() {
    this.loader.start();
    this.service.postShippingPort(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.itemList=res.data;
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteShippingPort(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteShippingPort(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getShippingPortIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete shipping port');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** shipping port end ***********


}
