<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Pickup Order
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <label for="">Delivery Status</label>
          <select class="form-control" name="status" id="" [(ngModel)]="porder.status">
            <option value="">Select delivery status</option>
            <option *ngFor="let item of deliveryStatusList" [value]="item.stringValue">{{item.Text}}</option>
          </select>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <label for="">Notes</label>
          <input name="notes" type="text" class="form-control" [(ngModel)]="porder.notes">
        </div>
      </div>
      <div class="category-table custom-datatable">
        <div class="table-responsive">
          <table class="custom-table">
            <tr class="custom-tr">
              <th class="custom-th text-center">ILC</th>
              <th class="custom-th text-center">Description</th>
              <th class="custom-th text-center">UOM</th>
              <th class="custom-th text-center">OnHand</th>
              <th class="custom-th text-center">OrderQty</th>
              <th class="custom-th text-center">TotalQty2bPicked</th>
              <th class="custom-th text-center">Action</th>
            </tr>
            <ng-container *ngFor="let item of pickupOrder.lstItems;let j=index">
              <tr class="custom-tr">
                <td class="custom-td text-center">
                  <i class="fa fa-chevron-circle-down float-left" aria-hidden="true" *ngIf="!item.buttonclicked"
                    (click)="item.buttonclicked = !item.buttonclicked; toggleClick(item)"></i>
                  <i class="fa fa-chevron-circle-up float-left" aria-hidden="true" *ngIf="item.buttonclicked"
                    (click)="item.buttonclicked = !item.buttonclicked; toggleClick(item)"></i>
                  {{item.ilc}}
                </td>
                <td class="custom-td text-center">{{item.description}}</td>
                <td class="custom-td text-center">{{item.uom}}</td>
                <td class="custom-td text-center">{{item.qtyonhand}}</td>
                <td class="custom-td text-center">{{item.qtyonorder}}</td>
                <td class="custom-td text-center">{{item.qtyonorder - item.receivedqty}}</td>
                <td class="custom-td text-center">
                  <div class="ActionBtn d-flex justify-content-center" [style.pointer-events]="(item.qtyonorder - item.receivedqty) > 0 ? '' : 'none'">
                    <a class="hoverBtn">
                      <i class="fa fa-cog" container="body" ngbTooltip="Pick Item" (click)="openPickItem(item)"></i>
                    </a>
                  </div>
                </td>
                
              </tr>
              <tr class="custom-tr" *ngIf="item.buttonclicked">
                <td class="custom-td text-left" colspan="9">
                  <table class="custom-table">
                    <tr class="custom-tr">
                      <th class="custom-th text-center">
                        Bin Code
                      </th>
                      <th class="custom-th text-center">
                        Bin Name
                      </th>
                      <th class="custom-th text-center">
                        Qty
                      </th>
                    </tr>
                    <tr class="custom-tr" *ngFor="let bin of itemBins">
                      <td class="custom-td text-center">
                        {{bin.code}}
                      </td>
                      <td class="custom-td text-center">
                        {{bin.name}}
                      </td>
                      <td class="custom-td text-center">
                        {{bin.cnt}}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

            </ng-container>

          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary" (click)="updateOrderStatus()">Update Status</button>
    </div>
  </div>
</resize-border>