<resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Pick Item</h5>
    <div>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form class="needs-validation" name="form" #f="ngForm">
      <fieldset>
        <div class="row mb-3">
          <div class="col-lg-12 col-md-12 form-group">
            <label for="" class="strong">ILC : <span *ngIf="pickItemDetails.isserilized">Serialized</span></label>
            <input type="text" class="form-control" name="ilc" [(ngModel)]="pickItemDetails.ilc">
          </div>
          <div class="col-lg-12 col-md-12 form-group">
            <label for="">Description</label>
            <input type="text" class="form-control" name="description" [(ngModel)]="pickItemDetails.description">
          </div>
          <div class="col-lg-12 col-md-12 form-group">
            <label for="">UOM</label>
            <input type="text" class="form-control" name="uom" [(ngModel)]="pickItemDetails.uom" readonly>
          </div>
          <div class="col-lg-12 col-md-12 form-group">
            <label for="">Qty to be pick</label>
            <input type="text" class="form-control" name="qty2pick" [(ngModel)]="pickItemDetails.qty2pick" readonly>
          </div>
          <div class="col-lg-12 col-md-12 form-group">
            <label for="">From Bin Location</label>
            <!-- <select class="form-control" [(ngModel)]="pickItemDetails.frombinlocation" name="frombinlocation"
                (change)="selectBinLocation()" #frombinlocation="ngModel"
                [ngClass]="{ 'is-invalid': frombinlocation.invalid && frombinlocation.errors , 'is-valid': frombinlocation.valid}"
                required>
                <option value="">Select Bin Location</option>
                <option value="{{item.Value}}" *ngFor="let item of pickItemDetails.frombins">{{item.Text}}</option>
              </select> -->

            <ng-autocomplete #binLocation="ngModel" [data]="pickItemDetailsfrombins" [searchKeyword]="keyword2"
              placeholder="Search bin location" (selected)='selectEvent($event)' (inputFocused)='onFocused($event)'
              [itemTemplate]="itemsTemplate" [notFoundTemplate]="notFoundTemplate" [(ngModel)]="binlocation"
              [ngModelOptions]="{standalone: true}"
              [ngClass]="{ 'is-invalid': binLocation.invalid && binLocation.errors , 'is-valid': binLocation.valid}"
              required>
            </ng-autocomplete>

            <ng-template #itemsTemplate let-item>
              <a [innerHTML]="item.Text"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>

            <div *ngIf="binLocation.invalid && binLocation.errors" class="invalid-feedback d-block">
              <div *ngIf="binLocation.errors.required">
                Please select location
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 form-group">
            <label for="">In Stock Qty</label>
            <input type="number" class="form-control" name="qty" [(ngModel)]="pickItemDetails.qty" readonly>
          </div>
          <div class="col-lg-6 col-md-12 form-group">
            <label for="">Pickup Qty</label>
            <input type="text" class="form-control" name="qtycount" [disabled]="pickItemDetails.isserilized"
              [(ngModel)]="pickItemDetails.qtycount" (focusout)="checkQty()">

          </div>
          <div class="col-lg-12 col-md-12 form-group" *ngIf="pickItemDetails.isserilized">
            <label class="required">Serial - Provide serial for serilized products only</label>
            <div class="input-group">
              <ng-select #serial="ngModel" [(ngModel)]="pickItemDetails.serial" name="serial" placeholder="Search Serial" [ngClass]="{ 'is-invalid': serial.invalid && serial.errors , 'is-valid': serial.valid}" required [clearable]="pickItemDetails.serial">
                <ng-container *ngFor="let item of pickItemDetails.serials">
                  <ng-option value="{{item.Value}}" *ngIf="item.Value2==pickItemDetails.frombinlocation">{{item.Text}}
                  </ng-option>
                </ng-container>
              </ng-select>
              <!-- <input class="form-control"> -->
              <div class="input-group-append" [disabled]="pickItemDetails.isserilized">
                <span class="input-group-text btn-primary cursor-pointer" id="basic-addon2" (click)="openserial()">Add
                  Serial</span>
              </div>
            </div>
            <div *ngIf="serial.invalid && serial.errors" class="invalid-feedback d-block">
              <div *ngIf="serial.errors.required">
                Please select serial
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 form-group">
            <label for="">Warranty Expiry</label>

            <mat-form-field [class.disabled]="fieldsetDisabled">
              <input matInput [matDatepicker]="picker1006" [(ngModel)]="pickItemDetails.warrantyexpiry"
                #warrantyexpiry="ngModel" placeholder="Choose a date" name="warrantyexpiry"
                [disabled]="fieldsetDisabled">
              <mat-datepicker-toggle matSuffix [for]="picker1006">
              </mat-datepicker-toggle>
              <mat-datepicker #picker1006></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-12 col-md-12 form-group">
            <label for="">Reason/Notes</label>
            <input type="text" class="form-control" name="msg" [(ngModel)]="pickItemDetails.msg">
          </div>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>

    <button type="button" class="btn btn-primary" [disabled]="!f.valid || pickItemDetails.qtycount==0"
      (click)="savePickItem()">Save</button>
  </div>
</resize-border>