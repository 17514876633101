<resize-border [dragHolder]="header" class="modal-resize">
<div #header class="modal-header">
  <h5 class="modal-title f-w-600" id="exampleModalLabel">Hold QTY</h5>
  <button type="button" class="close" aria-label="Close" (click)="closemodal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
      <a>ILC</a> :<span *ngIf="itemData.isserilized" style="color:red"> Serilized</span>
      <input type="text" class="form-control" placeholder="ilc" name="ilc" [(ngModel)]="itemData.ilc" readonly>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
      <a>Description</a>
      <input type="text" class="form-control" placeholder="Description" name="description"
        [(ngModel)]="itemData.description" readonly>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
      <a>From bin location</a>
      <ng-select placeholder="Search Bin Location" required="" name="frombinlocation" [(ngModel)]="itemData.frombinlocation"
        (ngModelChange)="selectOwnBinLocation($event)" [clearable]="itemData.frombinlocation"  appendTo=".modal-resize">
        <ng-option *ngFor="let item of ownItemProdBinList" [value]="item.Value">
          {{item.Text}}</ng-option>
      </ng-select>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
      <a>Total</a>
      <input type="text" class="form-control" placeholder="0" name="total" [(ngModel)]="itemData.qty" readonly>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
      <a>Hold Qty</a>
      <input type="text" class="form-control" placeholder="0" name="holdqty" [(ngModel)]="itemData.holdqty"
        (focusout)="checkValidation(itemData)" [disabled]="itemData.isserilized">
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 form-group" *ngIf="itemData.isserilized">
      <a>Serial- Provide serial for serilized item only </a>
      <div class="input-group mb-2 SearchBox">
        <ng-select placeholder="Search Serial" required="" name="to bin location" [(ngModel)]="itemData.serial" [clearable]="itemData.serial"  appendTo=".modal-resize">
          <!-- <option value="">Select Serial</option> -->
          <ng-option *ngFor="let item of serialList" [value]="item.Value">
            {{item.Text}}</ng-option>
        </ng-select>
        <div class="input-group-append">
          <span class="input-group-text btn-primary cursor-pointer" (click)="open()">
            Add Serial
          </span>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
      <a class="required">Customer </a>
      <ng-autocomplete #Customer [data]="customerList" [searchKeyword]="keyword" placeholder="Select Customer"
        (selected)='selectCustomer($event)' [itemTemplate]="itemTemplate2" (inputFocused)='onFocusedCustomer($event)'
        name="customerid" [(ngModel)]="customerid_validation">
      </ng-autocomplete>

      <ng-template #itemTemplate2 let-item>
        <a [innerHTML]="item.Text"></a>
      </ng-template>
      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
      <a>Reason/Notes</a>
      <input type="text" class="form-control" placeholder="Reason/Notes" name="msg" [(ngModel)]="itemData.msg">
    </div>

  </div>
</div>
<div class="modal-footer justify-content-center">
  <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closemodal()">Close</button>
  <button type="button" class="btn btn-primary" (click)="postHoldQty()">Save</button>
</div>
</resize-border>
<ngx-ui-loader></ngx-ui-loader>
