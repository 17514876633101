<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">{{'Modal_Title' | translate}}</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation" name="form" #f="ngForm">
        <div class="form row">
          <div class="form-group col-md-6 col-sm-12">
            <label for="">{{'Modal_Inputs.Name_Label' | translate}}</label>
            <input type="text" class="form-control" name="name" [(ngModel)]="contact.name" #name="ngModel"
              [ngClass]="{ 'is-invalid': name.invalid && name.errors , 'is-valid': name.valid}" required>
            <div *ngIf="name.invalid && name.errors" class="invalid-feedback d-block">
              <div *ngIf="name.errors.required">
                {{'Modal_Inputs.Name_Required' | translate}}
              </div>
            </div>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="">{{'Modal_Inputs.Email_Label' | translate}}</label>
            <input type="text" class="form-control" name="email" [(ngModel)]="contact.email" [(ngModel)]="contact.email"
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$" #email="ngModel"
              [ngClass]="{ 'is-invalid': email.invalid && email?.errors , 'is-valid': email.valid}" required>
            <div *ngIf="email.invalid && email.errors" class="invalid-feedback d-block">
              <div *ngIf="email.errors.required">{{'Modal_Inputs.Email_Required' | translate}}</div>
              <div *ngIf="email.errors?.pattern">{{'Modal_Inputs.Email_Invalid' | translate}}</div>
            </div>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="">{{'Modal_Inputs.Phone_Label' | translate}}</label>
            <input type="text" name="phone" id="phone" class="form-control" [(ngModel)]="contact.phone"
              #phone="ngModel">
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="">{{'Modal_Inputs.Skype_Label' | translate}}</label>
            <input type="text" class="form-control" name="skype" [(ngModel)]="contact.skype">
          </div>
          <div class="form-group col-md-12 col-sm-12">
            <label for="">{{'Modal_Inputs.Designation_Label' | translate}}</label>
            <input type="text" class="form-control" name="designation" [(ngModel)]="contact.designation">
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="">{{'Modal_Inputs.IsPrimary_Label' | translate}}</label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="isPrimary">
                <input class="radio_animated" [value]="true" id="isprimary1" type="radio" name="isprimary"
                  [(ngModel)]="contact.isprimary">
                  {{'Modal_Inputs.IsPrimary_RadioYes' | translate}}
              </label>
              <label class="d-block" for="isPrimary1">
                <input class="radio_animated" [value]="false" id="isprimary2" type="radio" name="isprimary"
                  [(ngModel)]="contact.isprimary">
                  {{'Modal_Inputs.IsPrimary_RadioNo' | translate}}
              </label>
            </div>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="">{{'Modal_Inputs.NotifyMe_Label' | translate}}</label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="NotifyMe">
                <input class="radio_animated" [value]="true" id="notifyme" type="radio" name="notifyme"
                  [(ngModel)]="contact.notifyme">
                  {{'Modal_Inputs.NotifyMe_RadioYes' | translate}}
              </label>
              <label class="d-block" for="NotifyMe1">
                <input class="radio_animated" [value]="false" id="notifyme1" type="radio" name="notifyme"
                  [(ngModel)]="contact.notifyme">
                  {{'Modal_Inputs.NotifyMe_RadioNo' | translate}}
              </label>
            </div>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="">{{'Modal_Inputs.Active_Label' | translate}}</label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="Active1">
                <input class="radio_animated" [value]="true" id="active1" type="radio" name="active"
                  [(ngModel)]="contact.active">
                  {{'Modal_Inputs.Active_RadioYes' | translate}}
              </label>
              <label class="d-block" for="Active2">
                <input class="radio_animated" [value]="false" id="active2" type="radio" name="active"
                  [(ngModel)]="contact.active">
                  {{'Modal_Inputs.Active_RadioNo' | translate}}
              </label>
            </div>
          </div>
          <div class="form-group col-sm-12">
            <label for="">{{'Modal_Inputs.Notes_Label' | translate}}</label>
            <textarea name="notes" class="form-control" [(ngModel)]="contact.notes" cols="" rows="2"></textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">{{'Modal_Buttons.Close_Button' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="saveContact()"
        [disabled]="!f.valid || isEdit">{{'Modal_Buttons.Save_Button' | translate}}</button>
    </div>
  </div>
</resize-border>