import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThemeService } from 'ng2-charts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { purchaseServices } from '../../purchase/purchase.service';
import { InventoryServices } from '../../inventory/inventory.service';

@Component({
  selector: 'app-purchaserequestitemanaylsis',
  templateUrl: './purchaserequestitemanaylsis.component.html',
  styleUrls: ['./purchaserequestitemanaylsis.component.scss']
})
export class PurchaserequestitemanaylsisComponent implements OnInit {
  fullScreen = false;
  @Input('poid') poid
  order: any
  codeList: any = []
  appname = environment.APP_NAME;
  userdata: any;
  fromdate: any;
  todate: any;
  searchtext: any;
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private inventoryService: InventoryServices,
    private datepipe: DatePipe,
    private loader: NgxUiLoaderService,
    private service: purchaseServices,
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  ngOnInit(): void {
    this.getItemAnlysisList();
  }

  getItemAnlysisList() {
    this.loader.start();
    this.inventoryService.getItemAnalyses(this.poid, this.datepipe.transform(new Date(this.fromdate), this.userdata.postdateformat), this.datepipe.transform(new Date(this.todate), this.userdata.postdateformat), this.searchtext).subscribe((res) => {
      this.loader.stop();
      this.codeList = res;
      environment.production ? '' : console.log(' item analysis item list ', this.codeList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }
  closeModal() {
    this.modalService.dismissAll('')
  }
}
