<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h4 class="modal-title">Department</h4>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <div>
        <button type="button" class="close" aria-label="Close" (click)="closeModal('close')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body">
      <div class="input-group mb-3">
        <select class="form-control" name="site" [(ngModel)]="custDept.siteid">
          <option value="">Select Site</option>
          <option *ngFor="let item of siteList" [value]="item.Value">
            {{item.Text}}
          </option>
        </select>
        &nbsp;
        <input type="text" class="form-control" placeholder="Name" name="name" [(ngModel)]="custDept.name">
        &nbsp;
        <input type="text" class="form-control" placeholder="Point of Contact" name="poc" [(ngModel)]="custDept.poc">
        &nbsp;
        <input type="text" class="form-control" placeholder="Phone" name="phone" [(ngModel)]="custDept.phone">
        <div class="input-group-append">
          <span class="input-group-text btn-primary cursor-pointer" id="basic-addon2"
            (click)="postCustomerDept()">Save</span>
        </div>
      </div>
      <div class="category-table custom-datatable mb-3">
        <div class="table-responsive2">
          <table class="custom-table">
            <thead>
              <tr class="custom-tr">
                <th class="custom-th text-center">Site Id #</th>
                <th class="custom-th text-center">Name</th>
                <th class="custom-th text-center">POC</th>
                <th class="custom-th text-center">Phone</th>
                <th class="custom-th text-center">Active</th>
                <th class="custom-th text-center">Create By</th>
                <th class="custom-th text-center">Create Date</th>
                <th class="custom-th text-center">Update By</th>
                <th class="custom-th text-center">Update Date</th>
                <th class="custom-th text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr class="custom-tr" *ngFor="let item of deptList">
                <td class="custom-td text-left">{{item.siteid}}</td>
                <td class="custom-td text-left">{{item.name}}</td>
                <td class="custom-td text-left">{{item.poc}}</td>
                <td class="custom-td text-left">{{item.phone}}</td>
                <td class="custom-td text-center"><span *ngIf="item.active">Yes</span><span
                    *ngIf="!item.active">No</span>
                </td>
                <td class="custom-td text-left">{{item.createby}}</td>
                <td class="custom-td text-left">{{item.createdate}}</td>
                <td class="custom-td text-left">{{item.updateby}}</td>
                <td class="custom-td text-left">{{item.updatedate}}</td>
                <td class="custom-td text-center">
                  <div class="modalActionBtn">
                    <i class="eb-edit mr-1" (click)="getCustDepartmentById(item.id)"></i>
                    <i class="eb-delete" (click)="deleteCustomerDept(item.id)"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="closeModal('close')">Close</button>
      <button type="button" class="btn btn-outline-primary" (click)="refresh()">Refresh</button>
    </div>
  </div>
</resize-border>
<ngx-ui-loader></ngx-ui-loader>