<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="productModalLabel">Items Code List
            </h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
              </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal(null)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <h5>Search by Code Or Description</h5>
            <div class="form-row">
                <div class="col-lg-10 col-md-8">
                    <input type="search" class="form-control" placeholder="Enter code or description" name="serialno"
                        [(ngModel)]="serialno">
                </div>
                <div class="col-lg-2 col-md-4">
                    <div class="btn btn-primary" (click)="getCodeList()">Show</div>
                </div>
            </div>
            <div class="category-table custom-datatable mt-3">
                <div class="table-responsive">
                    <table class="custom-table">
                        <tr class="custom-tr">
                            <th class="custom-th text-center">Code</th>
                            <th class="custom-th text-center">Description</th>
                            <th class="custom-th text-center">UOM</th>
                            <th class="custom-th text-center">Duty</th>
                        </tr>
                        <tr class="custom-tr" *ngFor="let item of codeList" (click)="closeModal(item)">
                            <td class="custom-td text-left">{{item.code}}</td>
                            <td class="custom-td text-left">{{item.description}}</td>
                            <td class="custom-td text-left">{{item.uom}}</td>
                            <td class="custom-td text-left">{{item.rate}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal(null)">Close</button>
            <!-- <button class="btn btn-primary" type="button">Save</button> -->
        </div>
    </div>
</resize-border>