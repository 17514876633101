import { Injectable, HostListener, Inject, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppServices } from './app.services';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnInit {
	public menuItems;
	exportMenu: any;
	public screenWidth: any;
	public collapseSidebar: boolean = false;
	appname = environment.APP_NAME;
	constructor(@Inject(WINDOW) private window, private service: AppServices) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true;
		}
		if (localStorage.getItem(this.appname + 'menu')) {
			this.menuItems = JSON.parse(localStorage.getItem(this.appname + 'menu'));
		}

		// this.service.getMenuList().subscribe(res => {
		// 	environment.production ? '' : console.log('menu', res);
		// 	this.exportMenu = res;
		// 	if (localStorage.getItem(this.appname + 'menu') != null) {
		// 		this.menuItems = JSON.parse(localStorage.getItem(this.appname + 'menu'));
		// 		this.menuItems = JSON.parse(localStorage.getItem(this.appname + 'menu'));
		// 		return;
		// 	}

		// 	this.menuItems = res;
		// })
	}

	ngOnInit(): void {

	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [

		{
			path: '/dashboard', title: 'Dashboard', icon: 'eb-home', type: 'link', badgeType: 'primary', active: false
		},
		{
			path: '/insights', title: 'Insights', icon: 'eb-insights', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Accounts', icon: 'eb-accounts', type: 'sub', active: false, children: [
				{ path: '/chartofaccounts', title: 'Chart of Accounts', type: 'link' },
				{ path: '/customers', title: 'Customers', type: 'link' },
				{ path: '/suppliers', title: 'Suppliers', type: 'link' },
				{ path: '/financialstatements', title: 'Financial Statements', type: 'link' },
				{ path: '/journalentries', title: 'Journal Entries', type: 'link' },
				{ path: '/ledgers', title: 'Ledgers', type: 'link' },
				{ path: '/arcustomerstatements', title: 'AR Customer Statements', type: 'link' },
				{ path: '/batchtransactions', title: 'Review Transactions', type: 'link' },
				{ path: '/customerdeposits', title: 'Customer Deposits', type: 'link' },
				{ path: '/chequebooks', title: 'Cheque Books', type: 'link' },
				{ path: '/vendorpayments', title: 'Supplier Payments', type: 'link' },
				{ path: '/paybills', title: 'Bill Payments', type: 'link' },
				{ path: '/receivepayments', title: 'Receive Payments', type: 'link' },
				{ path: '/financialcharges', title: 'Financial Charges', type: 'link' },
			]
		},
		{
			title: 'Project Manager', icon: 'eb-projectmanager', type: 'sub', active: false, children: [
				{ path: '/projects', title: 'Projects', type: 'link' },
				{ path: '/projecttask', title: 'Project Tasks', type: 'link' },
				{ path: '/snagbags', title: 'snag bugs', type: 'link' },
				{ path: '/testcases', title: 'Test Cases', type: 'link' },
				{ path: '/unbilledhours', title: 'Unbilled Hours', type: 'link' },

			]
		},
		{
			title: 'Service Manager', icon: 'eb-service-manager', type: 'sub', active: false, children: [
				{ path: '/servicecontracts', title: 'Service Contracts', type: 'link' },
				{ path: '/servicetickets', title: 'Service Tickets', type: 'link' },
				{ path: '/servicelineitems', title: 'Service Line Items', type: 'link' },
				{ path: '/checklistgroup', title: 'Checklist Group', type: 'link' },
				{ path: '/checklists', title: 'Checklists', type: 'link' },
				{ path: '/noteslist', title: 'Notes List', type: 'link' },
				{ path: '/techrecommendations', title: 'Tech Recommendations', type: 'link' },

			]
		},
		{
			title: 'Purchase', icon: 'eb-purchase', type: 'sub', active: false, children: [
				{ path: '/Psuppliers', title: 'Suppliers', type: 'link' },
				{ path: '/expenses', title: 'Expenses/Bills', type: 'link' },
				{ path: '/rfqlist', title: 'Request for Quotes', type: 'link' },
				{ path: '/purchaseorder', title: 'Purchase Order', type: 'link' },
				{ path: '/purchaserequest', title: 'Purchase Request', type: 'link' },
				{ path: '/purchaserequestitems', title: 'Purchase Request Items', type: 'link' },
				{ path: '/customdeclarations', title: 'Custom Declarations', type: 'link' },
				{ path: '/shipto', title: 'ShipTo', type: 'link' },
				{ path: '/bolsummary', title: 'BOL Summary', type: 'link' },
				{ path: '/receiveitembybol', title: 'Recieve Items by BOL', type: 'link' },
				{ path: '/inventoryreplenishment', title: 'Inventory Replenishment', type: 'link' },
				{ path: '/purchaseinvoices', title: 'Purchase Invoices', type: 'link' },
				{ path: '/purchasepaybills', title: 'Bill Payments', type: 'link' },
			]
		},
		{
			title: 'Sales', icon: 'eb-sales', type: 'sub', active: false, children: [
				{ path: '/Leads', title: 'Leads', type: 'link' },
				{ path: '/customers', title: 'Customers', type: 'link' },
				{ path: '/Invoices', title: 'Invoices', type: 'link' },
				{ path: '/Estimates', title: 'Quote/Estimates', type: 'link' },
				{ path: '/Salesorders', title: 'Sales Orders', type: 'link' },
				{ path: '/Salesreq', title: 'Sales Requests', type: 'link' },
				{ path: '/Salesreqitems', title: 'Sales Request Items', type: 'link' },
				{ path: '/Receivepay', title: 'Recieve Payments', type: 'link' },

			]
		},
		{
			title: 'Inventory', icon: 'eb-inventory', type: 'sub', active: false, children: [
				{ path: '/inventoryadjustments', title: 'Inventory Adjustments', type: 'link' },
				{ path: '/products', title: 'Products', type: 'link' },
				{ path: '/assemblies', title: 'Assemblies', type: 'link' },
				{ path: '/manualcount', title: 'Manual Count', type: 'link' },
				{ path: '/categories', title: 'Categories', type: 'link' },
				{ path: '/TransferRequest', title: 'Transfer Request', type: 'link' },
				{ path: '/SubCategories', title: 'Sub Categories', type: 'link' },
				{ path: '/BinLocations', title: 'Bin Locations', type: 'link' },
				{ path: '/Pricings', title: 'Pricings', type: 'link' },
			]
		},
		{
			title: 'Assets', icon: 'eb-assets', type: 'sub', active: false, children: [
				{ path: '/assets', title: 'Assets', type: 'link' },
				{ path: '/assetsrequest', title: ' ', type: 'link' },
				{ path: '/assetcontracts', title: 'Asset Contracts', type: 'link' },
				{ path: '/assetusers', title: 'Asset Users', type: 'link' },
			]
		},
		{
			title: 'HR', icon: 'eb-hr', type: 'sub', active: false, children: [
				{ path: '/employees', title: 'Employees', type: 'link' },
				{ path: '/publicholidays', title: 'Public Holidays', type: 'link' },
				{ path: '/leavesconfigurations', title: 'Leaves Configurations', type: 'link' },
				{ path: '/leavesvacations', title: 'Leaves/ Vacations', type: 'link' },
				{ path: '/clockings', title: 'Job Site Clock-In/Out', type: 'link' },
				{ path: '/sitelogs', title: 'Site Logs', type: 'link' },
			]
		},
		{
			title: 'Payroll', icon: 'eb-payroll', type: 'sub', active: false, children: [
				{ path: '/payroll', title: 'Payroll', type: 'link' },

			]
		},
		{
			path: '/timeline', title: 'Scheduler', icon: 'eb-scheduler', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Loyality Program', icon: 'eb-loyalty-prorgams', type: 'sub', active: false, children: [
				{ path: '/productslist', title: 'Products', type: 'link' },
				{ path: '/customerlist', title: 'Customers List', type: 'link' },
				{ path: '/transactions', title: 'Transactions', type: 'link' },
				{ path: '/programslist', title: 'Programs List', type: 'link' },
			]
		},
		{
			title: 'Documents', icon: 'eb-documents', type: 'sub', active: false, children: [
				{ path: '/dragdropfiles', title: 'Drag Drop Files', type: 'link' },
				{ path: '/documentlist', title: 'Document List', type: 'link' },
				{ path: '/documenttypes', title: 'Document Types', type: 'link' },
			]
		},
		{
			title: 'Tools', icon: 'eb-tools', type: 'sub', active: false, children: [
				{ path: '/manualjobs', title: 'Manual Jobs', type: 'link' },
				{ path: '/printsequence', title: 'Print Sequence', type: 'link' },
				{ path: '/emailers', title: 'Emailers', type: 'link' },
			]
		},
		{
			title: 'Reports', icon: 'eb-reports', type: 'sub', active: false, children: [
				{
					title: 'Company Financials', type: 'sub', children: [
						{ path: '/balancesheet', title: 'Balance Sheet', type: 'link' },
						{ path: '/profitloss', title: 'Profit Loss', type: 'link' },
						{ path: '/trailbalance', title: 'Trail Balance', type: 'link' },
						{ path: '/balancesheetyearlycomparison', title: 'Balance Sheet Yearly Comparison ', type: 'link' },
						{ path: '/Balancesheetlikecomparison', title: 'Balance Sheet Like Comparison', type: 'link' },
					]
				},
				{
					title: 'Accounts', type: 'sub', active: false, children: [
						{ path: '/acc-ledgers', title: 'Ledgers', type: 'link' },
						{ path: '/acc-journalentries', title: 'Journal Entries', type: 'link' },
						{ path: '/acc-audittrail', title: 'Audit Trail', type: 'link' },
						{ path: '/acc-deletedtransactions', title: 'Deleted Transactions', type: 'link' },
					]
				},
				{
					title: 'Customer Receivable', type: 'sub', children: [
						{ path: '/customeragingsummary', title: 'A/R Aging Summary', type: 'link' },
						{ path: '/creditusesummary', title: 'Credit Use Summary', type: 'link' },
						{ path: '/invoices', title: 'Invoices', type: 'link' },
						{ path: '/quotesestimates', title: 'Quotes/Estimates', type: 'link' },
						{ path: '/salesbycategory', title: 'Sales by Category', type: 'link' },
					]
				},
				{
					title: 'Vendors Payables', type: 'sub', children: [
						{ path: '/apagingsummary', title: 'A/P Aging Summary', type: 'link' },
						{ path: '/expenseshistory', title: 'Expenses History', type: 'link' },
						{ path: '/expensessummary', title: 'Expenses Summary', type: 'link' },
						{ path: '/purchaseorders', title: 'Purchase Orders', type: 'link' },
					]
				},
				{
					title: 'Inventory', type: 'sub', children: [
						{ path: '/replenishment', title: 'Replenishment', type: 'link' },
						{ path: '/orderhistorybyproduct', title: 'Order History by Product', type: 'link' },
						{ path: '/inventorymovementhistory', title: 'Inventory Movement History', type: 'link' },
						{ path: '/revenuebyproduct', title: 'Revenue by Product', type: 'link' },
						{ path: '/landedcostaudit', title: 'Landed Cost Audit', type: 'link' },
						{ path: '/salesvslandedcostsummary', title: 'Sales vs Landed Cost Summary', type: 'link' },
						{ path: '/stockcostsummary', title: 'Stock Cost Summary', type: 'link' },
						{ path: '/stocksummary', title: 'Stock Summary', type: 'link' },
						{ path: '/manualstockaudit', title: 'Manual Stock Audit', type: 'link' },
						{ path: '/priceauditing', title: 'Price Auditing', type: 'link' },
						{ path: '/bolsummaryreport', title: 'BOL Summary', type: 'link' },
						{ path: '/productsserialsummary', title: 'Products Serial Summary', type: 'link' },
						{ path: '/catalogsummary', title: 'Catalog Summary', type: 'link' },
						{ path: '/inventoryvaluebyinvoices', title: 'Inventory Value by Invoices', type: 'link' },
						{ path: '/pickuphistory', title: 'SO Delivery/Pickup History', type: 'link' },
						{ path: '/inventoryadjustment', title: 'Inventory Adjustment', type: 'link' },
						{ path: '/inventorysalescountbymonth', title: 'Inventory Sales Count by Month', type: 'link' },
						{ path: '/inventorysalescountbyyear', title: 'Inventory Sales Count by Year', type: 'link' },
						{ path: '/inventorybybinlocations', title: 'Inventory by Bin Locations', type: 'link' },
						{ path: '/armaterialcostsummary', title: 'Inventory Sales Summary', type: 'link' },
						{ path: '/womaterials', title: 'WO Materials', type: 'link' },
						{ path: '/inventorybalancereport', title: 'Inventory Balance Report', type: 'link' },
						{ path: '/bolcostingexport', title: 'BOL Costing Export', type: 'link' },
						{ path: '/assemblysalesbymonth', title: 'Assembly Sales by Month', type: 'link' },
						{ path: '/lotexpiry', title: 'Lot Expiry', type: 'link' },
						{ path: '/inventorycountdiscrepancy', title: 'Inventory Count Discrepancy', type: 'link' },
						{ path: '/internaltransfer', title: 'Internal Transfer', type: 'link' },
					]
				},
				{
					title: 'Transactions', type: 'sub', children: [
						{ path: '/dailytransactions', title: 'Daily Transactions', type: 'link' },
						{ path: '/postransactions', title: 'POS Transactions', type: 'link' },
						{ path: '/transactionssyncreport', title: 'Transactions Sync Report', type: 'link' },
					]
				},
				{
					title: 'Tasks/Tickets', type: 'sub', children: [
						{ path: '/ticketstimelogs', title: 'Tickets Time Logs', type: 'link' },
						{ path: '/ticketsummary', title: 'Ticket Summary', type: 'link' },
						{ path: '/taskstimelogs', title: 'Task Time Logs', type: 'link' },
						{ path: '/unbilledtimelogs', title: 'Unbilled Time Logs', type: 'link' },
						{ path: '/orders', title: 'Project Orders', type: 'link' },
						{ path: '/reportinvoices', title: 'Invoices', type: 'link' },
						{ path: '/ticketsinventorycostexport', title: 'Tickets Inventory Cost Export', type: 'link' },
						{ path: '/techhours', title: 'Tech Hours', type: 'link' },
					]
				},
				{
					title: 'HR Payroll', type: 'sub', children: [
						{ path: '/listofemployees', title: 'List of Employees', type: 'link' },
						{ path: '/employeecostingsummary', title: 'Employee Costing Summary', type: 'link' },
						{ path: '/employeesanniversary', title: 'Employees Anniversary', type: 'link' },
						{ path: '/employeesbirthday', title: 'Employees Birthday', type: 'link' },
						{ path: '/workpermit', title: 'Work Permits', type: 'link' },
						{ path: '/accesscards', title: 'Access Cards', type: 'link' },
						{ path: '/concessionpasses', title: 'Concession Passes', type: 'link' },
						{ path: '/insurance', title: 'Insurances', type: 'link' },
						{ path: '/pensions', title: 'Pensions', type: 'link' },
						{ path: '/appraisals', title: 'Appraisals', type: 'link' },
						{ path: '/disciplinarylogs', title: 'Disciplinary Logs', type: 'link' },
						{ path: '/hurricanecontacts', title: 'Hurricane Contacts', type: 'link' },
						{ path: '/jobapplications', title: 'Job Applications', type: 'link' },
					]
				},

			]
		},
		{
			title: 'Logout', path: '/auth/login', icon: 'fa fa-sign-out', type: 'link', active: false
		}
	];
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
