import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';
import { BinholddetailsComponent } from '../binholddetails/binholddetails.component';
import { InventoryServices } from '../../../inventory.service';
import { SerialsListComponent } from '../serials/serials.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-binlocation',
  templateUrl: './binlocations.component.html',
  styleUrls: ['./binlocations.component.scss']
})
export class BinlocationsListComponent implements OnInit {

  @ViewChild('id') id;
  @ViewChild('isSerilzed') isSerilzed;

  //#region userdata block
  appname = environment.APP_NAME;
  userdata: any;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  totalRows: any = 0;
  //#endregion userdata block
  totalOnhandQty: any = 0;
  totalMax: any = 0;
  totalMin: any = 0;
  totalAvail: any = 0;
  totalSold: any = 0
  totalOnHold: any = 0
  binLocations: any = [];
  Serilzed: boolean = false;
  searchText: any
  filteredItems: any = [];
  itemid: any;
  forsale = true;
  private langChangeSubscription: Subscription; 
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private service: InventoryServices,
    private activeModal: NgbActiveModal, private loader: NgxUiLoaderService,
    private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient, 
      ) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.companyLogo += this.userdata.logo;
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block

  }

  gotoEdit(data: any) {
    const encrptData = btoa(data.id);
    const url = this.router.serializeUrl(this.router.createUrlTree(['/binlocation/addbinlocation'], { queryParams: { id: encrptData, action: 'edit' } }));
    window.open(url, '_blank');
  }

  openBinHoldDetail(item) {
    if (item.onhold == 0) {
      return;
    }
    var modalRef
    modalRef = this.modalService.open(BinholddetailsComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' })
    modalRef.componentInstance.itemdata = item;
    environment.production ? '' : console.log('id', modalRef.componentInstance.id);
    modalRef.result.then((result) => {
      this.getProductBins()
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.getProductBins()
    });
  }
  serialModal(item) {
    var modalRef
    modalRef = this.modalService.open(SerialsListComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' })
    // modalRef.componentInstance.itemdata = item;
    modalRef.componentInstance.itemdata = {
      prodid: item.prodid,
      binid: item.binid,
      bincode: item.code,
      searchtxt: '',
      status: ''
    }
    environment.production ? '' : console.log('id', modalRef.componentInstance.id);
    modalRef.result.then((result) => {
      this.getProductBins();
    }, (reason) => {
      this.getProductBins();
    });
  }

  ngOnInit(): void {
    this.itemid = this.id;
    this.getProductBins();
    if (this.isSerilzed == 'YES') {
      this.Serilzed = true;
    } else {
      this.Serilzed = false;
    }
    console.log('isserialized', this.isSerilzed);
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
  }
  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/sales/customers/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
    
    
  getProductBins() {
    this.loader.start();
    this.service.getProductBins(this.itemid, this.forsale ? 1 : 0).subscribe(res => {
      this.loader.stop();
      this.binLocations = res;
      this.totalOnhandQty = 0;
      this.totalMax = 0;
      this.totalMin = 0;
      this.totalAvail = 0;
      // this.totalSold = 0
      this.totalOnHold = 0
      this.binLocations.forEach(element => {
        if (element.active == true) {
          this.totalOnhandQty += element.cnt ? element.cnt : 0
          this.totalMax += element.maxqty ? element.maxqty : 0;
          this.totalMin += element.minqty ? element.minqty : 0;
          this.totalAvail += element.availableqty ? element.availableqty : 0;
          // this.totalSold += element.sold ? element.sold : 0;
          this.totalOnHold += element.onhold ? element.onhold : 0
        }
        element.binid = element.binid.toString();

      });
      this.filteredItems = this.binLocations;
    }, err => {
      this.loader.stop();
    });
  }
  onSearchChange() {
    this.filterItems();
  }



  filterItems() {
    this.filteredItems = this.binLocations.filter(item => item.description.toLowerCase().includes(this.searchText.toLowerCase()));
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}

