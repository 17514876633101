import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { accountServices } from '../../accounts/account.service';
import { GenericprintComponent } from '../genericprint/genericprint.component';
import * as moment from 'moment';

@Component({
  selector: 'app-arstatements',
  templateUrl: './arstatements.component.html',
  styleUrls: ['./arstatements.component.scss']
})
export class ArstatementsComponent implements OnInit {
  //#region userdata block
  fullScreen = false;
  appname = environment.APP_NAME;
  userdata: any;
  format: any;
  //#endregion userdata block
  arstatement: any = {
    type: '1',
    template: '1'
  }
  arStMentList: any = []
  @Input('id') id;
  customerid: any;
  constructor(private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private datepipe: DatePipe,
    private toastr: NotificationService,
    private loader: NgxUiLoaderService,
    private service: accountServices) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format;
    this.arstatement.fromdate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    this.arstatement.todate = this.datepipe.transform(this.userdata.enddate, this.userdata.postdateformat);

    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block
    // this.getARStatements();
  }
  saveARStatement() {
    this.arStMentList = [];
    this.arstatement.companyid = this.userdata.companyid;
    this.arstatement.customerid = [this.id]
    this.arstatement.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    this.arstatement.createby = this.userdata.email;
    this.arstatement.companyname = this.userdata.companyname;
    this.arstatement.fromdate = this.datepipe.transform(this.arstatement.fromdate, this.userdata.postdateformat);
    this.arstatement.todate = this.datepipe.transform(this.arstatement.todate, this.userdata.postdateformat);
    this.arstatement.sendinemail = false;
    this.arstatement.printonly = false;
    this.arstatement.logo = this.userdata.logo;
    environment.production ? '' : console.log(this.arstatement);
    this.loader.start();
    this.service.postARstatement(this.arstatement).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
      this.arstatement = {}
      this.arStMentList = res.data;
      this.ngOnInit();
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading Post AR statements', err);
    });
  }
  getARStatements() {
    this.loader.start();
    this.service.getARStatements(this.customerid).subscribe(res => {
      this.loader.stop();
      this.arStMentList = res;
      environment.production ? '' : console.log('get AR statements list', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading get AR statements', err);
    });
  }

  arstatementprint() {
    var modalRef = null
    modalRef = this.modalService.open(GenericprintComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.name = "printarstmt";
    modalRef.componentInstance.type = this.arstatement.type;
    modalRef.componentInstance.fromdate = this.datepipe.transform(this.arstatement.fromdate, this.userdata.postdateformat);
    modalRef.componentInstance.todate = this.datepipe.transform(this.arstatement.todate, this.userdata.postdateformat);
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  ngOnInit(): void {
    this.customerid = this.id;
    this.getARStatements();
  }
  download(element) {
    var printURL = environment.DOC_URL + '/' + this.userdata.companyid + '/' + element.doc_name

    window.open(printURL, '_blank');
    this.closeModal();
  }

}
