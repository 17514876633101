import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-projjoblistpomodal',
  templateUrl: './projjoblistpomodal.component.html',
  styleUrls: ['./projjoblistpomodal.component.scss']
})
export class ProjjoblistpomodalComponent implements OnInit {
  fullScreen = false;
  @Input('searchtxt') searchtxt;
  @Input('customerid') customerid;
  jobList: any = [];
  selectedJob: any = '';
  soOrderTypes: any = [];
  ordertype: any = '';
  constructor(private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private commoanService: CommonServices,
    private toastr: NotificationService,
    private datePipe: DatePipe,
    private loader: NgxUiLoaderService) {
    this.getSalesOrderTypes();
  }
  getSalesOrderTypes() {
    this.soOrderTypes = []
    this.commoanService.getSOTypes().subscribe((res: any) => {
      this.soOrderTypes = res;
    }, err => {
    });
  }
  getProjJobslist() {
    if (this.searchtxt == undefined || this.searchtxt == null) {
      this.searchtxt == '';
      return;
    }
    if (this.customerid == undefined || this.customerid == null) {
      this.customerid = '';
    }
    if (this.searchtxt.length < 3) {
      return
    }
    this.loader.start();
    this.commoanService.getProjJobslist(this.searchtxt, this.customerid).subscribe(res => {
      this.loader.stop();
      this.jobList = res;
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading expType list ', err);
    });
  }
  selectJob(item) {
    if (this.ordertype == '') {
      this.toastr.Error('Please select order type');
      return;
    }
    this.selectedJob = item;
    this.selectedJob.ordertype = this.ordertype;
    this.activeModal.close(this.selectedJob)
  }

  ngOnInit(): void {
    this.getProjJobslist();
  }

  closeModal() {
    this.activeModal.close();
  }

}
