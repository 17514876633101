import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/shared/service/language.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SpecmapServices } from '../../specmap.service';
import { MarkersComponent } from '../markers.component';

@Component({
  selector: 'app-addmarker',
  templateUrl: './addmarker.component.html',
  styleUrls: ['./addmarker.component.scss']
})
export class AddmarkerModalComponent implements OnInit {
  marker: any = {};
  editSetLocation: any;
  locationList: any = [];
  searchLocation: any = 'Text';
  userdata: any;
  types: any = [];
  subTypes: any = [];
  @Input('id') id;
  actionView: any;
  @Input('flag') flag;
  appname: any = environment.APP_NAME;
  private langChangeSubscription: Subscription; 
  constructor(private activeModal: NgbActiveModal, private toastr: NotificationService, private datepipe: DatePipe, private service: SpecmapServices, private loader: NgxUiLoaderService, private markersComponent: MarkersComponent, private cdr: ChangeDetectorRef,private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient, 
     ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.getSpecMapSites();
    this.getTypes();

  }

  ngOnInit(): void {
    if (this.flag == 'view') {
      this.actionView = true;
    } else {
      this.actionView = false;
    }
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
     
  }
  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/specmap/markers/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
  getMarkerByid() {
    this.service.getMarkersListById(this.id).subscribe(data => {
      console.log(data)
      this.marker = data;
      this.locationList.forEach(element => {
        if (element.Value == this.marker.siteid) {
          this.editSetLocation = element;
          this.cdr.detectChanges();
        }
      });
      if (this.marker.marker_type) {
        this.getSubTypes();
      }
    })
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  getSpecMapSites() {
    this.loader.start();
    this.service.getSpecMapSites().subscribe(res => {
      this.loader.stop();
      this.locationList = res;
      if (this.id) {
        this.getMarkerByid();
      }
    }, err => {
      this.loader.stop();
    })
  }



  getTypes() {
    this.loader.start();
    this.service.getSpecMapCategories().subscribe(res => {
      this.loader.stop();
      this.types = res;
    }, err => {
      this.loader.stop();
    })
  }

  getSubTypes() {
    this.loader.start();
    this.service.getSpecMapSubCategories(this.marker.marker_type).subscribe((res: any) => {
      this.loader.stop();
      this.subTypes = res.lists;
    }, err => {
      this.loader.stop();
    })
  }

  saveMarker() {
    if (!this.marker.description) {
      Swal.fire('', 'Please enter description', 'warning');
      return;
    }
    this.marker.companyid = this.userdata.companyid;
    this.marker.active = true;
    if (this.marker.id) {
      this.marker.updateby = this.userdata.email;
      this.marker.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.marker.createby = this.marker.createby;
      this.marker.createdate = this.datepipe.transform(this.marker.createdate, this.userdata.postdatetimeformat);
    } else {
      this.marker.createby = this.userdata.email;
      this.marker.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.loader.start();

    this.service.postMarker(this.marker).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          this.marker = {};
          this.activeModal.close();
          this.markersComponent.getMarkersList();
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
    })
  }


  selectLocation($event) {
    console.log($event);
    this.marker.siteid = $event.Value;
  }

  inputLocationCleared() {

  }

  onFocusedLocation($event) {

  }

}
