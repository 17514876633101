import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SupplierListComponent } from './supplier-list.component';
import { NgToggleModule } from 'ng-toggle-button';

@NgModule({
    declarations: [SupplierListComponent],
    imports:[CommonModule, FormsModule, NgbModule,  NgbTooltipModule, NgToggleModule],
    exports: [SupplierListComponent],
})
export class SupplierListModule {}