<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="productModalLabel">AP Supplier Statement
            </h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-6 col-md-6 form-group">
                    <label for="fromdate">From Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker401" placeholder="Choose a date"
                            [(ngModel)]="apstatement.fromdate" name="fromdate">
                        <mat-datepicker-toggle matSuffix [for]="picker401"></mat-datepicker-toggle>
                        <mat-datepicker #picker401></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-6 form-group">
                    <label for="todate">To Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker402" placeholder="Choose a date"
                            [(ngModel)]="apstatement.todate" name="todate">
                        <mat-datepicker-toggle matSuffix [for]="picker402"></mat-datepicker-toggle>
                        <mat-datepicker #picker402></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-6 form-group">
                    <label for="">Type</label>
                    <select name="type" id="" class="form-control" [(ngModel)]="apstatement.type">
                        <option value="0">All</option>
                        <option value="1">Balance Forward</option>
                        <option value="2">Open Items</option>
                        <option value="3">Transactions</option>
                    </select>
                </div>
                <div class="col-md-6 form-group">
                    <label for="">Default Email Template</label>
                    <select name="template" id="" class="form-control" [(ngModel)]="apstatement.template">
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div class="col-md-12 form-group">
                    <label for="">Special Message for Supplier</label>
                    <textarea name="msg" class="form-control" rows="2" placeholder="Message"
                        [(ngModel)]="apstatement.msg"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
            <button class="btn btn-primary" type="button" (click)="saveAPStatement()">Submit</button>
            <button class="btn btn-primary" type="button" (click)="apstatementprint()">Print</button>
        </div>
        <div class="category-table custom-datatable mb-3 col-lg-12">
            <div class="table-responsive2">
                <table class="custom-table">
                    <tr class="custom-tr">
                        <th class="custom-th text-center">Ref Name</th>
                        <th class="custom-th text-center">Created</th>
                        <th class="custom-th text-center">Emailed</th>
                        <th class="custom-th text-center">Type</th>
                        <th class="custom-th text-center">Doc Name</th>
                        <th class="custom-th text-center">Createby</th>
                        <th class="custom-th text-center">Createdate</th>
                        <th class="custom-th text-center">Document</th>
                    </tr>
                    <tr class="custom-tr" *ngFor="let item of apStMentList">
                        <td class="custom-td text-left">{{item.ref_name}}</td>
                        <td class="custom-td text-center"><span *ngIf="item.created">Yes</span><span
                                *ngIf="!item.created">No</span></td>
                        <td class="custom-td text-center"><span *ngIf="item.emailed">Yes</span><span
                                *ngIf="!item.emailed">No</span></td>
                        <td class="custom-td text-center">{{item.type}}</td>
                        <td class="custom-td text-left" (click)="download(item)"><span style="cursor: pointer;color: blue;">{{item.doc_name}}</span></td>
                        <td class="custom-td text-left">{{item.createby}}</td>
                        <td class="custom-td text-left">{{item.createdate |date:userdata.displaydatetimeformat}}</td>
                        <td class="custom-td text-center" (click)="download(item)"> <i class="fa fa-download"
                                style="font-size: 20px" (click)="download(item)"></i></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</resize-border>