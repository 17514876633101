<div id="main" class="main-content-wraper">
    <div class="main-content-inner">
        <div class="main-header">
            <div class="TopNav">
                <div class="container">
                    <ul>
                        <li><i class="fa fa-phone"></i><a href="tel:3453255469" title="call us">+1(345) 325 5469</a> &
                            <a href="tel:+13456406292" title="call us"> +1(345) 640 6292</a>
                        </li>
                        <li><a href="mailto:contactus@sanmolsoftware.com" title="mail us"><i class="fa fa-envelope-open"
                                    style="margin-right:10px;"></i>contactus@sanmolsoftware.com</a></li>
                    </ul>
                </div>
            </div>
            <header class="header">
                <div class="container">
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" href="" title="ezyBooks"><img src="assets/images/applogo.svg"
                                alt="ezyBooks"></a>
                        <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
                            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                            <span class="" [ngClass]="isCollapsed?'fa fa-bars':'fa fa-times'"></span>
                        </button>
                        <div class="navbar-collapse" [ngbCollapse]="isCollapsed">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item active"><a class="nav-link" title="Home"
                                        (click)="gotoHome()">Home</a></li>
                                <li class="nav-item"><a class="nav-link" title="About" (click)="gotoAbout()">About</a>
                                </li>
                                <li class="nav-item"><a class="nav-link" (click)="gotoOverview()"
                                        title="Overview">Overview</a></li>
                                <li class="nav-item"><a class="nav-link" title="Features">Features</a></li>
                                <li class="nav-item"><a class="nav-link" (click)="gotoPricing()"
                                        title="Pricing">Pricing</a></li>
                                <li class="nav-item"><a class="nav-link" (click)="gotoContact()" title="Contact">Contact
                                        us</a></li>
                            </ul>
                            <ul class="nav navbar-nav navbar-right">
                                <li><a (click)="gotoPricing()" class="btn btn-outline-primary" title="Sign Up">Sign
                                        Up</a>
                                </li>
                                <li>
                                    <a (click)="gotoLogin()" class="btn btn-primary" title="Sign In">Sign In</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </div>

        <!-- --------------Main Page body html code start ---------------->
        <div class="wrapper" #home>
            <!--  OfferTime -->
            <div class="container">
                <div class="OfferDetails">
                    <div class="row align-items-center">
                        <div class="col-lg-3 col-md-12">
                            <div class="total_offer">{{homePagePlan.discount}}<span
                                    *ngIf="homePagePlan.discount_type=='percent'">%</span><span
                                    *ngIf="homePagePlan.discount_type=='amount'">$</span></div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="offer_time">Limited time only. Ezybooks for 3 months.* </div>
                        </div>
                        <div class="col-lg-5 text-lg-right text-center col-md-12">
                            <a class="btn btn-primary" title="" (click)="goToCreateAccount(homePagePlan)">Try
                                free for {{30*homePagePlan.duration_in_months}}
                                days</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- features-section -->
            <section class="features" #features>
                <div class="container">
                    <article class="text-center">
                        <h3 class="main-heading">EzyBooks Features</h3>
                        <p>Learn about your options for growing your business and how the right and appropriate software
                            can help.</p>
                    </article>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/leads.png" alt="Leads Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Leads Management</h4>
                                <p>Leads management bridges the gap between marketing and sales. It's a client obtaining
                                    process which distinguishes potential purchasers, instructs them, engages with them,
                                    and when the leads are viewed as qualified, get passed from marketing to sales.</p>
                            </div>
                            <span class="over-back"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/customer.png" alt="Customer Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Customer Management</h4>
                                <p>Customer Management module helps retailers overcome customer-related challenges, &
                                    achieve a truly integrated, comprehensive perspective of clients. It gives a unique
                                    ability to engage shoppers in a consistent and truly personalized manner across all
                                    sales channels and touch points.</p>
                            </div>
                            <span class="over-back blue"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/purchase.png" alt="Purchase Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Purchase Management</h4>
                                <p>The EzyBooks is business process the board programming that enables an association to
                                    utilize an arrangement of coordinated applications to deal with the business &
                                    office capacities identified with innovation, administrations, & HR. A productive &
                                    strong Purchasing module is key for all merchants.</p>
                            </div>
                            <span class="over-back"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/salesmanagement.png" alt="Sales Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Sales Management</h4>
                                <p>Sales module executes elements of request arrangements, booking, sending and
                                    invoicing. Sales management takes the responsibility of making sales & manage the
                                    distribution chain in order to make a profit. The orders for sales are entered by
                                    the management process of EzyBooks.</p>
                            </div>
                            <span class="over-back blue"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/projectmanagement.png" alt="Project Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Project Management</h4>
                                <p>An EzyBooks Project the board is the best & smart arrangement accessible to deliver
                                    the necessities identified with viable undertaking the board & drive operational
                                    brilliance. The application offers the learning, abilities etc. required to oversee
                                    or surpass partner desires.
                                </p>
                            </div>
                            <span class="over-back"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/taskmanagement.png" alt="Task Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Task Management</h4>
                                <p>It is one of the popular task management software used by companies to improve their
                                    profits. Implementation of EzyBooks according to company needs to complete the order
                                    process. An employee can be assigned multiple tasks & this task management feature
                                    ensures that is not over or under allocated.</p>
                            </div>
                            <span class="over-back blue"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/timesheetmanagement.png" alt="Timesheet Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Timesheet Management</h4>
                                <p>We have to make it basic & simple for representative to enter hours worked &
                                    supporting timesheet information. The more reasonable procedure almost certain
                                    timesheet will be exact & entered on schedule. With EzyBooks, clients can rapidly &
                                    effectively catch point by timesheets.</p>
                            </div>
                            <span class="over-back"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/invoicemanagement.png" alt="Invoice Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Invoice Management</h4>
                                <p>Invoice programming is utilized to make client billing document related to the sale
                                    of goods & services. When the business arrange achieves its last stage you may
                                    taking a shot at the installment method. An Invoice assume essential job, which is
                                    critical for the two Sale & Accounting purpose.</p>
                            </div>
                            <span class="over-back blue"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/quotemanagement.png" alt="Quote Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Quote Management</h4>
                                <p>It enables you to construct a remarkable statement for an item that doesn't exist in
                                    your framework to accumulate an exact gauge. It causes you anticipate with more
                                    precision when a client will put in a request to improve your business estimating,
                                    including occasional clients.</p>
                            </div>
                            <span class="over-back"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/billmanagement.png" alt="Bill Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Bill Management</h4>
                                <p>It incorporates the records of all products and benefits provided to clients and
                                    maintains the history of all past transactions associated with them. To make things
                                    trouble free, EzyBooks Sales & Billing Management organizes these by grouping
                                    various products in a single invoice for customers.</p>
                            </div>
                            <span class="over-back blue"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/accountreport.png"
                                    alt="Account Reports & Custom Report" class="img-fluid f-icon" width="200"
                                    height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Account Reports & Custom Report</h4>
                                <p>The EzyBooks & other key value-based system can hold a fortune of information about
                                    organization & its task. Businesses that effectively retrieve, deliver this
                                    information in a timely fashion are maximizing their investment in EzyBooks are
                                    better positioned to make key informed business decision.</p>
                            </div>
                            <span class="over-back"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/employeemanagement.png" alt="Employee Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Employee Management</h4>
                                <p>Employee the board is an imperative segment of a well-run retail business. This
                                    module oversees representative information with all the security checks set up
                                    mechanizing your employees procedure. It liberates your HR Department from
                                    dreary,dull & tedious calculative undertakings.</p>
                            </div>
                            <span class="over-back blue"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/inventorymanagement.png" alt="Inventory Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Inventory Management</h4>
                                <p>Whether the organizations buy it or make it, the businesses are required to know what
                                    they have on hand to sell when the customer requires the material & where does it
                                    exist. This will help the business to manage the track record of customer’s
                                    requirement to a proper functioning of their inventory.</p>
                            </div>
                            <span class="over-back"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/payrollmanagement.png" alt="Payroll Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Payroll Management</h4>
                                <p>Payment-related functionalities are the most frequently represented human resources
                                    features in EzyBooks software. A good EzyBooks tool will not only allow you to
                                    compensate your employees but also will help you automatically calculate and deduct
                                    the federal tax and other stuff.</p>
                            </div>
                            <span class="over-back blue"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/multiplecurrency.png" alt="Multiple currency support"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Multiple currency support</h4>
                                <p>International organization can use multiple currencies for opportunities, forest, &
                                    other currency fields. The administrator sets the "corporate cash," which mirrors
                                    the money of the corporate home office. With globalization EzyBooks software fully
                                    supports transactions across multiple currencies.</p>
                            </div>
                            <span class="over-back"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/paymentgateway.png" alt="Payment Gateway"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Payment Gateway</h4>
                                <p>Ezybooks has First Atlantic payment gateway implemented, to avail this feature
                                    customers just need to get merchant id and configure merchant id in company profile,
                                    thats it you are ready to go and receive your invoice payments to your account. Easy
                                    to get your receivables on track and get paid.</p>
                            </div>
                            <span class="over-back blue"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/servicetickets.png" alt="Service Tickets"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Service Tickets</h4>
                                <p>Service tickets module is very important for any service based companies. In this
                                    module you can create service tickets, assign tickets to technicians, technicians
                                    can see assigned tickets to them on tablet and from they can add inventory parts and
                                    service line items with qty or hours. When technicians update tickets then
                                    administrator or backoffice can raise invoice from there and send invoice to
                                    customer and get paid.</p>
                            </div>
                            <span class="over-back"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/colsintegration.png" alt="COL's Integration"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>COL's Integration</h4>
                                <p>COL's integration to clear shipments or overseas purchase orders easily. You can
                                    raise overseas purchase orders, when purchase order shipped then you can declare
                                    your goods and generate XML then upload XML to COL's online portal and get it
                                    cleared easily.</p>
                            </div>
                            <span class="over-back blue"></span>
                        </div>
                    </div>
                </div>
            </section>

            <!-- footer -->
            <div class="container">
                <footer class="footer-section ptb-60">
                    <div class="row footer-wrap">
                        <div class="col-lg-9">
                            <div class="row">
                                <div class="col-lg-3 col-md-6">
                                    <div class="footer-single-col">
                                        <h6>Company</h6>
                                        <ul class="footer-link-list">
                                            <li>
                                                <a href="https://sanmolsoftware.com/about-us" title="About us"
                                                    target="_blank" rel="noopener">About us</a>
                                            </li>
                                            <li>
                                                <a href="https://sanmolsoftware.com/manuals/ezybooks" title="User Guide"
                                                    target="_blank" rel="noopener">User guide</a>
                                            </li>
                                            <li>
                                                <a href="https://sanmolsoftware.com/privacy-policy"
                                                    title="Privacy Policy" target="_blank" rel="noopener">Privacy
                                                    policy</a>
                                            </li>
                                            <li>
                                                <a href="" title="Contact Us">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="footer-single-col">
                                        <h6>Features</h6>
                                        <ul class="footer-link-list">
                                            <li>
                                                <a href="" title="Accounts Reports &amp; Custom Reports">Accounts
                                                    Reports &amp; Custom Reports </a>
                                            </li>
                                            <li>
                                                <a href="" title="Payments Gateway">Payments Gateway</a>
                                            </li>
                                            <li>
                                                <a href="" title="Shopping Carts">Shopping Carts</a>
                                            </li>
                                            <li>
                                                <a href="" title="Payroll">Payroll</a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="footer-single-col">
                                        <h6>Explore</h6>
                                        <ul class="footer-link-list">
                                            <li>
                                                <a href="" title="Pricing">Pricing</a>
                                            </li>
                                            <li>
                                                <a href="" title="Demo" target="_blank">Free trials and demo</a>
                                            </li>
                                            <li>
                                                <a href="" title="Features">Features</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="footer-single-col">
                                        <h6>Learning</h6>
                                        <ul class="footer-link-list">
                                            <li>
                                                <a href="https://sanmolsoftware.com/custom-solutions/ezybooks-project-management-software"
                                                    title="Links" target="_blank" rel="noopener">Resources</a>
                                            </li>
                                            <!-- <li>
                                    <a href="https://eb.sanmolapps.com/help" title="Links" target="_blank" rel="noopener">API documentation</a>
                                    </li> -->
                                            <li>
                                                <a href="https://sanmolsoftware.com/faq" title="FAQ" target="_blank"
                                                    rel="noopener">FAQ</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-3">
                            <div class="footer-single-col Subscribe">
                                <h6>Subscribe our Newsletter</h6>
                                <span>Subscribe our newsletter</span>
                                <div class="Subscribe-input">
                                    <input type="text" class="form-control" placeholder="Enter the Email ID" />
                                    <button class="btn btn-primary">Subscribe</button>
                                </div>
                                <div class="socialIcons">
                                    <div>
                                        <a href="https://www.facebook.com/sanmolconsultancy" title="Facebook"
                                            aria-label="Facebook" rel="noopener" target="_blank">
                                            <img src="assets/images/homepage/facebook.png"
                                                onerror="this.onerror=null; this.src='../Images/facebook.png'"
                                                class="img-fluid" alt="facebook" height="50" width="50" />
                                        </a>
                                        <a href="https://twitter.com/Sanmolsoftware" title="Twitter"
                                            aria-label="Twitter" rel="noopener" target="_blank">
                                            <img src="assets/images/homepage/twitter.png"
                                                onerror="this.onerror=null; this.src='../Images/facebook.png'"
                                                class="img-fluid" alt="facebook" height="50" width="50" />
                                        </a>
                                        <a href="https://www.linkedin.com/company/sanmol-software-solutions"
                                            title="LinkedIn" aria-label="LinkedIn" rel="noopener" target="_blank">
                                            <img src="assets/images/homepage/instagram.png"
                                                onerror="this.onerror=null; this.src='../Images/facebook.png'"
                                                class="img-fluid" alt="facebook" height="50" width="50" />
                                        </a>
                                        <a href="https://www.facebook.com/sanmolconsultancy" title="Facebook"
                                            aria-label="Facebook" rel="noopener" target="_blank">
                                            <img src="assets/images/homepage/youtube.png"
                                                onerror="this.onerror=null; this.src='../Images/facebook.png'"
                                                class="img-fluid" alt="facebook" height="50" width="50" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="clearfix"></div>
                                <div class="col-sm-9">
                                    <p class="text-center">Powered by:</p>
                                </div>
                                <div class="col-sm-3">
                                    <span class="secure-text">Feel Secure Feel Safe as we are Secured with:</span><br />
                                </div>
                                <div class="col-sm-9 secureCard">
                                    <img src="assets/images/homepage/visa.svg" alt="visa" loading="lazy"
                                        class="col-sm-3">
                                    <img src="assets/images/homepage/master.svg" alt="master" loading="lazy"
                                        class="col-sm-3">
                                    <img src="assets/images/homepage/FAC.svg" alt="FAC" loading="lazy" class="col-sm-3">
                                    <img src="assets/images/homepage/PNP.svg" alt="PNP" loading="lazy" class="col-sm-3">
                                </div>
                                <div class="col-sm-3 secureCard">
                                    <span>Feel Secure Feel Safe as we are Secured with:</span><br />
                                    <img src="assets/images/homepage/SSL.svg" alt="SSL" loading="lazy">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="develop-by">
                        <a href="" target="_blank"> Developed by <span>Sanmol Software </span></a>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</div>