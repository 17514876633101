import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SalesServices } from 'src/app/components/Sales/sales.service';
import { InventoryServices } from '../../../inventory.service';
import { AddProductComponent } from '../add-product.component';
import { getLocaleCurrencyCode } from '@angular/common';
@Component({
  selector: 'app-loyaltyprograms',
  templateUrl: './loyaltyprograms.component.html',
  styleUrls: ['./loyaltyprograms.component.scss']
})
export class LoyaltyprogramsComponent implements OnInit {
  public closeResult: string;
  public categories = []
  lastaction: any;
  fieldsetDisabled = false;
  product: any;
  loyalityPrograms: any = [];
  constructor(private modalService: NgbModal,
    private service: InventoryServices,
    private maincomponent: AddProductComponent,
    private route: ActivatedRoute,
    private loader: NgxUiLoaderService) {
    this.categories = categoryDB.category;
    this.product = maincomponent.product;
    this.lastaction = this.route.snapshot.queryParamMap.get('action');
    if (this.lastaction == 'view') {
      this.fieldsetDisabled = true;
    } else if (this.lastaction == 'edit') {
      this.fieldsetDisabled = false;
    }
    this.getProductLoyalityPrograms();
  }

  ngOnInit(): void {

  }

  //#region Sorting Table Header
  sortDir = 1;//1= 'ASE' -1= DSC
  onSortClick(event, colum) {
    let target = event.currentTarget,
      childtarget = target.querySelector('i'),
      classList = childtarget.classList;

    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      target.classList.add('Sorted');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      target.classList.remove('Sorted');
      this.sortDir = 1;
    }
    this.sortArr(colum);
  }

  sortArr(colName: any) {
    this.loyalityPrograms.sort((a, b) => {
      let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
      let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

      if (!valueA && !valueB) {
        return 0;
      }
      if (!valueA) {
        return -this.sortDir;
      }
      if (!valueB) {
        return this.sortDir;
      }

      if (valueA > valueB) {
        return this.sortDir;
      }
      if (valueA < valueB) {
        return -this.sortDir;
      }
      return 0;
    });
  }


  getProductLoyalityPrograms() {
    this.loader.start();
    this.service.getProductLoyalityPrograms(this.product.id).subscribe(res => {
      this.loader.stop();
      this.loyalityPrograms = res;
    }, err => {
      this.loader.stop();
    })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
