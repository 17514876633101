import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PartysComponent } from './partysmodal.component';


@NgModule({
    declarations: [PartysComponent],
    imports:[CommonModule, FormsModule, NgbModule,  NgbTooltipModule],
    exports: [PartysComponent],
})
export class PartysmodalModule {}