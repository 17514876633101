import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { assetServices } from '../../assets/asset.service';
import { AddassetsComponent } from '../../assets/assets/addassets/addassets.component';
import * as moment from 'moment';

@Component({
  selector: 'app-assetservicelog',
  templateUrl: './assetservicelog.component.html',
  styleUrls: ['./assetservicelog.component.scss']
})
export class AssetservicelogComponent implements OnInit {
  fullScreen = false;
  @Input('tabmaintenancelog') tabmaintenancelog;
  @Input('assetLog') assetLog;
  @Input('assetid') assetid;
  @Input('action') action;
  pageTitel: any;
  appname = environment.APP_NAME;
  userdata: any;
  asset: any = {
    type: '',
    locationid: '',
    condition: '',
    status: '',
    labor: '',
    notes: '',
    parts: '',
    serviceby: '',
    description: ''
  }
  response: any;
  mainList: any = [];
  assetdata: any = [];
  assignToList: any = []
  locationList: any = [];
  conditionList: any = [];
  assetServiceTypeList: any = [];
  assetServiceStatusList: any = [];
  isView: boolean = false;
  constructor(
    private datepipe: DatePipe,
    private loader: NgxUiLoaderService,
    private toastr: NotificationService,
    private commonservice: CommonServices,
    private service: assetServices,
    private modalService: NgbModal,
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.service.getAssetLocation().subscribe(res => {
      this.locationList = res;
      environment.production ? '' : console.log('locationList list data ', this.locationList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading locationList list ', err);
    });

    this.commonservice.getAssignTo().subscribe(res => {
      this.assignToList = res;
      environment.production ? '' : console.log('assignto list', this.assignToList);
      environment.production ? '' : console.log(' bug newassignToList list data ', this.assignToList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading newassignToList list ', err);
    });

    this.commonservice.getComboEnums('assetconditions').subscribe(res => {
      this.conditionList = res;
      environment.production ? '' : console.log('conditionList list data ', this.conditionList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading conditionList list ', err);
    });
    this.commonservice.getComboEnums('assetservicetypes').subscribe(res => {
      this.assetServiceTypeList = res;
      environment.production ? '' : console.log('assetServiceTypeList list data ', this.assetServiceTypeList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading assetServiceTypeList list ', err);
    });
    this.commonservice.getComboEnums('assetservicestatus').subscribe(res => {
      this.assetServiceStatusList = res;
      environment.production ? '' : console.log('assetservicestatus list data ', this.assetServiceStatusList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading assetservicestatus list ', err);
    });
  }

  ngOnInit(): void {
    if (this.assetLog != undefined && this.assetLog != null) {
      this.asset = this.assetLog;
    } else {
      this.asset.dos = this.datepipe.transform(new Date(), this.userdata.postdateformat);
      this.asset.nextdue = moment().add(7, 'days').format('YYYY-MM-DD');
    }
    if (this.action == 'view') {
      this.isView = true;
    } else {
      this.isView = false;
    }
  }
  postServiceLog() {
    var obj
    if (this.asset.id == undefined || this.asset.id == null) {
      obj = {
        "description": this.asset.description,
        "type": this.asset.type,
        "labor": this.asset.labor,
        "parts": this.asset.parts,
        "dos": this.datepipe.transform(new Date(this.asset.dos), this.userdata.postdatetimeformat),
        "nextdue": this.datepipe.transform(new Date(this.asset.nextdue), this.userdata.postdatetimeformat),
        "serviceby": this.asset.serviceby,
        "notes": this.asset.notes,
        "assid": this.assetid,
        "status": this.asset.status,
        "companyid": this.userdata.companyid,
        "createby": this.userdata.email,
        "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
        "condition": this.asset.condition,
        "locationid": this.asset.locationid,
      }
    }
    else {
      obj = {
        "id": this.asset.id,
        "description": this.asset.description,
        "type": this.asset.type,
        "labor": this.asset.labor,
        "parts": this.asset.parts,
        "dos": this.datepipe.transform(new Date(this.asset.dos), this.userdata.postdateformat),
        "nextdue": this.datepipe.transform(new Date(this.asset.nextdue), this.userdata.postdateformat),
        "serviceby": this.asset.serviceby,
        "notes": this.asset.notes,
        "assid": this.asset.assid,
        "status": this.asset.status,
        "companyid": this.userdata.companyid,
        "createby": this.asset.createby,
        "createdate": this.datepipe.transform(new Date(this.asset.createdate), this.userdata.postdatetimeformat),
        "updateby": this.userdata.email,
        "updatedate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
        "condition": this.asset.condition,
        "locationid": this.asset.locationid,
      }
    }
    environment.production ? '' : console.log('post service call ', obj);
    // return true
    this.loader.start();
    this.service.postAssetServiceLog(obj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.modalService.dismissAll(res.data);
      }
      else {
        this.asset = {};
        this.modalService.dismissAll();
      }

    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  closeModal() {
    this.modalService.dismissAll();
  }

}
function ouput(arg0: string) {
  throw new Error('Function not implemented.');
}

