import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppServices } from 'src/app/shared/service/app.services';
import { ConversionService } from 'src/app/shared/service/conversion.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-forgot-pass',
	templateUrl: './forgot-pass.component.html',
	styleUrls: ['./forgot-pass.component.scss']
})
export class ForgotPassComponent implements OnInit {

	ForgotForm: NgForm;
	companyidList: any;
	findUserData: any;
	forpassform: any = {
		username: ''
	}
	constructor(
		private service: AppServices,
		private router: Router,
		private loader: NgxUiLoaderService,
		private conService: ConversionService
	) { }
	owlcarousel = [
		{
			title: "Ezybooks.net",
			desc: "one account, access all services and multiple companies",
		},
		{
			title: "Ezybooks.net",
			desc: "one account, access all services and multiple companies",
		},
		{
			title: "Ezybooks.net",
			desc: "one account, access all services and multiple companies",
		}
	]
	owlcarouselOptions = {
		loop: true,
		items: 1,
		dots: true
	};
	ngOnInit(): void {
	}


	RetrivePassword() {
		this.loader.start();
		this.service.findUser(this.forpassform.username).subscribe((res: any) => {
			this.loader.stop();
			window.localStorage.clear();      //clear all localstorage if availble..
			environment.production ? '' : console.log('this.findUserData : ', this.findUserData);
			if (res.status_code != '1') {
				this.loader.stop();
				Swal.fire('', res.message, 'error');
				return false;
			} else {
				this.findUserData = res.data;
				this.companyidList = this.findUserData.companyids;
				environment.production ? '' : console.log('this.companyidList : ', this.companyidList);
				if (this.companyidList.length > 0) {
					this.forpassform.companyid = this.companyidList[0].companyid;
					environment.production ? '' : console.log('this.forpassform.companyid : ', this.forpassform.companyid);
				}
				var data = {
					email: this.forpassform.username,
					userid: this.findUserData.id,
					companyid: this.forpassform.companyid
				}
				environment.production ? '' : console.log('data : ', data);
				this.service.resetPassword(data).subscribe((res: any) => {
					this.loader.stop();
					environment.production ? '' : console.log('Post reset response : ', res);
					this.forpassform.username = '';
					Swal.fire('', 'Password sent to your registered Email', 'success')
					this.router.navigate(['/login'],{replaceUrl:true});
				}, err => {
					this.loader.stop();
				});
			}
		}, err => {
			this.loader.stop();
		});
	}
}
