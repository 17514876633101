import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { InventoryServices } from 'src/app/components/inventory/inventory.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { AddProductComponent } from '../add-product.component';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  public closeResult: string;
  public categories = []
  product: any;
  reviewList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  reviewData: any = {
    "id": null,
    "description": '',
    "createby": '',
    "createdate": '',
    "updateby": '',
    "updatedate": '',
    "rating": '',
    "active": true,
    "approved": true,
    "prodid": '',
  };
  response: any;
  lastaction: any;
  fieldsetDisabled = false;
  isview: boolean;
  private langChangeSubscription: Subscription;
  constructor(private modalService: NgbModal,
    private translate: TranslateService,
    private languageService: LanguageService,
    private http: HttpClient,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private toastr: NotificationService,
    private maincomponent: AddProductComponent,
    private loader: NgxUiLoaderService,
    private service: InventoryServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.product = maincomponent.product;
    this.lastaction = this.route.snapshot.queryParamMap.get('action');
    if (this.lastaction == 'view') {
      this.fieldsetDisabled = true;
    } else if (this.lastaction == 'edit') {
      this.fieldsetDisabled = false;
    }
    this.getProductReviews();
  }

  ngOnInit(): void {
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => {
      this.loadComponentTranslations(lang);
    });
  }

  private loadComponentTranslations(lang: string) {
		const translationFilePath = `./assets/i18n/inventory/productitems/reviews/${lang}.json`;
		this.http.get(translationFilePath).subscribe((translations:any) => {
		  this.translate.setTranslation(lang, translations, true);
		  this.translate.use(lang);
		});
	}

  sortDir = 1;//1= 'ASE' -1= DSC
  onSortClick(event,colum) {
    let target = event.currentTarget,
      childtarget = target.querySelector('i'),
      classList = childtarget.classList;
  
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      target.classList.add('Sorted');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      target.classList.remove('Sorted');
      this.sortDir = 1;
    }
    this.sortArr(colum);
  }
  
  sortArr(colName: any) {
    this.reviewList.sort((a, b) => {
      let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
      let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];
  
  if (!valueA && !valueB) {
    return 0; 
  }
  if (!valueA) {
    return -this.sortDir; 
  }
  if (!valueB) {
    return this.sortDir; 
  }
  
  if (valueA > valueB) {
    return this.sortDir;
  }
  if (valueA < valueB) {
    return -this.sortDir;
  }
  return 0;
  });
  }
  
  //#endregion Sorting Table Header
  getProductReviews() {
    this.loader.start();
    this.service.getProductReviews(this.product.id).subscribe(res => {
      this.loader.stop();
      console.log(res)
      this.reviewList = res;
      this.reviewList.forEach(rev => {
        rev.active = rev.active ? 'Yes' : 'No';
      })
    }, err => {
      this.loader.stop();
    })
  }

  open(content, data, action) {
    console.log('log', action);

    if (action == 'view') {
      this.isview = true
    } else if (action == 'edit') {
      this.isview = false
    }
    if (data.id) {
      this.reviewData = {
        active: data.active,
        approved: data.approved,
        createby: data.createby,
        createdate: data.createdate,
        description: data.description,
        id: data.id,
        prodid: data.prodid,
        rating: data.rating,
        updateby: data.updateby,
        updatedate: data.updatedate
      };
    } else {
      this.reviewData = {};
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closemodal() {
    this.modalService.dismissAll()
  }
  postProductReview() {
    this.reviewData.companyid = this.userdata.companyid;
    if (this.reviewData.id != undefined && this.reviewData.id != null) {
      this.reviewData.createby = this.reviewData.createby;
      this.reviewData.createdate = this.datepipe.transform(this.reviewData.createdate, this.userdata.postdatetimeformat);
      this.reviewData.updateby = this.userdata.email;
      this.reviewData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    else {
      this.reviewData.prodid = this.product.id;
      this.reviewData.createby = this.userdata.email;
      this.reviewData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.reviewData.active = true;

    environment.production ? '' : console.log('post review data', this.reviewData);
    this.loader.start();
    this.service.postItemReview(this.reviewData).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          this.reviewList = res.data;
          this.reviewList.forEach(rev => {
            rev.active = rev.active ? 'Yes' : 'No';
          })
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });

  }
  closeModal() {
    this.modalService.dismissAll();
  }

}
