import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppServices } from 'src/app/shared/service/app.services';
import { CommonServices } from 'src/app/shared/service/common.service';
import { ExcelService } from 'src/app/shared/service/excel.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { reportServices } from '../../reports/reports.service';
import { ReportgenericprintComponent } from '../reportgenericprint/reportgenericprint.component';
import { accountServices } from '../../accounts/account.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { Subscription } from 'rxjs';
// import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-coamodal',
  templateUrl: './coamodal.component.html',
  styleUrls: ['./coamodal.component.scss']
})
export class COAModalComponent implements OnInit {
  fullScreen = false;
  @Input('coa_id') coa_id;
  @Input('coa_name') coa_name;
  @Input('params') params;
  //#region userdata block
  appname = environment.APP_NAME;
  userdata: any;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  totalRows: any = 0;
  //#endregion userdata block
  coa: any = {
    "id": null,
    "name": '',
    "description": '',
    "seq": 1,
    "coano": null,
    "createby": '',
    "createdate": "",
    "companyid": null,
    "parentid": '',
    "active": true,
    "detail_type": '',
    "account_type": '',
    "updateby": '',
    "updatedate": '',
  };
  closeResult: string;
  isShow = false;
  isSaved = false;
  public config = {
    printMode: 'template-popup',
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    styles: ['#print-section {width:100%;display: block !important; page-break-after: always;}', '.TaskPrint {width:100%;}', 'body { width:100%;}', 'table {width:100%}']
  }
  dropdowntoggle() {
    this.isShow = !this.isShow;
  }
  glAccList: any = [];
  accountGroupingList: any = []
  accountTypesList: any = [];
  detailsTypesList: any = [];
  parentCoaList: any = [];
  isEdit = false;
  private langChangeSubscription: Subscription;
  constructor(
    private loader: NgxUiLoaderService,
    private excel: ExcelService,
    private modalService: NgbModal,
    private service: accountServices,
    private datepipe: DatePipe,
    private toastr: NotificationService,
    private commonservice: CommonServices,
    private activeModal: NgbActiveModal,
    private languageService: LanguageService,
    private translate: TranslateService,
    private http: HttpClient,

  ) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.companyLogo += this.userdata.logo;
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block
    console.log('coamodal');
    var editDataForHeader = ''
    this.commonservice.headerEditItemData.next(editDataForHeader);
    this.accountType();
  }

  accountType() {
    this.service.getAccountType().subscribe(res => {
      this.accountTypesList = res;
      environment.production ? '' : console.log('Account type', res);
    })
  }
  detailType() {
    this.service.getAccountDetailTypes(this.coa.account_type).subscribe(res => {
      this.detailsTypesList = res;
      environment.production ? '' : console.log('Details Type data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading contract types data', err);
    });
  }

  parentCoa() {
    this.service.getSubAccount(this.coa.account_type).subscribe(res => {
      this.parentCoaList = res;
      environment.production ? '' : console.log('Sub account data ', res);
    })
  }

  closeModal() {
    this.activeModal.close(this.isSaved ? 'save' : '');
  }

  getDismissReason(reason: any) {
    throw new Error('Method not implemented.');
  }
  ngOnInit() {
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => {
      // If you need to do anything additional when the language changes, do it here

      this.loadComponentTranslations(lang);
    });

    if (this.params.action == 'new') {
      this.isEdit = false;
    } else if (this.params.action == 'add') {
      this.isEdit = true;
      this.coa.account_type = this.params.account_type;
      this.coa.detail_type = this.params.detail_type;
      this.coa.parentid = this.params.parentid;
    } else {
      this.isEdit = true;
      this.getAccountDetails(this.params.id);
    }
  }

  private loadComponentTranslations(lang: string) {
    const translationFilePath = `./assets/i18n/accounts/coa/${lang}.json`;
    this.http.get(translationFilePath).subscribe((translations: any) => {
      this.translate.setTranslation(lang, translations, true);
      this.translate.use(lang);
    });
  }

  getAccountDetails(id) {
    this.loader.start();
    this.service.getCOADetails(id).subscribe(res => {
      this.loader.stop();
      this.coa = res;
    }, err => {
      this.loader.stop();
    })
  }
  saveCoa() {
    if (this.coa.id == undefined || this.coa.id == null) {
      var account_type;
      var account_type_id;
      this.accountTypesList.forEach(acc => {
        if (this.coa.account_type == acc.Value) {
          account_type = acc.Text;
          account_type_id = acc.Value;
        }
      })
      this.coa.parentid = this.coa.parentid ? this.coa.parentid : account_type_id;
      this.coa.account_type = account_type ? account_type : (this.coa.account_type ? this.coa.account_type : '');

      this.coa.companyid = this.userdata.companyid;
      this.coa.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.coa.createby = this.userdata.email;
    } else {
      this.coa.createdate = this.datepipe.transform(new Date(this.coa.createdate), this.userdata.postdatetimeformat);
      this.coa.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.coa.updateby = this.userdata.email;
    }
    // this.coa.parentid = Number(this.coa.parentid);
    environment.production ? '' : console.log(JSON.stringify(this.coa));
    this.loader.start();
    this.service.postCoa(this.coa).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isSaved = true;
      }
      this.coa = {};
      this.closeModal();
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading chart of account ', err);
    });
  }

}
