import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { element } from 'screenfull';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { purchaseServices } from '../../purchase/purchase.service';

@Component({
  selector: 'app-shortshipmentpo',
  templateUrl: './shortshipmentpo.component.html',
  styleUrls: ['./shortshipmentpo.component.scss']
})
export class ShortshipmentpoComponent implements OnInit {
  fullScreen = false;
  @Input('poid') poid;
  @Input('pon') pon;
  poItems: any = [];
  qtyOnOrderTotal = 0;
  shippedQtyTotal = 0;
  receivedQtyTotal = 0;
  shortQtyTotal = 0;
  poDetails: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  constructor(private activeModal: NgbActiveModal,
    private toaster: NotificationService,
    private loader: NgxUiLoaderService, private datepipe: DatePipe, private toastr: NotificationService, private service: purchaseServices, private modalService: NgbModal) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  ngOnInit(): void {
    this.getPOItems();
  }
  getPOItems() {
    this.loader.start();
    this.service.getShortPOItems(this.poid).subscribe(res => {
      this.loader.stop();
      this.poItems = res;
      this.qtyOnOrderTotal = 0;
      this.shippedQtyTotal = 0;
      this.receivedQtyTotal = 0;
      this.shortQtyTotal = 0;
      if (this.poItems != null) {
        this.poItems.forEach(item => {
          item.shortqty = ((item.qty ? Number(item.qty) : 0) - (item.qty ? Number(item.receivedqty) : 0));
          this.qtyOnOrderTotal += item.qty;
          this.shippedQtyTotal += item.shippedqty;
          this.receivedQtyTotal += item.receivedqty;
          this.shortQtyTotal += item.shortqty;
        })
      }
      environment.production ? '' : console.log(' supplier po data ', this.poItems);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }
  postShortPO() {
    var itemsLists = [];
    if (this.poItems != null && this.poItems != undefined) {
      this.poItems.forEach(item => {
        if (item.shortqty > 0) {
          itemsLists.push({
            "poeid": item.poeid,
            "ilc": item.ilc,
            "description": item.description,
            "qty": item.qty,
            "shippedqty": item.shippedqty,
            "receivedqty": item.receivedqty,
            "shortqty": item.shortqty
          })
        }
      })
    }
    if (itemsLists.length == 0) {
      this.toaster.Error('Please enter short qty')
      return;
    }

    var postObj = {
      "companyid": this.userdata.companyid,
      "createby": this.userdata.email,
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "pon": this.pon,
      "itemsLists": itemsLists
    }
    this.loader.start();
    this.service.postPOShortItems(postObj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }
  calculateItemTotal(item) {
    this.shortQtyTotal = 0;
    item.shortqty = item.shortqty ? item.shortqty : 0;
    var qt2berecv = ((item.qty ? item.qty : 0) - (item.receivedqty ? item.receivedqty : 0));
    if (item.shortqty > qt2berecv) {
      item.shortqty = (item.qty ? item.qty : 0) - (item.receivedqty ? item.receivedqty : 0)
    }
    this.poItems.forEach(item => {
      if (item.shortqty != null && item.shortqty != '' && item.shortqty > 0) {
        this.shortQtyTotal += item.shortqty;
      }
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  onSortClick(event) {

  }
}
