import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductActionColumn, ProductListComponent } from './products.component';
import { AddProductComponent } from './add-product/add-product.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { AliasComponent } from './add-product/alias/alias.component';
import { BinlocationsComponent } from './add-product/binlocations/binlocations.component';
import { SuppliersComponent } from './add-product/suppliers/suppliers.component';
import { PriceauditlogsComponent } from './add-product/priceauditlogs/priceauditlogs.component';
import { PohistoryComponent } from './add-product/pohistory/pohistory.component';
import { TransactionhistoryComponent } from './add-product/transactionhistory/transactionhistory.component';
import { PicturesComponent } from './add-product/pictures/pictures.component';
import { LoyaltyprogramsComponent } from './add-product/loyaltyprograms/loyaltyprograms.component';
import { DocumentTabComponent } from './add-product/documents/documents.component';
import { ReviewComponent } from './add-product/review/review.component';
import { AuditlogsComponent } from './add-product/auditlogs/auditlogs.component';
import { SerialsComponent } from './add-product/serials/serials.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { CKEditorModule } from 'ng2-ckeditor';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SubstituteproductComponent } from './add-product/substituteproduct/substituteproduct.component';
import { AdjustQtyComponent } from './modal/adjust-qty/adjust-qty.component';
import { TransferQtyComponent } from './modal/transfer-qty/transfer-qty.component';
import { HoldqtyComponent } from './modal/holdqty/holdqty.component';
import { ItemanalysisComponent } from './modal/itemanalysis/itemanalysis.component';
import { PurchasehistoryComponent } from './modal/purchasehistory/purchasehistory.component';
import { FileUploadModule } from 'ng2-file-upload';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AdduomComponent } from './modal/adduom/adduom.component';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BinLocationFilterPipe } from './add-product/binlocations/bin-location-filter.pipe';
import { SupplierfilterPipe } from './add-product/suppliers/supplierfilter.pipe';
import { BinholddetailsComponent } from './modal/binholddetails/binholddetails.component';
import { TaxComponent } from './add-product/tax/tax.component';
import { BinlocationsListComponent } from './modal/binlocations/binlocations.component';
import { SerialsListComponent } from './modal/serials/serials.component';
import { LottrackingComponent } from './add-product/lottracking/lottracking.component';
import { AddonsComponent } from './add-product/addons/addons.component';
import { TimeingComponent } from './add-product/timeing/timeing.component';
import { ResizeModule } from '../../modal/resize/resize-border.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TransferserialsComponent } from './modal/transferserials/transferserials.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { ProductBinComponent } from './modal/product-bin/product-bin.component';
import { UomRatioComponent } from './modal/uom-ratio/uom-ratio.component';
import { DetailsComponent } from './add-product/details/details.component';
import { ItemCompatibilityComponent } from './modal/itemcompatibility/itemcompatibility.component';
import { CompatibilityComponent } from './add-product/compatibility/compatibility.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgToggleModule } from 'ng-toggle-button';
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};

@NgModule({
  declarations: [ProductListComponent, AddProductComponent, AliasComponent, DetailsComponent,CompatibilityComponent, BinlocationsComponent, SuppliersComponent, PriceauditlogsComponent, PohistoryComponent, TransactionhistoryComponent, PicturesComponent, LoyaltyprogramsComponent, DocumentTabComponent, ReviewComponent, AuditlogsComponent, SerialsComponent, ProductActionColumn, SubstituteproductComponent, AdjustQtyComponent, ProductBinComponent, TransferQtyComponent, HoldqtyComponent, ItemanalysisComponent, PurchasehistoryComponent, ItemCompatibilityComponent, AdduomComponent, BinLocationFilterPipe, SupplierfilterPipe, BinholddetailsComponent, TaxComponent, BinlocationsListComponent, SerialsListComponent, LottrackingComponent, AddonsComponent, TimeingComponent, TransferserialsComponent, UomRatioComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    AutocompleteLibModule,
    ProductsRoutingModule,
    Ng2SmartTableModule,
    NgbModule,
    DropzoneModule,
    GalleryModule.forRoot(),
    NgxUiLoaderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    FileUploadModule,
    MatChipsModule,
    MatAutocompleteModule,
    AngularMultiSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    ResizeModule,
    NgToggleModule,
    NgSelectModule,
    NgImageSliderModule,
    HttpClientModule, 
TranslateModule.forRoot({ 
 loader: { 
 provide: TranslateLoader, 
 useFactory: HttpLoaderFactory, 
 deps: [HttpClient] 
 } 
 }) 
 
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    NgbActiveModal
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class ProductsModule { }
export function HttpLoaderFactory(http: HttpClient) { 
  return new TranslateHttpLoader(http); 
 } 