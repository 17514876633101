<div id="main" class="main-content-wraper">
    <div class="main-content-inner">
        <div class="main-header">
            <div class="TopNav">
                <div class="container">
                    <ul>
                        <li><i class="fa fa-phone"></i><a href="tel:3453255469" title="call us">+1(345) 325 5469</a> &
                            <a href="tel:+13456406292" title="call us"> +1(345) 640 6292</a>
                        </li>
                        <li><a href="mailto:contactus@sanmolsoftware.com" title="mail us"><i class="fa fa-envelope-open"
                                    style="margin-right:10px;"></i>contactus@sanmolsoftware.com</a></li>
                    </ul>
                </div>
            </div>
            <header class="header">
                <div class="container">
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" href="" title="ezyBooks">
                            <img src="assets/images/applogo.svg" alt="ezyBooks">
                        </a>
                        <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
                            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                            <span class="" [ngClass]="isCollapsed?'fa fa-bars':'fa fa-times'"></span>
                        </button>
                        <div class="navbar-collapse" [ngbCollapse]="isCollapsed">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item active"><a class="nav-link" title="Home"
                                        (click)="gotoHome()">Home</a></li>
                                <li class="nav-item"><a class="nav-link" title="About" (click)="gotoAbout()">About</a>
                                </li>
                                <li class="nav-item"><a class="nav-link" (click)="gotoOverview()"
                                        title="Overview">Overview</a></li>
                                <li class="nav-item"><a class="nav-link" (click)="gotoFeature()"
                                        title="Features">Features</a></li>
                                <li class="nav-item"><a class="nav-link" (click)="gotoPricing()"
                                        title="Pricing">Pricing</a></li>
                                <li class="nav-item"><a class="nav-link" (click)="gotoContact()" title="Contact">Contact
                                        us</a></li>
                            </ul>
                            <ul class="nav navbar-nav navbar-right">
                                <li><a routerLink="/public/createaccount" class="btn btn-outline-primary"
                                        title="Sign Up">Sign Up</a>
                                </li>
                                <li>
                                    <a routerLink="/auth/login" class="btn btn-primary" title="Sign In">Sign In</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
        <div class="container">
            <div class="auth-section">
                <div class="row">
                    <div class="col-lg-6 content">
                        <a (click)="back()" title="Back" class="bnt btnBack">Back</a>
                        <ng-container *ngIf="otpDivVisibility">
                            <h3>We have sent OTP to your registered email</h3>
                            <form name="verifyotp" #f="ngForm" (ngSubmit)="VerifyOTP()">
                                <div class="form-group">
                                    <label class="required" for="myotp">OTP</label>
                                    <input type="tel" class="form-control" name="myotp" #myotp="ngModel" minlength="6"
                                        id="myotp" [(ngModel)]="verificationCode.myotp" placeholder="Code"
                                        [ngClass]="{ 'is-invalid': myotp.invalid && myotp.errors , 'is-valid': myotp.valid}"
                                        required="" />
                                    <div *ngIf="myotp.invalid && myotp.errors" class="invalid-feedback d-block">
                                        <div *ngIf="myotp.errors.required">
                                            OTP is required
                                        </div>
                                        <div *ngIf="myotp.errors.minlength">
                                            Must have 6 digits
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary btn-block mt-3" [disabled]="!f.valid">
                                    Verify
                                </button>
                            </form>
                        </ng-container>
                        <ng-container *ngIf="!otpDivVisibility">
                            <h3>Your email is associated with following company files</h3>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml radio-animated">
                                <label class="d-block" for="company1" *ngFor="let comp of otpdetails.companies">
                                    <input class="radio_animated" id="company1" [value]="comp.companyid" [checked]="comp.ischecked" type="radio"
                                        name="companyid"><span class="ml-1">{{comp.displayname}}</span>
                                </label>
                            </div>
                            <div class="row mt-3">
                                <div class="col-lg-6 col-md-6">
                                    <button type="button" (click)="gotoLogin()" class="btn btn-primary btn-block">
                                        Click here to Login
                                    </button>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <button type="button" (click)="gotoChooseIndustry()" class="btn btn-primary btn-block">
                                        Create new company
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-lg-6">
                        <img src="assets/images/homepage/free-account.png" alt="free account sign up"
                            class="img-fluid auth-img" width="500" height="500" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>