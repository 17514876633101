import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SalesServices } from '../../Sales/sales.service';

@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.scss']
})
export class CreditComponent implements OnInit {
  fullScreen = false;
  @Input('customerid') customerid = null;
  creditInfo: any;
  credit: any = {
    amt: 0,
    payment_type: '',
    memo: 'Credit applied',
    currency: '',
    notify_customer: false,
    roe: 0,
    allDues: []
  };
  receivePayment = true;
  paymentTypeList: any = [];
  customerCredits: any = [];
  item: any = {};
  selectCreditTotal: any = 0.0;
  duepayments: any = []
  credtitotal: any = 0;
  appname = environment.APP_NAME;
  userdata: any;
  currencyList: any = [];
  balanceTotal: any = 0;
  appliedPayTotal: any = 0;
  constructor(private modalService: NgbModal,
    private toastr: NotificationService,
    private toaster:ToastrService,
    private datepipe: DatePipe, private service: SalesServices, private loader: NgxUiLoaderService, private commonService: CommonServices, private cd: ChangeDetectorRef, private activeModal: NgbActiveModal) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.credit.trandate = datepipe.transform(new Date(), this.userdata.date_format);
    this.credit.roe = Number(0).toFixed(this.userdata.decimals_allowed);
    this.credit.amt = Number(0).toFixed(this.userdata.decimals_allowed);
    this.appliedPayTotal = Number(0).toFixed(this.userdata.decimals_allowed);
    this.balanceTotal = Number(0).toFixed(this.userdata.decimals_allowed);
    this.commonService.getComboEnums('paymenttype').subscribe(res => {
      this.paymentTypeList = res;
      environment.production ? '' : console.log('payment types data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading payment types data', err);
    });

    this.commonService.getCurrency().subscribe(res => {
      this.currencyList = res;
      this.selectROE();
    }, err => {
    });
  }

  ngOnInit(): void {
    this.getAvailableCredit();
  }

  getAvailableCredit() {
    this.credtitotal = 0;
    this.service.getCustCreditBal(this.customerid).subscribe(res => {
      this.credit = res;
      this.balanceTotal = 0;
      this.credit.allDues.forEach(inv => {
        this.balanceTotal += (inv.bal ? Number(inv.bal) : 0);
        inv.bal = inv.bal ? Number(inv.bal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        inv.invdate = this.datepipe.transform(new Date(inv.invdate), this.userdata.date_format);
      })
      this.balanceTotal = this.balanceTotal ? Number(this.balanceTotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.credit.trandate = this.datepipe.transform(new Date(), this.userdata.date_format);
      this.credit.payment_type = 'normal';
      this.credtitotal = this.credit.availbalance ? Number(this.credit.availbalance).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.selectROE();
    }, err => {
      environment.production ? '' : console.log('Error : While loading creditInfo', err);
    });
  }
  selectROE() {
    this.currencyList.forEach(element => {
      if (this.credit.currency == element.Value) {
        this.credit.roe = element.Value2;
      }
      else if (!this.credit.currency) {
        this.credit.roe = '';
      }
    })
    this.credit.roe = this.credit.roe ? Number(this.credit.roe).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
  }
  selectInv(item) {
    environment.production ? '' : console.log('selected item', item);
    this.credit.allDues.forEach(element => {
      if (element.Value == item) {
        var list = element.Text.split('-')
        this.credit.amt = list[1] ? Number(list[1]).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      }
    });
  }
  getCurrentROE() {
		if (!this.credit.currency) {
			this.toaster.error('Please select currency');
			return;
		}
   
		this.loader.start();
		this.commonService.getExchangeRate(this.userdata.currency, this.credit.currency).subscribe(res => {
			this.loader.stop();
			this.credit.roe = res ? Number(res).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
		}, err => {
			this.loader.stop();
		});
	}

  deleteLine(item) {
    this.loader.start();
    this.service.deleteCustomerCredit(item.pay_id, item.avail_credit).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          this.getAvailableCredit();
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
    }, err => {
      this.loader.stop();
    })
  }

  selectCreditLineItemCheckBox(event, item) {
    environment.production ? '' : console.log('item selected', event.target.checked);
    if (event.target.checked == true) {
      item.pay_applied = item.pay_applied = Number(item.bal).toFixed(this.userdata.decimals_allowed);
    } else {
      item.pay_applied = Number(0).toFixed(this.userdata.decimals_allowed);
    }
    this.cd.detectChanges();
    this.calculatetotal(item);
    environment.production ? '' : console.log('updated list', this.duepayments);
  }
  calculatetotal(item) {
    if (item.pay_applied == undefined || item.pay_applied == '' || item.pay_applied == null) {
      item.pay_applied = Number(0).toFixed(this.userdata.decimals_allowed);
    }
    if (item.pay_applied > 0) {
      if (Number(item.pay_applied) > Number(item.bal)) {
        item.pay_applied = item.bal;
      }
      item.checked = true;
    } else {
      item.checked = false;
    }
    this.calculateCreditTotal()
  }
  calculateCreditTotal() {
    this.credit.amt = 0;
    this.balanceTotal = 0;
    this.credit.allDues.forEach(element => {
      this.balanceTotal += element.bal ? Number(element.bal) : 0;
      if (element.pay_applied > 0) {
        this.credit.amt += Number(element.pay_applied);
      }
    });
    this.credit.amt = Number(this.credit.amt).toFixed(this.userdata.decimals_allowed);
    this.appliedPayTotal = Number(this.credit.amt).toFixed(this.userdata.decimals_allowed);
    this.balanceTotal = Number(this.balanceTotal).toFixed(this.userdata.decimals_allowed);
  }

  postCredit() {
    var isInvSelected = false;
    var allDues = [];
    if (this.credit.allDues.length > 0) {
      this.credit.allDues.forEach(due => {
        if (due.pay_applied > 0) {
          isInvSelected = true;
          allDues.push(due);
        }
      })
    }
    if (!isInvSelected) {
      this.toastr.Error('Please select invoice');
      return;
    }
    if ((this.credit.availbalance ? this.credit.availbalance : 0) < this.credit.amt) {
      this.toastr.Error('Applied payment cannot exceed the credit available');
      return;
    }
    if (!this.credit.memo) {
      this.toastr.Error('Please enter memo');
      return;
    }
    if (!this.credit.currency) {
      this.toastr.Error('Please select currency');
      return;
    }
    this.credit.createby = this.userdata.email;
    this.credit.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    this.credit.trandate = this.datepipe.transform(new Date(this.credit.trandate), this.userdata.postdatetimeformat);
    this.credit.allDues = allDues;
    this.loader.start();
    this.service.postCustomerCredit(this.credit).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.modalService.dismissAll();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading credit ', err);
    });
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}
