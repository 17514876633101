import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { hrServices } from '../../hr/hr.services';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { settingServices } from '../../settings/setting.service';

@Component({
  selector: 'app-createoffer',
  templateUrl: './createoffer.component.html',
  styleUrls: ['./createoffer.component.scss']
})
export class ModalCreateOfferComponent implements OnInit {
  fullScreen = false;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  userdata: any;
  appname = environment.APP_NAME;
  @Input('params') params;
  offerData: any = {
    offer_type: '',
    paytype: '',
    workshiftid: '',
    status: '',
    designationid: '',
    locationid: '',
    worktimezone: '',
    contractid: '',
    id: null,
    gender: '',
    name: '',
    email: ''
  }
  selectedList: any = [];
  designationList: any = [];
  locationList: any = [];
  timezoneList: any = [];
  isSaved = false;
  contractList: any = [];
  componentList: any = [];
  offerStatusList: any = [];
  offertype: any = [];
  shiftList: any = [];
  paytypeList: any = [];
  lastaction: any;
  constructor(private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private commonService: CommonServices,
    private settingService: settingServices,
    private service: hrServices,
    private toastr: NotificationService,
    private commonsevice: CommonServices,
    private datepipe: DatePipe,) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.companyLogo += this.userdata.logo;
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block
    this.commonsevice.getComboEnums('employeeofferstatus').subscribe(res => {
      this.offerStatusList = res;
    }, err => {
    });
    this.commonsevice.getComboEnums('employmentoffertype').subscribe(res => {
      this.offertype = res;
    }, err => {
    });
    this.service.getcompShiftDrop().subscribe(res => {
      this.shiftList = res;
    }, err => {
      this.loader.stop();
    });

    this.commonsevice.getComboEnums('paytype').subscribe(res => {
      this.paytypeList = res;
    }, err => {
    });
    this.commonsevice.getCompLocation().subscribe(res => {
      this.locationList = res;
    }, err => {
    });
    this.commonsevice.getDesignationList().subscribe(res => {
      this.designationList = res;
    }, err => {
    });
    this.settingService.getClockTimezone().subscribe(res => {
      this.timezoneList = res;
    }, err => {
    });
    this.selectedList = [];
    this.getContracts();
    this.getSalaryComponentList();
  }
  getOfferDetailsById(id) {
    this.service.getJobOfferById(id).subscribe(res => {
      this.offerData = res;
      this.selectedList = this.offerData.offerComponents;
      this.selectedList.forEach(item => {
        this.calculateInDecimal(item);
      })
    })
  }
  getContracts() {
    this.loader.start();
    this.service.getContractLegalType().subscribe(res => {
      this.loader.stop();
      this.contractList = res;
    }, err => {
      this.loader.stop();
    });
  }
  getSalaryComponentList() {
    this.loader.start();
    this.settingService.getCompanySalaryComponentList().subscribe(res => {
      this.loader.stop();
      this.componentList = res;
    }, err => {
      this.loader.stop();
    });
  }
  calculateInDecimal(item) {
    item.fix_value = item.fix_value ? Number(item.fix_value).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    item.fix_yearly_value = (item.fix_value ? Number(item.fix_value) : 0) * 12;
    item.fix_yearly_value = item.fix_yearly_value ? Number(item.fix_yearly_value).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    item.max_value = item.max_value ? Number(item.max_value).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    item.max_yearly_value = (item.max_value ? Number(item.max_value) : 0) * 12;
    item.max_yearly_value = item.max_yearly_value ? Number(item.max_yearly_value).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
  }
  addSalaryComponent(item) {
    this.selectedList.push(item)
  }
  selectComponent(item) {
    this.selectedList.push({
      'type': item.Value3,
      'description': item.Text,
      'max_value': item.max_value,
      'fix_value': item.fax_value,
      'company_salary_componentid': item.Value,
      // 'employment_offerid': this.mainComponent.applicationData.id
    })
    this.offerData.selectedComponent = [];
  }

  ngOnInit(): void {
    this.lastaction = this.params.action;
    if (this.params.action != 'add') {
      this.getOfferDetailsById(this.params.id);
    } else {
      this.offerData.gender = this.params.gender;
      this.offerData.name = this.params.name;
      this.offerData.email = this.params.email;
    }
  }

  closeModal() {
    this.activeModal.close(this.isSaved ? 'save' : '');
  }

  saveOffer() {
    this.offerData.companyid = this.userdata.companyid;
    if (this.offerData.id) {
      this.offerData.createby = this.offerData.createby;
      this.offerData.createdate = this.datepipe.transform(this.offerData.createdate, this.userdata.postdatetimeformat)
      this.offerData.updateby = this.userdata.email;
      this.offerData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat)
    } else {
      this.offerData.refid = this.params.refid;
      this.offerData.createby = this.userdata.email;
      this.offerData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat)
    }
    this.offerData.date_accepted = this.offerData.date_accepted ? this.datepipe.transform(this.offerData.date_accepted, this.userdata.postdateformat) : null;
    this.offerData.expected_doj = this.offerData.expected_doj ? this.datepipe.transform(this.offerData.expected_doj, this.userdata.postdateformat) : null;

    this.offerData.t_employment_offer_components = this.selectedList;
    this.offerData.contractid = this.offerData.contractid ? Number(this.offerData.contractid) : null;
    this.loader.start();
    this.service.postJobOffer(this.offerData).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isSaved = true;
      }
      this.selectedList = []
      this.offerData = {};
      this.closeModal();
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading Post education history  ', err);
    });
  }
  deleteOfferComponent(index, item) {
    if (item.id == undefined || item.id == null) {
      this.selectedList.splice(index, 1);
      return;
    }
    Swal.fire({
      title: 'Are you sure want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.selectedList.splice(index, 1);
        this.loader.start();
        this.service.deleteJobOfferComponent(item.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
          }
        }, err => {
          this.loader.stop();
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

}
