<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">{{'Index_Filter.product_catalog_title' | translate }}
      </h5>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" (scroll)="onWindowScroll($event)">
      <div class="form-row">
        <div class="col-lg-2 form-group">
          <ng-select placeholder="{{'Index_Filter.search_department_placeholder' | translate }}" [(ngModel)]="pcatg.deptid" name="deptid" [clearable]="pcatg.deptid"
            (change)="selectDept()">
            <ng-option *ngFor="let item of deptList" [value]="item.Value">
              {{item.Text}}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-lg-2 form-group">
          <ng-select placeholder="{{'Index_Filter.search_category_placeholder' | translate }}" [(ngModel)]="pcatg.categoryid" name="categoryid"
            [clearable]="pcatg.categoryid" (change)="selectCategory()">
            <ng-option *ngFor="let item of categoryList" [value]="item.Value">
              {{item.Text}}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-lg-2 form-group">
          <ng-select placeholder="{{'Index_Filter.search_subcategory_placeholder' | translate }}" [(ngModel)]="pcatg.subcategoryid" name="subcategoryid"
            [clearable]="pcatg.subcategoryid" (change)="searchProduct()">
            <ng-option *ngFor="let item of subCategoryList" [value]="item.Value">
              {{item.Text}}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-lg-2 form-group">
          <select class="form-control" name="dept" [(ngModel)]="pcatg.pricingtype" (change)="searchProduct();">
            <option value="">{{'Index_Filter.pricing_type_placeholder' | translate }}</option>
            <option *ngFor="let item of priceingTypeList" [value]="item.stringValue">{{item.Text}}</option>
          </select>
        </div>
        <div class="col-lg-4 form-group">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="{{'Index_Filter.search_placeholder' | translate }}" aria-label="Search"
              aria-describedby="basic-addon2" name="product" [(ngModel)]="product">
            <div class="input-group-append">
              <span class="input-group-text btn-primary" id="basic-addon2" (click)="searchProduct()"><span
                  class="fa fa-search text-white"></span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="SearchOption d-flex justify-content-end">

        <div class="btn-group toggler">
          <button type="button" id="gridView" class="btn btn-primary" autocomplete="off" (click)="gridView()">
            <i class="fa fa-table" aria-hidden="true"></i> {{'Index_Filter.grid_view_button' | translate }}
          </button>
          <button type="button" id="tileView" class="btn btn-primary" autocomplete="off" (click)="tileView()">
            <i class="fa fa-th-list" aria-hidden="true"></i> {{'Index_Filter.Picture_view_button' | translate }}
          </button>
        </div>
      </div>
      <div class="ShowtileView row mt-2" *ngIf="isShowtileView">
        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6" *ngFor="let item of productList;let i=index">
          <div class="proBox">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <img [src]="item.pic" onerror="this.src='assets/images/100.png';" class="img-fluid">
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <div class="ProName"><b>{{item.ilc}}</b></div>
                <div>
                  <b>{{'Index_Filter.description_Text' | translate }} </b>
                  {{item.desc}}<br>
                  {{'Index_Filter.uom_Text' | translate }} {{item.uom}}
                </div>
                <div><b>{{'Index_Filter.do_not_sell_Text' | translate }}</b> <span *ngIf="item.donosell">{{'Index_Filter.do_notsell_OptionYes' | translate }}</span><span *ngIf="!item.donosell">{{'Index_Filter.do_notsell_OptionNo' | translate }}</span>
                </div>
                <div><b>{{'Index_Filter.instock_Text' | translate }}</b> {{item.qtyonhand}}</div>
                <div> <b>{{'Index_Filter.price_Text' | translate }}</b> {{item.price|number:'1.2-2'}}</div>
                <div class="FootBox">
                  <span><b>{{'Index_Filter.qty_Text' | translate }}</b></span>
                  <input placeholder="Enter Quatity" name="qty-{{i}}" [(ngModel)]="item.qty" value="1"
                    class="form-control">
                  <button class="btn btn-primary btn-sm" (click)="selectEvent(item)">{{'Index_Filter.add_to_order_button' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ShowGridView mt-2" *ngIf="isShowGridView">
        <!-- Need to add custom html code here -->
        <table class="table table-responsive input-table">
          <thead>
            <tr class="custom-tr">
              <th class="custom-th text-center">{{'Catlog_Table.ILC_Column' | translate }}</th>
              <th class="custom-th text-center" style="width:30%">{{'Catlog_Table.Description_Column' | translate }}</th>
              <th class="custom-th text-center" style="width:4%">{{'Catlog_Table.QtyInStock_Column' | translate }}</th>
              <th class="custom-th text-center" style="width:4%">{{'Catlog_Table.QtyOnOrder_Column' | translate }}</th>
              <th class="custom-th text-center">{{'Catlog_Table.DoNotSell_Column' | translate }}</th>
              <th class="custom-th text-center">{{'Catlog_Table.QtyAvail_Column' | translate }} </th>
              <th class="custom-th text-center">{{'Catlog_Table.Price_Column' | translate }} </th>
              <th class="custom-th text-center" style="width:4%">{{'Catlog_Table.UOM_Column' | translate }}</th>
              <th class="custom-th text-center" style="width:10%">{{'Catlog_Table.Qty_Column' | translate }}</th>
              <th class="custom-th text-center">{{'Catlog_Table.Actions_Column' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="custom-tr" *ngFor="let item of productList;let i=index">
              <td class="custom-td text-center">{{item.ilc}}</td>
              <td class="custom-td text-left">{{item.desc}}</td>
              <td class="custom-td text-center">{{item.qtyonhand}}</td>
              <td class="custom-td text-center">{{item.qtyonorder}}</td>
              <td class="custom-td text-center">{{item.donosell}}</td>
              <td class="custom-td text-center">{{item.qtyavailable}}</td>
              <td class="custom-td text-right">{{item.price | number:'1.2-2'}}</td>
              <td class="custom-td text-center">{{item.uom}}</td>
              <td class="custom-td text-center"><input type="number" [(ngModel)]="item.qty" class="form-control"
                  name="qty-{{i}}"></td>
              <td class="custom-td text-center">
                <div ngbDropdown class="d-inline-block ActionCell" container="body" (click)="showDropdown()">
                  <i class="fa fa-plus" id="add" (click)="selectEvent(item)"></i>
                  &nbsp;
                  &nbsp;
                  <ng-container (click)="showDropdown()">
                    <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                  </ng-container>
                  <ul ngbDropdownMenu *ngIf="myDropShow" class="ActionCell-List" aria-labelledby="dropdownBasic1">
                    <li><span ngbDropdownItem (click)="itemalysis(item)">{{'Catlog_Table.item_analysis_button' | translate }}</span></li>
                    <li><span ngbDropdownItem (click)="purchasehistory(item)">{{'Catlog_Table.purchase_history_button' | translate }}</span></li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End code here -->
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">{{'Catlog_Table.close_modal_button' | translate }}</button>
    </div>
  </div>
</resize-border>