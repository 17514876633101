<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                data-toggle="modal" data-original-title="Add" container="body" ngbTooltip="{{ 'Table_Columns.Loyalty_Tooltip' | translate }}"
                data-target="#exampleModal" [class.disabled]="fieldsetDisabled" (click)="open(content)"><i
                  class="fa fa-plus"></i> </a></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'name')">{{ 'Table_Columns.Name_Column' | translate }}<i
                class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'active')">{{ 'Table_Columns.Active_Column' | translate }}<i
                class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'createby')">{{ 'Table_Columns.CreateBy_Column' | translate }}<i
                class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'createdate')">{{ 'Table_Columns.CreateDate_Column' | translate }}<i
                class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'updateby')">{{ 'Table_Columns.UpdateBy_Column' | translate }} <i
                class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'updatedate')"> {{ 'Table_Columns.UpdateDate_Column' | translate }}<i
                class="Sorting fa fa-chevron-up"></i> </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of loyalityPrograms">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem data-toggle="modal" data-original-title="View" data-target="#exampleModal"
                      (click)="open(content)">{{ 'Table_Actions.View_Action' | translate }}</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled" data-toggle="modal"
                      data-original-title="Edit" data-target="#exampleModal" (click)="open(content)">{{ 'Table_Actions.Edit_Action' | translate }}</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled">{{ 'Table_Actions.Delete_Action' | translate }}</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.name}}</td>
            <td class="custom-td text-center">{{item.active}}</td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-left">{{item.createdate}}</td>
            <td class="custom-td text-left">{{item.updateby}}/td>
            <td class="custom-td text-left">{{item.updatedate}}</td>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">{{ 'Modal_Title' | translate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation">
        <div class="form">
          <div class="form-group">
            <label for="" class="mb-1">{{ 'Modal_Input.LoyaltyProgram_Label' | translate }} :</label>
            <select class="form-control" required="">
              <option value=""> {{ 'Modal_Input.LoyaltyProgram_Placeholder' | translate }}</option>
              <option value="1">eBooks</option>
              <option value="2">Graphic Design</option>
              <option value="3">3D Impact</option>
              <option value="4">Application</option>
              <option value="5">Websites</option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
        (click)="modal.dismiss('Cross click')">{{ 'Modal_Buttons.Close_Button' | translate }}</button>
      <button type="button" class="btn btn-primary">{{ 'Modal_Buttons.Save_Button' | translate }}</button>
    </div>
  </resize-border>
</ng-template>