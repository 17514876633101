<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <form (keydown.enter)="getMarkersList()">
          <div class="custom-row">
            <div class="form-row">
              <div class="col-lg-3 col-md-6 form-group pl-0">
                <label for="">{{ 'Index_Filter.Search_Label' | translate }}</label>
                <input type="text" class="form-control" placeholder="{{ 'Index_Filter.Search_Placeholder' | translate }}" name="description"
                  [(ngModel)]="filter.description">
              </div>
              <div class="col-lg-3 col-md-6 form-group pl-0">
                <label for="">{{ 'Index_Filter.SiteLocation_Label' | translate }}</label>
                <ng-select placeholder="{{ 'Index_Filter.SiteLocation_Placeholder' | translate }}" id="siteid" [(ngModel)]="filter.siteid" #siteid="ngModel"
                  name="siteid" [clearable]="filter.siteid" (change)="getMarkersList()">
                  <ng-option *ngFor="let item of locationList" [value]="item.Value">
                    {{item.Text}}</ng-option>
                </ng-select>
              </div>
              <div class="col-lg-3 col-md-6 form-group pl-0">
                <label for="">{{ 'Index_Filter.Type_Label' | translate }}</label>
                <ng-select placeholder="{{ 'Index_Filter.Type_Placeholder' | translate }}" id="markertype" [(ngModel)]="filter.markertype"
                  #markertype="ngModel" name="markertype" [clearable]="filter.markertype" (change)="getMarkersList();getSubTypes()">
                  <ng-option *ngFor="let item of types" [value]="item.Value">
                    {{item.Text}}</ng-option>
                </ng-select>
              </div>
              <div class="col-lg-3 col-md-6 form-group pl-0">
                <label for="">{{ 'Index_Filter.SubType_Label' | translate }}</label>
                <ng-select placeholder="{{ 'Index_Filter.SubType_Placeholder' | translate }}" id="submarkertype" [(ngModel)]="filter.submarkertype"
                  #submarkertype="ngModel" name="submarkertype" [clearable]="filter.submarkertype"
                  (change)="getMarkersList()">
                  <ng-option *ngFor="let item of subTypes" [value]="item.Value">
                    {{item.Text}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="btn-group CsearchBtn1 CsearchBtn" role="group">
              <button type="button" container="body" ngbTooltip="{{ 'Index_Filter.Search_Button' | translate }}" class="btn btn-primary right-radius"
                (click)="getMarkersList()"><i class="refresh-btn fa fa-search"></i></button>
              <button type="button" container="body" ngbTooltip="{{ 'Index_Filter.Refresh_Button' | translate }}"
                class="ml-2 btn btn-primary right-radius left-radius" (click)="refresh()"><i
                  class="refresh-btn fa fa-refresh"></i></button>
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="eb-dots toggleBtn" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="open()">{{ 'Index_Filter.Filter_Options.AddMarker_Option' | translate }}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </form>
        <div class="category-table custom-datatable ">
          <div class="table-responsive">
            <table class="input-table">
              <thead>
                  <tr class="custom-tr">
                      <th class="custom-th text-center">{{ 'Index_Table.Actions_Column' | translate }}</th>
                      <th class="custom-th text-center" (click)="onSortClick($event,'description')">{{ 'Index_Table.Description_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i> </th>
                      <th class="custom-th text-center" (click)="onSortClick($event,'type')">{{ 'Index_Table.Type_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i> </th>
                      <th class="custom-th text-center" (click)="onSortClick($event,'sub_type')">{{ 'Index_Table.SubType_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i> </th>
                      <th class="custom-th text-center" (click)="onSortClick($event,'lat')">{{ 'Index_Table.Latitude_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i> </th>
                      <th class="custom-th text-center" (click)="onSortClick($event,'lng')">{{ 'Index_Table.Longitude_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i> </th>
                      <th class="custom-th text-center" (click)="onSortClick($event,'active')">{{ 'Index_Table.Active_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i> </th>
                      <th class="custom-th text-center" (click)="onSortClick($event,'id')">{{ 'Index_Table.Id_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i> </th>
                      <th class="custom-th text-center" (click)="onSortClick($event,'createby')">{{ 'Index_Table.CreatedBy_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i> </th>
                      <th class="custom-th text-center" (click)="onSortClick($event,'createdate')">{{ 'Index_Table.CreatedDate_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i> </th>
                      <th class="custom-th text-center" (click)="onSortClick($event,'updateby')">{{ 'Index_Table.UpdatedBy_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i> </th>
                      <th class="custom-th text-center" (click)="onSortClick($event,'updatedate')">{{ 'Index_Table.UpdatedDate_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i> </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="custom-tr" *ngFor="let row of markersList">
                      <td class="custom-td text-center">
                        <div ngbDropdown class="d-inline-block ActionCell" container="body">
                          <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                          <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                            <li *ngFor="let n of menuActions"><span ngbDropdownItem  (click)="clicked(n.title, row)">{{n.title}}</span></li>
                          </ul> 
                        </div>
                      </td>
                      <td class="custom-td text-left">
                          <div class="csstooltip position-relative">
                              <div class="show-less position-relative"s>
                                  {{ row.description ? row.description : '' }}
                              </div>
                              <span class="tooltiptext">{{ row.description }}</span>
                          </div>
                      </td>
                      <td class="custom-td text-left">{{ row.type }}</td>
                      <td class="custom-td text-left">{{ row.sub_type }}</td>
                      <td class="custom-td text-left">{{ row.lat }}</td>
                      <td class="custom-td text-left">{{ row.lng }}</td>
                      <td class="custom-td text-center">
                          <span class="badge" title="{{ row.active }}">{{ row.active }}</span>
                      </td>
                      <td class="custom-td text-left">{{ row.id }}</td>
                      <td class="custom-td text-left">{{ row.createby }}</td>
                      <td class="custom-td text-center">{{ datepipe.transform(row.createdate, userdata.displaydatetimeformat) }}</td>
                      <td class="custom-td text-left">{{ row.updateby }}</td>
                      <td class="custom-td text-center">{{ datepipe.transform(row.updatedate, userdata.displaydatetimeformat) }}</td>
                  </tr>
              </tbody>
          </table>
          
          </div>
          <div class="total-count" *ngIf="totalRows">
            <b class="mr-1">{{ 'total_rows' | translate }} </b> {{totalRows}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>


