import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InventoryServices } from '../../../inventory.service';
import { AddProductComponent } from '../add-product.component';

@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.scss']
})
export class AddonsComponent implements OnInit {
  public closeResult: string;
  public categories = [];
  modalRef:any = null;
  product: any;
  timingList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  addonData: any = {
    "id": null,
    "createby": '',
    "createdate": '',
    "updateby": '',
    "updatedate": '',
    "active": true,
    "approved": true,
    "prodid": '',
    "maxallowed": '',
    "minallowed": '',
    "price": ''
  };
  response: any;
  lastaction: any;
  fieldsetDisabled = false;
  isview: boolean;
  addonFilterData: any = {
    supplierid: '',
    dept: '',
    cat: '',
    subcat: '',

  }

  deptList: any = []
  supplierList: any = []
  catList: any = []
  subCatlist: any = []
  substituteSearchItemList: any = []
  constructor(private modalService: NgbModal,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private toastr: NotificationService,
    private maincomponent: AddProductComponent,
    private loader: NgxUiLoaderService,
    private commonService: CommonServices,
    private service: InventoryServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.product = maincomponent.product;
    this.lastaction = this.route.snapshot.queryParamMap.get('action');
    if (this.lastaction == 'view') {
      this.fieldsetDisabled = true;
    } else if (this.lastaction == 'edit') {
      this.fieldsetDisabled = false;
    }
    var isservice = false;
    this.service.getParentCat(isservice).subscribe(res => {
      this.deptList = res;
      environment.production ? '' : console.log('dept list list  ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading dept list ', err);
    });


    this.service.getProductSupplier().subscribe(res => {
      this.supplierList = res;
      environment.production ? '' : console.log('supplierList list  ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading supplierList data', err);
    });
    this.getAddonList();
  }

  getCatUsingDept() {
    this.service.getProductCategory(this.addonFilterData.dept, false).subscribe(res => {
      this.catList = res;
      environment.production ? '' : console.log('catList list  ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading catList data', err);
    });
  }
  getSubCatUsingCategory() {
    this.commonService.getSubCategory(this.addonFilterData.cat).subscribe(res => {
      this.subCatlist = res;
      environment.production ? '' : console.log('SubcatList list  ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading catList data', err);
    });
  }
  getSearSubstitudeItems() {
    this.loader.start();
    this.service.getSubtitudeCatlog(this.addonFilterData.supplierid, this.addonFilterData.dept, this.addonFilterData.cat, this.addonFilterData.subcat, this.addonFilterData.searchtxt).subscribe(res => {
      this.loader.stop();
      this.substituteSearchItemList = res;
      this.substituteSearchItemList.forEach(element => {
        element.cost = Number(element.cost).toFixed(this.userdata.decimals_allowed) ? Number(element.cost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        element.landedcost = Number(element.landedcost).toFixed(this.userdata.decimals_allowed) ? Number(element.landedcost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        element.price = Number(element.price).toFixed(this.userdata.decimals_allowed) ? Number(element.price).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        element.pocost = Number(element.pocost).toFixed(this.userdata.decimals_allowed) ? Number(element.pocost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      });
    }, err => {
      this.loader.stop();
    })
  }
  ngOnInit(): void {

  }

  getAddonList() {
    this.loader.start();
    this.service.getAddonList(this.product.id).subscribe(res => {
      this.loader.stop();
      console.log(res)
      this.timingList = res;
      this.timingList.forEach(rev => {
        rev.active = rev.active ? 'Yes' : 'No';
      })
    }, err => {
      this.loader.stop();
    })
  }

  open(content, data, action) {
    console.log('log', action);

    if (action == 'view') {
      this.isview = true
    } else if (action == 'edit') {
      this.isview = false
    }
    if (data.id) {
      this.addonData = {
        active: data.active,
        approved: data.approved,
        createby: data.createby,
        createdate: data.createdate,
        description: data.description,
        id: data.id,
        prodid: data.prodid,
        rating: data.rating,
        updateby: data.updateby,
        updatedate: data.updatedate
      };
    } else {
      this.addonData = {
        active: true,
        maxallowed: 0,
        minallowed: 0,
        price: 0
      };
      this.addonFilterData = {
        supplierid: '',
        dept: '',
        cat: '',
        subcat: '',
      }

    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closemodal() {
    this.modalService.dismissAll()
  }
  postProductAddons() {
    this.addonData.companyid = this.userdata.companyid;
    if (this.addonData.id != undefined && this.addonData.id != null) {
      this.addonData.createby = this.addonData.createby;
      this.addonData.createdate = this.datepipe.transform(this.addonData.createdate, this.userdata.postdatetimeformat);
      this.addonData.updateby = this.userdata.email;
      this.addonData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    else {
      this.addonData.prodid = this.product.id;
      this.addonData.createby = this.userdata.email;
      this.addonData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.addonData.active = true;

    environment.production ? '' : console.log('post addon data', this.addonData);
    this.loader.start();
    this.service.postAddon(this.addonData).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          this.getAddonList();

        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });

  }
  closeModal() {
    this.modalService.dismissAll();
  }
  deleteAddon(item) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteAddon(item.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.getAddonList();
            this.modalService.dismissAll();
          }
        }, err => {
          this.loader.stop();
          environment.production ? '' : console.log('Error : While loading delete Currency', err);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  addValue(details_content, item) {
    this.addonData.main_prodid = item.id;
    this.modalRef = this.modalService.open(details_content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  closeModal2() {
    this.modalRef.close()
  }
}
