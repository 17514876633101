<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Milestone</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation">
        <div class="form row">
          <div class="form-group col-md-12">
            <label for="" class="mb-1">Name</label>
            <input class="form-control" type="text">
          </div>
          <div class="form-group col-md-12">
            <label for="" class="mb-1">Scope of Work</label>
            <textarea class="form-control" rows="5">
          </textarea>
          </div>
          <div class="form-group col-md-6">
            <label for="" class="mb-1">Start Date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker7">
              <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
              <mat-datepicker #picker7></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-md-6">
            <label for="" class="mb-1">End Date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker8">
              <mat-datepicker-toggle matSuffix [for]="picker8"></mat-datepicker-toggle>
              <mat-datepicker #picker8></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-md-6">
            <label for="" class="mb-1">Completion Date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker9">
              <mat-datepicker-toggle matSuffix [for]="picker9"></mat-datepicker-toggle>
              <mat-datepicker #picker9></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-md-6">
            <label for="" class="mb-1">Status</label>
            <select class="form-control">
              <option value="">Select</option>
            </select>
          </div>
          <div class="form-group col-md-12">
            <label for="" class="mb-1">Estimated Cost</label>
            <input class="form-control" type="text">
          </div>
          <div class="form-group col-md-12">
            <label for="" class="mb-1">Price Method</label>
            <select class="form-control">
              <option>Select</option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary">Save</button>
    </div>
  </div>
</resize-border>