<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                data-toggle="modal" data-original-title="Add" container="body" ngbTooltip="Add Timing"
                [class.disabled]="fieldsetDisabled" (click)="open(content)"><i class="fa fa-plus"></i> </a></th>
            <th class="custom-th text-center">Day available</th>
            <th class="custom-th text-center">Start date</th>
            <th class="custom-th text-center">End date</th>

            <th class="custom-th text-center">Active</th>

            <th class="custom-th text-center">
              CreateBy</th>
            <th class="custom-th text-center">
              CreateDate</th>
            <th class="custom-th text-center">
              UpdateBy</th>
            <th class="custom-th text-center">
              UpdateDate</th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of timingList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem data-toggle="modal" data-original-title="View" data-target="#exampleModal"
                      (click)="getTimingByID(content,item,'view')">View</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled" data-toggle="modal"
                      data-original-title="Edit" data-target="#exampleModal"
                      (click)="getTimingByID(content,item,'edit')">Edit</span>
                  </li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled">Delete</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left" [innerHTML]="item.dayavail"></td>
            <td class="custom-td text-left">{{item.startdate | date : userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.enddate | date : userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-center"><span class="badge" title="{{item.active}}">{{item.active}}</span></td>

            <td class="custom-td">{{item.createby}}</td>
            <td class="custom-td text-center text-nowrap">{{item.createdate | date : userdata.displaydatetimeformat}}
            </td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-center text-nowrap">{{item.updatedate | date : userdata.displaydatetimeformat}}
            </td>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Timing</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" name="from" #f="ngForm">
      <div class="form row">
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">Day Available</label>
          <div class="input-group ">
            <select class="form-control" [(ngModel)]="timingData.dayavail" name="dayavail">
              <option value="">Select Day</option>
              <option *ngFor="let item of daysList" [value]="item.stringValue">{{item.Text}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="startdate">Start Date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker500" [(ngModel)]="timingData.startdate" #startdate="ngModel"
                placeholder="Choose a date" name="startdate">
              <mat-datepicker-toggle matSuffix [for]="picker500">
              </mat-datepicker-toggle>
              <mat-datepicker #picker500></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="enddate">End Date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker501" [(ngModel)]="timingData.enddate" #enddate="ngModel"
                placeholder="Choose a date" name="enddate">
              <mat-datepicker-toggle matSuffix [for]="picker501">
              </mat-datepicker-toggle>
              <mat-datepicker #picker501></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <!-- <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="" class="mb-1">Description</label>
          <textarea class="form-control" rows="2" name="description" [(ngModel)]="timingData.description"
            #description="ngModel"
            [ngClass]="{ 'is-invalid': description.invalid && description.errors , 'is-valid': description.valid}"
            required>
            </textarea>
        </div> -->
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="" class="mb-1">Active</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="">
              <input class="radio_animated" [value]=true id="" type="radio" name="rdo-ani"
                [(ngModel)]="timingData.active">
              Yes
            </label>
            <label class="d-block" for="">
              <input class="radio_animated" [value]=false id="" type="radio" name="rdo-ani"
                [(ngModel)]="timingData.active">
              No
            </label>
          </div>

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!f.valid || isview"
      (click)="postProductTiming()">Save</button>
  </div>
</resize-border>
</ng-template>
