<resize-border [dragHolder]="header" class="modal-resize">
    <div #header class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">Adjustment</h5>
        <button type="button" class="close" aria-label="Close" (click)="closemodal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <a>ILC</a>:<span *ngIf="itemData.isserilized" style="color:red"> Serilized</span>
                <input type="text" class="form-control" placeholder="ilc" name="ilc" [(ngModel)]="itemData.ilc"
                    readonly>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <a>Description</a>
                <input type="text" class="form-control" placeholder="Description" name="description"
                    [(ngModel)]="itemData.description" readonly>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <a>Bin Location</a>
                <ng-select placeholder="Search Bin Location" [readonly]="setReadonly" required="" name="bintype"
                    [(ngModel)]="itemData.frombinlocation" (ngModelChange)="selectOwnBinLocation($event)"
                    [clearable]="itemData.frombinlocation" appendTo=".modal-resize">
                    <ng-option *ngFor="let item of ownItemProdBinList" [value]="item.Value">
                        {{item.Text}}</ng-option>
                </ng-select>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <a>On-hand Qty</a>
                <input type="text" class="form-control" placeholder="0" name="total" [(ngModel)]="itemData.total"
                    readonly>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <a>Adjust Qty</a>
                <input type="text" class="form-control" placeholder="0" name="adjustqty"
                    [(ngModel)]="itemData.adjustqty" [disabled]="itemData.isserilized">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <a>Action Type</a>
                <select class="form-control" required="" name="action type" [(ngModel)]="itemData.action">
                    <option value="">Select Action</option>
                    <option value="A">Add</option>
                    <option value="D">Deplete</option>
                    <option value="F">Found</option>
                    <option value="L">Lost</option>
                </select>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 form-group" *ngIf="itemData.isserilized">
                <a>Serial- Provide serial for serilized item only </a>
                <div class="input-group mb-2 SearchBox">
                    <ng-select placeholder="Search Serial" required="" name="to bin location"
                        [(ngModel)]="itemData.serial" [clearable]="itemData.serial" appendTo=".modal-resize">
                        <!-- <option value="">Select Serial</option> -->
                        <ng-option *ngFor="let item of serialList" [value]="item.Value">
                            {{item.Text}}</ng-option>
                    </ng-select>
                    <!-- <div class="input-group-append">
                        <span class="input-group-text btn-primary cursor-pointer" (click)="open()">
                            Add Serial
                        </span>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <a>Reason/Notes</a>
                <input type="text" class="form-control" placeholder="Notes" name="msg" [(ngModel)]="itemData.msg">
            </div>

        </div>
    </div>
    <div class="modal-footer justify-content-center">
        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
            (click)="closemodal()">Close</button>
        <button type="button" class="btn btn-primary" (click)="postTransferRequest()">Save</button>
    </div>
</resize-border>