<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Product Catalog
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" (scroll)="onWindowScroll($event)">
      <form class="needs-validation">
        <!-- <div class="form">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="basic-addon2"
          [(ngModel)]="product" name="product">
        <div class="input-group-append cursor-pointer">
          <span class="input-group-text btn-primary" id="basic-addon2" (click)="searchProduct()"><span
              class="fa fa-search text-white"></span></span>
        </div>
      </div>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="customCheck" name="example1">
        <label class="custom-control-label" for="customCheck">Check
          all</label>
      </div>
    </div> -->
        <div class="form-row">
          <div class="col-lg-3 form-group">
            <select class="form-control" name="dept" [(ngModel)]="pcatg.deptid" (change)="searchProduct();selectDept()">
              <option value="">Department</option>
              <option *ngFor="let item of deptList" [value]="item.Value">{{item.Text}}</option>
            </select>
          </div>
          <div class="col-lg-3 form-group">
            <select class="form-control" name="cat" [(ngModel)]="pcatg.categoryid"
              (change)="searchProduct();selectCategory()">
              <option value="">Categories</option>
              <option *ngFor="let item of categoryList" [value]="item.Value">{{item.Text}}</option>
            </select>
          </div>
          <div class="col-lg-3 form-group">
            <select class="form-control" name="subat" [(ngModel)]="pcatg.subcategoryid" (change)="searchProduct()">
              <option value="">Sub Categories</option>
              <option *ngFor="let item of subCategoryList" [value]="item.Value">{{item.Text}}</option>
            </select>
          </div>
          <!-- <div class="col-lg-4 form-group">
        <select class="form-control" [(ngModel)]="pcatg.supplierid" (change)="searchProduct()">
          <option value="">Suppliers</option>
          <option *ngFor="let n of supplierList" [value]="n.Value">{{n.Text}}</option>
        </select>
      </div> -->
          <div class="col-lg-3 form-group">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search" aria-label="Search"
                aria-describedby="basic-addon2" name="product" [(ngModel)]="product">
              <div class="input-group-append">
                <span class="input-group-text btn-primary" id="basic-addon2" (click)="searchProduct()"><span
                    class="fa fa-search text-white"></span></span>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="">
        <!-- Need to add custom html code here -->
        <table class="table table-responsive customTable">
          <thead>
            <tr class="custom-tr">

              <th class="custom-th" (click)="onSortPro($event)">
                ILC
                <!-- <i class="Sorting fa fa-chevron-up"></i> -->
              </th>
              <th class="custom-th" style="width: 60%;" (click)="onSortPro($event)">
                Description
                <!-- <i class="Sorting fa fa-chevron-up"></i> -->
              </th>
              <th class="custom-th" (click)="onSortPro($event)">
                Primary Supplier
                <!-- <i class="Sorting fa fa-chevron-up"></i> -->
              </th>
              <th class="custom-th" (click)="onSortPro($event)">
                InStock
                <!-- <i class="Sorting fa fa-chevron-up"></i> -->
              </th>
              <th class="custom-th" (click)="onSortPro($event)">
                DoNotOrder
                <!-- <i class="Sorting fa fa-chevron-up"></i> -->
              </th>
              <th class="custom-th" (click)="onSortPro($event)">
                ReorderQty
                <!-- <i class="Sorting fa fa-chevron-up"></i> -->
              </th>
              <th class="custom-th" (click)="onSortPro($event)">
                PO Cost
                <!-- <i class="Sorting fa fa-chevron-up"></i> -->
              </th>
              <th class="custom-th" style="min-width: 65px;" (click)="onSortPro($event)">
                QTY
                <!-- <i class="Sorting fa fa-chevron-up"></i> -->
              </th>
              <th class="custom-th" (click)="onSortPro($event)">
                UOM
                <!-- <i class="Sorting fa fa-chevron-up"></i> -->
              </th>
              <th class="custom-th" (click)="onSortPro($event)">
                Cost
                <!-- <i class="Sorting fa fa-chevron-up"></i> -->
              </th>
              <th class="custom-th" (click)="onSortPro($event)">
                Actions
                <!-- <i class="Sorting fa fa-chevron-up"></i> -->
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="custom-tr" *ngFor="let data of productList;let i=index">

              <td class="custom-td text-left">
                {{data.ilc}}
              </td>
              <td class="custom-td text-left">
                {{data.desc}}
              </td>
              <td class="custom-td text-left">
                {{data.supplier}}
              </td>
              <td class=" custom-td text-center">
                {{data.qtyonhand}}
              </td>
              <td class="custom-td">
                {{data.donotorder}}
              </td>
              <td class="custom-td text-center">
                {{data.reorderqty}}
              </td>
              <td class="custom-td text-right">
                {{data.pocost}}
              </td>
              <!-- <td class="custom-td text-left">
            <input type="text" class="form-control text-center" style="width: 65px;" [(ngModal)]="data.qty" id="qty"
              name="qty">
          </td> -->
              <td class="custom-td text-left"><input type="number" [(ngModel)]="data.qty" class="form-control"
                  name="qty-{{i}}"></td>
              <td class="custom-td text-right">
                {{data.puom}}
              </td>
              <td class="custom-td text-right">
                {{data.cost}}
              </td>
              <!-- <td class="custom-td text-left" class="ActionCell">
            <span><i class="fa fa-plus" (click)="selectEvent(data)"></i></span>
            <span><i class="fa fa-cog ml-2"></i></span>
          </td> -->
              <td class="custom-td text-center">
                <div ngbDropdown class="d-inline-block ActionCell" container="body">
                  <i class="fa fa-plus" id="add" (click)="selectEvents(data)"></i>
                  &nbsp;
                  &nbsp;
                  <ng-container (click)="showDropdown()">
                    <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                  </ng-container>
                  <ul ngbDropdownMenu *ngIf="myDropShow" class="ActionCell-List" aria-labelledby="dropdownBasic1">
                    <li><span ngbDropdownItem (click)="itemalysis(data)">Item Analysis</span></li>
                    <li><span ngbDropdownItem (click)="purchasehistory(data)">Purchase History</span></li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End code here -->
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
    </div>
  </div>
</resize-border>