import { DatePipe } from '@angular/common';
import { Component, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-assemblycatalog',
	templateUrl: './assemblycatalog.component.html',
	styleUrls: ['./assemblycatalog.component.scss']
})
export class AssemblycatalogComponent implements OnInit {
	fullScreen = false;
	assemItemList: any = [];
	appname = environment.APP_NAME;
	userdata: any;
	product: any = '';
	assembliesList: any = [];
	// assemItemList: any = [];
	allRowChecked = false;
	constructor(
		private modalService: NgbModal,
		private activeModal: NgbActiveModal,
		private commoanService: CommonServices,
		private toastr: NotificationService,
		private datePipe: DatePipe,
		private loader: NgxUiLoaderService,
	) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
	}

	searchAssemblies() {
		this.loader.start();
		this.commoanService.getAssembliesCatalog(this.product).subscribe(res => {
			this.loader.stop();
			this.assembliesList = res;
			this.assembliesList.forEach(p => {
				p.qty = 0;
				p.checked = false;
				p.assemblyLines.forEach(al => {
					al.desc = al.description;
					al.id = al.prodid;
					al.cost = al.landedcost;
					al.custom_name = null;
				})
			})
			environment.production ? '' : console.log('asemblies list data ', this.assembliesList);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading expType list ', err);
		});
	}
	assemblyItemCheck(item) {
		item.checked = !item.checked;
		if (item.checked == false) {
			this.allRowChecked = false;
		}

		if (this.assembliesList.every(element => element.checked === true)) {
			this.allRowChecked = true;
		}
	}
	assemblyAllItemCheck() {
		if (!this.allRowChecked) {
			this.assembliesList.forEach(item => {
				item.checked = true;
			})
		} else {
			this.assembliesList.forEach(item => {
				item.checked = false;
			})
		}
	}
	assembliesSave() {
		this.assemItemList = [];
		if (this.assembliesList.length > 0) {
			this.assembliesList.forEach(ai => {
				if (ai.checked && ai.qty > 0) {
					ai.assemblyLines.forEach(itm => {
						itm.grp = ai.custom_name ? ai.custom_name : ai.name;
						itm.geid = itm.assid;
						itm.qty = (itm.qty ? Number(itm.qty) : 0) * Number(ai.qty)
						this.assemItemList.push(itm);
					});
				}
			})
		}
		this.closeModal();
	}

	closeModal() {
		this.activeModal.close(this.assemItemList);
	}
	ngOnInit(): void {
	}

}
