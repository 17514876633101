<resize-border *ngIf="!isEdit" [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">{{'Modal_title' | translate}}</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation" name="form" #f="ngForm">
        <div class="form row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="account_type">{{'Modal_Inputs.Input1_Label' | translate}}</label>
            <input type="text" class="form-control" #account_type="ngModel" name="account_type"
              [(ngModel)]="coa.account_type"
              [ngClass]="{ 'is-invalid': account_type.invalid && account_type.errors , 'is-valid': account_type.valid}"
              required>
            <div *ngIf="account_type.invalid && account_type.errors" class="invalid-feedback d-block">
              <div *ngIf="account_type.errors.required">
                Please select account type
              </div>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="detail_type">{{'Modal_Inputs.Input2_Label' | translate}}</label>
            <input type="text" class="form-control" #detail_type="ngModel" name="detail_type"
              [(ngModel)]="coa.detail_type"
              [ngClass]="{ 'is-invalid': detail_type.invalid && detail_type.errors , 'is-valid': detail_type.valid}">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="name">{{'Modal_Inputs.Input3_Label' | translate}}</label>
            <input type="text" class="form-control" #acc_name="ngModel" name="name" [(ngModel)]="coa.name"
              [ngClass]="{ 'is-invalid': acc_name.invalid && acc_name.errors , 'is-valid': acc_name.valid}" required>
            <div *ngIf="acc_name.invalid && acc_name.errors" class="invalid-feedback d-block">
              <div *ngIf="acc_name.errors.required">
                Please enter name
              </div>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="description">{{'Modal_Inputs.Input4_Label' | translate}}</label>
            <input type="text" class="form-control" id="description" name="description" [(ngModel)]="coa.description">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="rpt_code">{{'Modal_Inputs.RptCode_Label' | translate}}</label>
            <input type="text" class="form-control" id="rpt_code" name="rpt_code" [(ngModel)]="coa.rpt_code">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label class="active">Active</label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="">
                <input class="radio_animated" [class.disabled]="fieldsetDisabled" id="active" type="radio"
                  [value]="true" [(ngModel)]="coa.active" #active="ngModel" name="active">
                Yes
              </label>
              <label class="d-block" for="">
                <input class="radio_animated" [class.disabled]="fieldsetDisabled" id="active1" [value]="false"
                  [(ngModel)]="coa.active" #active="ngModel" name="active" type="radio">
                No
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
        (click)="closeModal()">{{'Modal_Buttons.Button1' | translate}}</button>
      <button type="button" class="btn btn-primary" [disabled]="!f.valid" (click)="saveCoa()">{{'Modal_Buttons.Button2'
        | translate}}</button>
    </div>
  </div>
</resize-border>
<resize-border *ngIf="isEdit" [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">COA# {{coa.coano}} </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation" name="form" #f="ngForm">
        <div class="form row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="account_type">{{'Modal_Inputs.Input1_Label' | translate}}</label>
            <input type="text" class="form-control" name="account_type" readonly [(ngModel)]="coa.account_type">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="detail_type">{{'Modal_Inputs.Input2_Label' | translate}}</label>
            <input type="text" class="form-control" name="detail_type" readonly [(ngModel)]="coa.detail_type">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="name1">{{'Modal_Inputs.Input3_Label' | translate}}</label>
            <input type="text" class="form-control" id="name1" #acc_name="ngModel" name="name" [(ngModel)]="coa.name"
              [ngClass]="{ 'is-invalid': acc_name.invalid && acc_name.errors , 'is-valid': acc_name.valid}" required>
            <div *ngIf="acc_name.invalid && acc_name.errors" class="invalid-feedback d-block">
              <div *ngIf="acc_name.errors.required">
                Please enter name
              </div>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="description">{{'Modal_Inputs.Input4_Label' | translate}}</label>
            <input type="text" class="form-control" name="description" [(ngModel)]="coa.description">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="rpt_code">{{'Modal_Inputs.RptCode_Label' | translate}}</label>
            <input type="text" class="form-control" id="rpt_code" name="rpt_code" [(ngModel)]="coa.rpt_code">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label class="active">Active</label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="">
                <input class="radio_animated" [class.disabled]="fieldsetDisabled" id="active" type="radio"
                  [value]="true" [(ngModel)]="coa.active" #active="ngModel" name="active">
                Yes
              </label>
              <label class="d-block" for="">
                <input class="radio_animated" [class.disabled]="fieldsetDisabled" id="active1" [value]="false"
                  [(ngModel)]="coa.active" #active="ngModel" name="active" type="radio">
                No
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
        (click)="closeModal()">{{'Modal_Buttons.Button1' | translate}}</button>
      <button type="button" class="btn btn-primary" [disabled]="!f.valid || action=='View'"
        (click)="saveCoa()">{{'Modal_Buttons.Button2' | translate}}</button>
    </div>
  </div>
</resize-border>