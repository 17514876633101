import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { purchaseServices } from '../../purchase/purchase.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-productview',
  templateUrl: './productview.component.html',
  styleUrls: ['./productview.component.scss']
})
export class ProductviewComponent implements OnInit {
  fullScreen = false;
  @Input('item') item;
  @Input('poid') poid;
  poDetails: any = {
    costingItems: []
  };
  itemStatus: any = ''
  userdata: any;
  appname = environment.APP_NAME;
  constructor(private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private toastr: NotificationService, private datepipe: DatePipe, private service: purchaseServices, private modalService: NgbModal) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  postItem() {
    var postObj = {
      "id": this.item.prodid,
      "companyid": this.userdata.companyid,
      "ilc": this.item.ilc,
      "description": this.item.description,
      "salepricepercentage": this.item.salepricepercentage ? this.item.salepricepercentage : 0,
      "salesprice": this.item.salesprice,
      "landedcost": this.item.landedcost,
      "isserilized": this.item.isserilized,
      "updateby": this.userdata.email,
      "updatedate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat)
    }
    this.loader.start();
    this.service.postPOProdUpdate(postObj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          this.poDetails = res.data;
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading updated po item ', err);
    });
  }

  ngOnInit(): void {
    if (this.item.isserilized == true) {
      this.itemStatus = 'Serilized'
    } else {
      this.itemStatus = ''
    }
    this.calculateSalesTotal();
    environment.production ? '' : console.log('modal data ', this.item);
  }

  calculateSalesTotal() {
    this.item.landedcost = this.item.landedcost ? this.item.landedcost : 0;
    var salepricepercentage = this.item.salepricepercentage ? this.item.salepricepercentage : 0;
    var salesPercentValue = salepricepercentage / 100;
    this.item.salesprice = parseFloat(this.item.landedcost) + (this.item.landedcost * salesPercentValue);
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }


}
