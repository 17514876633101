<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Purchase Order-Short Shipment
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form">
        <div class="category-table ">
          <div class="table-responsive">
            <table class="custom-table">
              <tr class="custom-tr">
                <th class="custom-th text-center w-150" (click)="onSortClick($event)">
                  ILC <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Description <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  QtyOnOrder <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  ShippedQty <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  ReceivedQty <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center w-90" (click)="onSortClick($event)">
                  ShortQty <i class="Sorting fa fa-chevron-up"></i>
                </th>
              </tr>
              <tr class="custom-tr" *ngFor="let item of poItems; index as i">
                <td class="custom-td text-left w-150">
                  {{item.ilc}}
                </td>
                <td class="custom-td text-left">
                  {{item.description}}
                </td>
                <td class="custom-td text-center">
                  {{item.qty}}
                </td>
                <td class="custom-td text-center">
                  {{item.shippedqty}}
                </td>
                <td class="custom-td text-center">
                  {{item.receivedqty}}
                </td>
                <td class="custom-td text-center">
                  <input class="form-control" type="number" name="shortqty-{{i}}" [(ngModel)]="item.shortqty"
                    placeholder="0.0000" (focusout)="calculateItemTotal(item)">
                </td>
              </tr>
              <tr class="custom-tr">
                <td class="custom-td text-right w-150" colspan="2">
                  Totals
                </td>
                <td class="custom-td text-center">
                  {{qtyOnOrderTotal}}
                </td>
                <td class="custom-td text-center">
                  {{shippedQtyTotal}}
                </td>
                <td class="custom-td text-center">
                  {{receivedQtyTotal}}
                </td>
                <td class="custom-td text-center">
                  {{shortQtyTotal}}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button class="btn btn-primary" (click)="postShortPO()" type="button">Save</button>
    </div>
  </div>
</resize-border>