import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppServices } from 'src/app/shared/service/app.services';
import { environment } from 'src/environments/environment';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { ChatSignalr } from '../customerservice/chatsignalr.service';
import { Events } from 'src/app/shared/service/events.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { data } from 'jquery';
import Swal from 'sweetalert2';
import { element } from 'screenfull';
import { formatDate } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-homepage',
	templateUrl: './homepage.component.html',
	styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, AfterViewInit {
	@ViewChild('home') public home: ElementRef;
	@ViewChild('aboutus') public aboutus: ElementRef;
	@ViewChild('overview') public overview: ElementRef;
	@ViewChild('features') public features: ElementRef;
	@ViewChild('pricing') public pricing: ElementRef;
	@ViewChild('contact') public contact: ElementRef;

	startchatform: any = {
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		department: ""
	}

	// form: FormGroup;
	// chatform() {
	// 	this.form = this.formBuilder.group({
	// 		firstname: ['', Validators.required],
	// 		lastname: ['', Validators.required],
	// 		email: ['', [Validators.required, Validators.email]],
	// 		phone: ['', Validators.required],
	// 		department: ['', Validators.required],
	// 	})
	// }
	basicChatFrom: NgForm;

	chatMsg: any = {
		msg: ""
	}

	sendMsg: FormGroup = new FormGroup({
		msg: new FormControl(''),
	});

	active = 'top';
	submitted = false;

	public isCollapsed = true;
	public chaticon: boolean = false;
	public chatstart: boolean = false;
	public isverifyOTP: boolean = false;
	public formUser: boolean = true;

	signPlans: any;
	messageList: any = [];
	department: any;
	toggleChat() {
		this.chaticon = !this.chaticon;
	}
	gotoNext() {
		if (this.formUser == true) {
			this.formUser = false;
		}
		if (this.isverifyOTP == false) {
			this.isverifyOTP = true;
		}
	}
	goToCreateAccount(item) {
		this.router.navigate(['/public/createaccount'], { queryParams: { id: btoa(item.id), code: item.code ? item.code : '' }, replaceUrl: true })
	}
	gotoConversation() {
		if (this.isverifyOTP == true) {
			this.isverifyOTP = false;
		}
		if (this.chatstart == false) {
			this.chatstart = true;
		}
	}
	joinedUser: any;
	fingerprint: any;
	newMessage: any;
	homePagePlan: any = {
		discount: 0,
		discount_type: '%',
		duration_in_months: 3
	};

	private langChangeSubscription: Subscription; 
	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private event: Events,
		public signalrHUB: ChatSignalr,
		private loader: NgxUiLoaderService,
		private translate: TranslateService, 
private languageService: LanguageService, 
private http: HttpClient, 
		// public signalConfig: SignalRConfiguration,
		private meta: Meta,
		private toastr: NotificationService,
		private service: AppServices) {
		window.localStorage.clear();
		this.getPlans();

		this.event.subscribe('event:joinSupportChat', (event: any) => {
			environment.production ? '' : console.log('event joinSupportChat' + JSON.stringify(event.data));
			this.joinedUser = event.data;
			this.formUser = false;
			this.chatstart = true;
		});
		this.meta.addTags([
			{ name: 'description', content: 'Feed your growing business with the right tools. Inventory tracking, Purchase Orders, Sales Orders, Invoices & Estimates/Quotes to reduce paperwork using our developed robust EzyBooks with lot more features with the latest technology.' },
			{ name: 'keywords', content: 'Best Software Development Company In Cayman Island, software development company, consultancy in cayman islands,  software solution in cayman island' }
		]);
		this.event.subscribe('event:newMessage', (event: any) => {
			this.newMessage = '';
			environment.production ? '' : console.log('event newMessage' + JSON.stringify(event.data));
			this.newMessage = event.data;
			if (this.newMessage.ToId != this.joinedUser.UUID) {
				return;
			}
			this.messageList.push({
				UUID: this.newMessage.UUID,
				Content: this.newMessage.Content,
				isMine: false,
				SentTimeStamp: this.newMessage.SentTimeStamp,
				FromName: this.newMessage.FromName,
				FromId: this.newMessage.FromId,
				ToId: this.newMessage.ToId,
				ToName: this.newMessage.ToName,
				MessageType: 2,
			});
		});
	}

	//#region HomePage Sliders
	imgSlider = {
		loop: true,
		items: 1,
		dots: true,
		navText: ['', ''],
		responsive: {
			0: {
				items: 1
			},
			400: {
				items: 2
			},
			740: {
				items: 3
			},
			940: {
				items: 4
			}
		},
		nav: false
	}

	testimonialSlider: OwlOptions = {
		loop: true,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
		responsive: {
			0: {
				items: 1
			},
			400: {
				items: 1,
			},
		},
		nav: true
	}
	//#endregion HomePage Sliders

	ngOnInit(): void {
		// this.chatform();

		this.sendMsg = this.formBuilder.group({
			msg: ['', Validators.required],
		})

		//#region Get Fingerprint of browser
		this.fingerprint = getBrowserFingerprint({
			hardwareOnly: true,
			enableWebgl: true
		});
		environment.production ? '' : console.log('Fingerprint ', this.fingerprint);
		// this.signalConfig.qs = { 'userId': fingerprint };
		//#endregion Get Fingerprint of browser

		// this.signalrHUB.connect(); //Uncomment to connect signalr
		this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
			this.loadComponentTranslations(lang); 
		}); 
	}
	private loadComponentTranslations(lang: string) { 
		const translationFilePath = `./assets/i18n/sales/customers/${lang}.json`; 
		this.http.get(translationFilePath).subscribe((translations:any) => { 
		this.translate.setTranslation(lang, translations, true); 
		this.translate.use(lang); 
		}); 
		   } 
	changeDept(e: any) {
		let getdepartmentData = e.target.value;
		this.department = getdepartmentData;
	}

	get f(): { [key: string]: AbstractControl } {
		return this.chatMsg.controls;
	}

	startSupport(): void {
		var room = {
			UserUUID: this.fingerprint,
			DisplayName: this.startchatform.firstname + " " + this.startchatform.lastname,
			Email: this.startchatform.email,
			PhoneNumber: this.startchatform.phone,
			Department: "Accounting",
			FileSharing: !1,
			RoomType: 2,
			Status: 1,
		};

		this.signalrHUB.joinSupportChat(room);
	}

	startChat() {
		if (!this.signalrHUB.connectionIsEstablished) {
			this.signalrHUB.connect();
		}
		if (this.chatMsg.msg == undefined || this.chatMsg.msg == null) {

			this.toastr.Error("Please enter message")

			return;
		}
		this.submitted = true;

		// if (this.form.invalid) {
		// 	return;
		// }
		var data: any = {
			UUID: this.generateUUID(),
			Content: this.chatMsg.msg,
			// URL: this.chatMsg.fileUrl(),
			SentTimeStamp: this.getUTCTime(new Date()).toString(),
			FromName: this.joinedUser.DisplayName,
			FromId: this.joinedUser.UserUUID,
			ToId: this.joinedUser.UUID,
			ToName: this.joinedUser.DisplayName,
			// ReadTimeStamp: null,
			MessageType: 2,
			// IsFile: (this.chatMsg.fileUrl().trim() != '')
		}
		environment.production ? '' : console.log("UUID: " + data.UUID);
		environment.production ? '' : console.log("From Id: " + this.joinedUser.Id);
		this.signalrHUB.sendMsg('customer', data);
		data.isMine = true;
		data.SentTimeStamp = this.customDateFormat(new Date(parseInt(data.SentTimeStamp)), 'hh:mm a')
		this.messageList.push(data);
		this.chatMsg.msg = null;
	}

	customDateFormat(date: Date, format: string) {
		return formatDate(date, format, 'en-US')
	};

	generateUUID() {
		var d = new Date().getTime();//Timestamp
		var d2 = (performance && performance.now && (performance.now() * 1000)) || 0; //Time in microseconds since page-load or 0 if unsupported
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16;//random number between 0 and 16	
			if (d > 0) {//Use timestamp until depleted	
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {//Use microseconds since page-load if supported	
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	}

	getUTCTime(data) {
		return Date.UTC(
			data.getUTCFullYear(),
			data.getUTCMonth(),
			data.getUTCDate(),
			data.getUTCHours(),
			data.getUTCMinutes(),
			data.getUTCSeconds()
		);
	}

	onReset(): void {
		this.submitted = false;
		this.basicChatFrom.reset();
	}

	ngAfterViewInit(): void {
		if (this.route.snapshot.queryParams['action'] != undefined) {
			var action = this.route.snapshot.queryParams['action'];

			if (action == 'aboutus') {
				this.aboutus.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			} else if (action == 'overview') {
				this.overview.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
			else if (action == 'feature') {
				this.features.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
			else if (action == 'contact') {
				this.contact.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
			else if (action == 'pricing') {
				this.pricing.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
				// this.gotoPricing()
			}
		}
	}

	//#region  Page Redirections
	gotoHome() {
		this.home.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}
	gotoAbout() {
		this.aboutus.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	gotoOverview() {
		this.overview.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	gotoFeature() {
		this.features.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	gotoPricing() {
		this.pricing.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}

	gotoContact() {
		this.contact.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	//#endregion

	getPlans() {
		this.loader.start();
		this.service.getSignupPlans().subscribe(res => {
			this.loader.stop();
			this.signPlans = res;
			this.signPlans.forEach(element => {
				if (element.home_page == 1) {
					this.homePagePlan = element;
				}
			})
			environment.production ? '' : console.log('Plan response', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('check err', err);
		});
	}

	gotoLogin() {
		window.localStorage.clear();
		window.location.replace('/auth/login');
	}
}
