<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Product
        Catalog
      </h5>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" (scroll)="onWindowScroll($event)">
      <div class="form-row">
        <div class="col-lg-2 form-group">
          <ng-select placeholder="Search Department" [(ngModel)]="pcatg.deptid" name="deptid" [clearable]="pcatg.deptid"
            (change)="selectDept()">
            <ng-option *ngFor="let item of deptList" [value]="item.Value">
              {{item.Text}}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-lg-2 form-group">
          <ng-select placeholder="Search Category" [(ngModel)]="pcatg.categoryid" name="categoryid"
            [clearable]="pcatg.categoryid" (change)="selectCategory()">
            <ng-option *ngFor="let item of categoryList" [value]="item.Value">
              {{item.Text}}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-lg-2 form-group">
          <ng-select placeholder="Search Sub Category" [(ngModel)]="pcatg.subcategoryid" name="subcategoryid"
            [clearable]="pcatg.subcategoryid" (change)="searchProduct()">
            <ng-option *ngFor="let item of subCategoryList" [value]="item.Value">
              {{item.Text}}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-lg-2 form-group">
          <select class="form-control" name="dept" [(ngModel)]="pcatg.pricingtype" (change)="searchProduct();">
            <option value="">Pricing Type</option>
            <option *ngFor="let item of priceingTypeList" [value]="item.stringValue">{{item.Text}}</option>
          </select>
        </div>
        <div class="col-lg-4 form-group">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Search" aria-label="Search"
              aria-describedby="basic-addon2" name="product" [(ngModel)]="product">
            <div class="input-group-append">
              <span class="input-group-text btn-primary" id="basic-addon2" (click)="searchProduct()"><span
                  class="fa fa-search text-white"></span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="SearchOption d-flex justify-content-end">

        <div class="btn-group toggler">
          <button type="button" id="gridView" class="btn btn-primary" autocomplete="off" (click)="gridView()">
            <i class="fa fa-table" aria-hidden="true"></i> Grid View
          </button>
          <button type="button" id="tileView" class="btn btn-primary" autocomplete="off" (click)="tileView()">
            <i class="fa fa-th-list" aria-hidden="true"></i> Picture View
          </button>
        </div>
      </div>
      <div class="ShowtileView row mt-2" *ngIf="isShowtileView">
        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6" *ngFor="let item of productList;let i=index">
          <div class="proBox">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <img [src]="item.pic" onerror="this.src='assets/images/100.png';" class="img-fluid">
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <div class="ProName"><b>{{item.ilc}}</b></div>
                <div>
                  <b>Description : </b>
                  {{item.desc}}<br>
                  UOM: {{item.uom}}
                </div>
                <div><b>DoNotSell? :</b> <span *ngIf="item.donosell">Yes</span><span *ngIf="!item.donosell">No</span>
                </div>
                <div><b>InStock :</b> {{item.qtyonhand}}</div>
                <div> <b>Price :</b> {{item.price|number:'1.2-2'}}</div>
                <div class="FootBox">
                  <span><b>Qty</b></span>
                  <input placeholder="Enter Quatity" name="qty-{{i}}" [(ngModel)]="item.qty" value="1"
                    class="form-control">
                  <button class="btn btn-primary btn-sm" (click)="selectEvent(item)">Add to Order</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ShowGridView mt-2" *ngIf="isShowGridView">
        <!-- Need to add custom html code here -->
        <table class="table table-responsive input-table">
          <thead>
            <tr class="custom-tr">
              <th class="custom-th text-center">ILC</th>
              <th class="custom-th text-center" style="width:30%">Description</th>
              <th class="custom-th text-center" style="width:4%">QtyInStock</th>
              <th class="custom-th text-center" style="width:4%">QtyOnOrder</th>
              <th class="custom-th text-center">DoNotSell</th>
              <th class="custom-th text-center">QtyAvail </th>
              <th class="custom-th text-center">Price </th>
              <th class="custom-th text-center" style="width:4%">UOM</th>
              <th class="custom-th text-center" style="width:10%">Qty</th>
              <th class="custom-th text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="custom-tr" *ngFor="let item of productList;let i=index">
              <td class="custom-td text-center">{{item.ilc}}</td>
              <td class="custom-td text-left">{{item.desc}}</td>
              <td class="custom-td text-center">{{item.qtyonhand}}</td>
              <td class="custom-td text-center">{{item.qtyonorder}}</td>
              <td class="custom-td text-center">{{item.donosell}}</td>
              <td class="custom-td text-center">{{item.qtyavailable}}</td>
              <td class="custom-td text-right">{{item.price | number:'1.2-2'}}</td>
              <td class="custom-td text-center">{{item.uom}}</td>
              <td class="custom-td text-center"><input type="number" [(ngModel)]="item.qty" class="form-control"
                  name="qty-{{i}}"></td>
              <td class="custom-td text-center">
                <div ngbDropdown class="d-inline-block ActionCell" container="body" (click)="showDropdown()">
                  <i class="fa fa-plus" id="add" (click)="selectEvent(item)"></i>
                  &nbsp;
                  &nbsp;
                  <ng-container (click)="showDropdown()">
                    <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                  </ng-container>
                  <ul ngbDropdownMenu *ngIf="myDropShow" class="ActionCell-List" aria-labelledby="dropdownBasic1">
                    <li><span ngbDropdownItem (click)="itemalysis(item)">Item Analysis</span></li>
                    <li><span ngbDropdownItem (click)="purchasehistory(item)">Purchase History</span></li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End code here -->
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
    </div>
  </div>
</resize-border>