import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Events } from 'src/app/shared/service/events.service';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class assetServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    userprofile: any;

    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }


    //asset List
    getAssetList(pg,searchtext, assignto, status) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size  + '&searchtext=' + searchtext + '&assignto=' + assignto + '&status=' + status);
    }
    getParent() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAsset?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAssetAction() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAssetActions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAssetStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAssetStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAssetLocation() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAssetLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAssetServiceTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAssetServiceTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAssetConditions() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAssetConditions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAssetDepreciation() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDepreciationType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAssetTypeList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAssetTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postAsset(val) {
        return this.http.post(this.baseUrl + 'api/Assets/Postt_Assets', val);
    }
    postAssetAssignment(val) {
        return this.http.post(this.baseUrl + 'api/Assets/Post_Assignment', val);
    }
    deleteAssignment(id) {
        return this.http.delete(this.baseUrl + 'api/Assets/Delete_Assignment?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postAssetServiceLog(val) {
        return this.http.post(this.baseUrl + 'api/Assets/Post_ServiceLogs', val);
    }
    postAssetPicture(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Assets/Post_Picture', data);
    }

    // Asset tab services
    getAssetLicenses(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetLicensings?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getAssetChild(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_ChildAssets?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getAssetEvents(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetEvents?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getAssetLog(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetServiceLogs?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getAssetTransactions(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetTransactions?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getAssetPictures(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetPictures?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getDeleteAssetPictures(id) {
        return this.http.delete(this.baseUrl + 'api/Assets/Delete_AssetPicture?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getAssetPictureMain(id, assid, mainpic) {
        return this.http.get(this.baseUrl + 'api/Assets/Post_AssetMainPicture?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&assid=' + assid + '&mainpic=' + mainpic);
    }

    getAssetDocuments(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Gett_AssetDocuments?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }


    //asset Licence tab
    postAssetLicence(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Assets/Post_AssetLicense', data)
    }
    deleteAssetLicence(id) {
        return this.http.delete(this.baseUrl + 'api/Assets/Delete_AssetLicense?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getAssetDetails(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Gett_AssetByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //asset user
    getAssetUserList(name) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetUsers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name)
    }
    postAssetUser(val) {
        return this.http.post(this.baseUrl + 'api/Assets/Post_AssetUsers', val);
        // 
    }

    //Assets Req
    getAssetsRequestList(requestedby, desc) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetRequests?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&requestedby=' + requestedby + '&desc=' + desc)
    }
    postAssetsRequest(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Assets/Post_AssetRequet', data)
    }
    getAssetsRequestbyID(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetRequestByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //asset contract

    getAssetUserContractList(name) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetContracts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name)
    }

    //delete contract api.
    deletecontract(id) {
        return this.http.delete(this.baseUrl + 'api/Assets/Delete_AssetContract?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id)

    }
    postAssetContractData(val) {
        return this.http.post(this.baseUrl + 'api/Assets/Post_AssetContracts', val);
    }
    getAssetUserType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAssetUserType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //asset category
    getAssetCategory(description) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetCategoryIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description)
    }
    getAssetsCategorybyID(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Gett_AssetCategoryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postAssetCategory(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Assets/Postt_AssetCaegory', data)
    }
    postAssetTransaction(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Assets/Post_AssetTransaction', data)
    }
    getAssetTransaction(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetTransactions?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteAssetTransaction(id) {
        return this.http.delete(this.baseUrl + 'api/Assets/Delete_AssetTransaction?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Depreciation Configs
    getAssetDepreciationConfig(id) {
        return this.http.get(this.baseUrl + 'api/Assets/Get_AssetDepreciationConfigs?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postAssetDepreciationConfig(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Assets/Post_DepreciationConfig', data)
    }
    deleteAssetDepreciationConfig(id) {
        return this.http.delete(this.baseUrl + 'api/Assets/Delete_DepreciationConfig?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteAssetCategory(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_AssetCategory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteAsset(id) {
        return this.http.delete(this.baseUrl + 'api/Assets/Delete_Asset?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getNotifyAllAssetUsers(compname, from) {
        return this.http.get(this.baseUrl + 'api/Emailer/Get_NotifyAllAssetUsers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&compname=' + compname + '&from=' + from);
    }
    getdepreciation(fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Assets/Bulk_depreciation?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    postDepreciation(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Assets/Post_BulkDepreciation', data)
    }
    deleteAssetRequest(id) {
        return this.http.delete(this.baseUrl + 'api/Assets/Delete_AssetRequest?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    deleteAssetUser(id) {
        return this.http.delete(this.baseUrl + 'api/Assets/Delete_AssetUsers?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    deleteservicelog(id) {
        return this.http.delete(this.baseUrl + 'api/Assets/Delete_ServiceLog?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteAssetLicense(id) {
        return this.http.delete(this.baseUrl + 'api/Assets/Delete_AssetLicense?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
