<div class="main-header-left d-none d-lg-block">
  <div class="logo-wrapper"><a [routerLink]="'/dashboard/default'"><img class="blur-up lazyloaded" [src]="companyLogo"
        alt="EzyBook"></a></div>
</div>
<div class="sidebar custom-scrollbar">
  <!-- <div class="sidebar-user text-center">
    <div><img class="img-60 rounded-circle" src="assets/images/dashboard/man.png" alt="#">
    </div>
    <h6 class="mt-3 f-14">{{userData.nikname}}</h6>
    <p>general manager.</p>
  </div> -->
  <div class="sidemenu-searchbox">
    <i class="fa fa-search"></i>
    <input class="form-control" placeholder="Search" name="searchText" [(ngModel)]="searchText" (input)="filterMenu()" autocomplete="off">
  </div>
  <ul class="sidebar-menu">
    <div class="menu-label" *ngIf="filteredList.length != 0">Search Result</div>
    <li *ngFor="let menuItem of filteredList" [ngClass]="{active: menuItem.active}">
      <!-- Sub -->
      <a href="javascript:void(0)" class="sidebar-header" *ngIf="menuItem.type === 'sub'"
        (click)="toggletNavActive(menuItem)" placement="top" [ngbTooltip]="menuItem.title"
        tooltipClass="custom-tooltip">
        <i [class]="menuItem.icon"></i><span>{{menuItem.title}}<span class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children.length>0"></i>
      </a>
      <!-- Link -->
      <a (click)="navigate(menuItem)" routerLinkActive="active" class="sidebar-header" *ngIf="menuItem.type === 'link'">
        <i [class]="menuItem.icon"></i><span>{{menuItem.title}}<span class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
      </a>

      <!-- 2nd Level Menu -->
      <ul class="sidebar-submenu" [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }"
        *ngIf="menuItem.children.length>0">
        <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
          <!-- Sub -->
          <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggletNavActive(childrenItem)">
            <i class="fa fa-circle"></i><span> {{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span> </span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- Link -->
          <a [routerLink]="!childrenItem.type ? null : [childrenItem.ang_root]" *ngIf="childrenItem.type === 'link'"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" placement="top"
            [ngbTooltip]="childrenItem.title" tooltipClass="custom-tooltip">
            <i class="fa fa-circle"></i><span>{{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span></span>
          </a>

          <!-- 3rd Level Menu -->
          <ul class="sidebar-submenu" *ngIf="childrenItem.children">
            <li *ngFor="let childrenSubItem of childrenItem.children" placement="top"
              [ngbTooltip]="childrenSubItem.title" tooltipClass="custom-tooltip">
              <!-- Link -->
              <a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.ang_root]"
                *ngIf="childrenSubItem.type === 'link'" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <i class="fa fa-circle"></i><span> {{childrenSubItem.title}} <span
                    class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue}}</span> </span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <div class="menu-label">Menu</div>
    <li *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}">
      <!-- Sub -->
      <a href="javascript:void(0)" class="sidebar-header" *ngIf="menuItem.type === 'sub'"
        (click)="toggletNavActive(menuItem)" placement="top" [ngbTooltip]="menuItem.title"
        tooltipClass="custom-tooltip">
        <i [class]="menuItem.icon"></i><span>{{menuItem.title}}<span class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children.length>0"></i>
      </a>
      <!-- Link -->
      <a (click)="navigate(menuItem)" routerLinkActive="active" class="sidebar-header" *ngIf="menuItem.type === 'link'">
        <i [class]="menuItem.icon"></i><span>{{menuItem.title}}<span class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children.length>0"></i>
      </a>

      <!-- 2nd Level Menu -->
      <ul class="sidebar-submenu" [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }"
        *ngIf="menuItem.children.length>0">
        <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
          <!-- Sub -->
          <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggletNavActive(childrenItem)">
            <i class="fa fa-circle"></i><span> {{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span> </span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children.length>0"></i>
          </a>
          <!-- Link -->
          <a [routerLink]="!childrenItem.type ? null : [childrenItem.ang_root]" *ngIf="childrenItem.type === 'link'"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" placement="top"
            [ngbTooltip]="childrenItem.title" tooltipClass="custom-tooltip">
            <i class="fa fa-circle"></i><span>{{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span></span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children.length>0"></i>
          </a>

          <!-- 3rd Level Menu -->
          <ul class="sidebar-submenu" *ngIf="childrenItem.children.length>0">
            <li *ngFor="let childrenSubItem of childrenItem.children" placement="top"
              [ngbTooltip]="childrenSubItem.title" tooltipClass="custom-tooltip">
              <!-- Link -->
              <a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.ang_root]"
                *ngIf="childrenSubItem.type === 'link'" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <i class="fa fa-circle"></i><span> {{childrenSubItem.title}} <span
                    class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue}}</span> </span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>