import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { NavService } from '../../service/nav.service';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators';
import { CommonServices } from '../../service/common.service';
import { environment } from 'src/environments/environment';
import { Events } from '../../service/events.service';
import { DashboardServices } from 'src/app/components/dashboard/dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerannouncementsComponent } from 'src/app/components/modal/customerannouncements/customerannouncements.component';
import Swal from 'sweetalert2';
import { LoginComponent } from 'src/app/components/auth/login/login.component';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../service/language.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;
  public breadcrumbs;
  public selectedcompanyData;
  public title: string;
  userdata: any = {
    companyid: '',
    companyids: []
  };
  iconClass: any;
  appname = environment.APP_NAME;
  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  id: any;
  editData: string;
  ReminderData: any = [{
    type: '',
    title: '',
    notes: ''
  },
  {
    type: '',
    title: '',
    notes: ''
  }];
  dashboardData: any;
  loader: any;
  Lang:any='EN';
  announcements: any = [];
  reminderLength: any = 0;
  companyDropdown: boolean = false;
  public isShow: boolean = false
  constructor( private languageService: LanguageService,private translate: TranslateService, public navServices: NavService, private events: Events, private commonService: CommonServices, private activatedRoute: ActivatedRoute,
    private router: Router, private titleService: Title, private dashboardservice: DashboardServices, private modalService: NgbModal, private loginComponent: LoginComponent) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    if(localStorage.getItem('language')) {
      if(localStorage.getItem('language') == 'en') {
        this.Lang = 'EN';
      } else if (localStorage.getItem('language') == 'es') {
        this.Lang = 'ES';
      } else if (localStorage.getItem('language') == 'ar') {
        this.Lang = 'AR';
      }
    } else {
      this.Lang = 'EN';
    }
    this.userdata.companyids.forEach(data => {
      if (this.userdata.companyid == data.companyid) {
        this.selectedcompanyData = data;
      }
    })
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        let snapshot = this.router.routerState.snapshot;
        let title = route.snapshot.data['title'];
        let id = route.snapshot.data['id'];
        let parent = route.parent.snapshot.data['breadcrumb'];
        let child = route.snapshot.data['breadcrumb'];
        this.breadcrumbs = {};
        this.title = title;
        this.setTitle();
        this.updateFilterDates();
        this.id = id;
        this.breadcrumbs = {
          "parentBreadcrumb": parent,
          "childBreadcrumb": child
        }
      });
    this.commonService.headerEditItemData.subscribe(value => {
      this.editData = value;
      this.setTitle();
    });
    if (screen.width < 1200) {
      this.collapseSidebar();
    }
    this.events.subscribe('event:dashboard', (event: any) => {
      environment.production ? '' : console.log('event status' + event.data.from);
      this.ReminderData = event.data.reminders;
      this.reminderLength = this.ReminderData.length;
    });
  }
  switchLanguage(language: string) {
    this.languageService.changeLanguage(language);
    if(language == 'en') {
      this.Lang = 'EN';
    } else if(language == 'es') {
      this.Lang = 'ES';
    } else if(language == 'ar') {
      this.Lang = 'AR';
    }
  }
  setTitle() {
    this.titleService.setTitle(this.title + '' + this.editData);
  }
  updateFilterDates() {
    if (localStorage.getItem(this.appname + 'userprofile')) {
      var userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
      userdata.enddate = moment().add(parseFloat(userdata.future_data_view_days), 'days').format('YYYY-MM-DD');
      userdata.fromdate = moment().subtract(parseFloat(userdata.back_data_view_days), 'days').format('YYYY-MM-DD');
      if (this.userdata.enddate < userdata.enddate) {
        localStorage.setItem(this.appname + 'userprofile', JSON.stringify(userdata));
      }
    }
  }
  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }
  goToLogout() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    sessionStorage.clear();
    localStorage.clear();
    // window.location.replace('/auth/login');

    this.events.publish('event:logout', {
      data: {
        'from': 'header logout'
      }
    });
    this.router.navigate(['/login'], { replaceUrl: true })
  }

  ngOnInit() {
    this.getReminderData('')
    this.getDashBoardData();


  }
  dropdowntoggle() {
    this.isShow = !this.isShow;
  }

  toggledropdown() {
    this.companyDropdown = !this.companyDropdown;
  }
  saveImage(img: string) {
    environment.production ? '' : console.log(img);
  }


  getReminderData(assign) {

  }

  getDashBoardData() {
    // this.loader.start();
    this.dashboardservice.getHomeAnnouncements().subscribe(res => {
      // this.loader.stop();

      this.announcements = res;
      // environment.production ? '' : console.log('Announcements', this.announcements)
    }, err => {
      // this.loader.stop();
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }

  gotoAnnouncements() {
    this.modalService.open(CustomerannouncementsComponent, { size: 'lg' })
  }

  changeCompany(company) {
    if (this.userdata.companyid == '') {
      return;
    }
    Swal.fire({
      title: 'Are you sure want to switch company?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.selectedcompanyData = company;
        this.userdata.companyid = company.companyid;

        localStorage.clear();
        if (this.userdata.login_by_auth_token == 0) {
          this.loginComponent.tempForm = {
            username: this.userdata.email,
            companyid: this.userdata.companyid,
            password: this.userdata.password,
          }
          this.loginComponent.doLogin();
        } else {
          this.loginComponent.socialAuth = {
            loginame: this.userdata.email,
            companyid: this.userdata.companyid,
            auth_token: this.userdata.auth_token,
          }
          this.loginComponent.doSocialLogin();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {


      }
    })
  }

  selectedCompany(company) {
    if (this.userdata.companyids.length == 1) {
      return false
    }

    this.changeCompany(company);
  }
}
