<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">{{ 'RCTracking_Template.RCTracking_Title' | translate }}</h5>
        <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
            <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
            <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
        </button>
        <button type="button" class="close" aria-label="Close" (click)="closeModal('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="needs-validation">
            <div class="form row">
                <div class="form-group col-sm-12">
                    <label for="">{{ 'RCTracking_Template.Status_Label' | translate }}</label>
                    <select class="form-control" name="status" [(ngModel)]="tracking.status">
                        <option value="">{{ 'RCTracking_Template.Status_Placeholder' | translate }}</option>
                        <option *ngFor="let item of statusList" [value]="item.stringValue">{{item.Text}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-sm-12">
                    <label for="">{{ 'RCTracking_Template.Notes_Label' | translate }}</label>
                    <textarea type="text" class="form-control" rows="5" [(ngModel)]="tracking.notes"></textarea>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer justify-content-center">
        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
            (click)="closeModal('close')">{{ 'RCTracking_Template.Close_Button' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="saveTracking()">{{ 'RCTracking_Template.Save_Button' | translate }}</button>
    </div>
    </div>
</resize-border>