import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { InventoryServices } from '../../../inventory.service';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { NotificationService } from 'src/app/shared/service/notification.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { AddProductComponent } from '../add-product.component';

@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss']
})
export class PicturesComponent implements OnInit {
  public closeResult: string;
  public categories = []
  product: any;
  picList: any = [];
  public environment = environment;
  showMain = false;
  pictureData: any = {}
  uploader: FileUploader;
  userdata: any;
  appname = environment.APP_NAME;
  response: string;
  filename: any;
  filepath: string;

  lastaction: any;
  fieldsetDisabled = false;
  constructor(private modalService: NgbModal,
    private toastr: NotificationService,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private loader: NgxUiLoaderService,
    private maincomponent: AddProductComponent,
    private service: InventoryServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.product = this.maincomponent.product;
    this.filepath = environment.DOC_URL + '/' + this.userdata.companyid + '/';

    this.initializeFileUploader();

    this.lastaction = this.route.snapshot.queryParamMap.get('action');
    if (this.lastaction == 'view') {
      this.fieldsetDisabled = true;
    } else if (this.lastaction == 'edit') {
      this.fieldsetDisabled = false;
    }
    this.getProductPictures();
  }

  ngOnInit(): void {
  }

  getProductPictures() {
    this.loader.start();
    this.service.getProductPictures(this.product.id).subscribe(res => {
      this.loader.stop();
      this.picList = res;
    }, err => {
      this.loader.stop();
    });
  }


  initializeFileUploader() {
    this.uploader = new FileUploader({
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + this.userdata.token,
      url: environment.API_URL + 'api/Documents/UploadFiles',
      method: 'POST',
      removeAfterUpload: true,
      queueLimit: 100
    })
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('companyid', this.userdata.companyid);
      form.append('createby', this.userdata.email);
      form.append('createdate', this.datepipe.transform(new Date(), this.userdata.postdatetimeformat));
      form.append('dtype', 'prod-picture-files');
    };
    this.response = '';
    this.uploader.response.subscribe(res => {
      environment.production ? '' : console.log('res ' + res);
      // this.toastr.Success("File uploaded sucessfully")
      this.response = res.data;
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
  }
  uploadFile() {
    this.uploader.queue[0].onSuccess = function (response, status, headers) {
      if (status == 200) {
        let tempRes = JSON.parse(response);
      } else {
        // Swal.fire('', 'File uploaded', 'success')
      }
    };
    this.uploader.queue[0].upload();
  }

  selectedFileOnChanged(event: any) {
    environment.production ? '' : console.log(event.target.value);
    this.filename = event.target.value.split(/(\\|\/)/g).pop()
    this.postPictureData();
  }
  viewDocument(doc) {
    const url = this.filepath + doc.filename;
    window.open(url, '_blank');
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  postPictureData() {
    this.uploadFile();
    var data = {
      "createby": this.userdata.email,
      "companyid": this.userdata.companyid,
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "prodid": this.product.id,
      "filename": this.filename,
      "mainpic": this.pictureData.mainpic,
      "orderby": 1,
      "active": true,

    }
    environment.production ? '' : console.log('post review data', data);
    this.loader.start();
    this.service.postPicture(data).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.picList = res.data;
        this.closemodal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading single item post ', err);
    });
  }
  changeStatus(item) {
    this.loader.start();
    this.service.getPostProductMainPicture(item.id, this.product.id, item.mainpic).subscribe(res => {
      this.loader.stop();
      if (res == '1') {

        this.toastr.Success('Main Picture Updated');

      }
    }, err => {
      this.loader.stop();
    })
  }


  deletePic(item) {
    Swal.fire({
      title: 'Do you want to delete product picture?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.loader.start();
        this.service.getProductDeletePicture(item.id).subscribe(res => {
          this.loader.stop();
          if (res == '1') {

            this.toastr.Success('Picture Deleted');

          }
          this.getProductPictures();
        }, err => {
          this.loader.stop();
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  closemodal() {
    this.modalService.dismissAll()
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
