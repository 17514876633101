<div class="custom-row">
  <div class="form-row">
    <div class="col-lg-3 col-md-6 form-group"> </div>
    <div class="col-lg-3 col-md-6 form-group">
      <label for="">PO#</label>
      <input type="search" class="form-control" [(ngModel)]="filter.searchtxt" name="pon" placeholder="PO#">
    </div>
    <div class="col-lg-3 col-md-6 form-group pl-0">
      <label for="fromdate">From Date</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker460" placeholder="Choose a date" name="fromdate"
          [(ngModel)]="filter.fromdate">
        <mat-datepicker-toggle matSuffix [for]="picker460"></mat-datepicker-toggle>
        <mat-datepicker #picker460></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-6 form-group pl-0">
      <label for="fromdate">To Date</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker461" placeholder="Choose a date" name="todate"
          [(ngModel)]="filter.todate">
        <mat-datepicker-toggle matSuffix [for]="picker461"></mat-datepicker-toggle>
        <mat-datepicker #picker461></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="btn-group CsearchBtn" role="group">
    <button type="button" class="btn btn-primary" (click)="getProductOrders()">Search</button>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center" (click)="onSortClick($event,'ilc')">ILC <i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'description')">Description<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'pon')">PON<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'podate')">PODate<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'potype')">Type<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'potitle')">POTitle<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'supplier')">Supplier<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'qty')">QtyOrdered<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'cost')">POCost<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'shippedqty')">QtyShipped<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'receivedqty')">QtyReceived<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'damageqty')">QtyDamageqty<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'overageqty')">QtyOverage<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'status')">Status<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'duedate')">Duedate<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'createby')">CreateBy<i class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'createdate')">CreateDate<i class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'updateby')">UpdateBy<i class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'updatedate')"> UpdateDate<i class="Sorting fa fa-chevron-up"></i> </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of historyList">
            <td class="custom-td text-left">{{item.ilc}}</td>
            <td class="custom-td text-left">{{item.description}}</td>
            <td class="custom-td text-left">{{item.pon}}</td>
            <td class="custom-td text-left text-nowrap">{{item.podate | date:'yyy-MM-dd'}}</td>
            <td class="custom-td text-left">{{item.potype}}</td>
            <td class="custom-td text-left">{{item.potitle}}</td>
            <td class="custom-td text-left">{{item.supplier}}</td>
            <td class="custom-td text-center">{{item.qty}}</td>
            <td class="custom-td text-right">{{item.cost}}</td>
            <td class="custom-td text-center">{{item.shippedqty}}</td>
            <td class="custom-td text-center">{{item.receivedqty}}</td>
            <td class="custom-td text-center">{{item.damageqty}}</td>
            <td class="custom-td text-center">{{item.overageqty}}</td>
            <td class="custom-td text-center"><span class="badge" title="{{item.status}}">{{item.status}}</span></td>
            <td class="custom-td text-left text-nowrap">{{item.duedate | date:'yyy-MM-dd'}}</td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.createdate | date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.updatedate | date:userdata.displaydatetimeformat}}</td>
          </tr>
          <tr class="custom-tr">
            <td class="custom-td text-right" colspan="7"><strong>Total</strong> </td>
            <td class="custom-td text-center"><strong>{{totalQty}}</strong></td>
            <td class="custom-td text-right"><strong>{{totalPOCost}}</strong></td>
            <td class="custom-td text-center"><strong>{{totalShippedQty}}</strong></td>
            <td class="custom-td text-center"><strong>{{totalReceivedqty}}</strong></td>
            <td class="custom-td text-center"><strong>{{totalDamageqty}}</strong></td>
            <td class="custom-td text-center"><strong>{{totalOverageqty}}</strong></td>
            <td class="custom-td text-right" colspan="5"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>