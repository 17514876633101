<resize-border [dragHolder]="header" class="modal-resize">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Transfer QTY</h5>
    <button type="button" class="close" aria-label="Close" (click)="closemodal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" name="from" #f="ngForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">ILC</label> :<span *ngIf="itemData.isserilized" style="color:red"> Serilized</span>
          <input type="text" class="form-control" placeholder="ilc" name="ilc" [(ngModel)]="itemData.ilc" readonly>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">Description</label>
          <input type="text" class="form-control" placeholder="Description" name="description"
            [(ngModel)]="itemData.description" readonly>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">From Bin Location</label>
          <ng-select placeholder="Search Bin Location" required="" name="bintype" [(ngModel)]="itemData.frombinlocation"
            (ngModelChange)="selectOwnBinLocation($event)" [clearable]="itemData.frombinlocation"
            appendTo=".modal-resize" #frombinlocation="ngModel"
            [ngClass]="{ 'is-invalid': frombinlocation.invalid && frombinlocation.errors , 'is-valid': frombinlocation.valid}"
            required>
            <ng-option *ngFor="let item of ownItemProdBinList" [value]="item.Value">
              {{item.Text}}</ng-option>
          </ng-select>
          <div *ngIf="frombinlocation.invalid && frombinlocation.errors" class="invalid-feedback d-block">
            <div *ngIf="frombinlocation.errors.required">
              Please select bin
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">Total</label>
          <input type="text" class="form-control" placeholder="0" name="total" [(ngModel)]="itemData.availableqty"
            readonly>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">To Bin Location</label>
          <ng-select placeholder="Search Bin Location" required="" name="to bin location"
            [(ngModel)]="itemData.tobinlocation" [clearable]="itemData.tobinlocation" appendTo=".modal-resize"
            #tobinlocation="ngModel"
            [ngClass]="{ 'is-invalid': tobinlocation.invalid && tobinlocation.errors , 'is-valid': tobinlocation.valid}"
            required>
            <ng-option *ngFor="let item of allBinList" [value]="item.Value">
              {{item.Text}}</ng-option>
          </ng-select>
          <div *ngIf="tobinlocation.invalid && tobinlocation.errors" class="invalid-feedback d-block">
            <div *ngIf="tobinlocation.errors.required">
              Please select bin
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">Transfer Qty</label>
          <input type="text" class="form-control" placeholder="0" name="qtycount" [(ngModel)]="itemData.qtycount"
            [disabled]="itemData.isserilized" (focusout)="checkQty()">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group" *ngIf="itemData.isserilized">
          <label for="">Serial - <i>Provide serial for serilized products only</i> </label>
          <div class="input-group mb-2 SearchBox">
            <ng-select placeholder="Search Serial" required="" name="to bin location" [(ngModel)]="itemData.serial"
              [clearable]="itemData.serial" appendTo=".modal-resize">
              <!-- <option value="">Select Serial</option> -->
              <ng-option *ngFor="let item of serialList" [value]="item.Value">
                {{item.Text}}</ng-option>
            </ng-select>
            <div class="input-group-append">
              <span class="input-group-text btn-primary cursor-pointer" (click)="open()">
                Add Serial
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">Reason/Notes</label>
          <input type="text" class="form-control" placeholder="Notes" name="Notes" [(ngModel)]="itemData.msg">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closemodal()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!f.valid" (click)="postTransferRequest()">Save</button>
  </div>
</resize-border>
<ngx-ui-loader></ngx-ui-loader>