<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Purchase Order# {{pon}}
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ngb-tabset class="tab-coupon" (tabChange)="tabClick($event)">
        <ngb-tab title="Costing">
          <ng-template ngbTabContent>
            <div class="row mt-3">
              <div class="col-md-3 form-group">
                <label for="">Cubic Feet</label>
                <input type="number" name="cf" [(ngModel)]="poCostingDetails.cf" class="form-control"
                  placeholder="Cubic Feet">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Date of Release</label>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker285" placeholder="Date of Release" name="dor"
                    [(ngModel)]="poCostingDetails.dor">
                  <mat-datepicker-toggle matSuffix [for]="picker285">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker285></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-3 form-group">
                <label for="">Date of Calculation</label>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker286" placeholder="Date of Calculation" name="doc"
                    [(ngModel)]="poCostingDetails.doc">
                  <mat-datepicker-toggle matSuffix [for]="picker286">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker286></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-3 form-group">
                <label for="">Duty</label>
                <input type="number" class="form-control" name="duty" [(ngModel)]="poCostingDetails.duty"
                  placeholder="Duty">
              </div>
              <div class="col-md-3 form-group">
                <label for="">WarehouseFee%</label>
                <input type="number" name="wfpercentage" [(ngModel)]="poCostingDetails.wfpercentage"
                  class="form-control" placeholder="WarehouseFee%">
              </div>
              <div class="col-md-3 form-group">
                <label for="">WarehouseFee</label>
                <input type="number" name="warehousefee" [(ngModel)]="poCostingDetails.warehousefee"
                  class="form-control" placeholder="WarehouseFee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Freight</label>
                <input type="number" name="freight" [(ngModel)]="poCostingDetails.freight" class="form-control"
                  placeholder="Freight">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Container HaulFee</label>
                <input type="number" name="haulfee" [(ngModel)]="poCostingDetails.haulfee" class="form-control"
                  placeholder="Container HaulFee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Customs Clearance Fee</label>
                <input type="number" name="customsfee" [(ngModel)]="poCostingDetails.customsfee" class="form-control"
                  placeholder="Customs Clearance Fee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Document Fee</label>
                <input type="number" name="docfee" [(ngModel)]="poCostingDetails.docfee" class="form-control"
                  placeholder="Document Fee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Crate Fee</label>
                <input type="number" name="cratefee" [(ngModel)]="poCostingDetails.cratefee" class="form-control"
                  placeholder="Crate Fee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Demurrage Fee</label>
                <input type="number" name="demurragefee" [(ngModel)]="poCostingDetails.demurragefee"
                  class="form-control" placeholder="Demurrage Fee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Tax</label>
                <input type="number" name="tax" [(ngModel)]="poCostingDetails.tax" class="form-control"
                  placeholder="Tax">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Insurance</label>
                <input type="number" name="insurance" [(ngModel)]="poCostingDetails.insurance" class="form-control"
                  placeholder="Insurance">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Handling Fee</label>
                <input type="number" name="handlingfee" [(ngModel)]="poCostingDetails.handlingfee" class="form-control"
                  placeholder="Handling Fee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Service Fee</label>
                <input type="number" name="servicefee" [(ngModel)]="poCostingDetails.servicefee" class="form-control"
                  placeholder="Service Fee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Foreign Delivery Fee</label>
                <input type="number" name="fdeliveryfee" [(ngModel)]="poCostingDetails.fdeliveryfee"
                  class="form-control" placeholder="Foreign Delivery Fee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Other Fee</label>
                <input type="number" name="otherfee" [(ngModel)]="poCostingDetails.otherfee" class="form-control"
                  placeholder="Other Fee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Local Delivery Fee</label>
                <input type="number" name="ldeliveryfee" class="form-control" placeholder="Local Delivery Fee"
                  [(ngModel)]="poCostingDetails.ldeliveryfee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">HZMAT Fee</label>
                <input type="number" name="hzmatfee" [(ngModel)]="poCostingDetails.hzmatfee" class="form-control"
                  placeholder="HZMAT Fee">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Costing ApprovedBy</label>
                <input type="text" [(ngModel)]="poCostingDetails.approvedby" readonly class="form-control"
                  placeholder="Costing ApprovedBy">
              </div>
              <div class="col-md-3 form-group">
                <label for="">Costing Approved Date</label>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker287" readonly placeholder="Costing Approved Date"
                    [(ngModel)]="poCostingDetails.approveddate" name="approveddate">
                  <mat-datepicker-toggle matSuffix [for]="picker287">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker287></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-3 form-group">
                <label for="">Costed</label>
                <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <label class="d-block" for="iscosted">
                    <input class="radio_animated" [value]=true id="iscosted" type="radio" name="iscosted"
                      [(ngModel)]="poCostingDetails.iscosted">
                    Yes
                  </label>
                  <label class="d-block" for="iscosted1">
                    <input class="radio_animated" [value]=false id="iscosted1" type="radio" name="iscosted"
                      [(ngModel)]="poCostingDetails.iscosted">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
              <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
              <button type="button" class="btn btn-primary" (click)="postPOCosting()">Save</button>
              <!-- <button type="button" class="btn btn-primary" (click)="goToPOList()">PO List</button> -->
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Item List" [id]="2">
          <ng-template ngbTabContent>
            <div class="text-right mt-3">
              <!-- <button type="button" class="btn btn-primary mr-3 mb-2" (click)="recalculate()"
                *ngIf="!iscosted">Recalculate</button> -->
              <button *ngIf="!poCostingDetails.iscosted" class="btn btn-primary mb-2" (click)="approvePOCosting()">Approve Costing</button>
            </div>
            <!-- <div class="form-row mt-3">
                <div class="form-group col-lg-2 col-6">
                  <label for="" class="mb-1">PON</label>
                  <input class="form-control" id="pon" name="pon" type="text" readonly [(ngModel)]="poDetails.pon"
                    placeholder="PON">
                </div>
                <div class="form-group col-lg-2 col-6">
                  <label for="" class="mb-1">PO Date</label>
                  <input class="form-control" id="podate" name="podate" type="date" readonly
                    [(ngModel)]="poDetails.podate" placeholder="PON">
                </div>
                <div class="form-group col-lg-2 col-6">
                  <label for="" class="mb-1">PO Total</label>
                  <input class="form-control" id="invtotal" name="invtotal" type="text" readonly
                    [(ngModel)]="poDetails.invtotal" placeholder="0.00">
                </div>
                <div class="form-group col-lg-2 col-6">
                  <label for="" class="mb-1">Shipping Fee</label>
                  <input class="form-control" id="shippingfees" name="shippingfees" type="text"
                    [(ngModel)]="poDetails.shippingfees" placeholder="0.00">
                </div>
                <div class="form-group col-lg-2 col-6">
                  <label for="" class="mb-1">Other Fee</label>
                  <input class="form-control" id="otherfees" name="otherfees" type="text"
                    [(ngModel)]="poDetails.otherfees" placeholder="0.00">
                </div>
                <div class="form-group col-lg-2 col-6">
                  <label for="" class="mb-1">Adjustments</label>
                  <input class="form-control" id="adjustment" name="adjustment" type="text"
                    [(ngModel)]="poDetails.adjustment" placeholder="0.00">
                </div>
              </div>  -->
              <div class="category-table ">
                <div class="table-responsive">
                  <table class="custom-table">
                    <tr class="custom-tr">
                      <th class="custom-th text-center w-150" (click)="onSortClick($event)">
                        ILC <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        Description <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        Type <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        Pur. UOM <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center w-90" (click)="onSortClick($event)">
                        Expected <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        Received <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        PO Cost<i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        LandedCost <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        AvgCost <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        SalesMargin% <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        Sales/Retail <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        Service <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        FlatPrice <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        UOM <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                      <th class="custom-th text-center" (click)="onSortClick($event)">
                        SellQty <i class="Sorting fa fa-chevron-up"></i>
                      </th>
                    </tr>
                    <tr class="custom-tr" *ngFor="let item of costingItems">
                      <td class="custom-td text-left w-150">
                        <a class="link-text" (click)="ProdctView(item)">{{item.ilc}}</a>
                      </td>
                      <td class="custom-td text-left">
                        {{item.description}}
                      </td>

                      <td class="custom-td text-left">
                        {{item.ptype}}
                      </td>
                      <td class="custom-td text-center">
                        {{item.puom}}
                      </td>
                      <td class="custom-td text-right">
                        {{item.expected}}
                      </td>
                      <td class="custom-td text-center">
                        {{item.received}}
                      </td>
                      <td class="custom-td text-center">
                        {{item.pocost}}
                      </td>
                      <td class="custom-td text-center">
                        {{item.landedcost}}
                      </td>
                      <td class="custom-td text-center">
                        {{item.avgcost}}
                      </td>
                      <td class="custom-td text-center">
                        {{item.salesmargin}}
                      </td>
                      <td class="custom-td text-center">
                        {{item.salesprice}}
                      </td>
                      <td class="custom-td text-center">
                        {{item.service}}
                      </td>
                      <td class="custom-td text-center">
                        {{item.flatprice}}
                      </td>
                      <td class="custom-td text-center">
                        {{item.uom}}
                      </td>
                      <td class="custom-td text-center">
                        {{item.actualqtytosell}}
                      </td>
                     
                    </tr>
                  </table>
                </div>
              </div>
           
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
    </div>

</resize-border>