import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { hrServices } from '../../../hr.services';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-addleave',
  templateUrl: './addleave.component.html',
  styleUrls: ['./addleave.component.scss']
})
export class AddleaveComponent implements OnInit {
  private langChangeSubscription: Subscription; 
  @Input() id: string;
  @Input() empid: string;
  @Input() action: boolean;
  @Input() from: string;
  appname = environment.APP_NAME;
  userdata: any;
  fullScreen = false;
  leavesData: any = {
    "id": null,
    "empid": null,
    "fromdate": null,
    "todate": null,
    "type": '',
    "status": 'submitted',
    "reason": "",
    "halfday1": false,
    "addressduringleave": "",
    "phoneduringleave": "",
    "managernotes": "",
    "createdate": null,
    "createby": null,
    "updateby": null,
    "backupduringleave": null,
    "companyid": null,
    "approvedby": null,
    "tlp": 0,
    "halfday2": false,
    "approvedbymanager": false,
    "approvedbyhr": false,
    "hrnotes": "",
    "manager": "",
    "tags": "",
    "updatedate": null,
    "finyear": null,
    "leavesconfigid": '',
    "pl_cnt": 0,
    "lwp_cnt": 0,

  };
  format: any;
  closeResult: string;
  leaveList: any = []
  statusList: any = []
  typeList: any = [];
  holidayList: any = [];
  booleanList: any = []
  isView: boolean = false;
  response: any;
  tlp: any;
  fieldsetDisabled = false;
  employee: any;
  leave: any = {};
  isShowStatusDropdown = true;
  publicHolidayDates = [];
  publicHolidayFilter: any;
  constructor(
    private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient,
    private modalService: NgbModal,
    private toaster: NotificationService,
    private toastr: NotificationService, private commonsevice: CommonServices, private service: hrServices, private datepipe: DatePipe, private loader: NgxUiLoaderService) {

    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.leavesData.fromdate = this.userdata.fromdate;
    this.leavesData.enddate = this.datepipe.transform(new Date(), 'yyyy-MM-dd')
    this.leave.fromdate = this.userdata.fromdate;
    this.leave.enddate = this.userdata.enddate;
    this.format = this.userdata.date_format;
    this.commonsevice.getComboEnums('leavestatus').subscribe(res => {
      this.statusList = res;
      environment.production ? '' : console.log(' bank status list data ', this.statusList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading status list ', err);
    });
    this.commonsevice.getBooleanList().subscribe(res => {
      this.booleanList = res;
      environment.production ? '' : console.log(' booleanList list data ', this.booleanList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading booleanList list ', err);
    });
    this.getLeavesType();
  }

  ngOnInit(): void {
    this.isView = this.action;
    if (this.from && this.from == 'profile') {
      this.isShowStatusDropdown = false;
    }
    if (this.id) {
      this.getleavebyId(this.id)
    }

    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
    
  }

  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/hr/employees/leaves/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
   } 
  getleavebyId(id) {
    this.loader.start();
    this.service.getLeavesbyId(id).subscribe(res => {
      this.loader.stop();
      environment.production ? '' : console.log('leaves by id', res)
      this.leavesData = res;
      this.getLeavesType();
      this.updateCalcs();
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading get leave by id list data', err);
    });
  }

  getLeavesType() {
    this.loader.start();
    this.service.getLeaveConfig(this.datepipe.transform(this.leavesData.fromdate, 'YYYY')).subscribe((res: any) => {
      this.loader.stop();
      this.leavesData.id ? this.leavesData.leavesconfigid = '' + this.leavesData.leavesconfigid : '';
      this.typeList = res.type;
      this.holidayList = res.holidays;
      this.holidayList.forEach(item => {
        this.publicHolidayDates.push(item.hdate);
      })
      this.disableHolidayDates();
    }, err => {
      this.loader.stop();
    });
  }

  disableHolidayDates() {
    this.publicHolidayFilter = (d: Date | null): boolean => {
			if (!d || isNaN(new Date(d).getTime())) {
				return true; // Allow null or invalid values
			}
    // Create a copy of the date to avoid modifying the original
		const adjustedDate = new Date(d);
		adjustedDate.setDate(adjustedDate.getDate() + 1);
		const dateStr = adjustedDate.toISOString().split('T')[0];

			// Check if the date is in the holiday list
			return !this.publicHolidayDates.includes(dateStr);
		};
  }
  saveEmpLeaves() {
    var type;
    this.typeList.forEach((element: any) => {
      if (element.Value == this.leavesData.leavesconfigid) {
        type = element.Text;
      }
    });
    this.leavesData.type = type;
    this.leavesData.companyid = this.userdata.companyid;
    this.leavesData.empid = this.empid;
    if (this.leavesData.id) {
      this.leavesData.createby = this.leavesData.createby;
      this.leavesData.createdate = this.datepipe.transform(this.leavesData.createdate, this.userdata.postdatetimeformat)
      this.leavesData.updateby = this.userdata.email;
      this.leavesData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat)
    } else {
      this.leavesData.createby = this.userdata.email;
      this.leavesData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat)
    }

    this.leavesData.fromdate = this.leavesData.fromdate ? this.datepipe.transform(this.leavesData.fromdate, this.userdata.postdateformat) : null;
    this.leavesData.todate = this.leavesData.todate ? this.datepipe.transform(this.leavesData.todate, this.userdata.postdateformat) : null;
    this.leavesData.finyear = this.datepipe.transform(this.leavesData.fromdate, 'YYYY');
    if (this.leavesData.leavesconfigid) {
      this.typeList.forEach(element => {
        if (element.Value == this.leavesData.leavesconfigid) {
          this.leavesData.type = element.Text;
        }
      })
    }
    this.loader.start()
    this.service.postEmpLeaves(this.leavesData).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.leavesData = {};
        this.loader.stop();
        this.modalService.dismissAll();
      }

    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading Post  postEmpLeaves  ', err);
    });
  }
  close() {
    this.modalService.dismissAll();
  }
  updateCalcs() {
    if (this.leavesData.fromdate != null && this.leavesData.todate != null) {
      this.loader.start();
      this.commonsevice.getLeaveCount(this.datepipe.transform(this.leavesData.fromdate, ('yyyy-MM-dd')), this.leavesData.halfday1, this.datepipe.transform(this.leavesData.todate, ('yyyy-MM-dd')), this.leavesData.halfday2).subscribe(res => {
        this.loader.stop();
        this.leavesData.tlp = res;
      }, err => {
        this.loader.stop();
      })
    } else {
      this.leavesData.tlp = 0;
    }
    return;
    if (this.leavesData.fromdate != null && this.leavesData.todate != null) {
      var a = moment(this.leavesData.fromdate, "YYYY-MM-DD");
      var b = moment(this.leavesData.todate, "YYYY-MM-DD");
      this.tlp = moment.duration(b.diff(a)).asDays();

      if (this.datepipe.transform(this.leavesData.fromdate, ('dd/MM/yyyy')) == this.datepipe.transform(this.leavesData.todate, ('dd/MM/yyyy'))) {
        this.tlp += 1;
      }
      else {
        this.tlp += 1;
      }
      if (this.leavesData.halfday1 == true) {
        var halfday1 = true;
        this.tlp = parseFloat(this.tlp) - 0.5;
      }
      if (this.leavesData.halfday2 == true) {
        var halfday2 = true;
        this.tlp = parseFloat(this.tlp) - 0.5;
      }
      if (this.tlp) {
        this.checkDate();
      }

    }
  }
  checkDate() {
    var startDate = this.datepipe.transform(this.leavesData.fromdate, ('dd/MM/yyyy'));
    var endDate = this.datepipe.transform(this.leavesData.todate, ('dd/MM/yyyy'));
    var regExp = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/;
    if (parseInt(endDate.replace(regExp, "$3$2$1")) >= parseInt(startDate.replace(regExp, "$3$2$1"))) {
      environment.production ? '' : console.log('start date is greater than end date');
    } else {
      this.leavesData.todate = null
      this.leavesData.tlp = 0;
      this.toastr.Error("End date should be greater than start date")
      return false
    }
  }
}
