<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="assembliesModalLabel">
        Unaudited Items
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="d-flex align-items-center justify-content-end w-100 mb-2">
        <div class="form-group">
          <label>Type</label>
          <div class="m-checkbox-inline mb-2 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="">
              <input class="radio_animated" [(ngModel)]="isTimeCard" [value]=true id="" type="radio" name="audittype"
                (change)="getAuditCards()">
              Time Card
            </label>
            <label class="d-block" for="">
              <input class="radio_animated" [(ngModel)]="isTimeCard" [value]=false id="" type="radio" name="audittype"
                (change)="getAuditItem()">
              Parts & Material
            </label>
          </div>
        </div>
        <div class="form-group">
          <label>Status</label>
          <div class="m-checkbox-inline mb-2 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="">
              <input class="radio_animated" [(ngModel)]="status" value="submitted" id="" type="radio" name="status"
                (change)="filterItems()">
              Submitted
            </label>
            <label class="d-block" for="">
              <input class="radio_animated" [(ngModel)]="status" value="audited" id="" type="radio" name="status"
                (change)="filterItems()">
              Audited
            </label>
          </div>
        </div>
        <span ngbDropdown container="body">
          <button class="btn btn-primary" id="dropdownBasic1" ngbDropdownToggle type="button">Save</button>
          <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1"
            style="min-width:102px">
            <li><span ngbDropdownItem (click)="postAudit('audited')">Audit</span></li>
            <li><span ngbDropdownItem (click)="postAudit('approved')">Approve</span></li>
          </ul>
        </span>
      </div>
      <div class="table-responsive">
        <!-- Time & Material -->
        <table class="table table-responsive customTable" *ngIf="!isTimeCard" style="width:110%">
          <thead>
            <tr class="custom-tr">
              <th class="custom-th text-center" style="width:60px;">
                <input type="checkbox" id="customCheckDisabled" name="materialMainCheckbox"
                  [(ngModel)]="materialMainCheckbox" (change)="checkMatrialValue()">
              </th>
              <th class="custom-th text-center" style="width:110px">Code</th>
              <th class="custom-th text-center">Description</th>
              <th class="custom-th text-center">Technician</th>
              <th class="custom-th text-center" style="width:140px">Date</th>
              <th class="custom-th text-center" style="width:90px; max-width:90px; min-width: 90px;">Qty</th>
              <th class="custom-th text-center" style="width:90px; max-width:90px; min-width: 90px;">Rate</th>
              <th class="custom-th text-center" style="width:90px; max-width:90px; min-width: 90px;">Total</th>
              <th class="custom-th text-center">Notes</th>
              <th class="custom-th text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inventoryItems; let i = index;">
              <td class="custom-td text-center">
                <input type="checkbox" name="ischecked-{{i}}" [(ngModel)]="data.ischecked">
              </td>
              <td class="custom-td text-left"> {{data.ilc}}</td>
              <td class="custom-td text-left"><input class="form-control" name="description-{{i}}"
                  [(ngModel)]="data.description"></td>
              <td class="custom-td text-left">
                <select name="tech-{{i}}" class="form-control text-center" name="tech-{{i}}" [(ngModel)]="data.tech">
                  <option value="">Select Tech</option>
                  <option *ngFor="let item of technicianList" [value]="item.id">{{item.title}}
                  </option>
                </select>
              </td>
              <td class="custom-td text-center">
                <input class="form-control" type="date" name="bookingtime{{i}}" [(ngModel)]="data.bookingtime"
                  style="width:120px">
              </td>
              <td class="custom-td text-center">
                <input class="form-control" (click)="goToEdit(data,'edit')" type="number" name="qty-{{i}}"
                  [(ngModel)]="data.qty" readonly style="width:100px">
              </td>
              <!-- <input class="form-control" type="number" name="hrs-{{i}}" [(ngModel)]="data.hrs" readonly
                style="width:100px"> -->
              <!-- </td> -->
              <td class="custom-td text-right">
                <input class="form-control" (focusout)="calculateLineTotal(data)" type="number" name="rate-{{i}}"
                  [(ngModel)]="data.rate" style="width:100px">
              </td>
              <td class="custom-td text-right">
                <input class="form-control" type="number" name="total-{{i}}" [(ngModel)]="data.total">
              </td>
              <td class="custom-td text-center">
                <textarea class="form-control" name="notes-{{i}}" [(ngModel)]="data.notes"></textarea>
              </td>
              <td class="custom-td text-center">
                {{data.status}}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End code here -->
        <!-- Time Card-->
        <div class="table-responsive">
          <table class="table table-responsive customTable" *ngIf="isTimeCard" style="width:120%">
            <thead>
              <tr class="custom-tr">
                <th class="custom-th text-center" style="width:60px;">
                  <input type="checkbox" id="customCheckDisabled" name="timeCardMainCheckbox"
                    [(ngModel)]="timeCardMainCheckbox" (change)="checkTimeCardValue()">
                </th>
                <th class="custom-th text-center" style="width:110px">Code</th>
                <th class="custom-th text-center" style="width:60px">Type</th>
                <th class="custom-th text-center">Description</th>
                <th class="custom-th text-center">Technician</th>
                <th class="custom-th text-center" style="width:130px">Date</th>
                <th class="custom-th text-center" style="width:110px">Start Time</th>
                <th class="custom-th text-center" style="width:110px">End Time</th>
                <th class="custom-th text-center" style="width:90px;  max-width:90px; min-width: 90px;">Hrs</th>
                <th class="custom-th text-center" style="width:90px;  max-width:90px; min-width: 90px;">Approved Hrs
                </th>
                <th class="custom-th text-center" style="width:90px;  max-width:90px;  min-width: 90px;">Rate</th>
                <th class="custom-th text-center" style="min-width:30%;">Notes</th>
                <th class="custom-th text-center" style="min-width:110px;">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of serviceItems; let i = index;">
                <td class="custom-td text-center">
                  <input type="checkbox" name="ischecked-{{i}}" [(ngModel)]="data.ischecked">
                </td>
                <td class="custom-td text-left"> {{data.ilc}}</td>
                <td class="custom-td text-left">{{data.worktype}}</td>
                <td class="custom-td text-left"><input class="form-control" name="description-{{i}}"
                    [(ngModel)]="data.description"></td>
                <td class="custom-td text-left">
                  <select name="tech-{{i}}" class="form-control text-center" name="tech-{{i}}" [(ngModel)]="data.tech">
                    <option value="">Select Tech</option>
                    <option *ngFor="let item of technicianList" [value]="item.id">{{item.title}}
                    </option>
                  </select>
                </td>
                <td class="custom-td text-center">
                  <input class="form-control" type="date" name="dw-{{i}}" [(ngModel)]="data.dw" style="width:120px">
                </td>
                <td class="custom-td text-center">
                  <input class="form-control" type="time" name="startime-{{i}}" [(ngModel)]="data.startime"
                    (change)="calculateHrs(data)">
                </td>
                <td class="custom-td text-center">
                  <input class="form-control" type="time" name="endtime-{{i}}" [(ngModel)]="data.endtime"
                    (change)="calculateHrs(data)">
                </td>
                <td class="custom-td text-center"><input readonly class="form-control" type="number" name="hrs-{{i}}"
                    [(ngModel)]="data.hrs"></td>
                <td class="custom-td text-center"><input class="form-control" type="number" name="approvedhrs-{{i}}"
                    [(ngModel)]="data.approvedhrs" (focusout)="validateHrs(data)"></td>
                <td class="custom-td text-center"><input class="form-control" type="number" name="rate-{{i}}"
                    [(ngModel)]="data.rate"></td>
                <td class="custom-td text-center"><textarea class="form-control" name="notes-{{i}}"
                    [(ngModel)]="data.notes"></textarea></td>
                <td class="custom-td text-center">
                  {{data.status}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- End code here -->
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
    </div>
  </div>
</resize-border>