import {NgModule} from '@angular/core';

import { ProjjoblistmodalComponent } from './projjoblistmodal.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [ProjjoblistmodalComponent],
    imports:[CommonModule, FormsModule, NgbModule,  NgbTooltipModule],
    exports: [ProjjoblistmodalComponent],
})
export class ProjjoblistModule {}