import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrintElementService } from 'ngx-print-element';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { purchaseServices } from 'src/app/components/purchase/purchase.service';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { UomRatioComponent } from '../uom-ratio/uom-ratio.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-adduom',
  templateUrl: './adduom.component.html',
  styleUrls: ['./adduom.component.scss']
})
export class AdduomComponent implements OnInit {
  @Input('uom') uom;
  itemList: any;
  userdata: any;
  appname = environment.APP_NAME;
  master: any = {
    id: null,
    description: '',
    createby: null,
    createdate: null,
    updateby: null,
    updatedate: null,
    active: true
  }
  issaved = false;
  private langChangeSubscription: Subscription; 
  constructor(private modalService: NgbModal, private cdRef: ChangeDetectorRef, private toastr: NotificationService, private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: CommonServices, public print: NgxPrintElementService, private purchaseservice: purchaseServices,
    private translate: TranslateService, 
private languageService: LanguageService, 
private http: HttpClient, ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.getProductUOMIndexValues()
  }

  ngOnInit(): void {
    console.log('uom');
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
     
  }
  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/sales/customers/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
    
  getProductUOMIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getProductUOMIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  editMasterData(item) {
    this.master = {
      active: item.active,
      companyid: item.companyid,
      createby: item.createby,
      createdate: item.createdate,
      deleteby: item.deleteby,
      deletedate: item.deletedate,
      description: item.description,
      id: item.id,
      qty: item.qty,
      updateby: item.updateby,
      updatedate: item.updatedate
    }
  }
  deleteProductUOM(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteProductUOM(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.issaved = true;
            this.getProductUOMIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting uom');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  postProductUOM() {
    if (this.master.id == undefined || this.master.id == null) {
      this.master.companyid = this.userdata.companyid;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.master.createby = this.userdata.email;
    } else {
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.master.updateby = this.userdata.email;
    }
    this.loader.start();
    this.service.postProductUOM(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.issaved = true;
        this.master.description = ''
        this.master.qty = ''
        this.getProductUOMIndexValues();
      } else {
        this.toastr.Error('Record not saved or updated');
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post uom');
    })
  }
  closeModal() {
    this.activeModal.close(this.issaved ? 'save' : 'Modal Closed');
  }
  uomRatioModal(uom) {
    var modalRef = null;
    modalRef = this.modalService.open(UomRatioComponent, { ariaLabelledBy: 'modal-basic-title', size: 'md' });
    modalRef.componentInstance.uom = uom;
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
    });
  }


}
