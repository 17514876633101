<resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Schedule# {{eventResponse.id}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" [style.pointer-events]="action=='view'?'none':''">
      <div class="col-lg-8">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Customer</label>
              <!-- <select class="form-control" name="guest" [(ngModel)]="eventResponse.customerid"
                        (ngModelChange)="onCustomerChange($event)">
                        <ng-container *ngFor="let n of customerList">
                            <option [value]="n.Value">{{n.Text}}</option>
                        </ng-container>
                        </select> -->
              <!-- <ng-autocomplete #Customer [data]="customerList" [initialValue]="editSetCustomer" [searchKeyword]="keyword"
              placeholder="Select Customer" (selected)='selectCustomer($event)' (opened)="changeDropDownStatus()"
              (inputCleared)="inputCustomerCleared()" [itemTemplate]="itemTemplate2"
              [notFoundTemplate]="notFoundTemplate" (inputFocused)='onFocusedCustomer($event)'>
            </ng-autocomplete> -->
              <div class="input-group" ngbDropdown>
                <input class="form-control" name="customer_name" type="text" autocomplete="off" readonly
                  [(ngModel)]="eventResponse.customer_name" Placeholder="Customer Name" #name="ngModel"
                  [ngClass]="{ 'is-invalid': name.invalid && name.errors , 'is-valid': name.valid}" required
                  ngbDropdownToggle>
                <i class="fa fa-chevron-down right-hover" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="main-dropdown ActionCell-List customer-dropdown"
                  aria-labelledby="dropdownBasic1">
                  <app-customerlistmodal (click)="SelectedCustomer()"></app-customerlistmodal>
                </ul>
              </div>
            </div>
            <ng-template #itemTemplate2 let-item>
              <a [innerHTML]="item.Text"></a>
            </ng-template>
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Site</label>
              <select name="businessdomain" class="form-control" [(ngModel)]="eventResponse.siteid"
                (change)="getCustomerDevices();getSiteInfo()">
                <option value="">Select Site</option>
                <option [ngValue]="data.Value" *ngFor="let data of customerSites">
                  {{data.Text}}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Title/ Subject</label>
              <input type="text" class="form-control" name="subject" [(ngModel)]="eventResponse.subject" />
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="form-group">
              <label for="" class="">Notify Customers</label>
              <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                <label class="d-block" for="edo-ani1">
                  <input class="radio_animated" value="Yes" id="notify" type="radio" name="notifyCustomer"
                    [(ngModel)]="eventResponse.notifycustomers">
                  Yes
                </label>

                <label class="d-block" for="edo-ani">
                  <input class="radio_animated" value="No" id="notify" type="radio" name="notifyCustomer"
                    [(ngModel)]="eventResponse.notifycustomers">
                  No
                </label>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="form-group">
              <label for="edo-multi_day">Multi Day</label>
              <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                <label class="d-block" for="edo-multi_day">
                  <input class="radio_animated" id="edo-multi_day" type="radio" value="Yes" name="rdo-multi_day"
                    [(ngModel)]="eventResponse.multi_day">
                  Yes
                </label>
                <label class="d-block" for="edo-autoemail1">
                  <input class="radio_animated" id="edo-autoemail1" value="No" [(ngModel)]="eventResponse.multi_day"
                    type="radio" name="rdo-multi_day">
                  No
                </label>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="" class="">Exclude Public Holidays</label>
                        <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                            <label class="d-block" for="edo-ani1">
                                <input class="radio_animated" value="Yes"
                                    id="exclude_public_holidays" type="radio"
                                    name="exclude_public_holidays"
                                    [(ngModel)]="eventResponse.exclude_public_holidays">
                                Yes
                            </label>

                            <label class="d-block" for="edo-ani">
                                <input class="radio_animated" value="No"
                                    id="exclude_public_holidays" type="radio"
                                    name="exclude_public_holidays"
                                    [(ngModel)]="eventResponse.exclude_public_holidays">
                                No
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="edo-week_days_only">Week Days Only</label>
                        <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                            <label class="d-block" for="edo-week_days_only">
                                <input class="radio_animated" id="edo-week_days_only" type="radio"
                                    value="Yes" name="rdo-week_days_only"
                                    [(ngModel)]="eventResponse.week_days_only">
                                Yes
                            </label>
                            <label class="d-block" for="edo-autoemail1">
                                <input class="radio_animated" id="edo-autoemail1" value="No"
                                    [(ngModel)]="eventResponse.week_days_only" type="radio"
                                    name="rdo-week_days_only">
                                No
                            </label>
                        </div>
                    </div>
                </div> -->
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="form-group">
              <label for="" class="">Full Day</label>
              <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                <label class="d-block" for="day">
                  <input class="radio_animated" value="Yes" id="day" type="radio" name="fullDay"
                    [(ngModel)]="eventResponse.fullday" (change)="isShowEndDate=false">
                  Yes
                </label>
                <label class="d-block" for="day">
                  <input class="radio_animated" value="No" id="day" type="radio" name="fullDay"
                    [(ngModel)]="eventResponse.fullday" (change)="isShowEndDate=true">
                  No
                </label>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="form-group">
              <label for="" class="">Start Date</label>
              <input type="datetime-local" class="form-control" name="start" [(ngModel)]="eventResponse.start_date"
                (focusout)="onDateChangeEvent()" />
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12" *ngIf="isShowEndDate">
            <div class="form-group">
              <label for="" class="">End/Due Date</label>
              <input type="datetime-local" class="form-control" name="due" [(ngModel)]="eventResponse.due_date"
                (focusout)="onDateChangeEvent()" />
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="form-group">
              <label for="" class="">Total Est. Duration</label>
              <input type="text" class="form-control" name="estimatedhrs" [(ngModel)]="eventResponse.estimatedhrs" />
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Est. Duration/day/Tech</label>
              <input type="number" class="form-control" name="estimatedhrs_perday_pertech"
                [(ngModel)]="eventResponse.estimatedhrs_perday_pertech" />
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Point of Contact</label>
              <input type="text" class="form-control" name="poc" [(ngModel)]="eventResponse.poc" />
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Requested By</label>
              <input type="text" class="form-control" name="requestedby" [(ngModel)]="eventResponse.requestedby" />
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Phone</label>
              <input type="text" class="form-control" name="phone" [(ngModel)]="eventResponse.phone" />
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Contract#</label>
              <input type="text" class="form-control" name="contractno" [(ngModel)]="eventResponse.contractno" />
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Recurrence#</label>
              <input type="text" readonly class="form-control" name="contract_recurrence_id"
                [(ngModel)]="eventResponse.contract_recurrence_id" />
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Authorized By</label>
              <input type="text" class="form-control" name="authorizedby" [(ngModel)]="eventResponse.authorizedby" />
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Authorization Notes</label>
              <input type="text" class="form-control" name="authorizationnotes"
                [(ngModel)]="eventResponse.authorizationnotes" />
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Customer Notice</label>
              <textarea class="form-control" rows="3" [readonly]="false" debounce="0" name="customer_notice"
                [(ngModel)]="eventResponse.customer_notice">
                        </textarea>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Scope of Work</label>
              <textarea class="form-control" rows="3" [readonly]="false" debounce="0" name="description"
                [(ngModel)]="eventResponse.text">
                        </textarea>

            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">WatchList(comma seperated emails)</label>
              <textarea class="form-control" rows="3" [readonly]="false" debounce="0" name="guest"
                [(ngModel)]="eventResponse.attentionto">
                        </textarea>
              <!-- <textarea rows="4" class="form-control" name="guest"
      [(ngModel)]="eventResponse.attentionto"></textarea> -->
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="form-group" name="category">
              <label for="" class="">Category</label>
              <select class="form-control" [(ngModel)]="eventResponse.categoryid" (change)="getSubCategories()">
                <option value="">Select Category</option>
                <ng-container *ngFor="let n of categoryList">
                  <option [value]="n.Value">{{n.Text}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="form-group" name="subcategory">
              <label for="" class="">Sub Category</label>
              <select class="form-control" [(ngModel)]="eventResponse.subcategoryid" (change)="selectSubCategory()">
                <option value="">Select Sub Category</option>
                <ng-container *ngFor="let n of subCategoryList">
                  <option [value]="n.Value">{{n.Text}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Location</label>
              <input type="text" class="form-control" name="location" [(ngModel)]="eventResponse.low" />
            </div>
          </div>
          <!-- <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="" class="">Directions(Google map)</label>
                         <input class="form-control" ngx-google-places-autocomplete [options]='options'
      (onAddressChange)="AddressChange($event)" name="low" [(ngModel)]="eventResponse.low"/>
    {{ formattedaddress }}
                        <input class="form-control" name="low"
                            [(ngModel)]="eventResponse.directions">
                    </div>
                </div> -->
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="form-group">
              <label>Directions (Google map)</label>
              <input type="text" class="form-control" name="directions" #directions="ngModel"
                [(ngModel)]="eventResponse.directions" placeholder="Search Nearest Location" autocorrect="off"
                autocapitalize="off" spellcheck="off" #search>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Priority</label>
              <select class="form-control" name="priority" [(ngModel)]="eventResponse.priority">
                <ng-container *ngFor="let n of priorityList">
                  <option [value]="n.stringValue">{{n.Text}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Work Completed?</label>
              <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                <label class="d-block" for="work">
                  <input class="radio_animated" value="No" id="work" type="radio" name="workcompleted"
                    [(ngModel)]="eventResponse.workcompleted">
                  No
                </label>
                <label class="d-block" for="work">
                  <input class="radio_animated" value="Yes" id="work" type="radio" name="workcompleted"
                    [(ngModel)]="eventResponse.workcompleted">
                  Yes
                </label>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Completed On</label>
              <input type="date" class="form-control" [(ngModel)]="eventResponse.end_date" />
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="" class="">Status</label>
              <select class="form-control" name="status" [(ngModel)]="eventResponse.status">
                <ng-container *ngFor="let n of statusList">
                  <option [value]="n.Value">{{n.Text}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="">Tags</label>
              <mat-form-field class="ezybooksChipList" appearance="fill">
                <mat-chip-list #tagList aria-label="Tag selection">
                  <mat-chip *ngFor="let tag of tags" (removed)="remove(tag)">
                    {{tag}}
                    <button matChipRemove>
                      <mat-icon><i class="fa fa-times-circle" aria-hidden="true"></i></mat-icon>
                    </button>
                  </mat-chip>
                  <input placeholder="Select or add new tag" #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
                    [matChipInputAddOnBlur]="addOnFocusOut" [matChipInputFor]="tagList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                    {{tag}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group devlist">
          <label for="" class="">Device or Equipment</label>
          <div class="tech form-control">
            <div class="checkbox">
              <div class="form-check" *ngFor="let n of customerDeviceList">
                <input class="form-check-input" type="checkbox" [checked]="n.checked" [value]="n.Value" id="n.Value"
                  (click)="deviceClick(n)">
                <label class="form-check-label">
                  {{n.Text}}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group devlist">
          <label for="" class="">Technicians</label>
          <div class="tech form-control">
            <div class="checkbox">
              <div class="form-check" *ngFor="let n of technicianList">
                <input class="form-check-input" type="checkbox" [checked]="n.checked" [value]="n.id" id="n.id"
                  (click)="technicianClick(n);">
                <label class="form-check-label">
                  {{n.title}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-danger float-left" [disabled]="action=='view' || action=='add'"
      (click)="deleteTicket()">
      Delete
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">
      Close
    </button>
    <button [disabled]="action=='view'" type="button" class="btn btn-primary" (click)="confirmationSubmit()">
      Save
    </button>
  </div>
</resize-border>