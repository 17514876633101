<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
            <thead class="sticky-tab-head">
            <tr class="custom-tr">
                <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                        [class.disabled]="fieldsetDisabled" container="body" ngbTooltip="{{ 'Table_Columns.Document_Tooltip' | translate }}" data-target="#exampleModal"
                        (click)="uploadDoc('','')"><i class="fa fa-plus"></i> </a></th>
                <th class="custom-th text-center"(click)="onSortClick($event,'description')">{{ 'Table_Columns.Title_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
                <th class="custom-th text-center"(click)="onSortClick($event,'name')">{{ 'Table_Columns.Document_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
                <th class="custom-th text-center"(click)="onSortClick($event,'dtype')">{{ 'Table_Columns.Type_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
                <th class="custom-th text-center"(click)="onSortClick($event,'createby')">{{ 'Table_Columns.CreateBy_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
                <th class="custom-th text-center"(click)="onSortClick($event,'createdate')">{{ 'Table_Columns.CreateDate_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            </tr>
        </thead>
        <tbody>
            <tr class="custom-tr" *ngFor="let item of docList ">
                <td class="custom-td text-center">
                    <div class="ActionBtn">
                        <a class="hoverBtn">
                            <i class="fa fa-trash"></i>
                        </a>
                    </div>
                </td>
                <td class="custom-td text-left">{{item.description}}</td>
                <td class="custom-td text-left"><span class="hyperlink" (click)="viewDocument(item)">{{item.name}}</span> </td>
                <td class="custom-td text-left">{{item.dtype}}</td>
                <td class="custom-td text-left">{{item.createby}}</td>
                <td class="custom-td text-left text-nowrap">{{item.createdate | date:userdata.displaydatetimeformat}}
                </td>
            </tr>
        </tbody>
        </table>
    </div>
    </div>
  </div>
</div>

<!-- <ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Documents</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation">
      <div class="form">
        <div class="form-group">
          <label for="" class="mb-1">Reference :</label>
          <input class="form-control" type="text" name="titel" [(ngModel)]="reference">
        </div>
        <div class="form-group">
          <label for="" class="mb-1">Title :</label>
          <input class="form-control" type="text" name="title" [(ngModel)]="title">
        </div>
        <div class="form-group">
          <label for="">Document Type</label>  
          <div class="input-group ">
              <select class="form-control" required="" [(ngModel)]="docType"
                 name="designation">
                  <option value="">Select</option>
                  <option *ngFor="let item of docTypeList" [value]="item.Value">{{item.Text}}
                  </option>
              </select>
              <div class="input-group-append">
                  <span class="input-group-text btn-primary cursor-pointer"
                      container="body" ngbTooltip="Add Documents Type" id="basic-addon2"
                      (click)="openMasterModal()"  [class.disabled]="fieldsetDisabled"><i class="fa fa-plus"></i>
                  </span>
              </div>
          </div>
      </div>
        <div class="form-group">
            <label for="" class="mb-1">Select File :</label>
            <input class="form-control" type="file" ng2FileSelect [uploader]="uploader"
												(change)="selectedFileOnChanged($event)"
												accept="application/pdf, image/gif, image/jpeg, image/png" />
          </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
      (click)="modal.dismiss('Cross click')">Close</button>
    <button type="button" class="btn btn-primary" >Save</button>
  </div>
</ng-template> -->