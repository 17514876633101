<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">{{ 'modal_title' | translate }} {{site.id}}</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="form" #f="ngForm">
        <fieldset [disabled]="isView">
          <div class="form row">
            <div class="form-group col-md-6 col-sm-12">
              <label for="name" class="required">{{ 'modal_inputs.name_label' | translate }}  </label>
              <div class="input-group">
                <input type="text" name="name" id="name" class="form-control" [(ngModel)]="site.name" #name="ngModel"
                  placeholder="{{ 'modal_inputs.name_placeholder' | translate }}"
                  [ngClass]="{ 'is-invalid': (name.touched || f.submitted) && name.errors , 'is-valid': f.submitted || name.valid}"
                  required />
                <div class="input-group-append" *ngIf="params.from=='sitecust'">
                  <span class="input-group-text btn-primary cursor-pointer" data-toggle="modal"
                    data-original-title="test" data-target="#exampleModal" (click)="openSite()"><i
                      class="fa fa-search"></i></span>
                </div>
              </div>
              <div *ngIf="(name.touched || f.submitted) && name.errors" class="invalid-feedback d-block">
                <div *ngIf="name.errors.required">{{ 'modal_inputs.name_required' | translate }} </div>
              </div>
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="shortname" class="required">{{ 'modal_inputs.short_name_label' | translate }} </label>
              <input type="text" name="shortname" id="shortname" class="form-control" [(ngModel)]="site.shortname"
                #shortname="ngModel" placeholder="{{ 'modal_inputs.short_name_placeholder' | translate }} "
                [ngClass]="{ 'is-invalid': (shortname.touched || f.submitted) && shortname.errors , 'is-valid': f.submitted || shortname.valid}"
                required />
              <div *ngIf="(shortname.touched || f.submitted) && shortname.errors" class="invalid-feedback d-block">
                <div *ngIf="shortname.errors.required">{{ 'modal_inputs.shortName_required' | translate }} </div>
              </div>
            </div>
            <div class="form-group col-md-6 col-sm-12" *ngIf="params.from=='sitemaster'">
              <label for="type">{{ 'modal_inputs.type_label' | translate }} </label>
              <select class="form-control" required="" name="type" [(ngModel)]="site.type">
                <option value="">{{ 'modal_inputs.type_placholder' | translate }}</option>
                <option *ngFor="let type of typeList" [value]="type.stringValue">{{type.Text}}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6 col-sm-12" *ngIf="params.from=='sitecust'">
              <label for="poc">Point of Contact </label>
              <input type="text" name="poc" id="poc" class="form-control" [(ngModel)]="site.poc" placeholder="POC" />
            </div>
            <div class="form-group col-md-6 col-sm-12" *ngIf="params.from=='sitecust'">
              <label for="email">Email </label>
              <input type="email" name="email" id="email" class="form-control" [(ngModel)]="site.email"
                placeholder="Email" />
            </div>
            <div class="form-group col-md-6 col-sm-12" *ngIf="params.from=='sitecust'">
              <label for="phone">Phone </label>
              <input type="number" name="phone" id="phone" class="form-control" [(ngModel)]="site.phone"
                #phone="ngModel" placeholder="Phone" />
            </div>
            <div class="form-group col-md-6 col-sm-12" *ngIf="params.from=='sitecust'">
              <label for="notice">Notice for outsider</label>
              <input type="text" name="notice4outsiders" class="form-control" id="notice4outsiders"
                [(ngModel)]="site.notice4outsiders" name="notice4outsiders" #notice4outsiders="ngModel" />
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="address1">{{ 'modal_inputs.address1_label' | translate }} </label>
              <input type="text" name="address1" id="address1" class="form-control" [(ngModel)]="site.address1"
                placeholder="{{ 'modal_inputs.address1_placeholder' | translate }} " />
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="address2">{{ 'modal_inputs.address2_label' | translate }} </label>
              <input type="text" name="address2" id="address2" class="form-control" [(ngModel)]="site.address2"
                placeholder="{{ 'modal_inputs.address2_placeholder' | translate }} " />
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="address3">{{ 'modal_inputs.address3_label' | translate }} </label>
              <input type="text" name="address3" id="address3" class="form-control" [(ngModel)]="site.address3"
                placeholder="{{ 'modal_inputs.address3_placeholder' | translate }} " />
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="address4">{{ 'modal_inputs.address4_label' | translate }} </label>
              <input type="text" name="address4" id="address4" class="form-control" [(ngModel)]="site.address4"
                placeholder="{{ 'modal_inputs.address4_placeholder' | translate }} " />
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="zip">{{ 'modal_inputs.zip_label' | translate }} </label>
              <input type="text" name="zip" id="zip" class="form-control" [(ngModel)]="site.zip" placeholder="{{ 'modal_inputs.zip_placeholder' | translate }} " />
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="directions">{{ 'modal_inputs.directions_label' | translate }} </label>
              <input type="text" class="form-control" name="directions" #directions="ngModel"
                [(ngModel)]="site.directions" placeholder="{{ 'modal_inputs.directions_placeholder' | translate }}" autocorrect="off"
                autocapitalize="off" spellcheck="off" #search>
            </div>
            <div class="form-group col-sm-6">
              <label for="">{{ 'modal_inputs.latitude_label' | translate }} </label>
              <input type="text" class="form-control" name="lat" [(ngModel)]="site.lat">
            </div>
            <div class="form-group col-sm-6">
              <label for="">{{ 'modal_inputs.longitude_label' | translate }}</label>
              <input type="text" class="form-control" name="lng" [(ngModel)]="site.lng">
            </div>
            <div class="form-group col-sm-6">
              <label for="">{{ 'modal_inputs.south_label' | translate }}</label>
              <input type="text" class="form-control" name="south" [(ngModel)]="site.south">
            </div>
            <div class="form-group col-sm-6">
              <label for="">{{ 'modal_inputs.west_label' | translate }}</label>
              <input type="text" class="form-control" name="west" [(ngModel)]="site.west">
            </div>
            <div class="form-group col-sm-6">
              <label for="">{{ 'modal_inputs.east_label' | translate }}</label>
              <input type="text" class="form-control" name="east" [(ngModel)]="site.east">
            </div>
            <div class="form-group col-sm-6">
              <label for="">{{ 'modal_inputs.north_label' | translate }}</label>
              <input type="text" class="form-control" name="north" [(ngModel)]="site.north">
            </div>
            <div class="form-group col-sm-6" *ngIf="params.from=='sitecust'">
              <label for="">Is Primary</label>
              <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                <label class="d-block" for="">
                  <input class="radio_animated" [value]=true id="" type="radio" name="isprimary"
                    [(ngModel)]="site.isprimary">
                  Yes
                </label>
                <label class="d-block" for="">
                  <input class="radio_animated" [value]=false id="isprimary" type="radio" name="isprimary"
                    [(ngModel)]="site.isprimary">
                  No
                </label>
              </div>
            </div>
            <div class="form-group col-sm-6">
              <label for="">{{ 'modal_inputs.specmap_label' | translate }}</label>
              <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                <label class="d-block" for="">
                  <input class="radio_animated" [value]=true id="" type="radio" name="specmap"
                    [(ngModel)]="site.specmap">
                    {{ 'modal_inputs.specmap_yes_radio' | translate }}
                </label>
                <label class="d-block" for="">
                  <input class="radio_animated" [value]=false id="specmap" type="radio" name="specmap"
                    [(ngModel)]="site.specmap">
                    {{ 'modal_inputs.specmap_no_radio' | translate }}
                </label>
              </div>
            </div>
            <div class="form-group col-sm-6">
              <label for=""> {{ 'modal_inputs.map_picture_label' | translate }}</label>
              <input class="form-control" type="file" ng2FileSelect [uploader]="uploader"
                (change)="selectedFileOnChanged($event)" accept="image/gif, image/jpeg, image/png" />
              <a [href]="picURL" target="_blank">{{site.pic}}</a>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()"> {{ 'modal_buttons.close_button' | translate }}</button>
      <button type="button" class="btn btn-primary" [disabled]="!f.valid || isView" (click)="addSites()"> {{ 'modal_buttons.save_button' | translate }}</button>
    </div>
  </div>
</resize-border>