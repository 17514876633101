<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">{{coa_name}}
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <form action="">
            <div class="custom-row">
              <div class="form-row">
                <div class="offset-6 col-lg-3 col-md-6 form-group">
                  <label for="">From Date</label>
                  <mat-form-field>
                    <input matInput [matDatepicker]="picker50" placeholder="Choose a date" [(ngModel)]="ledg.fromdate"
                      name="fromdate">
                    <mat-datepicker-toggle matSuffix [for]="picker50"></mat-datepicker-toggle>
                    <mat-datepicker #picker50></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-lg-3 col-md-6 form-group pl-0">
                  <label for="">To Date</label>
                  <mat-form-field>
                    <input matInput [matDatepicker]="picker51" placeholder="Choose a date" [(ngModel)]="ledg.todate"
                      name="todate">
                    <mat-datepicker-toggle matSuffix [for]="picker51"></mat-datepicker-toggle>
                    <mat-datepicker #picker51></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="btn-group CsearchBtn1 CsearchBtn" role="group">
                <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary right-radius"
                  (click)="getBalanceSheet()">Search</button>
                <div ngbDropdown class="d-inline-block ActionCell" container="body">
                  <i class="eb-dots toggleBtn" id="dropdownBasic1" ngbDropdownToggle></i>
                  <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1">
                    <li><span ngbDropdownItem (click)="exportAsCSV()"> Export to csv</span></li>
                    <li><span ngbDropdownItem (click)="exportAsXLSX()"> Export to excel</span></li>
                    <li><span ngbDropdownItem (click)="print()">Print</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </form>
          <div class="category-table custom-datatable LedgersTable">
            <div class="table-responsive">
              <ng2-smart-table [settings]="settings" [source]="ledgerList"></ng2-smart-table>
            </div>
          </div>
          <div class="total-count" *ngIf="totalRows">
            <b class="mr-1">Total rows : </b> {{totalRows}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-ui-loader></ngx-ui-loader>
</resize-border>