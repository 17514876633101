import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-customerprojjoblistmodal',
  templateUrl: './customerprojjoblistmodal.component.html',
  styleUrls: ['./customerprojjoblistmodal.component.scss']
})
export class CustomerProjJoblistModalComponent implements OnInit {
  @Input('searchtxt') searchtxt;
  @Input('customerid') customerid;
  @ViewChild('Customer') Customer;
  jobList: any = [];
  subscription: Subscription;
  @ViewChild('SearchElement') SearchElement: ElementRef;
  customerList: any = [];
  keyword2 = 'name';
  selectedJob: any = '';
  customer: any = {
    siteid: '',
    projjob: '',
    customerid: '',
    searchtxt: ''
  };
  cust: any;
  siteList: any = [];
  constructor(private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private commoanService: CommonServices,
    private toastr: NotificationService,
    private datePipe: DatePipe,
    private loader: NgxUiLoaderService) {
    this.getCompanySites();
  }

  getProjJobslist() {
    if (this.searchtxt == undefined || this.searchtxt == null || this.searchtxt.length < 3) {
      return;
    }
    if (this.customer.customerid == undefined || this.customer.customerid == null) {
      this.customerid = '';
    }
    this.loader.start();
    this.commoanService.getProjJobslist(this.customer.projjob, this.customer.customerid).subscribe(res => {
      this.loader.stop();
      this.jobList = res;
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading expType list ', err);
    });
  }
  selectCustomer(item) {

  }
  selectJob(item) {
    this.selectedJob = item;
    this.jobList = [];
  }

  ngOnInit(): void {

    // this.getProjJobslist();
  }
  selectSite() {
    if (this.customer.siteid) {
      this.onChangeSearch('');
    } else {
      this.customerList = [];
    }
  }
  getCompanySites() {
    this.loader.start();
    this.commoanService.getCompanySites().subscribe(res => {
      this.loader.stop();
      this.siteList = res;
      environment.production ? '' : console.log(' customer list data ', this.customerList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
  onFocused($event) {
    this.Customer.clear();
  }
  onChangeSearch(val: string) {
    this.loader.start();
    this.commoanService.getCustomers(this.customer.searchtxt, this.customer.siteid).subscribe(res => {
      this.loader.stop();
      this.customerList = res;
      environment.production ? '' : console.log(' customer list data ', this.customerList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
}
