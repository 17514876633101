<resize-border [dragHolder]="header">
	<div #header class="modal-header">
		<h5 class="modal-title f-w-600" id="productModalLabel">Item Compatibility
		</h5>
		<button type="button" class="close" aria-label="Close" (click)="closeModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="input-group mb-2 SearchBox">
			<input type="text" class="form-control small" id="chat-search" name="searchValue"
				placeholder="Search By Name, Model" aria-label="Search" aria-describedby="basic-addon2"
				[(ngModel)]="searchtxt" [ngModelOptions]="{standalone: true}">
			<div class="input-group-append">
				<span class="input-group-text btn-primary cursor-pointer" (click)="getItemCompatibility()">
					<i class="fa fa-search"></i>
				</span>
			</div>
		</div>
		<div class="category-table custom-datatablemb-3 deviceList">
			<div class="table-responsive2">
				<table class="custom-table">
					<thead>
						<tr class="custom-tr">
							<th class="custom-th text-center">Name</th>
							<th class="custom-th text-center">Model</th>
							<th class="custom-th text-center">Serial</th>
							<th class="custom-th text-center">Make</th>
							<th class="custom-th text-center">Manufacturer</th>
							<th class="custom-th text-center">Description</th>
						</tr>
					</thead>
					<tbody>
						<tr class="custom-tr" *ngFor="let item of deviceList">
							<td class="custom-td text-left">{{item.name}}</td>
							<td class="custom-td text-left">{{item.model}}</td>
							<td class="custom-td text-left">{{item.serial}}</td>
							<td class="custom-td text-left">{{item.make}}</td>
							<td class="custom-td text-left">{{item.manufacturer}}</td>
							<td class="custom-td text-left">{{item.description}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer justify-content-center">
		<button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
			(click)="closeModal()">Close</button>
	</div>
</resize-border>