<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="productModalLabel">Contacts
            </h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="custom-row">
                <div class="form-row ">
                    <div class="offset-6 col-lg-6 col-md-6 form-group">
                        <label for="">Search by name, email</label>
                        <input type="search" class="form-control" [(ngModel)]="contact.name" name="name"
                            placeholder="Name, email">
                    </div>
                </div>
                <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
                    <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary right-radius"
                        (click)="getContact()">Search</button>
                </div>
            </div>
            <div class="category-table custom-datatable">
                <div class="table-responsive">
                    <table class="custom-table">
                        <thead class="sticky-tab-head">
                            <tr class="custom-tr">
                                <th class="custom-th text-center"> Name</th>
                                <th class="custom-th text-center">Email</th>
                                <th class="custom-th text-center">Phone</th>
                                <th class="custom-th text-center"> Notes</th>
                                <th class="custom-th text-center">Active</th>
                                <th class="custom-th text-center">CreateBy</th>
                                <th class="custom-th text-center">CreateDate</th>
                                <th class="custom-th text-center"> UpdateBy</th>
                                <th class="custom-th text-center">UpdateDate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="custom-tr" *ngFor="let item of contactList">
                                <td class="custom-td text-left"><span (click)="selectContact(item)"
                                        class="hyperlink">{{item.name}}</span></td>
                                <td class="custom-td text-left"><span (click)="selectContact(item)"
                                        class="hyperlink">{{item.email}}</span></td>
                                <td class="custom-td text-left"><span (click)="selectContact(item)"
                                        class="hyperlink">{{item.phone}}</span></td>
                                <td class="custom-td text-left">{{item.notes}}</td>
                                <td class="custom-td text-center"><span class="badge"
                                        title="{{item.active}}">{{item.active}}</span>
                                </td>
                                <td class="custom-td text-left">{{item.createby}}</td>
                                <td class="custom-td text-center text-nowrap">{{item.createdate |
                                    date:userdata.displaydatetimeformat}}
                                </td>
                                <td class="custom-td text-left">{{item.updateby}}</td>
                                <td class="custom-td text-center text-nowrap">{{item.updatedate |
                                    date:userdata.displaydatetimeformat}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
        </div>
    </div>
</resize-border>