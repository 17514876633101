<resize-border [dragHolder]="header">
<div #header class="modal-header">
	<h5 class="modal-title f-w-600" id="productModalLabel">{{ 'Page_title' | translate }}
	</h5>
	<button type="button" class="close" aria-label="Close" (click)="closeModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div class="input-group mb-2 SearchBox">
		<input type="text" class="form-control small" id="chat-search" name="searchValue" placeholder="{{ 'Index_Filter.Search_placeholder' | translate }}"
			aria-label="Search" aria-describedby="basic-addon2" [(ngModel)]="searchtext"
			[ngModelOptions]="{standalone: true}">
		<div class="input-group-append">
			<span class="input-group-text btn-primary cursor-pointer" (click)="getPurchaseOrderList()">
				<i class="fa fa-search"></i>
			</span>
		</div>
	</div>
	<div class="category-table custom-datatablemb-3 productpurchasehistorytable">
		<div class="table-responsive2">
			<table class="custom-table">
				<thead>
					<tr class="custom-tr">
						<th class="custom-th text-center">{{ 'Index_table.ILC_column' | translate }}</th>
						<th class="custom-th text-center">{{ 'Index_table.Description_column' | translate }}</th>
						<th class="custom-th text-center">{{ 'Index_table.PO#_column' | translate }}</th>
						<th class="custom-th text-center">{{ 'Index_table.PODate_column' | translate }}</th>
						<th class="custom-th text-center">{{ 'Index_table.DueDate_column' | translate }}</th>
						<th class="custom-th text-center">{{ 'Index_table.Type_column' | translate }}</th>
						<th class="custom-th text-center">{{ 'Index_table.POTitle_column' | translate }}</th>
						<th class="custom-th text-center">{{ 'Index_table.POCost_column' | translate }}</th>
						<th class="custom-th text-center">{{ 'Index_table.QtyOrdered_column' | translate }}</th>
						<th class="custom-th text-center">{{ 'Index_table.QtyShipped_column' | translate }}</th>
						<th class="custom-th text-center">{{ 'Index_table.QtyReceived_column' | translate }}</th>
						<th class="custom-th text-center">{{ 'Index_table.Status_column' | translate }}</th>
					</tr>
				</thead>
				<tbody>
					<tr class="custom-tr" *ngFor="let item of itemDataList">
						<td class="custom-td text-left">{{item.ilc}}</td>
						<td class="custom-td text-left">{{item.description}}</td>
						<td class="custom-td text-left">{{item.pon}}</td>
						<td class="custom-td text-left">{{item.podate | date:'yyy-MM-dd'}}</td>
						<td class="custom-td text-left">{{item.duedate | date:'yyy-MM-dd'}}</td>
						<td class="custom-td text-left">{{item.potype}}</td>
						<td class="custom-td text-left">{{item.potitle}}</td>
						<td class="custom-td text-right">{{item.pocost | number:'1.2-2'}}</td>
						<td class="custom-td text-center">{{item.qty}}</td>
						<td class="custom-td text-center">{{item.shippedqty}}</td>
						<td class="custom-td text-center">{{item.receivedqty}}</td>
						<td class="custom-td text-center"><span class="badge" title="{{item.status}}">{{item.status}}</span></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
<div class="modal-footer justify-content-center">
	<button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">{{ 'Index_table.Close_buttons' | translate }}</button>
</div>
</resize-border>
<!-- <ngx-ui-loader></ngx-ui-loader> -->