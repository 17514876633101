<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Choose Serial</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="padding-div">
        <form>
          <div class="form-row">
            <div class="col-lg-12 col-md-12 form-group position-relative">
              <i class="fa fa-search hover-icon"></i><input #SearchElement style="padding-left:20px;" type="text"
                class="form-control" name="searchTxt" placeholder="Search Serials" [(ngModel)]="searchTxt"
                (input)="onSearch()">
            </div>
          </div>
        </form>
        <div class="category-table custom-datatable mt-1">
          <div class="table-responsive">
            <table class="custom-table">
              <thead>
                <tr class="custom-tr">
                  <th class="custom-th text-center">Serials</th>
                </tr>
              </thead>
              <tbody>
                <tr class="custom-tr" *ngFor="let item of filteredSerials;let i=index">
                  <td class="custom-td position-relative text-left">
                    <div class="checklist">
                      <input type="checkbox" [(ngModel)]="item.checked" name="srno-{{i}}"> {{item.srno}}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary mb-1 mr-1" (click)="isSaved=true;closeModal()">Submit</button>
    </div>
  </div>
</resize-border>