<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Expense Request# {{expRequestData.reqno}}
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="required">{{ 'form_inputs.date_label' | translate }}</label>
              <mat-form-field
                [ngClass]="{ 'is-invalid': expensedate.invalid && expensedate.errors , 'is-valid': expensedate.valid}"
                [class.disabled]="fieldsetDisabled">
                <input matInput [matDatepicker]="picker95"
                  placeholder="{{ 'form_inputs.date_placeholder' | translate }}"
                  [(ngModel)]="expRequestData.expensedate" #expensedate="ngModel" name="expensedate" required
                  [disabled]="fieldsetDisabled">
                <mat-datepicker-toggle matSuffix [for]="picker95">
                </mat-datepicker-toggle>
                <mat-datepicker #picker95></mat-datepicker>
              </mat-form-field>
              <div *ngIf="expensedate.invalid && expensedate.errors" class="invalid-feedback d-block">
                <div *ngIf="expensedate.errors.required">
                  {{ 'form_inputs.date_required' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="required">{{ 'form_inputs.submitted_by_label' | translate }}</label>
              <ng-select placeholder="{{ 'form_inputs.Select_employee_placholder' | translate }}" name="submittedby"
                #submittedby="ngModel" [(ngModel)]="expRequestData.submittedby" [clearable]="expRequestData.submittedby"
                required=""
                [ngClass]="{ 'is-invalid': submittedby.invalid && submittedby.errors , 'is-valid': submittedby.valid}">
                <ng-option *ngFor="let item of employeeList" [value]="item.Value">
                  {{item.Text}}</ng-option>
              </ng-select>
              <div *ngIf="submittedby.invalid && submittedby.errors" class="invalid-feedback d-block">
                <div *ngIf="submittedby.errors.required">
                  {{ 'form_inputs.submittedBy_required' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-4 col-sm-12">
            <div class="form-group">
              <label>{{ 'form_inputs.receipt_label' | translate }}</label>
              <input class="form-control" id="receiptno" type="text" name="receiptno"
                [(ngModel)]="expRequestData.receiptno"
                placeholder="{{ 'form_inputs.receipt_placeholder' | translate }}">
            </div>
          </div>
          <div class="col-xl-3 col-md-4 col-sm-12">
            <div class="form-group">
              <label>{{ 'form_inputs.expense_label' | translate }}</label>
              <input class="form-control" id="expno" type="text" name="expno" [(ngModel)]="expRequestData.expno"
                placeholder="{{ 'form_inputs.expense_placeholder' | translate }}">
            </div>
          </div>
          <div class="col-xl-3 col-md-4 col-sm-12">
            <div class="form-group">
              <label>{{ 'form_inputs.third_party_code_label' | translate }}</label>
              <input class="form-control" id="tpcode" type="text" name="tpcode" [(ngModel)]="expRequestData.tpcode"
                placeholder="{{ 'form_inputs.third_party_code_placeholder' | translate }}">
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="required">{{ 'form_inputs.supplier_label' | translate }}</label>
              <div class="input-group" ngbDropdown>
                <input class="form-control" required="" type="text" autocomplete="off" readonly #supplier="ngModel"
                  [(ngModel)]="expRequestData.supplier" name="sup" Placeholder="Supplier Name" ngbDropdownToggle
                  [ngClass]="{ 'is-invalid': supplier.invalid && supplier.errors , 'is-valid': supplier.valid}">
                <i class="fa fa-chevron-down right-hover" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="main-dropdown ActionCell-List customer-dropdown"
                  aria-labelledby="dropdownBasic1">
                  <app-supplier-list (click)="SelectedSupplier()"></app-supplier-list>
                </ul>
              </div>
            </div>
            <div *ngIf="supplier.invalid && supplier.errors" class="invalid-feedback d-block">
              <div *ngIf="supplier.errors.required">
                {{ 'form_inputs.supplier_required' | translate }}
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group proj-group">
              <label for="">{{ 'form_inputs.project_job_ticket_label' | translate }}</label>
              <div class="input-group" ngbDropdown>
                <input class="form-control" name="projref" type="text" [(ngModel)]="expRequestData.projref"
                  Placeholder="Project Job" ngbDropdownToggle>
                <i class="fa fa-chevron-down right-hover" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="main-dropdown ActionCell-List customer-dropdown"
                  aria-labelledby="dropdownBasic1">
                  <app-projjoblistmodal (click)="selectedJob()"></app-projjoblistmodal>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label>{{ 'form_inputs.reimbursable_label' | translate }}</label>
              <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                <label class="d-block" for="">
                  <input class="radio_animated" [class.disabled]="fieldsetDisabled" id="" type="radio" [value]=true
                    name="reimbursable" [(ngModel)]="expRequestData.reimbursable" #reimbursable="ngModel">
                  {{ 'form_inputs.reimbursable_radio1' | translate }}
                </label>
                <label class="d-block" for="">
                  <input class="radio_animated" [class.disabled]="fieldsetDisabled" id="" [value]=false
                    [(ngModel)]="expRequestData.reimbursable" #reimbursable="ngModel" type="radio" name="reimbursable1">
                  {{ 'form_inputs.reimbursable_radio2' | translate }}
                </label>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label>{{ 'form_inputs.amount_label' | translate }}</label>
              <input class="form-control" id="amount" type="number"
                placeholder="{{ 'form_inputs.amount_placeholder' | translate }}" [(ngModel)]="expRequestData.amount"
                name="amount" #rebuiamount="ngModel"
                [ngClass]="{ 'is-invalid': rebuiamount.invalid && rebuiamount.errors , 'is-valid': rebuiamount.valid}"
                required>
              <div *ngIf="rebuiamount.invalid && rebuiamount.errors" class="invalid-feedback d-block">
                <div *ngIf="rebuiamount.errors.required">
                  {{ 'form_inputs.amount_required' | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="required">{{ 'form_inputs.currency_label' | translate }}</label>
              <select class="form-control" id="currency" required="" name="currency"
                [(ngModel)]="expRequestData.currency" #currency="ngModel" (change)="selectROE()"
                [ngClass]="{ 'is-invalid': currency.invalid && currency.errors , 'is-valid': currency.valid}">
                <option value="">{{ 'form_inputs.currency_placeholder' | translate }}</option>
                <option *ngFor="let item of currencyList" [value]="item.Value">
                  {{item.Text}}
                </option>
              </select>
              <div *ngIf="currency.invalid && currency.errors" class="invalid-feedback d-block">
                <div *ngIf="currency.errors.required">
                  {{ 'form_inputs.currency_required' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label>{{ 'form_inputs.xr_label' | translate }}</label>
              <div class="input-group">
                <input class="form-control text-right" id="roe" type="number"
                  placeholder="{{ 'form_inputs.xr_placeholder' | translate }}" [(ngModel)]="expRequestData.roe"
                  name="roe" #roe="ngModel" required="" #exchnagerate="ngModel"
                  [ngClass]="{ 'is-invalid': exchnagerate.invalid && exchnagerate.errors , 'is-valid': exchnagerate.valid}">
                <div class="input-group-append">
                  <span class="input-group-text btn-primary cursor-pointer" id="basic-addon2" (click)="getCurrentROE()"
                    [class.disabled]="fieldsetDisabled"><i class="fa fa-refresh"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="required">{{ 'form_inputs.payment_mode_label' | translate }}</label>
              <select class="form-control" id="payment_mode" [(ngModel)]="expRequestData.payment_mode"
                name="payment_mode" #payment_mode="ngModel">
                <option value="">{{ 'form_inputs.payment_mode_placeholder' | translate }}</option>
                <option *ngFor="let item of paymentModes" [value]="item.stringValue">
                  {{item.Text}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="expRequestData.payment_mode=='companycheque'">
            <div class="form-group">
              <label>{{ 'form_inputs.cheque#_label' | translate }}</label>
              <input class="form-control" id="cheque_no" type="number"
                placeholder="{{ 'form_inputs.cheque#_placeholder' | translate }}" [(ngModel)]="expRequestData.cheque_no"
                name="cheque_no" #cheque_no="ngModel">
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label>{{ 'form_inputs.approved_by_label' | translate }}</label>
              <input class="form-control" id="approvedby"
                placeholder="{{ 'form_inputs.approved_by_placeholder' | translate }}" type="text"
                [(ngModel)]="expRequestData.approvedby" name="approvedby" #approvedby="ngModel">
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="required">{{ 'form_inputs.status_label' | translate }} </label>
              <select class="form-control" id="status" [(ngModel)]="expRequestData.status" name="status"
                #status="ngModel">
                <option value="">{{ 'form_inputs.status_placeholder' | translate }} </option>
                <option *ngFor="let status of statusList" [value]="status.stringValue">
                  {{status.Text}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <label>{{ 'form_inputs.description_label' | translate }}</label>
              <textarea class="form-control" rows="2"
                placeholder="{{ 'form_inputs.description_placeholder' | translate }}" [readonly]="fieldsetDisabled"
                id="description" debounce="0" [(ngModel)]="expRequestData.description" name="description"
                #description="ngModel">
                                    </textarea>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <label>{{ 'form_inputs.notes_label' | translate }}</label>
              <textarea class="form-control" rows="5" placeholder="{{ 'form_inputs.notes_placeholder' | translate }}"
                [readonly]="fieldsetDisabled" id="notes" debounce="0" [(ngModel)]="expRequestData.notes" name="notes"
                #notes="ngModel">
                                    </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button class="btn btn-primary" type="button" (click)="addExpenseReq()">Save</button>
    </div>
  </div>
</resize-border>