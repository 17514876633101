import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { purchaseServices } from 'src/app/components/purchase/purchase.service';
import { CommonServices } from 'src/app/shared/service/common.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { environment } from 'src/environments/environment';
import { InventoryServices } from '../../../inventory.service';

@Component({
  selector: 'app-purchasehistory',
  templateUrl: './purchasehistory.component.html',
  styleUrls: ['./purchasehistory.component.scss']
})
export class PurchasehistoryComponent implements OnInit {
  @Input() fromParent;
  itemData: any = {}
  binList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  private langChangeSubscription: Subscription; 
  searchtext: any = '';
  itemDataList: any = []
  itemData1: any = {}
  constructor(
    private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient, 
    private activeModal: NgbActiveModal,
    private service: InventoryServices,
    private purchaseService: purchaseServices,
    private datepipe: DatePipe,
    private commonService: CommonServices,
    private loader: NgxUiLoaderService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));

  }

  ngOnInit(): void {
    environment.production ? '' : console.log('item data', this.fromParent);
    this.getPurchaseOrderList()
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    });     
  }

  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/purchase/inventoryreplenishment/purchasehistory/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 


  getPurchaseOrderList() {
    this.loader.start()
    this.purchaseService.getPurchaseRequestItemHistory(this.fromParent.id, this.searchtext, '', '').subscribe(res => {
      this.loader.stop();
      this.itemDataList = res;
      environment.production ? '' : console.log('getProdOrders response ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading getProdOrders ', err);
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
}
