<div class="custom-row">
  <div class="form-row">
    <div class="col-lg-8 col-md-6 form-group"> </div>
    <div class="col-lg-2 col-md-6 form-group pl-0">
      <label for="fromdate">From Date</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker463" placeholder="Choose a date" name="fromdate">
        <mat-datepicker-toggle matSuffix [for]="picker463"></mat-datepicker-toggle>
        <mat-datepicker #picker463></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-2 col-md-6 form-group pl-0">
      <label for="fromdate">To Date</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker464" placeholder="Choose a date" name="todate">
        <mat-datepicker-toggle matSuffix [for]="picker464"></mat-datepicker-toggle>
        <mat-datepicker #picker464></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="btn-group CsearchBtn" role="group">
    <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary">Search</button>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable priceaudittable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center"(click)="onSortClick($event,'pocost')">PO Cost <i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'landedcost')">Landed Cost<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'avgcost')">Avg Cost<i class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'store')">Store<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'wholesale')">Wholesale<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'online')">Online<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'service')">Service<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'flat')">Flat<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'doe')">Create Date<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'eby')">Create By<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'notes')">Notes<i class="Sorting fa fa-chevron-up"></i></th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of pricings">
            <td class="custom-td text-right">{{item.pocost}}</td>
            <td class="custom-td text-right">{{item.landedcost}}</td>
            <td class="custom-td text-right">{{item.avgcost}}</td>
            <td class="custom-td text-right">{{item.store}}</td>
            <td class="custom-td text-right">{{item.wholesale}}</td>
            <td class="custom-td text-right">{{item.online}}</td>
            <td class="custom-td text-right">{{item.service}}</td>
            <td class="custom-td text-right">{{item.flat}}</td>
            <td class="custom-td text-left">{{item.doe | date:'yyy-MM-dd HH:mm'}}</td>
            <td class="custom-td text-left">{{item.eby}}</td>
            <td class="custom-td text-left">{{item.notes}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Pricing</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation">
      <div class="form row">
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Landed Cost</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Avg Cost</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Store</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Wholesale</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Online</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Service</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-sm-12">
          <label for="" class="mb-1">Notes </label>
          <textarea name="" class="form-control" rows="2"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
      (click)="modal.dismiss('Cross click')">Close</button>
    <button type="button" class="btn btn-primary">Save</button>
  </div>
  </resize-border>
</ng-template>
