import { NgModule } from '@angular/core';

import { CustomerProjJoblistModalComponent } from './customerprojjoblistmodal.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
    declarations: [CustomerProjJoblistModalComponent],
    imports: [CommonModule, FormsModule, NgbModule, NgbTooltipModule, AutocompleteLibModule, NgSelectModule],
    exports: [CustomerProjJoblistModalComponent],
})
export class CustomerProjJoblistModule { }