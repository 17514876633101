<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title" *ngIf="showtitle">{{modalTitle}}</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="custom-row">
        <div class="form-row">
          <div class="col-lg-4 col-md-6 form-group">
            <label for="">Name</label>
            <input type="text" class="form-control" placeholder="Name">
          </div>
          <div class="col-lg-4 col-md-6 form-group">
            <label for="">Description</label>
            <input type="text" class="form-control" placeholder="Description">
          </div>
          <div class="col-lg-4 col-md-6 form-group">
            <label for="">Status</label>
            <select name="" class="form-control" id="">
              <option value="">Select status</option>
            </select>
          </div>
        </div>
        <div class="CsearchBtn">
          <button type="button" class="btn btn-primary">Search</button>
          <button type="button" class="btn btn-primary ml-2">Sync All</button>
        </div>
      </div>
      <div class="category-table">
        <div class="table-responsive2">
          <table class="custom-table">
            <thead>
              <tr class="custom-tr">
                <th class="custom-th text-center" style="width: 10%;">
                  <div class="checkBox">
                    <input class="mr-2" type="checkbox" id="flexCheckDefault">
                    <span>Action</span>
                  </div>
                </th>
                <th class="custom-th text-center">Name</th>
                <th class="custom-th text-center">Description</th>
                <th class="custom-th text-center">Is Synced</th>
                <th class="custom-th text-center">TP No</th>
              </tr>
            </thead>
            <tbody>
              <tr class="custom-tr" *ngFor="let item of listRes">
                <th class="custom-td text-center">
                  <div class="checkBox">
                    <input class="" type="checkbox" id="flexCheckDefault">
                    <i class="fa fa-refresh ml-2 cursor-pointer" container="body" ngbTooltip="QB Sync"></i>
                  </div>
                </th>
                <td class="custom-td">{{item.name}}</td>
                <td class="custom-td">{{item.description}}</td>
                <td class="custom-td">{{item.issynce}}</td>
                <td class="custom-td text-center">{{item.tpaccno}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button class="btn btn-primary" type="button">Save</button>
    </div>
  </div>
</resize-border>
<ngx-ui-loader></ngx-ui-loader>