import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { DashboardServices } from '../../dashboard/dashboard.service';
import { SalesServices } from '../../Sales/sales.service';

@Component({
  selector: 'app-addcard',
  templateUrl: './addcard.component.html',
  styleUrls: ['./addcard.component.scss']
})
export class AddcardComponent implements OnInit {
  fullScreen = false;
  userData: any;
  @ViewChild('iFrameID') iFrameID;
  appname = environment.APP_NAME;
  farmheight:any;
  @Input() title: string;
  @Input() url: string;

  constructor(private service: DashboardServices, private salesService: SalesServices,
    private toaster: NotificationService, private loader: NgxUiLoaderService, private router: Router, private modalService: NgbModal) {
    this.userData = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));

  }

  ngOnInit(): void {
    var iFrame = document.getElementById('iFrameID') as HTMLIFrameElement;
    if(iFrame && iFrame.contentWindow) {
      this.farmheight = iFrame.contentWindow.document.body.scrollHeight + "px";
      console.log(this.farmheight);
    }
    
  }

  ngOnChanges() {
    var iFrame = document.getElementById('iFrameID') as HTMLIFrameElement;
    if(iFrame && iFrame.contentWindow) {
      this.farmheight = iFrame.contentWindow.document.body.scrollHeight + "px";
      console.log(this.farmheight);
    }
    
  }
  dismissModal() {
    this.modalService.dismissAll();
  }

}
