<resize-border [dragHolder]="header" class="modal-resize">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Transfer Serials</h5>
    <button type="button" class="close" aria-label="Close" (click)="closemodal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" name="from" #f="ngForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">ILC</label>
          <input type="text" class="form-control" placeholder="ilc" name="ilc" [(ngModel)]="itemData.ilc" readonly>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">Description</label>
          <input type="text" class="form-control" placeholder="Description" name="description"
            [(ngModel)]="itemData.description" readonly>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">From bin location</label>
          <ng-select placeholder="Search Bin Location" required="" name="bintype" [(ngModel)]="itemData.frombinlocation"
            (ngModelChange)="selectOwnBinLocation($event)" [clearable]="itemData.frombinlocation"
            appendTo=".modal-resize" #frombinlocation="ngModel"
            [ngClass]="{ 'is-invalid': frombinlocation.invalid && frombinlocation.errors , 'is-valid': frombinlocation.valid}"
            required>
            <ng-option *ngFor="let item of ownItemProdBinList" [value]="item.Value">
              {{item.Text}}</ng-option>
          </ng-select>
          <div *ngIf="frombinlocation.invalid && frombinlocation.errors" class="invalid-feedback d-block">
            <div *ngIf="frombinlocation.errors.required">
              Please select bin
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">To Bin Location</label>
          <ng-select placeholder="Search Bin Location" required="" name="to bin location"
            [(ngModel)]="itemData.tobinlocation" [clearable]="itemData.tobinlocation" appendTo=".modal-resize" #tobinlocation="ngModel"
            [ngClass]="{ 'is-invalid': tobinlocation.invalid && tobinlocation.errors , 'is-valid': tobinlocation.valid}"
            required>
            <ng-option *ngFor="let item of allBinList" [value]="item.Value">
              {{item.Text}}</ng-option>
          </ng-select>
          <div *ngIf="tobinlocation.invalid && tobinlocation.errors" class="invalid-feedback d-block">
            <div *ngIf="tobinlocation.errors.required">
              Please select bin
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-6 col-sm-12 form-group">
          <label for="">Reason/Notes</label>
          <textarea class="form-control" rows="2" name="notes" [(ngModel)]="itemData.msg"></textarea>
        </div>
        <div class="col-lg-12 table-responsive2">
          <table class="custom-table">
            <thead>
              <tr class="custom-tr">
                <th class="custom-th text-center">
                  <input type="checkbox" name="selectall" [(ngModel)]="selectall" (change)="onAllChange()">
                </th>
                <th class="custom-th text-center">Serial No</th>
              </tr>
            </thead>
            <tbody>
              <tr class="custom-tr" *ngFor="let item of serialList;let i=index">
                <td class="custom-td text-center">
                  <input type="checkbox" name="serial-{{i}}" [(ngModel)]="item.checked" (change)="onChange()">
                </td>
                <td class="custom-td text-left">{{item.Value}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closemodal()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!f.valid" (click)="postTransferRequest()">Save</button>
  </div>
</resize-border>
<ngx-ui-loader></ngx-ui-loader>