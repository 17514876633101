import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { CountToModule } from 'angular-count-to';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CKEditorModule } from 'ng2-ckeditor';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { SharedModule } from 'src/app/shared/shared.module';
import { TrackingComponent } from './tracking.component';
import { TrackingRoutingModule } from './tracking-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [TrackingComponent],
  imports: [
    CommonModule,
    TrackingRoutingModule,
    CountToModule,
    SharedModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    Ng2SmartTableModule,
    
    FormsModule,
    NgbModule,
    NgxUiLoaderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    CKEditorModule,
    AutocompleteLibModule,
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyAuoQSNu8Ct-jXbHFdjGLyhYy5Y_mNm3Y4',
        libraries: ['places']
      }),
      HttpClientModule,
TranslateModule.forRoot({ 
 loader: { 
 provide: TranslateLoader, 
 useFactory: HttpLoaderFactory, 
 deps: [HttpClient] 
 } 
 }) 
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    NgbActiveModal,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class TrackingModule { }
export function HttpLoaderFactory(http: HttpClient) { 
  return new TranslateHttpLoader(http); 
 } 