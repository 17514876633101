import { Component, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Events } from 'src/app/shared/service/events.service';
import { CommonServices } from 'src/app/shared/service/common.service';
import { ChatSignalr } from '../customerservice/chatsignalr.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  locationsList: any = [];
  location: any;
  keyword = 'Text';

  // Hadapsar lat long
  lat: number = 18.4892145719125;
  lng: number = 73.9519786834717;
  connectionStatusEvent: any;
  updateLocationEvent: any;
  markers: any = [];
  markers1: any = [
    {
      latitude: 18.485307308290057,
      longitude: 73.95481109619142,
      user_code: 'A',
      userid: 1,
      heading: 0,
      online: 0,
      companyid: 0,
      createdate: '2023-07-01',
      createby: ''
    }
  ]
  private langChangeSubscription: Subscription; 
  constructor(private signalrHUB: ChatSignalr, private event: Events, private commonService: CommonServices,private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient, 
     ) {
    environment.production ? '' : console.log('#########in public tracking page#####');
    // this.subscripbeEvents();
    // this.getLocations();
  }

  ngOnInit(): void {
    // if (!this.signalrHUB.connectionIsEstablished) {
    //   this.signalrHUB.connect();
    // } else {
    //   this.signalrHUB.joinLocationReceiveGroup();
    // }
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
  }
private loadComponentTranslations(lang: string) { 
 const translationFilePath = `./assets/i18n/sales/customers/${lang}.json`; 
 this.http.get(translationFilePath).subscribe((translations:any) => { 
 this.translate.setTranslation(lang, translations, true); 
 this.translate.use(lang); 
 }); 
	} 
  getLocations() {
    this.commonService.getLocationIndex().subscribe(res => {
      this.locationsList = res;
      var isDefault = false;
      this.locationsList.forEach((loc, index) => {
        if (loc.Value4) {
          isDefault = true;
        }
        if (isDefault) {
          this.lat = loc.Value1 ? Number(loc.Value1) : 18.4892145719125;
          this.lng = loc.Value3 ? Number(loc.Value3) : 73.9519786834717;
          this.location = loc;
          return;
        }
        if (index == this.locationsList.length - 1 && !isDefault) {
          this.lat = loc.Value1 ? Number(loc.Value1) : 18.4892145719125;
          this.lng = loc.Value3 ? Number(loc.Value3) : 73.9519786834717;
          this.location = loc;
        }
      })
      console.log(this.locationsList);
    })
  }

  

  onFocused($event) {

  }

  selectEvent(event) {
    this.lat = event.Value1 ? Number(event.Value1) : 0.00;
    this.lng = event.Value3 ? Number(event.Value3) : 0.00;
  }

  ngOnDestroy() {
    environment.production ? '' : console.log('called ngOnDestroy');
    this.connectionStatusEvent.unsubscribe();
    this.updateLocationEvent.unsubscribe();
  }
  subscripbeEvents() {
    this.connectionStatusEvent = this.event.subscribe('event:connectionStatus', (event: any) => {
      environment.production ? '' : console.log('event connectionStatus' + JSON.stringify(event.data));
      this.signalrHUB.joinLocationReceiveGroup();
    });
    this.updateLocationEvent = this.event.subscribe('event:newLocation', (event: any) => {
      var data = event.data ? event.data.split(',') : '';
      var curLoc = {
        latitude: null,
        longitude: null,
        user_code: null,
        userid: null,
        heading: null,
        online: null,
        companyid: null,
        createby: null,
        createdate: null
      }
      if (data.length > 0) {
        curLoc.companyid = data[0].split(':')[1];
        curLoc.userid = data[1].split(':')[1];
        curLoc.latitude = data[2].split(':')[1];
        curLoc.longitude = data[3].split(':')[1];
        curLoc.heading = data[4].split(':')[1];
        curLoc.online = data[5].split(':')[1];
        curLoc.user_code = data[6].split(':')[1];
        curLoc.createdate = data[7].split(':')[1];
        curLoc.createby = data[8].split(':')[1];

        curLoc.user_code = curLoc.user_code ? curLoc.user_code.slice(0, 2) : '';
      }
      environment.production ? '' : console.log('event newLocation' + JSON.stringify(event.data));

      var isLocExist = false;
      if (this.markers.length > 0 && curLoc.userid != null) {
        this.markers.forEach(loc => {
          if (loc.userid == curLoc.userid) {
            loc.latitude = curLoc.latitude;
            loc.longitude = curLoc.longitude;
            isLocExist = true;
          }
        })
      }
      if (!isLocExist && curLoc.userid != null) {
        this.markers.push(curLoc);
      }
    });
  }
}
