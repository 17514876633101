<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h4 class="modal-title" *ngIf="showtitle">Device# {{deviceDetails.ilc}} Inventory</h4>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <div>
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <button type="button" class="close"></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="col-lg-2 form-group">
                    <ng-select placeholder="Select Department" [(ngModel)]="filter.deptid" name="deptid"
                        [clearable]="filter.deptid" (change)="selectDept()">
                        <ng-option *ngFor="let item of deptList" [value]="item.Value">
                            {{item.Text}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-lg-2 form-group">
                    <ng-select placeholder="Select Category" [(ngModel)]="filter.categoryid" name="categoryid"
                        [clearable]="filter.categoryid" (change)="selectCategory()">
                        <ng-option *ngFor="let item of categoryList" [value]="item.Value">
                            {{item.Text}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-lg-2 form-group">
                    <ng-select placeholder="Select Sub Category" [(ngModel)]="filter.subcategoryid" name="subcategoryid"
                        [clearable]="filter.subcategoryid" (change)="searchProduct()">
                        <ng-option *ngFor="let item of subCategoryList" [value]="item.Value">
                            {{item.Text}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-lg-6 form-group">
                    <div class="input-group flex-nowrap">
                        <div class="ng-autocomplete">
                            <ng-autocomplete #Product [data]="productList" [searchKeyword]="keyword2"
                                placeholder="Search product" (selected)='selectEvent($event)'
                                (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                [itemTemplate]="itemsTemplate" [notFoundTemplate]="notFoundTemplate"
                                [(ngModel)]="searchitem" [ngModelOptions]="{standalone: true}">
                            </ng-autocomplete>
                            <ng-template #itemsTemplate let-item>
                                <a [innerHTML]="item.ilc"></a>
                            </ng-template>
                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>

                    </div>
                </div>
            </div>
            <div class="category-table custom-datatable mb-3">
                <div class="table-responsive2">
                    <table class="custom-table">
                        <thead>
                            <tr class="custom-tr">
                                <th class="custom-th text-center" style="width: 5%;">ID#</th>
                                <th class="custom-th text-center" style="width: 20%;">ILC</th>
                                <th class="custom-th text-center" style="width: 25%;">Description</th>
                                <th class="custom-th text-center">CreateBy</th>
                                <th class="custom-th text-center">CreateDate</th>
                                <th class="custom-th text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="custom-tr" *ngFor="let item of itemList">
                                <td class="custom-td text-center">{{item.id}}</td>
                                <td class="custom-td text-left">{{item.ilc}}</td>
                                <td class="custom-td text-left">{{item.description}}</td>
                                <td class="custom-td text-left">{{item.createby}}</td>
                                <td class="custom-td text-left">{{item.createdate | date:userdata.displaydatetimeformat}}</td>
                                <td class="custom-td text-center">
                                    <div class="modalActionBtn">
                                        <i class="eb-delete" (click)="deleteDeviceMaterial(item)"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center" *ngIf="itemList.length==0">
                    <p style="color: gray;">No Items Found</p>
                </div>
            </div>
        </div>
    </div>
</resize-border>