import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { purchaseServices } from '../../purchase/purchase.service';
import { AddSupplierComponent } from '../../purchase/suppliers/add-supplier/add-supplier.component';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-suppliercontact',
  templateUrl: './suppliercontact.component.html',
  styleUrls: ['./suppliercontact.component.scss']
})
export class SuppliercontactComponent implements OnInit {
  fullScreen = false;
  @Input('editData') editData
  @Input('supplierid') supplierid
  @Input('action') action
  isEdit: boolean = false;
  userdata: any;
  contactList: any = []
  response: any;
  lastaction: string;
  fieldsetDisabled = false;
  contact: any = {
    notifyme: true,
    active: true,
    isprimary: false
  }
  appname = environment.APP_NAME;
  private langChangeSubscription: Subscription;
  constructor(private modalService: NgbModal,
    private loader: NgxUiLoaderService,
    private service: purchaseServices,
    private route: ActivatedRoute,
    private toastr: NotificationService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private http: HttpClient,
    private datepipe: DatePipe) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  ngOnInit(): void {
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => {
      this.loadComponentTranslations(lang);
    });
    console.log('edited data', this.editData);
    if (this.editData) {
      this.contact = this.editData;
    }
    if (this.action == 'view') {
      this.isEdit = true;
    }
  }

  private loadComponentTranslations(lang: string) {
		const translationFilePath = `./assets/i18n/purchase/suppliers/contacts/${lang}.json`;
		this.http.get(translationFilePath).subscribe((translations:any) => {
		  this.translate.setTranslation(lang, translations, true);
		  this.translate.use(lang);
		});
	}

  saveContact() {
    if ((this.contact.id)) {
      this.contact.updateby = this.userdata.email;
      this.contact.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.contact.companyid = this.userdata.companyid;
      this.contact.supplierid = this.supplierid
      this.contact.createby = this.userdata.email;
      this.contact.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.loader.start();
    this.service.postSupplierContact(this.contact).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.modalService.dismissAll();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading lead contact ', err);
    });
  }

  closeModal() {
    this.modalService.dismissAll();

  }


}
function input(arg0: string) {
  throw new Error('Function not implemented.');
}

