<resize-border  [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
<div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="productModalLabel">Pending Transactions
    </h5>
    <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body searchDiv">
                <div class="custom-row">
                    <div class="form-row">
                        <!-- <div class=" col-lg-4 col-md-6 form-group">
                            <label for="narr">Narration</label>
                            <input type="search" class="form-control" id="narr" name="narr" placeholder="Narration" [(ngModel)]="review.narr">
                        </div>
                        <div class="col-lg-3 col-md-6 form-group pl-0">
                            <label for="fromdate">From Date</label>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker134" placeholder="Choose a date"
                                    [(ngModel)]="review.fromdate" name="fromdate">
                                <mat-datepicker-toggle matSuffix [for]="picker134"></mat-datepicker-toggle>
                                <mat-datepicker #picker134></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3 col-md-6 form-group pl-0">
                            <label for="todate">To Date</label>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker135" placeholder="Choose a date"
                                    [(ngModel)]="review.todate" name="todate">
                                <mat-datepicker-toggle matSuffix [for]="picker135"></mat-datepicker-toggle>
                                <mat-datepicker #picker135></mat-datepicker>
                            </mat-form-field>
                        </div> -->
                        <div class="offset-8 col-lg-4 col-md-6 form-group pl-0">
                            <label for="type">Transaction type</label>
                            <ng-multiselect-dropdown placeholder="Select Type" [settings]="txtTypeSetting"
                                [data]="TransactionType" name="type" [(ngModel)]="selectedtxType"
                                (onSelect)="onItemSelectTxType($event)" (onSelectAll)="onSelectAllTxType($event)"
                                (onDeSelect)="onItemDeSelectTxType($event)"
                                (onDeSelectAll)="onDeSelectAllTxType($event)">
                            </ng-multiselect-dropdown>
                        </div>

                        <!-- <div class="col-lg-2 col-md-6 form-group pl-0">
                            <label for="status">Status</label>
                            <ng-multiselect-dropdown placeholder="Status" [settings]="statusSetting"
                                    [data]="statusList" name="status" [(ngModel)]="selectedstatus"
                                    (onSelect)="onItemSelectStatus($event)"
                                    (onSelectAll)="onSelectAllStatus($event)"
                                    (onDeSelect)="onItemDeSelectStatus($event)"
                                    (onDeSelectAll)="onDeSelectAllStatus($event)">
                                </ng-multiselect-dropdown>
                        </div> -->
                    </div>
                    <div class="CsearchBtn" role="group">
                        <button type="button" class="btn btn-primary ml-2" (click)="postTransaction()">Add</button>
                    </div>
                </div>
                <div class="category-table custom-datatable review-table">
                    <div class="table-responsive">
                        <ng2-smart-table #reviewTransTable [settings]="settings" [source]="reviewTransData"
                            (userRowSelect)="onUserRowSelect($event)"></ng2-smart-table>
                    </div>
                </div>
                <div class="total-count" *ngIf="totalRows">
                    <b class="mr-1">Total rows : </b> {{totalRows}}
                </div>
            </div>

        </div>
    </div>
</div>
</div>
<ngx-ui-loader></ngx-ui-loader>
</resize-border>