import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { CommonServices } from 'src/app/shared/service/common.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { MasterdataComponent } from '../masterdata/masterdata.component';


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  fullScreen = false;
  @Input('refno') refno;
  @Input('id') id;
  userdata: any;
  appname = environment.APP_NAME;
  docList: any = [];
  docTypeList: any = [];
  uploader: FileUploader;
  filename: any;
   
private langChangeSubscription: Subscription;
  response: string;
  dcument: any;
  title: any = ''
  docType: any = '';
  fieldsetDisabled: boolean;
  isDataSave = false;
  constructor(private activeModal: NgbActiveModal,
    private toastr: NotificationService,
    private datePipe: DatePipe,
    private translate: TranslateService, 
    private languageService: LanguageService, 
     private http: HttpClient, 
    private modalService: NgbModal,
    private router: Router,
    private loader: NgxUiLoaderService,
    private commonService: CommonServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.initializeFileUploader();
    this.getDocType();
  }

  getDocType() {
    var docType = '';
    if (this.docType) {
      docType = this.docType;
    }
    this.commonService.getDocType().subscribe(res => {
      this.docTypeList = res;
      this.docType = docType;
      environment.production ? '' : console.log('doc type list  ', this.docTypeList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading doc type list ', err);
    });
  }

  ngOnInit(): void {
    environment.production ? '' : console.log('refno', this.refno);
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => {
      this.loadComponentTranslations(lang);
    });
    this.getDocList(); 
  }

  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/sales/customers/documents/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
  } 
    

  getDocList() {
    this.commonService.getDocList(this.refno).subscribe(res => {
      this.docList = res;
      this.docList.forEach(item => {
        item.file_url = environment.DOC_URL + '/' + this.userdata.companyid + '/' + item.name;
      })
      environment.production ? '' : console.log('doc list  ', this.docList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading doc list ', err);
    });
  }

  selectedFileOnChanged(event: any) {
    environment.production ? '' : console.log(event.target.value);
    this.filename = event.target.value.split(/(\\|\/)/g).pop()
  }
  viewDocument(doc) {
    const url = environment.DOC_URL + '/' + this.userdata.companyid + '/' + doc.name;
    window.open(url, '_blank');
  }

  initializeFileUploader() {
    this.uploader = new FileUploader({
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + this.userdata.token,
      url: environment.API_URL + 'api/Documents/UploadFiles',
      method: 'POST',
      removeAfterUpload: true,
      queueLimit: 100
    })
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('companyid', this.userdata.companyid);
      form.append('createby', this.userdata.email);
      form.append('title', this.title);
      form.append('refno', this.refno);
      form.append('createdate', this.datePipe.transform(new Date(), this.userdata.postdatetimeformat));
      form.append('dtype', this.docType);
    };
    this.response = '';
    this.uploader.response.subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        res = JSON.parse(res)
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isDataSave = true;
        this.dcument = '';
        this.title = '';
        this.docType = '';
        this.dcument = ''
        this.initializeFileUploader();
        this.getDocList();
        // this.closeModal();
        this.response = res.data;
      }
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
  }
  uploadFile() {
    if (this.title == undefined || this.title == null || this.title == '') {
      this.toastr.Error('Please enter title')
      return;
    }
    if (this.filename == undefined || this.filename == null || this.filename == '') {
      this.toastr.Error('Please choose file')
      return;
    }
    this.loader.start()
    this.uploader.queue[0].onSuccess = function (response, status, headers) {
      this.loader.stop();
      if (status == 200) {
        let tempRes = JSON.parse(response);
      } else {
        this.initializeFileUploader();
        this.toastr.success('File uploaded');
      }
    };
    this.uploader.queue[0].upload();
  }
  closeModal(action) {
    this.activeModal.close(this.isDataSave ? 'save' : action);
  }

  //open Master Modal code
  openMasterModal() {
    // var modalRef = this.modalService.open(MasterdataComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
    var modalRef = null;
    modalRef = this.modalService.open(MasterdataComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.documenttype = 'documenttype';
    modalRef.result.then((result) => {
      if (result == 'save') {
        this.getDocType();
      }
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  deleteDocument(id) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.commonService.deleteDocument(id).subscribe(res => {
          environment.production ? '' : console.log('deleted condition data ', res);
          this.loader.stop();
          if (res != null) {
            this.toastr.Success('Document Deleted!');
            this.getDocList();
            this.isDataSave = true;
          } else {
            this.toastr.Error('Document not deleted');
          }
        }, err => {
          this.loader.stop();
          environment.production ? '' : console.log('Error : While loading delete condition ', err);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

}
