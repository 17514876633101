import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Events } from 'src/app/shared/service/events.service';
import { environment } from '../../../environments/environment';
import { modelComp, modelLogin } from '../../components/auth/login/modelLogin';

// import { ToastController } from '@angular/common';
// import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class InventoryServices {
  baseUrl = environment.API_URL;
  appname = environment.APP_NAME;
  isLoading: boolean;
  objLogin: modelLogin;
  objComp: modelComp;
  objCompId: any;
  userprofile: any;

  public productDtForEdit: ''
  public productDetails: '';
  inventoryItemAliases: any;

  constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.event.subscribe('event:logout', (event: any) => {
      environment.production ? '' : console.log('event status' + event.data.from);
      this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    });
  }
  //invenotry item grid action
  getServiceItems(pg, serilized, searchtxt, supplier, category, deptid, status, tpsync) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_ServiceItemIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&searchtxt=' + searchtxt + '&serilized=' + serilized + '&supplier=' + supplier + '&category=' + category + '&deptid=' + deptid + '&status=' + status + '&tpsync=' + tpsync);
  }
  getInventoryItems(pg, serilized, searchtxt, supplier, category, deptid, status, tpsync) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&searchtxt=' + searchtxt + '&serilized=' + serilized + '&category=' + category + '&deptid=' + deptid + '&status=' + status + '&tpsync=' + tpsync);
  }
  postTransferHoldAdjust(val) {
    const data = JSON.stringify(val);
    return this.http.post(this.baseUrl + 'api/InventoryItems/Post_ProdTran', data);
  }
  postProdMoveSerials(val) {
    const data = JSON.stringify(val);
    return this.http.post(this.baseUrl + 'api/InventoryItems/Post_ProdMoveSerials', data);
  }
  getTransferQty(prodid) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_ProdTransferQty?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
  }
  getAdjustQty(prodid) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_ProdAdjustQty?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
  }
  getHoldQty(prodid) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_ProdHoldQty?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
  }
  getSerialListUsingBin(binid, prod) {
    return this.http.get(this.baseUrl + 'api/Comman/GetBinSerials?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&binid=' + binid + '&prodid=' + prod);

  }
  postSerial(val) {
    const data = JSON.stringify(val);
    return this.http.post(this.baseUrl + 'api/InventoryItems/Post_ProdSerial', data);

  }
  //Inventory item edit tab
  getProductAlias(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryItemAliases?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getProductBins(id, forsale) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryItemBins?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&forsale=' + forsale);
  }

  getProductPictures(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryPictures?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getProductDocuments(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Gett_ProductDocuments?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getProductPricing(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryPricings?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getProductReviews(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryItemReviews?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getProductLoyalityPrograms(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryLoyalityPrograms?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getProductSerials(id, serialno, bincode, status) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryItemSerials?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&serialno=' + serialno + '&bincode=' + bincode + '&status=' + status);
  }

  getItemAnalyses(id, fromdate, todate, searchtxt) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Gett_ItemAnalysis?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&searchtxt=' + searchtxt);
  }

  getProductSubstitutes(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryItemSubstitutes?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getProductSupliers(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryItemSuppliers?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  deleteProductSupliers(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_ProdSupplier?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  postItemSupplier(val) {
    const data = JSON.stringify(val);
    return this.http.post(this.baseUrl + 'api/InventoryItems/Post_ProdSuppliers', data);

  }
  postItemAlias(val) {
    const data = JSON.stringify(val);
    return this.http.post(this.baseUrl + 'api/InventoryItems/Post_ProdAlias', data);
  }
  deleteItemAlias(id, prodid) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_ProductAlias?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
  }
  postItemReview(val) {
    const data = JSON.stringify(val);
    return this.http.post(this.baseUrl + 'api/InventoryItems/Post_ProductReview', data);
  }
  postSingleSubstitudeData(val) {
    const data = JSON.stringify(val);
    return this.http.post(this.baseUrl + 'api/InventoryItems/Post_SubstituteSingleProducts', data);

  }
  postPicture(val) {
    const data = JSON.stringify(val);
    return this.http.post(this.baseUrl + 'api/InventoryItems/Post_ProductPictures', data);
  }
  getProductCategory(dept, isservice) {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProductCategory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&dept=' + dept + '&isservice=' + isservice);
  }
  getProductSubCategory(categoryid) {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProductSubCatgeory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&categoryid=' + categoryid);

  }
  getSubtitudeCatlog(supplierid, dept, cat, subcat, searchtxt) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/GetSubstitueProductCatalog?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid + '&dept=' + dept + '&cat=' + cat + '&subcat=' + subcat + '&searchtxt=' + searchtxt);
  }

  getPostProductMainPicture(id, prodid, ismain) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Product_MainPicture?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid + '&mainpic=' + ismain);
  }

  getProductDeletePicture(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_ProductPicture?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getBinProducts(id, ilc, serial) {
    return this.http.get(this.baseUrl + 'api/BinLocations/Get_BinProducts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id + '&lic=' + ilc + '&serial=' + serial);
  }

  getProductType() {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProductType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getManifracturList() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetManufacturers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getProductSupplier() {

    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetSuppliers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getGLAccount() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetGLAccounts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getProductStatusList() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProductStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  getParaentProducts(catid, ptype) {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProducts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&catid=' + catid + '&ptype=' + ptype);
  }
  getInventoryItemsDetials(prodid) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Gett_InventoryByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + prodid);
  }
  getBinDetails(id) {
    return this.http.get(this.baseUrl + 'api/binlocations/Gett_BinLocationByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postProduct(val) {
    const data = JSON.stringify(val);
    return this.http.post(this.baseUrl + 'api/InventoryItems/Postt_InventoryItem', data);
  }
  //get department 
  getDepartmentList(service, desc, status, code) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_DepartmentIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&service=' + service + '&desc=' + desc + '&status=' + status + '&code=' + code);
  }
  getDepartmentById(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Gett_DepartmentByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postDept(val) {
    return this.http.post(this.baseUrl + 'api/InventoryItems/Postt_Department', val);
  }
  deleteDepartment(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_Department?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  //=====category=====
  getCategoryList(isservice, desc, status) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_CategoryIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&service=' + isservice + '&desc=' + desc + '&status=' + status);
  }
  getParentCat(isservice) {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProductDept?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&isservice=' + isservice);
  }
  getCategoiryById(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Gett_CategoryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postcat(val) {
    return this.http.post(this.baseUrl + 'api/InventoryItems/Postt_Category', val);
  }
  deleteCategory(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_Category?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  //=========================Bin Location===============================

  //Bin location list
  getBinLocations(pg, desc, code) {
    return this.http.get(this.baseUrl + 'api/binlocations/Get_BinIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&desc=' + desc + '&code=' + code);
  }
  //get bin product
  getBinProduct(id, ilc, serial) {
    return this.http.get(this.baseUrl + 'api/BinLocations/Get_BinProducts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id + '&ilc=' + ilc + '&serial=' + serial);
  }
  getBinLocationDetails(id) {
    return this.http.get(this.baseUrl + 'api/binlocations/Gett_BinLocationByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  //Bin location post
  postBinLocation(val) {
    return this.http.post(this.baseUrl + 'api/InventoryItems/Post_ProdBins', val);
  }
  postMasterBinLocation(val) {
    return this.http.post(this.baseUrl + 'api/BinLocations/Postt_BinLocation', val);
  }
  getBinLocationById(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_ProdBinByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  //Transfer Request
  getTransferRequestList(pg, reqno, status, fdate, edate) {
    return this.http.get(this.baseUrl + 'api/InventoryTransfers/Get_InventoryTransferIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&reqno=' + reqno + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate);
  }
  getRequestById(id) {
    return this.http.get(this.baseUrl + 'api/InventoryTransfers/Gett_InventoryTransferByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postTransferRequest(val) {
    return this.http.post(this.baseUrl + 'api/InventoryTransfers/Postt_InventoryTransfer', val);
  }
  getSearchProduct() {
    // GetProductInfo4Transfer
  }
  //assembly
  getAssembly(pg, name, assno, status, fromdate, todate) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_AssemblyIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&name=' + name + '&assno=' + assno + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate);
  }
  postAssembly(val) {
    return this.http.post(this.baseUrl + 'api/InventoryItems/Postt_Assembly', val);

  }
  getActiveInactive() {
    return this.http.get(this.baseUrl + 'api/ListHelper/ActiveInActiveFilter?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  getAssemblyDetailsById(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Gett_AssemblyByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  //inventory cycle count
  getCycleCount(pg, refno, status, reason, fromdate, todate) {
    return this.http.get(this.baseUrl + 'api/InventoryCount/Get_InventoryCountIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&refno=' + refno + '&status=' + status + '&reason=' + reason + '&fromdate=' + fromdate + '&todate=' + todate);
  }
  getCountDetails(id) {
    return this.http.get(this.baseUrl + 'api/InventoryCount/Gett_InventoryCountByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getInventoryCountBins(countid) {
    return this.http.get(this.baseUrl + 'api/InventoryCount/Gett_InventoryCountBins?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&countid=' + countid);
  }
  postCycleCount(val) {
    const data = JSON.stringify(val)
    return this.http.post(this.baseUrl + 'api/InventoryCount/Postt_InventoryCount', data);
  }
  postCycleCountBinLocation(val) {
    const data = JSON.stringify(val);
    return this.http.post(this.baseUrl + 'api/InventoryCount/Postt_InventoryCountBinLocation', data);
  }
  deleteCountBin(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryCount/Delete_InventoryCountBinLocation?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getBINLocations(searchtxt) {
    return this.http.get(this.baseUrl + 'api/InventoryCount/Get_BinLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt);
  }
  //invenotry Adj

  getInvenotryAdj(pg, adjno, status, adjtype, fromdate, todate) {
    return this.http.get(this.baseUrl + 'api/InventoryAdj/Get_InventoryAdjIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&adjno=' + adjno + '&status=' + status + '&adjtype=' + adjtype + '&fromdate=' + fromdate + '&todate=' + todate);
  }
  getAdjTypeList() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetInventoryAdjustType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getTranType() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProdTranType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getBinList(prodid) {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProdBinsData?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
  }
  getBinListforItemRequest() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetInternalDeptBins?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  getProductBin(prodid) {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProdBins?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
  }
  getActionType() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetTransactionTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getBinLocationList() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProductBins?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getProductItem(searchtxt) {
    return this.http.get(this.baseUrl + 'api/Comman/GetProductInfo4Adj?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt);
  }
  getInvenotryAdjById(id) {
    return this.http.get(this.baseUrl + 'api/InventoryAdj/Gett_InventoryAdjByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postInvenotryAdj(val) {
    return this.http.post(this.baseUrl + 'api/InventoryAdj/Postt_InventoryAdj', val);

  }
  //cycle count 
  getCycleCountItemList(countid, searchtxt, counted, variance) {
    return this.http.get(this.baseUrl + 'api/InventoryCount/Gett_InventoryCountItems?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&countid=' + countid + '&searchtxt=' + searchtxt + '&counted=' + counted + '&variance=' + variance);
  }
  getCycleCountManualEntriesByProdID(countid, binid, prodid, serial) {
    return this.http.get(this.baseUrl + 'api/InventoryCount/Get_CycleCountManualEntriesByProdID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&countid=' + countid + '&binid=' + binid + '&prodid=' + prodid + '&serial=' + serial);
  }
  getAdjustTypeList() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetAdjustType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getApproveCount(id, completiondate, uncounted_items_assumption) {
    return this.http.get(this.baseUrl + 'api/InventoryCount/Get_ApproveManualCount?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&completiondate=' + completiondate + '&uncounted_items_assumption=' + uncounted_items_assumption);
  }
  getCountInvAdj(id) {
    return this.http.get(this.baseUrl + 'api/InventoryCount/Get_CountInvAdj?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  startCycleCount(id) {
    return this.http.post(this.baseUrl + 'api/InventoryCount/Start_CycleCount?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&countid=' + id, '');
  }
  //pricing
  getBatchPricingList(pg, searchtxt, fromdate, todate, status) {
    return this.http.get(this.baseUrl + 'api/InventoryPricings/Get_PricingBatchIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&searchtxt=' + searchtxt + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate);
  }
  postPricingBatch(val) {
    return this.http.post(this.baseUrl + 'api/InventoryPricings/Post_PricingBatch', val);
  }
  getPricingBatchDetails(id) {
    return this.http.get(this.baseUrl + 'api/InventoryPricings/Get_PricingBatchByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postCycleCountlineItem(val) {
    return this.http.post(this.baseUrl + 'api/InventoryCount/Postt_InventoryCountItem', val);
  }
  getApplyChanges(id) {
    return this.http.get(this.baseUrl + 'api/InventoryPricings/Get_ApplyPriceChages?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id);
  }

  //bulkpricing
  getBulkPricingIndex(pg, searchtxt, fromdate, todate, status) {
    return this.http.get(this.baseUrl + 'api/InventoryPricings/Get_ProdBPCIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&searchtxt=' + searchtxt + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate);
  }
  postBulkPricingBatch(val) {
    return this.http.post(this.baseUrl + 'api/InventoryPricings/Post_ProdBPC', val);
  }
  getBulkPricingBatchDetails(id) {
    return this.http.get(this.baseUrl + 'api/InventoryPricings/Get_ProdBPCByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getApproveBulkPricingBatch(id) {
    return this.http.get(this.baseUrl + 'api/InventoryPricings/Approve_ProdBPCReq?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deleteBulkPriceChange(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryPricings/Delete_ProdBPCReq?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  //item request
  getItemRequestList(location, reqno, fromdate, todate) {
    return this.http.get(this.baseUrl + 'api/InventoryRequest/Get_InventoryRequestIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&location=' + location + '&reqno=' + reqno + '&fromdate=' + fromdate + '&todate=' + todate);
  }
  getItemRequestDetails(id) {
    return this.http.get(this.baseUrl + 'api/InventoryRequest/Gett_InventoryRequestByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postItemRequest(val) {
    return this.http.post(this.baseUrl + 'api/InventoryRequest/Postt_InventoryRequest', val);
  }
  postReceiveSerilizedItems(val) {
    return this.http.post(this.baseUrl + 'api/InventoryItems/Post_ReceiveSerilizedItems', JSON.stringify(val));
  }
  getapproveTransfer(id) {
    return this.http.get(this.baseUrl + 'api/InventoryTransfers/Get_ApproveTransfer?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id);

  }
  getDeleteInventoryTransferItem(id) {
    return this.http.get(this.baseUrl + 'api/InventoryTransfers/DeleteInventoryTransferEntry?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getDeleteInventoryTransfer(id) {
    return this.http.get(this.baseUrl + 'api/InventoryTransfers/DeleteInventoryTransfer?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getCompleteTransfer(id, dept_loc) {
    return this.http.get(this.baseUrl + 'api/InventoryTransfers/Get_CompleteTransfer?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id + '&dept_loc=' + dept_loc);
  }

  getInvProdBins(prodid, searchtxt) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_Prodbins?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid + '&searchtxt=' + searchtxt);
  }
  deleteAdjLineItem(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryAdj/Delete_InventoryAdjLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  deleteAssembly(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_Assembly?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deleteAssemblyMaterials(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_AssemblyMaterials?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deleteAssemblyLabor(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_AssemblyLabor?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deletebinLocation(id) {
    return this.http.delete(this.baseUrl + 'api/BinLocations/Delete_BinLocation?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deleteProdBin(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_InventoryBin?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  //get bin hold details
  getBinHoldDetails(binid, prodid) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_Binholddetails?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&binid=' + binid + '&prodid=' + prodid);

  }
  unHoldQty(customerid, eid) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_Undotransactions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&eid=' + eid);
  }
  undoProductTransaction(customerid, eid) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Undo_ProdTran?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&eid=' + eid);
  }
  postInventoryTax(val) {
    return this.http.post(this.baseUrl + 'api/InventoryItems/Postt_InventoryTax', JSON.stringify(val));
  }
  getInventoryTax(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryTax?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getInventoryTaxByID(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Gett_InventoryTaxByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deleteBatchLineItem(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryPricings/Delete_PricingBatchEntry?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deleteBatch(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryPricings/Delete_PricingBatch?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getLotTrackingData(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryItemLotTrackings?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
  }
  getLotDetailsById(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_ProdLotByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  postLotDetails(val) {
    return this.http.post(this.baseUrl + 'api/InventoryItems/Post_ProdLot', JSON.stringify(val));

  }
  deleteProduct(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/delete_inventoryitem?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deleteCycleCount(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryCount/Delete_InventoryCount?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deleteCycleCountItem(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryCount/Delete_InventoryCountItem?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deleteTemTax(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_InventoryItemTax?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getTiming(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryItemTimings?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getTimingByID(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Gett_InventoryItemTimingID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postTiming(val) {
    return this.http.post(this.baseUrl + 'api/InventoryItems/Postt_InventoryItemTiming', JSON.stringify(val));
  }
  deleteTiming(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_InventoryItemTiming?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getAddonList(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Get_InventoryItemAddons?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postAddon(val) {
    return this.http.post(this.baseUrl + 'api/InventoryItems/Postt_InventoryItemAddon', JSON.stringify(val));
  }
  deleteAddon(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_InventoryItemAddon?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getBinTemplateIndex(pg, searchtxt, active, fdate, edate) {
    return this.http.get(this.baseUrl + 'api/BinLocations/Get_BinTemplateIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&searchtxt=' + searchtxt + '&active=' + active + '&fdate=' + fdate + '&edate=' + edate);
  }
  getBinTemplateById(id) {
    return this.http.get(this.baseUrl + 'api/BinLocations/Gett_BinTemplateByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postBinTemplate(val) {
    return this.http.post(this.baseUrl + 'api/BinLocations/Postt_BinTemplate', JSON.stringify(val));
  }
  deleteBinTemplate(id) {
    return this.http.delete(this.baseUrl + 'api/BinLocations/DeleteBinTemplate?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deleteBinTemplateItem(id) {
    return this.http.delete(this.baseUrl + 'api/BinLocations/DeleteBinTemplateEntry?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postMapBinTemplate(val) {
    return this.http.post(this.baseUrl + 'api/BinLocations/Map_BinTemplate', JSON.stringify(val));
  }
  //Inventory Details
  getInventoryDetails(id) {
    return this.http.get(this.baseUrl + 'api/InventoryItems/Gett_InventoryDetails?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  deleteInventoryDetails(id) {
    return this.http.delete(this.baseUrl + 'api/InventoryItems/Delete_InventoryDetails?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  postInventoryDetails(val) {
    return this.http.post(this.baseUrl + 'api/InventoryItems/Postt_InventoryDetails', JSON.stringify(val));
  }
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
    }
    return throwError('Something went wrong. Please try again later.');
  }
}
