<resize-border [dragHolder]="header">
    <div #header class="modal-header">
        <h4 class="modal-title">UOM Ratio</h4>
        <div>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
            <button type="button" class="close"></button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <label for="">From</label>
                <ng-select name="from_uom" placeholder="Select from uom" disabled [(ngModel)]="master.from_uom">
                    <ng-option value="">Select from UOM</ng-option>
                    <ng-option *ngFor="let item of uomList" [value]="item.Value">{{item.Text}}</ng-option>
                </ng-select>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <label for="">To</label>
                <ng-select name="to_uom" placeholder="Select to uom" [(ngModel)]="master.to_uom">
                    <ng-option value="">Select to UOM</ng-option>
                    <ng-option *ngFor="let item of uomList" [value]="item.Value">{{item.Text}}</ng-option>
                </ng-select>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <label for="">Ratio</label>
                <input type="number" class="form-control" placeholder="Ratio" name="ratio" [(ngModel)]="master.ratio">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-center">
            <button type="button" class="btn btn-outline-secondary mr-2" (click)="closeModal()">Close</button>
            <button type="button" class="btn btn-primary" (click)="postProductUOM()">Save</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="category-table custom-datatable mb-3">
            <div class="table-responsive2">
                <table class="custom-table">
                    <thead>
                        <tr class="custom-tr">
                            <th class="custom-th text-center">From UOM</th>
                            <th class="custom-th text-center">To UOM</th>
                            <th class="custom-th text-center">Ratio</th>
                            <th class="custom-th text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="custom-tr" *ngFor="let item of prodUOMList">
                            <td class="custom-td text-center">{{item.from_uom}}</td>
                            <td class="custom-td">{{item.to_uom}}</td>
                            <td class="custom-td text-center">{{item.ratio}}</td>
                            <td class="custom-td text-center">
                                <div class="modalActionBtn">
                                    <i class="eb-edit mr-2" (click)="editMasterData(item)" ngbTooltip="Edit"></i>
                                    <i class="eb-delete" (click)="deleteProductUOM(item)" ngbTooltip="Delete"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</resize-border>