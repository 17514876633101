<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Supplier Payment
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <div class="custom-row">
            <div class="form-row">
              <div class="col-lg-6 col-md-6 form-group">
                <label for="">From Date</label>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker24" [(ngModel)]="paybill.fromdate" placeholder="Choose a date"
                    name="fromdate">
                  <mat-datepicker-toggle matSuffix [for]="picker24"></mat-datepicker-toggle>
                  <mat-datepicker #picker24></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-md-6 form-group">
                <label for="">To Date</label>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker25" [(ngModel)]="paybill.enddate" placeholder="Choose a date"
                    name="enddate">
                  <mat-datepicker-toggle matSuffix [for]="picker25"></mat-datepicker-toggle>
                  <mat-datepicker #picker25></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class=" btn-group CsearchBtn" role="group">
              <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary"
                (click)="getPayBillData()">Search</button>
            </div>
          </div>
          <div class="alert alert-danger p-1 mb-0"><small>PS: Filter supplier payables as per supplier name and
              between
              date
              range then provide payment method and enter amount to be paid and choose bills from list and
              Save it.</small> </div>
          <div class="vendorBlock p-4 mb-4 bg-light text-dark">
            <h3 class="text-center font-weight-bold text-dark">Supplier Name: {{payBillData.party_name}}</h3>
            <div class="balance">Balance: {{payBillData.availbalance}}</div>
            <div class="row">
              <div class="col-lg-3 col-md-6 form-group">
                <label for="">Date of Payment </label>
                <mat-form-field class="bg-white">
                  <input matInput [matDatepicker]="picker26" [(ngModel)]="payBillData.trandate"
                    placeholder="Choose a date" name="trandate">
                  <mat-datepicker-toggle matSuffix [for]="picker26"></mat-datepicker-toggle>
                  <mat-datepicker #picker26></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Payment Method </label>
                  <div class="input-group mb-3">
                    <select class="form-control" required="" name="mop" [(ngModel)]="payBillData.mop"
                      (change)="getPaymentAccounts()" #paymentmethod="ngModel"
                      [ngClass]="{ 'is-invalid': paymentmethod.invalid && paymentmethod.errors , 'is-valid': paymentmethod.valid}">
                      <option value="">Select Payment Method</option>
                      <option *ngFor="let item of paymentMethods" [value]="item.Value">{{item.Text}}
                      </option>
                    </select>
                    <div *ngIf="paymentmethod.invalid && paymentmethod.errors" class="invalid-feedback d-block">
                      <div *ngIf="paymentmethod.errors.required">Payment Method is
                        required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Cash/Bank</label>
                  <div class="input-group mb-3">
                    <select class="form-control" required="" name="bankid" [(ngModel)]="payBillData.bankid"
                      (change)="selectCheckBooks()" #cashbank="ngModel"
                      [ngClass]="{ 'is-invalid': cashbank.invalid && cashbank.errors , 'is-valid': cashbank.valid}">
                      <option value="">Select Cash/Bank</option>
                      <option *ngFor="let item of companyBanks" [value]="item.Value">{{item.Text}}
                      </option>
                    </select>
                    <div *ngIf="cashbank.invalid && cashbank.errors" class="invalid-feedback d-block">
                      <div *ngIf="cashbank.errors.required">Bank Name is
                        required</div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="isShowCheckBook">
                <div class="form-group">
                  <label>Check Book</label>
                  <select class="form-control" required="" name="checkbookid" [(ngModel)]="payBillData.checkbookid"
                    (change)="getCheckBookNos()" #checkbook="ngModel"
                    [ngClass]="{ 'is-invalid': checkbook.invalid && checkbook.errors , 'is-valid': checkbook.valid}">
                    <option value="">Select Checkbook</option>
                    <option *ngFor="let item of checkbookList" [value]="item.Value">{{item.Text}}
                    </option>
                  </select>
                  <div *ngIf="checkbook.invalid && checkbook.errors" class="invalid-feedback d-block">
                    <div *ngIf="checkbook.errors.required">Please select Check Book</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 form-group" *ngIf="isShowCheckBook">
                <label for="">Number eg. Cheque</label>
                <input type="text" class="form-control" readonly [(ngModel)]="payBillData.number"
                  placeholder="Number eg. Cheque">
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Payment Type</label>
                  <select class="form-control" required="" name="payment_type" [(ngModel)]="payBillData.payment_type">
                    <option [value]="n.stringValue" *ngFor="let n of paymentTypes">{{n.Text}}
                    </option>
                  </select>

                </div>
              </div>
              <div class="col-lg-3 col-md-6 form-group">
                <label for="">Memo </label>
                <input type="text" class="form-control" placeholder="Memo" name="memo" [(ngModel)]="payBillData.memo"
                  #memo="ngModel" [ngClass]="{ 'is-invalid': memo.invalid && memo.errors , 'is-valid': memo.valid}"
                  required>
                <div *ngIf="memo.invalid && memo.errors" class="invalid-feedback d-block">
                  <div *ngIf="memo.errors.required">Memo is
                    required</div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Notify Vendor</label>
                  <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                    <label class="d-block" for="showMultiple">
                      <input class="radio_animated" value='1' [(ngModel)]="payBillData.notify" id="showMultiple33"
                        type="radio" name="rdo-ani33">
                      Yes
                    </label>
                    <label class="d-block" for="showMultiple1">
                      <input class="radio_animated" value='0' [(ngModel)]="payBillData.notify" id="showMultiple44"
                        type="radio" name="rdo-ani44">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 form-group">
                <label for="">Amount Paid</label>
                <input type="text" class="form-control" placeholder="Amount" name="amt" (change)="updatePayAmt()"
                  #amount="ngModel"
                  [ngClass]="{ 'is-invalid': amount.invalid && amount.errors , 'is-valid': amount.valid}"
                  [(ngModel)]="payBillData.amt" required>
                <div *ngIf="amount.invalid && amount.errors" class="invalid-feedback d-block">
                  <div *ngIf="amount.errors.required">Amount is
                    required</div>
                </div>
              </div>
              <div class="form-group col-md-3 col-sm-12">
                <label for="">Change/Overpayment</label>
                <input type="text" name="change" id="" class="form-control" placeholder="Change/Overpayment"
                  [(ngModel)]="payBillData.change" readonly>
              </div>
              <div class="col-lg-3 col-md-6 form-group">
                <label for="">Paid Amount({{userdata.currency}})</label>
                <input type="number" readonly class="form-control" name="receivedbase_amt"
                  value="{{receivedBaseAmount}}">
              </div>
              <div class="col-lg-3 col-md-6 form-group">
                <label for="">FX Loss/Gain</label>
                <input type="number" class="form-control" name="fx_loss_gain" [(ngModel)]="fxLossGain">
              </div>
              <div class="form-group col-md-3 col-sm-12">
                <label for="">Change on Account? </label>
                <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <label class="d-block" for="showMultiple">
                    <input class="radio_animated" [(ngModel)]="payBillData.leave_debit" [value]=true id="showMultiple11"
                      type="radio" name="rdo-ani11">
                    Yes
                  </label>
                  <label class="d-block" for="showMultiple1">
                    <input class="radio_animated" [(ngModel)]="payBillData.leave_debit" [value]=false
                      id="showMultiple12" type="radio" name="rdo-ani12">
                    No
                  </label>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Currency </label>
                  <div class="input-group mb-3">
                    <select class="form-control" id="currency" required="" (change)="selectROE()" name="currency"
                      [(ngModel)]="payBillData.currency" #currencyname="ngModel"
                      [ngClass]="{ 'is-invalid': currencyname.invalid && currencyname.errors , 'is-valid': currencyname.valid}">
                      <option value="">Select currency</option>
                      <option *ngFor="let item of currencyList" [value]="item.Value">
                        {{item.Text}}
                      </option>
                    </select>
                    <div *ngIf="currencyname.invalid && currencyname.errors" class="invalid-feedback d-block">
                      <div *ngIf="currencyname.errors.required">Currency is
                        required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 form-group">
                <label for="">XR</label>
                <div class="input-group">
                  <input type="text" class="form-control  text-right" placeholder="0" name="ROE" (change)="calculateLineItemTotal()"
                    [(ngModel)]="payBillData.roe" #roe="ngModel" required="" #exchnagerate="ngModel"
                    [ngClass]="{ 'is-invalid': exchnagerate.invalid && exchnagerate.errors , 'is-valid': exchnagerate.valid}">
                  <div class="input-group-append">
                    <span class="input-group-text btn-primary cursor-pointer" id="basic-addon2"
                      (click)="getCurrentROE()"><i class="fa fa-refresh"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 form-group">
                <label for="">Notes </label>
                <textarea class="form-control" rows="5" name="notes" [(ngModel)]="payBillData.notes">
                    </textarea>
                <!-- <textarea type="text" class="form-control" cols="5" rows="5"></textarea> -->
              </div>
              <div class="col-lg-12 form-group">
                <p class="text-dark"><b>Due Payments:</b></p>
                <div class="custom-datatable">
                  <div class="table-responsive2">
                    <table class="input-table">
                      <tr class="custom-tr">
                        <th class="custom-th text-center">
                          <div class="d-flex align-items-center">
                            <input class="mb-0" type="checkbox" name="maincheckbox" (change)="onChange()"
                              [(ngModel)]="maincheckbox" value="" id="flexCheckDefault">
                            <label class="mb-0">Ref#</label>
                          </div>
                        </th>
                        <th class="custom-th text-center">Type</th>
                        <th class="custom-th text-center">Bill Ref#</th>
                        <th class="custom-th text-center">Date</th>
                        <th class="custom-th text-center">Status</th>
                        <th class="custom-th text-center">Curr.</th>
                        <th class="custom-th text-center">Total Due</th>
                        <th class="custom-th text-center">Total Due({{userdata.currency}})</th>
                        <th class="custom-th text-center">Paid Amt</th>
                        <th class="custom-th text-center">Base Total ({{userdata.currency}})</th>
                        <th class="custom-th text-center">Paid Amt({{userdata.currency}})</th>
                        <th class="custom-th text-center">Action</th>
                      </tr>
                      <tr class="custom-tr" *ngFor="let item of duepayments">
                        <td class="custom-td text-left">
                          <div class="d-flex align-items-center">
                            <input class="mb-0" type="checkbox" value="" id="flexCheckDefault" name="checkd"
                              [checked]="item.checked" [(ngModel)]="item.checked" (change)="onItemChange(item)">
                            <label class="mb-0">{{item.refno}}</label>
                          </div>
                        </td>
                        <td class="custom-td text-center">
                          {{item.type}}
                        </td>
                        <td class="custom-td text-left">
                          {{item.billref}}
                        </td>
                        <td class="custom-td text-center">
                          {{item.billdate | date: userdata.date_format}}
                        </td>
                        <td class="custom-td text-center">
                          {{item.status}}
                        </td>
                        <td class="custom-td text-center">{{item.currency}}
                        </td>
                        <td class="custom-td text-right">{{item.bal}}</td>
                        <td class="custom-td text-right">{{item.duebasetotal}}
                        </td>
                        <td class="custom-td text-right">
                          <input class="form-control text-right" type="number" placeholder="Amount" name="amount"
                            [(ngModel)]="item.pay" (focusout)="calculateLineItemTotal()">
                        </td>
                        <td class="custom-td text-right">{{item.basetotal}}
                        </td>
                        <td class="custom-td text-right">{{item.paidbasecurrtotal}}
                        </td>

                        <td class="custom-td text-center">
                          <div ngbDropdown class="d-inline-block ActionCell" container="body">
                            <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                            <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                              <li (click)="print(item)"><span ngbDropdownItem>Print</span></li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr class="custom-tr" *ngIf="isshowduepayments">
                        <td class="custom-td text-right" colspan="6"><b>Totals</b></td>
                        <td class="custom-td text-right mr-2"><b>{{ totalDue }}</b></td>
                        <td class="custom-td text-right mr-2"><b>{{ dueBaseTotal }}</b></td>
                        <td class="custom-td text-right pr-3" style="text-align: right !important;">
                          <b>{{paidTotal}}</b>
                        </td>
                        <td class="custom-td text-right mr-2"><b>{{ baseTotal }}</b></td>
                        <td class="custom-td text-right pr-3" style="text-align: right !important;">
                          <b>{{ paidCurrTotal}}</b>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 form-group">
                <label for="">Select files </label>
                <input type="file" class="form-control" placeholder=" ">
              </div>
              <div class="col-lg-12 text-center">
                <button type="button" class="btn btn-primary mb-1 mr-1" (click)="billPayment()">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-ui-loader></ngx-ui-loader>
</resize-border>