<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h4 class="modal-title">Print Tech Copy</h4>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <div>
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <button type="button" class="close" id="printButton">
                    <span aria-hidden="true" class="fa fa-print"></span>
                </button>
            </div>
        </div>
        <div class="modal-body" id="print-section">
            <div class="alert alert-warning noRecord" *ngIf="ticketsBatchPrintList.length == 0">No records found</div>
            <div class="TaskPrint" *ngFor="let ticket of ticketsBatchPrintList; let i = index;">
                <table class="topLogoBlock ticketTop">
                    <tr>
                        <td>
                            <img *ngIf="isShowCopanyLogo" [src]="companyLogo">
                            <div class="clearfix"></div>
                        </td>
                        <td align="middle">
                            <div class="Title">
                                <b>Service Ticket</b><br />
                                {{ticket.tno}}
                            </div>
                            <div class="clearfix"></div>
                        </td>
                    </tr>
                </table>
                <table class="table table-bordered">
                    <tr>
                        <td><b>Customer Name</b></td>
                        <td colspan="3">{{ticket.customer_name}}</td>
                        <td><b>Job in Progress?</b></td>
                        <td>
                            <input type="checkbox" value="" />
                        </td>
                        <td><b>Job Complete?</b></td>
                        <td>
                            <input type="checkbox" value="" />
                        </td>
                    </tr>
                    <tr>
                        <td><b>Site</b></td>
                        <td colspan="7">
                            {{ticket.site_name}}
                        </td>
                    </tr>
                    <tr>
                        <td><b>Subject</b></td>
                        <td colspan="7">{{ticket.subject}}</td>
                    </tr>
                    <tr>
                        <td><b>Scope of Work</b></td>
                        <td colspan="7" [innerHtml]="ticket.sow"></td>
                    </tr>
                    <tr>
                        <td><b>Devices</b></td>
                        <td colspan="7">
                            <span *ngFor="let device of ticket.ticketDevices">
                                {{device.name}} &nbsp;
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td><b>Service Notes</b></td>
                        <td colspan="7" [innerHtml]="ticket.servicenotes"></td>
                    </tr>
                    <tr>
                        <td><b>Client Notes</b></td>
                        <td colspan="7" [innerHtml]="ticket.customer_notice"></td>
                    </tr>
                    <tr>
                        <td><b>Tech List </b> </td>
                        <td colspan="3"><b>Call Out Type</b></td>
                        <td><b>Schedule Time </b></td>
                        <td><b>Finish Time </b></td>
                        <td><b>Travel Time</b></td>
                        <td><b>Total Time</b></td>
                    </tr>
                    <tr *ngFor="let item of ticket.ticketSchedules; let i = index;">
                        <td><b>{{item.tech}}</b></td>
                        <td>{{item.category}}</td>
                        <td colspan="3">{{item.description}}</td>
                        <td>{{item.schedule_date}} {{item.schedule_time}}</td>
                        <td>{{item.completeddate}} {{item.completedtime}}</td>
                        <td>{{item.enroutedate}} {{item.enroutetime}}</td>
                    </tr>
                    <tr>
                        <td colspan="8"><b>Desc of Work and Important
                                Findings:</b></td>
                    </tr>
                    <tr>
                        <td colspan="8" style="height:200px;">
                        </td>
                    </tr>
                    <tr>
                        <td colspan="8">
                            <b>Office use only:</b>
                            <table class="table" border="0">
                                <tr>
                                    <td class="NoBorder">
                                        Billable
                                        <input class="form-control" />
                                        Non-Paid
                                        <input class="form-control" />
                                    </td>
                                    <td class="NoBorder">
                                        Client Services
                                        <input class="form-control" />
                                        Over Time
                                        <input class="form-control" />
                                    </td>
                                    <td class="NoBorder">
                                        Estimating
                                        <input class="form-control" />
                                        P&R
                                        <input class="form-control" />
                                    </td>
                                    <td class="NoBorder">
                                        Holiday
                                        <input class="form-control" />
                                        Quality Control
                                        <input class="form-control" />
                                    </td>
                                    <td class="NoBorder">
                                        MA / Autos
                                        <input class="form-control" />
                                        Sales – New
                                        <input class="form-control" />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="NoBorder">
                                        MA / Shop
                                        <input class="form-control" />
                                        Sales – Brokerage
                                        <input class="form-control" />
                                    </td>
                                    <td class="NoBorder">
                                        MA / Tools
                                        <input class="form-control" />
                                        Sunday
                                        <input class="form-control" />
                                    </td>
                                    <td class="NoBorder">
                                        MA / Off
                                        <input class="form-control" />
                                        Surveys
                                        <input class="form-control" />
                                    </td>
                                    <td class="NoBorder">
                                        Marketing
                                        <input class="form-control" />
                                        Training
                                        <input class="form-control" />
                                    </td>
                                    <td class="NoBorder">
                                        Non-Billable
                                        <input class="form-control" />
                                        Warranty
                                        <input class="form-control" />
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <div class="clearfix"></div>
                <footer class="pagePrintFooter">
                    Powered by: Sanmol Software
                </footer>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Close</button>
        </div>
    </div>
</resize-border>