<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="productModalLabel">Sales Order Details
    </h5>
    <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
      <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
      <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
    </button>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form (keydown.enter)="getProjJobslist()">
      <div class="form-row">
        <div class="col-lg-8 col-md-12 position-relative">
          <i class="fa fa-search hover-icon"></i><input type="search" class="form-control" style="padding-left:20px"
            placeholder="Search Projects/Jobs/Tickets" name="searchtxt" [(ngModel)]="searchtxt" (input)="getProjJobslist()"
            autocomplete="off">
        </div>
        <div class="col-lg-4 col-md-12 position-relative">
          <div class="form-group">
            <div class="input-group">
              <select class="form-control" id="ordertype" name="ordertype" [(ngModel)]="ordertype">
                <option value="">Select Sales Order Type</option>
                <option *ngFor="let item of soOrderTypes" [value]="item.Value">
                  {{item.Text}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="category-table custom-datatable mt-2">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center">Ezb Ref#</th>
            <th class="custom-th text-center">Third Party Ref#</th>
            <th class="custom-th text-center">Type</th>
            <th class="custom-th text-center">Customer</th>
            <th class="custom-th text-center">Scope</th>
            <th class="custom-th text-center">Site</th>
            <th class="custom-th text-center">Tech</th>
            <th class="custom-th text-center">Service Notes</th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of jobList">
            <td class="custom-td position-relative text-left">
              <div class="hyperlink" (click)="selectJob(item)">{{item.proj}}</div>

            </td>
            <td class="custom-td position-relative text-left">
              <div class="hyperlink" (click)="selectJob(item)">{{item.tpcode}}</div>
            </td>
            <td class="custom-td position-relative text-left">
              <div class="hyperlink" (click)="selectJob(item)">
                {{item.type}}</div>
            </td>
            <td class="custom-td position-relative text-left">
              <div class="hyperlink" (click)="selectJob(item)">
                {{item.customername}}</div>
            </td>
            <td class="custom-td position-relative text-left">
              <div class="hyperlink" (click)="selectJob(item)" >{{item.name}}</div>
            </td>
            <td class="custom-td position-relative text-left">
              <div class="hyperlink" (click)="selectJob(item)" >{{item.siteother}}</div>
            </td>
            <td class="custom-td position-relative text-left">
              <div class="hyperlink" (click)="selectJob(item)" >{{item.assignto}}</div>
            </td>
            <td class="custom-td position-relative text-left">
              <div class="hyperlink" (click)="selectJob(item)" >{{item.servicenotes}}</div>
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
  </div>
</div>
</resize-border>