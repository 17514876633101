<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="exampleModalLabel">Site</h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal('close')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="needs-validation">
                <div class="form row">
                    <div class="form-group col-sm-6">
                        <label for="">Site Name</label>
                        <input type="text" class="form-control" name="name" [(ngModel)]="site.name">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Site Code</label>
                        <input type="text" class="form-control" name="shortname" [(ngModel)]="site.shortname">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Address 1</label>
                        <input type="text" class="form-control" name="address1" [(ngModel)]="site.address1">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Address 2</label>
                        <input type="text" class="form-control" name="address2" [(ngModel)]="site.address2">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Address 3</label>
                        <input type="text" class="form-control" name="address3" [(ngModel)]="site.address3">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Address 4</label>
                        <input type="text" class="form-control" name="address4" [(ngModel)]="site.address4">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Direction of Site</label>
                        <input type="text" class="form-control" name="directions" [(ngModel)]="site.directions">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Point of Contact</label>
                        <input type="text" class="form-control" name="poc" [(ngModel)]="site.poc">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Contact Email</label>
                        <input type="email" class="form-control" name="email" [(ngModel)]="site.email">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Contact Phone</label>
                        <input type="number" class="form-control" name="phone" [(ngModel)]="site.phone">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Notice for Outsiders</label>
                        <input type="text" class="form-control" name="notice4outsiders"
                            [(ngModel)]="site.notice4outsiders">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Latitude</label>
                        <input type="text" class="form-control" name="lat" [(ngModel)]="site.lat">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Longitude</label>
                        <input type="text" class="form-control" name="lng" [(ngModel)]="site.lng">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">South</label>
                        <input type="text" class="form-control" name="south" [(ngModel)]="site.south">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">West</label>
                        <input type="text" class="form-control" name="west" [(ngModel)]="site.west">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">East</label>
                        <input type="text" class="form-control" name="east" [(ngModel)]="site.east">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">North</label>
                        <input type="text" class="form-control" name="north" [(ngModel)]="site.north">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Is Primary</label>
                        <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                            <label class="d-block" for="isprimary">
                                <input class="radio_animated" [value]=true id="isprimary" type="radio" name="isprimary"
                                    [(ngModel)]="site.isprimary">
                                Yes
                            </label>
                            <label class="d-block" for="isprimary1">
                                <input class="radio_animated" [value]=false id="isprimary" type="radio" name="isprimary"
                                    [(ngModel)]="site.isprimary">
                                No
                            </label>
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Specmap</label>
                        <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                            <label class="d-block" for="specmap">
                                <input class="radio_animated" [value]=true id="specmap" type="radio" name="specmap"
                                    [(ngModel)]="site.specmap">
                                Yes
                            </label>
                            <label class="d-block" for="specmap1">
                                <input class="radio_animated" [value]=false id="specmap" type="radio" name="specmap"
                                    [(ngModel)]="site.specmap">
                                No
                            </label>
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Map Picture</label>
                        <input class="form-control" type="file" ng2FileSelect [uploader]="uploader"
                            (change)="selectedFileOnChanged($event)" accept="image/gif, image/jpeg, image/png" />
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal('close')">Close</button>
            <button type="button" class="btn btn-primary" (click)="addSites()">Save</button>
        </div>
    </div>
    <ngx-ui-loader></ngx-ui-loader>
</resize-border>