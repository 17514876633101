<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" (click)="dismissModal()">&times;</button>
    </div>
    <div class="modal-body">
      <iframe [src]="url | urlsafe" id="iFrameID" frameborder="0" style="width:100%; height:450px; overflow: scroll;"
        scrolling="yes"></iframe>
    </div>
  </div>
</resize-border>