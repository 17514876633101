export class projectCategoryDB {
    static projectDB = [
        {
            l:"PRJ1040",
            TPCode:"",
            Name:"west bay hospital",
            CustomerName:"Advance Fire & Plumbing",
            PointofContact:"IAN MORRISON ",
            HrsEstd:"0.00",
            HrsSpend:"0.00",
            StartDate:"23-05-2022",
            EndDate:"25-05-2022",
            CreateBy:"nkagade@gmail.com",
            Status: "<i class='fa fa-circle font-success f-12'></i>"
        },
       
    ]
}
