import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { AppServices } from 'src/app/shared/service/app.services';
import { LanguageService } from 'src/app/shared/service/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  homePagePlan: any = {
    discount: 0,
    discount_type: '%',
    duration_in_months: 3
  };
  signPlans: any;
  public isCollapsed = true;
  private langChangeSubscription: Subscription; 
  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private service: AppServices, 
    private loader: NgxUiLoaderService,
    private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient,  ) {
    window.localStorage.clear();
    this.getPlans();
  }

  //#region  Page Redirections
  gotoHome() {
    this.router.navigate(['/public/index'], { queryParams: { action: 'home' } });
  }
  gotoAbout() {
    this.router.navigate(['/public/index'], { queryParams: { action: 'aboutus' } });
  }

  gotoOverview() {
    this.router.navigate(['/public/index'], { queryParams: { action: 'overview' } });
  }

  gotoPricing() {
    this.router.navigate(['/public/index'], { queryParams: { action: 'pricing' } });
  }

  gotoContact() {
    this.router.navigate(['/public/index'], { queryParams: { action: 'contact' } });
  }
  //#endregion
  goToCreateAccount(item) {
    this.router.navigate(['/public/createaccount'], { queryParams: { id: btoa(item.id) }, replaceUrl: true })
  }
  gotoLogin() {
    window.localStorage.clear();
    window.location.replace('/auth/login');
  }
  getPlans() {
    this.loader.start();
    this.service.getSignupPlans().subscribe(res => {
      this.loader.stop();
      this.signPlans = res;
      this.signPlans.forEach(element => {
        if (element.home_page == 1) {
          this.homePagePlan = element;
        }
      })
      environment.production ? '' : console.log('Plan response', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('check err', err);
    });
  }
  form: FormGroup;

  submitted = false;
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  ngOnInit(): void {
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
  }
  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/sales/customers/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
}
