<div class="custom-row">
  <div class="form-row">
    <div class="col-lg-8 col-md-6 form-group"> </div>
    <div class="col-lg-2 col-md-6 form-group pl-0">
      <label for="fromdate">{{ 'Form_Inputs.FromDate_Label' | translate }}</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker463" placeholder="{{ 'Form_Inputs.FromDate_Placeholder' | translate }}" name="fromdate">
        <mat-datepicker-toggle matSuffix [for]="picker463"></mat-datepicker-toggle>
        <mat-datepicker #picker463></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-2 col-md-6 form-group pl-0">
      <label for="fromdate">{{ 'Form_Inputs.ToDate_Label' | translate }}</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker464" placeholder="{{ 'Form_Inputs.ToDate_Placeholder' | translate }}" name="todate">
        <mat-datepicker-toggle matSuffix [for]="picker464"></mat-datepicker-toggle>
        <mat-datepicker #picker464></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="btn-group CsearchBtn" role="group">
    <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary">{{ 'Form_Inputs.Search_Button' | translate }}</button>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable priceaudittable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center"(click)="onSortClick($event,'pocost')">{{ 'Table_Columns.POCost_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'landedcost')">{{ 'Table_Columns.LandedCost_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'avgcost')">{{ 'Table_Columns.AvgCost_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'store')">{{ 'Table_Columns.Store_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'wholesale')">{{ 'Table_Columns.Wholesale_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'online')">{{ 'Table_Columns.Online_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'service')">{{ 'Table_Columns.Service_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'flat')">{{ 'Table_Columns.Flat_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'doe')">{{ 'Table_Columns.CreateDate_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'eby')">{{ 'Table_Columns.CreateBy_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'notes')">{{ 'Table_Columns.Notes_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of pricings">
            <td class="custom-td text-right">{{item.pocost}}</td>
            <td class="custom-td text-right">{{item.landedcost}}</td>
            <td class="custom-td text-right">{{item.avgcost}}</td>
            <td class="custom-td text-right">{{item.store}}</td>
            <td class="custom-td text-right">{{item.wholesale}}</td>
            <td class="custom-td text-right">{{item.online}}</td>
            <td class="custom-td text-right">{{item.service}}</td>
            <td class="custom-td text-right">{{item.flat}}</td>
            <td class="custom-td text-left">{{item.doe | date:'yyy-MM-dd HH:mm'}}</td>
            <td class="custom-td text-left">{{item.eby}}</td>
            <td class="custom-td text-left">{{item.notes}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Pricing</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation">
      <div class="form row">
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Landed Cost</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Avg Cost</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Store</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Wholesale</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Online</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Service</label>
          <input class="form-control" type="text">
        </div>
        <div class="form-group col-sm-12">
          <label for="" class="mb-1">Notes </label>
          <textarea name="" class="form-control" rows="2"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
      (click)="modal.dismiss('Cross click')">Close</button>
    <button type="button" class="btn btn-primary">Save</button>
  </div>
  </resize-border>
</ng-template>
