import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import { InventoryServices } from '../../../inventory.service';
import { purchaseServices } from 'src/app/components/purchase/purchase.service';
import { reportServices } from 'src/app/components/reports/reports.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/shared/service/language.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-itemcompatibility',
  templateUrl: './itemcompatibility.component.html',
  styleUrls: ['./itemcompatibility.component.scss']
})
export class ItemCompatibilityComponent implements OnInit {
  @Input() fromParent;
  itemData: any = {}
  binList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  searchtxt: any = '';
  deviceList: any = []
  itemData1: any = {}
  private langChangeSubscription: Subscription; 
  constructor(private activeModal: NgbActiveModal,
    private service: reportServices,
    private datepipe: DatePipe,
    private commonService: CommonServices,
    private loader: NgxUiLoaderService,
    private translate: TranslateService, 
private languageService: LanguageService, 
private http: HttpClient, ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }
  ngOnInit(): void {
    environment.production ? '' : console.log('item data', this.fromParent);
    this.getItemCompatibility()
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
  }
  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/sales/customers/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
  getItemCompatibility() {
    this.loader.start()
    this.service.getItemCompatibility(this.fromParent.id, this.searchtxt).subscribe(res => {
      this.loader.stop();
      this.deviceList = res;
    }, err => {
      this.loader.stop();
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
}
