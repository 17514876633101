import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductListComponent } from './products.component';
import { AddProductComponent } from './add-product/add-product.component';



const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProductListComponent,
        data: {
          title: 'Products',
          breadcrumb: 'Product List'
        }
      },
      {
        path: 'add-product',
        component: AddProductComponent,
        data: {
          title: 'Product #',
          breadcrumb: 'Add Product'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
