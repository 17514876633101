import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrintElementService } from 'ngx-print-element';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-genericintegration',
  templateUrl: './genericintegration.component.html',
  styleUrls: ['./genericintegration.component.scss']
})
export class GenericintegrationComponent implements OnInit {
  fullScreen = false;
  @Input('name') name;
  @Input('url') url;
  ticketsBatchPrintList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  printURL: any;
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: CommonServices, public print: NgxPrintElementService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }
  ngOnInit(): void {
    // this.loader.start();
    setTimeout(() => {
      // this.loader.stop();
    }, 2000)
    this.printURL = this.url;
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}
