<div class="row">
  <div class="col-lg-9 col-md-9 col-12 form-group"></div>
  <div class="col-lg-3 col-md-3 col-12 form-group">
    <input type="search" class="form-control placehder" name="searchText" placeholder="&#xF002; {{ 'Form_Inputs.Search_Placeholder' | translate }}"
      [(ngModel)]="searchText">
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center"> <a class="add-table-button cursor-pointer" data-toggle="modal"
                data-original-title="Add" container="body" ngbTooltip="{{ 'Table_Columns.Supplier_ToolTip' | translate }}" [class.disabled]="fieldsetDisabled"
                (click)="open(content,null)"><i class="fa fa-plus"></i>
              </a></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'name')">
              {{ 'Table_Columns.Name_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'primarycontact')">
              {{ 'Table_Columns.Contact_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'islocal')">
              {{ 'Table_Columns.IsLocal_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'itemcode')">
              {{ 'Table_Columns.ItemCode_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'cost')">
              {{ 'Table_Columns.Cost_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'email')">
              {{ 'Table_Columns.Email_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'isprimary')">
              {{ 'Table_Columns.IsPrimary_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'createby')">
              {{ 'Table_Columns.CreateBy_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'createdate')">
              {{ 'Table_Columns.CreateDate_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'updateby')">
              {{ 'Table_Columns.UpdateBy_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'updatedate')">
              {{ 'Table_Columns.UpdateDate_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of tabsupplierList | supplierfilter: searchText">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="goToView(content,item)">{{ 'Table_Actions.View_Action' | translate }}</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled"
                      (click)="goToEdit(content,item)">{{ 'Table_Actions.Edit_Action' | translate }}</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled"
                      (click)="deleteSupplier(item)">{{ 'Table_Actions.Delete_Action' | translate }}</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.name}}</td>
            <td class="custom-td text-left">{{item.primarycontact}}</td>
            <td class="custom-td text-center">{{item.islocal}}</td>
            <td class="custom-td text-left">{{item.itemcode}}</td>
            <td class="custom-td text-right">{{item.cost | number:'1.2-2'}}</td>
            <td class="custom-td text-left">{{item.email}}</td>
            <td class="custom-td text-center">{{item.isprimary}}</td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-right text-nowrap">{{item.createdate |
              date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-right text-nowrap">{{item.updatedate |
              date:userdata.displaydatetimeformat}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">{{ 'Modal_Title' | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" name="from" #f="ngForm">
      <div class="form row">
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="" class="mb-1">{{ 'Modal_Inputs.Supplier_Label' | translate }}</label>
          <select class="form-control" name="to bin location" [(ngModel)]="supplierData.supplierid"
            #supplierid="ngModel"
            [ngClass]="{ 'is-invalid': supplierid.invalid && supplierid.errors , 'is-valid': supplierid.valid}"
            required>
            <option value="">{{ 'Modal_Inputs.Supplier_Placeholder' | translate }}</option>
            <option *ngFor="let item of supplierList" [value]="item.Value">
              {{item.Text}}</option>
          </select>
          <div *ngIf="supplierid.invalid && supplierid.errors" class="invalid-feedback d-block">
            <div *ngIf="supplierid.errors.required">
              {{ 'Modal_Inputs.Supplier_Required' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="" class="mb-1">{{ 'Modal_Inputs.ItemCode_Label' | translate }}</label>
          <input class="form-control" type="text" name="item ode" [(ngModel)]="supplierData.itemcode"
            #itemcode="ngModel"
            [ngClass]="{ 'is-invalid': itemcode.invalid && itemcode.errors , 'is-valid': itemcode.valid}" required>
          <div *ngIf="itemcode.invalid && itemcode.errors" class="invalid-feedback d-block">
            <div *ngIf="itemcode.errors.required">
              {{ 'Modal_Inputs.ItemCode_Required' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="" class="mb-1">{{ 'Modal_Inputs.Cost_Label' | translate }}</label>
          <input class="form-control" type="text" name="cost" [(ngModel)]="supplierData.cost" #cost="ngModel"
            [ngClass]="{ 'is-invalid': cost.invalid && cost.errors , 'is-valid': cost.valid}" required>
          <div *ngIf="cost.invalid && cost.errors" class="invalid-feedback d-block">
            <div *ngIf="cost.errors.required">
              {{ 'Modal_Inputs.Cost_Required' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="Active1" class="">{{ 'Modal_Inputs.Active_Label' | translate }}</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="Active">
              <input class="radio_animated" [value]="true" [(ngModel)]="supplierData.active" id="active" type="radio"
                name="active">
                {{ 'Modal_Inputs.Active_Radio1' | translate }}
            </label>
            <label class="d-block" for="Active2">
              <input class="radio_animated" [value]="false" [(ngModel)]="supplierData.active" id="active1" type="radio"
                name="active">
                {{ 'Modal_Inputs.Active_Radio2' | translate }}
            </label>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="" class="">{{ 'Modal_Inputs.IsPrimary_Label' | translate }}</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="isPrimary">
              <input class=" radio_animated" [value]="true" [(ngModel)]="supplierData.isprimary" id="isprimary"
                type="radio" name="isprimary">
                {{ 'Modal_Inputs.IsPrimary_Radio1' | translate }}
            </label>
            <label class="d-block" for="isPrimary1">
              <input class="radio_animated" [value]="false" [(ngModel)]="supplierData.isprimary" id="isprimary1"
                type="radio" name="isprimary">
                {{ 'Modal_Inputs.IsPrimary_Radio2' | translate }}
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">{{ 'Modal_Buttons.Close_Button' | translate }}</button>
    <button type="button" class="btn btn-primary" [disabled]="!f.valid || isView" (click)="postSupplier()">{{ 'Modal_Buttons.Save_Button' | translate }}</button>
  </div>
</resize-border>
</ng-template>
