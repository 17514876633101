import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import { SalesServices } from '../../Sales/sales.service';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { Subscription, interval } from 'rxjs';
@Component({
  selector: 'app-partysmodal',
  templateUrl: './partysmodal.component.html',
  styleUrls: ['./partysmodal.component.scss']
})
export class PartysComponent implements OnInit {
  @Input('type') type;
  pos: any = {};
  userdata: any;
  appname = environment.APP_NAME;
  @ViewChild('SearchElement') SearchElement:ElementRef;
  setCustomerValue: any;
  keyword = 'name';
  validatecustomer: any;
  customerList: any = []
  customer: any = {
    name: '',
    email: '',
    primaryphone: '',
    status: 'active'
  }
  selectedParty: any;
  typeList: any = []
  searchtxt: any = '';
  subscription: Subscription;
  constructor(private activeModal: NgbActiveModal,
    private commonServices: CommonServices,
    private loader: NgxUiLoaderService,
    private datePipe: DatePipe,
    private toastr: NotificationService,
    private service: SalesServices,
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  getPartyList() {
    if (this.searchtxt == undefined || this.searchtxt == null || this.searchtxt == '') {
      return;
    }
    if (this.type == undefined || this.type == null || this.type == '') {
      this.toastr.Error('Please select party type');
      return;
    }
    if (this.searchtxt.length < 3) {
      return;
    }
    this.loader.start();
    this.commonServices.getPartyList(this.type, this.searchtxt).subscribe(res => {
      this.loader.stop();
      this.customerList = res;
      environment.production ? '' : console.log(' customer list data ', this.customerList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
  selectCustomer(item) {
    this.selectedParty = item;
    this.searchtxt = '';
    this.customerList = [];
  }

  ngAfterViewInit() {
    const source = interval(500);
    this.subscription = source.subscribe(val =>  {
      if(!this.SearchElement.nativeElement.focus()){
        this.SearchElement.nativeElement.focus()
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    console.log('destroyed')
  }


  closeModal() {
    this.activeModal.close(this.selectedParty);
  }
  ngOnInit(): void {
    // this.customer.name = this.type ? this.type : '';
    this.getPartyList();
  }

}
