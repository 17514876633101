<div class="padding-div">
    <form (keydown.enter)="getProjJobslist()" autocomplete="off">
        <div class="form-row">
            <div class="col-lg-12 col-md-12 form-group d-flex ">
                <ng-select placeholder="Select Site" name="siteid" [clearable]="false" [(ngModel)]="customer.siteid"
                    (change)="selectSite()">
                    <ng-option value="">Select Site</ng-option>
                    <ng-option *ngFor="let item of siteList" [value]="item.Value">{{item.Text}}</ng-option>
                </ng-select>
                <div class="position-relative">
                    <ng-autocomplete #Customer [data]="customerList" [searchKeyword]="keyword2"
                        placeholder="Search customer" (selected)='selectCustomer($event)'
                        (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemsTemplate" [notFoundTemplate]="notFoundTemplate" [(ngModel)]="cust"
                        [ngModelOptions]="{standalone: true}">
                    </ng-autocomplete>
                    <ng-template #itemsTemplate let-item>
                        <a [innerHTML]="item.name"></a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
                <div class="position-relative"><i class="fa fa-search hover-icon"></i><input id="searchInput"
                        type="search" class="form-control" style="padding-left:20px" placeholder="Search"
                        name="searchtxt" [(ngModel)]="customer.searchtxt" (input)="getProjJobslist()" autocomplete="off"
                        autocorrect="off" spellcheck="false">
                </div>
            </div>
        </div>
    </form>
    <div class="category-table custom-datatable mt-1">
        <div class="table-responsive">
            <table class="custom-table">
                <tr class="custom-tr">
                    <th class="custom-th text-center">Ezb Ref#</th>
                    <th class="custom-th text-center">Third Party Ref#</th>
                    <th class="custom-th text-center" style="width: 200px;">Project Name</th>
                    <th class="custom-th text-center">Type</th>
                    <th class="custom-th text-center">Customer</th>
                    <th class="custom-th text-center">Site</th>
                    <th class="custom-th text-center">Tech</th>
                    <th class="custom-th text-center">Service Notes</th>
                </tr>
                <tr class="custom-tr" *ngFor="let item of jobList">
                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem [ngbTooltip]="Information"
                            placement="bottom top" tooltipClass="modal-dropdown-tooltip" [openDelay]="200">{{item.proj}}
                        </div>
                    </td>
                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem [ngbTooltip]="Information"
                            placement="bottom top" tooltipClass="modal-dropdown-tooltip" [openDelay]="200">
                            {{item.tpcode}}</div>
                        <ng-template #Information>
                            <div class="d-flex flex-column">
                                <div><b>Ezb Ref# :</b> {{item.proj}}
                                </div>
                                <div><b>Third Party Ref# :</b> {{item.tpcode}}
                                </div>
                                <div><b>Type :</b> {{item.type}}</div>
                                <div><b>Customer :</b> {{item.customername}}</div>
                                <div><b>Scope :</b> {{item.name}}</div>
                                <div><b>Site :</b> {{item.siteother}}</div>
                                <div><b>Tech :</b> {{item.assignto}}</div>
                                <div><b>Service Notes :</b> {{item.servicenotes}}</div>
                            </div>
                        </ng-template>
                    </td>
                    <td class="custom-td position-relative text-left" style="width: 200px;">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem [ngbTooltip]="Information"
                            placement="bottom top" tooltipClass="modal-dropdown-tooltip" [openDelay]="200">{{item.name}}
                        </div>
                    </td>
                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" (mouseenter)="tooltip3.toggle()"
                            ngbDropdownItem>{{item.type}}</div>
                    </td>

                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" (mouseenter)="tooltip3.toggle()"
                            ngbDropdownItem>{{item.customername}}</div>
                    </td>

                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem>{{item.siteother}}</div>
                    </td>
                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem>{{item.assignto}}</div>
                    </td>
                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem>{{item.servicenotes}}</div>
                    </td>
                </tr>
            </table>
            <div class="no-results" *ngIf="jobList.length == 0">Your search result yielded no results</div>
        </div>

    </div>
</div>