import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Events } from './events.service';

// import { ToastController } from '@angular/common';
// import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
    providedIn: 'root'
})
export class ExportDataServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    isLoading: boolean;
    objCompId: any;
    userdata: any;
    exportURL: any;
    @Input('params') params;
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }

    getRegions() {
        return this.http.get(this.baseUrl + 'api/Public/GetRegions');
    }
    export() {
        if (this.params.typename == 'ticketsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'customer=' + this.params.customer + '&proj=' + this.params.projid + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'invoiceagingsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&currency=' + this.params.currency + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'customerwalletbalance') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtext=' + this.params.searchtext + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'customerwallethistory') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtext=' + this.params.searchtext + '&trantype=' + this.params.trantype + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'chequesreceived') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&status=' + this.params.status + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'chequesissued') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&status=' + this.params.status + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'payrollsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + this.params.id + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'countdetails') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + this.params.id + '&searchtxt=' + this.params.searchtxt + '&counted=' + this.params.counted + '&variance=' + this.params.variance + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'assesments') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&assid=' + this.params.assid + '&employeeid=' + this.params.employeeid + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'pensioncontributions' || this.params.typename == 'insurancecontributions') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + this.params.id + '&id=' + this.params.id + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'pensioninsurcontributions' || this.params.typename == 'epfcontributions' || this.params.typename == 'esiccontributions' || this.params.typename == 'payrollsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + this.params.id + '&empid=' + this.params.empid + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'customerpayments') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtext=' + this.params.searchtext + '&currency=' + this.params.currency + '&mop=' + this.params.mop + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'paymentspaid') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtext=' + this.params.searchtxt + '&currency=' + this.params.currency + '&mop=' + this.params.mop + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'receivebol') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&sid=' + this.params.sid + '&bol=' + this.params.bol + '&pon=' + this.params.pon + '&ilc=' + this.params.ilc + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'rfqreport') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ids=' + this.params.ids + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'bslc') {
            this.exportURL = environment.API_URL + 'reports/bslc?companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&asondate=' + this.params.asondate + '&period=' + this.params.period + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'bsyc') {
            this.exportURL = environment.API_URL + 'reports/bsyc?companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&asondate=' + this.params.asondate + '&period=' + this.params.period + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'deletedtransactions') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&type=' + this.params.type + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'audittrails') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&module=' + this.params.module + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'ledgers') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&coa=' + this.params.coa + '&refno=' + this.params.refno + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'JournalIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'refno=' + this.params.refno + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'PayrollPaymentIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'search_text=' + this.params.searchtext + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&payroll_id=' + this.params.payroll_id + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'internaltransfers') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'lotexpiry') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'prodcostingreview') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&deptid=' + this.params.deptid + '&categoryid=' + this.params.catid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'assemblysalesbymonth') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'assname=' + this.params.assname + '&yr=' + this.params.yr + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'bolcostingexport') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'bol=' + this.params.bol + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'salesbyyear') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&deptid=' + this.params.deptid + '&category=' + this.params.category + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'salesbymonth') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&deptid=' + this.params.deptid + '&category=' + this.params.category + '&year=' + this.params.year + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'materialdj') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'month=' + this.params.month + '&year=' + this.params.year + '&trantype=' + this.params.trantype + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'sopickstatus') {
            //not used
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'orderno=' + this.params.orderno + '&orderref=' + this.params.orderref + '&ilc=' + this.params.ilc + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'invvaluebyinvoice') {
            //not used
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'asondate=' + this.params.asondate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'transactiontpsync') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'month=' + this.params.month + '&year=' + this.params.year + '&txttype=' + this.params.txttype + '&tpsync=' + this.params.tpsync + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'womaterialspending') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'month=' + this.params.month + '&year=' + this.params.year + '&vpsync=' + this.params.vpsync + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'ticketsinvcost') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'month=' + this.params.month + '&year=' + this.params.year + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'materialcosting') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'month=' + this.params.month + '&year=' + this.params.year + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'inventorycountsheet') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + this.params.id + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'employeejobapps') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&position=' + this.params.position + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeeshistory') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&gender=' + this.params.gender + '&email=' + this.params.email + '&paytype=' + this.params.paytype + '&etype=' + this.params.etype + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeespaysummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeeanniversaries') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&gender=' + this.params.gender + '&email=' + this.params.email + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeebirthdays') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&gender=' + this.params.gender + '&email=' + this.params.email + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeeworkpermits') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeeaccesscards') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.ename + '&code=' + this.params.code + '&fromdate=' + this.params.fdate + '&todate=' + this.params.edate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeeconcessionpasses') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeeinsurances') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeepensions') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeeappraisals') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeedisciplinarylog') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeehurricanepocs') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'techhours') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'tech=' + this.params.tech + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&status=' + this.params.status + '&department=' + this.params.department + '&designation=' + this.params.designation + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'techhourdetail') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'tech=' + this.params.tech + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&status=' + this.params.status + '&department=' + this.params.department + '&designation=' + this.params.designation + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'techticketlogs') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'tech=' + this.params.tech + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'catalogsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&deptid=' + this.params.deptid + '&categoryid=' + this.params.catid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'stockcostreport') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&category=' + this.params.category + '&type=' + this.params.type + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'inventorybybin') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&category=' + this.params.cat + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'serialsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&deptid=' + this.params.deptid + '&categoryid=' + this.params.catid + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'bolsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'bol=' + this.params.bol + '&po=' + this.params.po + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'creditusesummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'customerid=' + this.params.customerid + '&seldate=' + this.params.seldate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'invoiceshistory') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'invno=' + this.params.qteno + '&customer=' + this.params.customer + '&status=' + this.params.status + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'quoteshistory') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'qteno=' + this.params.qteno + '&customer=' + this.params.customer + '&status=' + this.params.status + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'salesbycategory') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'customer=' + this.params.customer + '&status=' + this.params.status + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'vendoragingsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&supplierid=' + this.params.supplierid + '&currency=' + this.params.currency + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'expensehistory') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'expno=' + this.params.expno + '&supplier=' + this.params.supplier + '&expensetype=' + this.params.type + '&submittedby=' + this.params.submittedby + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'expensesummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'expno=' + this.params.expno + '&supplierid=' + this.params.supplierid + '&type=' + this.params.type + '&createby=' + this.params.createby + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'purchaseordersummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'prodreplunishment') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&deptid=' + this.params.dept + '&supplierid=' + this.params.supplierid + '&categoryid=' + this.params.cat + '&van=' + this.params.van + '&minmax=' + this.params.minmax + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'prodorderhistory') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&fromdate=' + this.params.fdate + '&todate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'prodmovementhistory') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&fromdate=' + this.params.fdate + '&todate=' + this.params.tdate + '&deptid=' + this.params.deptid + '&categoryid=' + this.params.catid + '&trantype=' + this.params.trantype + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'revenuebyproducts') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&deptid=' + this.params.dept + '&categoryid=' + this.params.category + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'landedcostaudit') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&pon=' + this.params.pon + '&fromdate=' + this.params.fdate + '&todate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'salesvscostsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&fromdate=' + this.params.fdate + '&todate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'stockcostreport') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&category=' + this.params.category + '&type=' + this.params.type + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'stocksummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'ilc=' + this.params.ilc + '&description=' + this.params.desc + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'stockaudit') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'deptid=' + this.params.dept + '&categoryid=' + this.params.cat + '&printcount=' + this.params.printcount + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'productspriceauditing') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'timesheet') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?employeeid_id=' + this.params.employeeid_id + '&projid=' + this.params.project_id + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&type=' + this.params.type + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'unbilledtimelogs') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&customerid=' + this.params.customerid + '&projid=' + this.params.projid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'joborders') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pon=' + this.params.orderno + '&name=' + this.params.customerid + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'jobinvoices') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'num=' + this.params.num + '&pon=' + this.params.pon + '&name=' + this.params.name + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'billsagingsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&searchtxt=' + this.params.searchtxt + '&currency=' + this.params.currency + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'supplieragingsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&currency=' + this.params.currency + '&yr=' + this.params.yr + '&mon=' + this.params.mon + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'customeragingsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&currency=' + this.params.currency + '&yr=' + this.params.yr + '&mon=' + this.params.mon + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'hbalancesheet') {
            this.exportURL = environment.API_URL + 'reports/hbalancesheet?companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'vbalancesheet') {
            this.exportURL = environment.API_URL + 'reports/vbalancesheet?companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'pl') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'trialbalance') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'notcounteditems') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'countid=' + this.params.countid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'Customer') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'dailytransactions') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&ordertype=' + this.params.ordertype + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'sotransactions') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'recenttransactions') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'invbalance') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'asondate=' + this.params.asondate + 'onhandonly=' + this.params.onhandonly + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'invvaluebyinvoice') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'asondate=' + this.params.asondate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'sopickstatus') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'orderno=' + this.params.orderno + '&orderref=' + this.params.orderref + '&ilc=' + this.params.ilc + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'attendancemonthly') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'yr=' + this.params.yr + '&mon=' + this.params.mon + '&empid=' + this.params.empid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'contractsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&filterapplyto=' + this.params.filterapplyto + '&categoryid=' + this.params.categoryid + '&status=' + this.params.status + '&fdate=' + this.params.fdate + '&edate=' + this.params.edate + '&tpsync=' + this.params.tpsync + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'customerdevices') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&type=' + this.params.type + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'employeeroasterdetails') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&empid=' + this.params.empid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'onlinepayments') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'searchtxt=' + this.params.searchtxt + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'recenttransactions') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'servicecontractticketsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'contractid=' + this.params.contractid + '&categoryid=' + this.params.categoryid + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'snagsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'tasksummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'worklogsummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&empid=' + this.params.empid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'inventorycountdiscrepancy') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&binid=' + this.params.binid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'empshiftlog') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&searchtxt=' + this.params.searchtxt + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'fsnanalysis') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&searchtxt=' + this.params.searchtxt + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'empearlygoinglatecoming') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&searchtxt=' + this.params.searchtxt + '&empid=' + this.params.empid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'printdueinvoices') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + '&customerid=' + this.params.customerid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'InventoryIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&serilized=' + this.params.serilized + '&category=' + this.params.category + '&deptid=' + this.params.deptid + '&status=' + this.params.status + '&type=' + this.params.type + '&tpsync=' + this.params.tpsync + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'EstimateIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&fdate=' + this.params.fdate + '&edate=' + this.params.edate + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'customerstatement') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'customerid=' + this.params.customerid + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'SalesOrderIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&fdate=' + this.params.fdate + '&edate=' + this.params.edate + '&type=' + this.params.type + '&txntype=' + this.params.txntype + '&tpsync=' + this.params.tpsync + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'COAIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&account_type=' + this.params.account_type + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'SupplierIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&type=' + this.params.type + '&islocal=' + this.params.islocal + '&searchtxt=' + this.params.searchtxt + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'POIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&status=' + this.params.status + '&type=' + this.params.type + '&fdate=' + this.params.fdate + '&edate=' + this.params.edate + '&localpo=' + this.params.localpo + '&tpsync=' + this.params.tpsync + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'PIIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'RFQIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&fdate=' + this.params.fdate + '&edate=' + this.params.edate + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'LeadIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&name=' + this.params.name + '&status=' + this.params.status + '&salesperson=' + this.params.salesperson + '&country=' + this.params.country + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'CustomerIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&level=' + this.params.level + '&currency=' + this.params.currency + '&type=' + this.params.type + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'QuoteIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&fdate=' + this.params.fromdate + '&edate=' + this.params.enddate + '&status=' + this.params.status + '&salesrep=' + this.params.salesrep + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'StandingOrderIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchTxt + '&fdate=' + this.params.fdate + '&edate=' + this.params.edate + '&routeid=' + this.params.routeid + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'CustomDeclarationIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&fdate=' + this.params.fromdate + '&edate=' + this.params.enddate + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'InvoiceIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&fdate=' + this.params.fdate + '&edate=' + this.params.edate + '&status=' + this.params.status + '&tpsync=' + this.params.tpsync + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'ContractIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&categoryid=' + this.params.categoryid + '&subcategoryid=' + this.params.subcategoryid + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'Checklistgroups') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&name=' + this.params.name + '&type=' + this.params.type + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'CategoryIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&service=' + this.params.isservice + '&desc=' + this.params.desc + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'ServiceCategoryIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&name=' + this.params.name + '&code=' + this.params.code + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'TicketStatusIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.desc + '&code=' + this.params.code + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'DisputeIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtext + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'DepartmentIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&service=' + this.params.isservice + '&desc=' + this.params.desc + '&status=' + this.params.status + '&code=' + this.params.code + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'InventoryTransferIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&reqno=' + this.params.reqno + '&status=' + this.params.status + '&fdate=' + this.params.fromdate + '&edate=' + this.params.enddate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'InventoryAdjIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&adjno=' + this.params.adjno + '&status=' + this.params.status + '&adjtype=' + this.params.adjtype + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'Checklists') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&groupid=' + this.params.group + '&displayname=' + this.params.displayname + '&description=' + this.params.description + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'BinTemplateIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&active=' + this.params.active + '&fdate=' + this.params.fdate + '&edate=' + this.params.edate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'ServiceItemIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&page_number=' + this.params.page_number + '&grid_page_size=' + this.params.grid_page_size + '&serilized=' + this.params.serilized + '&supplier=' + this.params.supplier + '&category=' + this.params.category + '&deptid=' + this.params.deptid + '&status=' + this.params.status + '&tpsync=' + this.params.tpsync + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }

        else if (this.params.typename == 'PricingBatchIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'ProdBPCIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'ProductUOMMatrix') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'SupplierIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&type=' + this.params.type + '&islocal=' + this.params.islocal + '&searchtxt=' + this.params.searchtxt + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'InventoryIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&page_number=' + this.params.page + '&grid_page_size=' + this.params.limit + '&searchtxt=' + this.params.searchtxt + '&serilized=' + this.params.serilized + '&category=' + this.params.category + '&deptid=' + this.params.deptid + '&status=' + this.params.status + '&tpsync=' + this.params.tpsync + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'MilestoneIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&projid=' + this.params.projid + '&msno=' + this.params.msno + '&name=' + this.params.name + '&customerid=' + this.params.customerid + '&status=' + this.params.status + '&fdate=' + this.params.fromdate + '&edate=' + this.params.endate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'SnagIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&projid=' + this.params.projid + '&module_id=' + this.params.moduleid + '&assignto=' + this.params.assignto + '&name=' + this.params.name + '&customerid=' + this.params.customerid + '&status=' + this.params.status + '&taskid=' + this.params.taskid + '&fdate=' + this.params.fromdate + '&edate=' + this.params.endate + '&priority=' + this.params.priority + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'TaskIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&name=' + this.params.name + '&projid=' + this.params.projid + '&customerid=' + this.params.customerid + '&status=' + this.params.status + '&assignto=' + this.params.assignto + '&moduleid=' + this.params.moduleid + '&fdate=' + this.params.fromdate + '&edate=' + this.params.enddate + '&priority=' + this.params.priority + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'TestCaseIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&name=' + this.params.name + '&projid=' + this.params.projid + '&status=' + this.params.status + '&fdate=' + this.params.fromdate + '&edate=' + this.params.enddate + '&customerid=' + this.params.customerid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'ProjInvBatches') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&projectid=' + this.params.projid + '&fromdate=' + this.params.fdate + '&todate=' + this.params.edate + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'ProjectTypeIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'TaskTypeIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'EmployeeIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&name=' + this.params.name + '&code=' + this.params.code + '&status=' + this.params.status + '&type=' + this.params.type + '&paytype=' + this.params.paytype + '&designation_id=' + this.params.designation_id + '&department_id=' + this.params.department_id + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'LeaveIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&leavetype=' + this.params.leavetype + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'clockinout') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?employeeid_id=' + this.params.empid + '&status=' + this.params.status + '&fromdate=' + this.params.fdate + '&todate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'LeaveConfigIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.description + '&type=' + this.params.type + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'PublicHolidayIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&type=' + this.params.type + '&finyear=' + this.params.finyear + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'ClockingReasonIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'CustomerFeedbacks') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?customerid=' + this.params.customerid + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }
        else if (this.params.typename == 'EmployeeDesignationIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'InventoryCountIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&name=' + this.params.name + '&projid=' + this.params.projid + '&customerid=' + this.params.customerid + '&status=' + this.params.status + '&assignto=' + this.params.assignto + '&moduleid=' + this.params.moduleid + '&fdate=' + this.params.fromdate + '&edate=' + this.params.enddate + '&priority=' + this.params.priority + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'SalaryComponentIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&type=' + this.params.type + '&description=' + this.params.description + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'WeeklyOffIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&departmentid=' + this.params.departmentid + '&type=' + this.params.type + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'CompanyShifts') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'JobOpeningIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&type=' + this.params.type + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'LoanAppIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&type=' + this.params.type + '&status=' + this.params.status + '&fdate=' + this.params.fromdate + '&edate=' + this.params.enddate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'TicketIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&status=' + this.params.status + '&tags=' + this.params.tags + '&assignto=' + this.params.assignto + '&fdate=' + this.params.fdate + '&edate=' + this.params.edate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'ExpenseIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&status=' + this.params.status + '&type=' + this.params.type + '&tpsync=' + this.params.tpsync + '&billorreim=' + this.params.billorreim + '&fdate=' + this.params.fdate + '&edate=' + this.params.edate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'Technotes') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?desc=' + this.params.desc + '&type=' + this.params.type + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'TicketRecommdations') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?searchtxt=' + this.params.searchtxt + '&type=' + this.params.type + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'ContractTypeIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'AssetRequests') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?requestedby=' + this.params.requestedby + '&desc=' + this.params.desc + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'AssetLocationIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'AssetCategoryIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'AssetUsers') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?name=' + this.params.name + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'AssesmentParticulars') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?type=' + this.params.type + '&description=' + this.params.desc + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'JobAplicationIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&skillset=' + this.params.skillset + '&name=' + this.params.name + '&email=' + this.params.email + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'EmployeeAssesmentIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&employeeid=' + this.params.employeeid + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'CompanyLegalContracts') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?name=' + this.params.name + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'PayrollIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtext=' + this.params.searchtext + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'LeaveCategoryIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'EmpAdvanceIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'PRIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&type=' + this.params.type + '&searchtxt=' + this.params.searchtxt + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.enddate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'ShiptoIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&name=' + this.params.name + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'ExpenseCategoryIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'SRIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&type=' + this.params.salesType + '&searchtxt=' + this.params.searchtxt + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'DeliveryRouteIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&description=' + this.params.description + '&code=' + this.params.code + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'AssemblyIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&name=' + this.params.name + '&assno=' + this.params.assno + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'BinIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&desc=' + this.params.desc + '&code=' + this.params.code + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'IncomeIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&searchtxt=' + this.params.searchtxt + '&type=' + this.params.type + '&status=' + this.params.status + '&fdate=' + this.params.fromdate + '&edate=' + this.params.enddate + '&tpsync=' + this.params.tpsync + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'PayrollPaymentTransferExport') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?payrollid=' + this.params.id + '&bankid=' + this.params.bankid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'PurchaseInvoiceSummary') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?searchtxt=' + this.params.searchtxt + '&type=' + this.params.type + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        } else if (this.params.typename == 'SalaryIncreaseStructureIndex') {
            this.exportURL = environment.API_URL + 'reports/' + this.params.typename + '?' + 'pg=' + this.params.pg + '&rw=' + this.userdata.grid_page_size + '&departmentid=' + this.params.departmentid + '&designationid=' + this.params.designationid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&file_type=' + this.params.file_type;
        }


        window.open(this.exportURL, '_blank');
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
