<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Product View
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form">
        <div class="form row">
          <div class="form-group col-lg-12">
            <label for="" class="mb-1">ILC: <span class="text-red">{{itemStatus}}</span></label>
            <input class="form-control" id="" type="text" name="ilc" readonly [(ngModel)]="item.ilc" placeholder="ILC">
          </div>
          <div class="form-group col-lg-12">
            <label for="" class="mb-1">Description</label>
            <input class="form-control" id="" type="text" name="description" readonly [(ngModel)]="item.description"
              placeholder="Description">
          </div>
          <div class="form-group col-lg-12">
            <label for="" class="mb-1">Landed Cost</label>
            <input class="form-control" id="" type="text" name="landedcost" (focusout)="calculateSalesTotal()"
              [(ngModel)]="item.landedcost" placeholder="Landed cost">
          </div>
          <div class="form-group col-lg-12">
            <label for="" class="mb-1">Sales Percentage%</label>
            <input class="form-control" id="" type="number" max="100" (focusout)="calculateSalesTotal()"
              [(ngModel)]="item.salepricepercentage" placeholder="Sales percentage">
          </div>
          <div class="form-group col-lg-12">
            <label for="" class="mb-1">Sales Price</label>
            <input class="form-control" id="" type="text" name="salesprice" readonly [(ngModel)]="item.salesprice"
              placeholder="Sales Price">
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button class="btn btn-primary" type="button" (click)="postItem()">Save</button>
    </div>
  </div>
</resize-border>