import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import { projectmanagerServices } from '../../projectmanager/projectmanager.service';

@Component({
  selector: 'app-milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.scss']
})
export class MilestoneComponent implements OnInit {
  fullScreen = false;
  statusList: any = [];
  appname = environment.APP_NAME;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private commonService: CommonServices, private service: projectmanagerServices) {

    this.commonService.getComboEnums('milestonestatus').subscribe(res => {
      this.statusList = res;
      environment.production ? '' : console.log('tmile stone status ', this.statusList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading mile stone status ', err);
    });
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }


}
