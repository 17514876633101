import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-printlabel',
  templateUrl: './printlabel.component.html',
  styleUrls: ['./printlabel.component.scss']
})
export class PrintlabelComponent implements OnInit {
  fullScreen = false;
  @Input('name') name;
  @Input('id') id;
  @Input('type') type;
  @Input('desc') desc;
  @Input('alt') alt;
  userdata: any;
  appname = environment.APP_NAME;
  printURL: any;
  constructor(private activeModal: NgbActiveModal, private loader: NgxUiLoaderService,) { 
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  ngOnInit(): void {
    this.loader.start();
    setTimeout(() => {
      this.loader.stop();
    }, 2000)
    this.printURL = environment.API_URL + 'public/printoptions?id='+ this.id + '&lbltype=' + this.type + '&qty=' + '1' + '&printcompanyname=' + this.name + '&companyid=' + this.userdata.companyid + '&printdesc=' + this.desc + '&alt=' + this.alt + '&un=' + this.userdata.company_web_token;
  }

}
