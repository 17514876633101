import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { InventoryServices } from 'src/app/components/inventory/inventory.service';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AddProductComponent } from '../add-product.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
  selector: 'app-transactionhistory',
  templateUrl: './transactionhistory.component.html',
  styleUrls: ['./transactionhistory.component.scss']
})
export class TransactionhistoryComponent implements OnInit {
  public closeResult: string;
  public categories = []
  product: any;
  transactionList: any;
  userdata: any;
  appname = environment.APP_NAME;
  fromdate: any;
  todate: any;
  searchtext: any = '';
  constructor(private modalService: NgbModal,private toastr:NotificationService, private datepipe: DatePipe, private maincomponent: AddProductComponent, private loader: NgxUiLoaderService, private service: InventoryServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.product = maincomponent.product;
    this.todate = moment().add(1, 'days').format('YYYY-MM-DD');
    this.fromdate = moment().subtract(182, 'days').format('YYYY-MM-DD');
    this.getProductTransactions();
  }

  ngOnInit(): void {
  }

  //#region Sorting Table Header
	sortDir = 1;//1= 'ASE' -1= DSC
	onSortClick(event,column) {
		let target = event.currentTarget,
			childtarget = target.querySelector('i'),
			classList = childtarget.classList;

		if (classList.contains('fa-chevron-up')) {
			classList.remove('fa-chevron-up');
			classList.add('fa-chevron-down');
			target.classList.add('Sorted');
			this.sortDir = -1;
		} else {
			classList.add('fa-chevron-up');
			classList.remove('fa-chevron-down');
			target.classList.remove('Sorted');
			this.sortDir = 1;
		}
		this.sortArr(column);
	}

	sortArr(colName: any) {
		this.transactionList.sort((a, b) => {
			let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
			let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

	if (!valueA && !valueB) {
		return 0; 
	}
	if (!valueA) {
		return -this.sortDir; 
	}
	if (!valueB) {
		return this.sortDir; 
	}

	if (valueA > valueB) {
		return this.sortDir;
	}
	if (valueA < valueB) {
		return -this.sortDir;
	}
	return 0;
});
	}
	//#endregion Sorting Table Header
  getProductTransactions() {
    this.loader.start();
    this.service.getItemAnalyses(this.product.id, this.datepipe.transform(new Date(this.fromdate), this.userdata.postdateformat), this.datepipe.transform(new Date(this.todate), this.userdata.postdateformat), this.searchtext).subscribe((res) => {
      this.loader.stop();
      this.transactionList = res;
      this.transactionList.forEach(item => {
        item.pocost = item.pocost ? Number(item.pocost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        item.avgcost_weighted = item.avgcost_weighted ? Number(item.avgcost_weighted).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        item.avgcost_fifo = item.avgcost_fifo ? Number(item.avgcost_fifo).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        item.saleprice = item.saleprice ? Number(item.saleprice).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      })
    }, err => {
      this.loader.stop();
    })
  }



  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  undoProductTrans(item) {
		Swal.fire({
			title: 'Are you sure want to undo selected transaction?',
			// text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				this.loader.start();
				this.service.undoProductTransaction(item.customer, item.id).subscribe((res: any) => {
					this.loader.stop();
					if (res != null) {
						if (res.status_code == "1") {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
						} else {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
							return;
						}
					}
					this.getProductTransactions();
				}, err => {
					this.loader.stop();
					environment.production ? '' : console.log('Error : While loading expType list ', err);
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}
}
