import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { purchaseServices } from '../../purchase/purchase.service';
import { ProductviewComponent } from '../productview/productview.component';

@Component({
  selector: 'app-pocosting',
  templateUrl: './pocosting.component.html',
  styleUrls: ['./pocosting.component.scss']
})
export class PocostingComponent implements OnInit {
  fullScreen = false;
  @Input('poid') poid;
  @Input('pon') pon;
  pono: any;
  costingItems: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  poCostingDetails: any = {
    "id": null,
    "sid": null,
    "doc": null,
    "dor": null,
    "notes": null,
    "duty": 0,
    "invtotal": 0,
    "warehousefee": 0,
    "freight": 0,
    "haulfee": 0,
    "customsfee": 0,
    "docfee": 0,
    "cratefee": 0,
    "tax": 0,
    "insurance": 0,
    "handlingfee": 0,
    "servicefee": 0,
    "fdeliveryfee": 0,
    "otherfee": 0,
    "ldeliveryfee": 0,
    "hzmatfee": 0,
    "companyid": null,
    "totalcf": 0,
    "wfpercentage": 0,
    "approvedby": null,
    "approveddate": null,
    "iscosted": false,
    "deletedate": null,
    "createby": null,
    "createdate": null,
    "updateby": null,
    "updatedate": null,
    "demurragefee": 0
  };
  constructor(private activeModal: NgbActiveModal,
    private toastr: NotificationService, private datepipe: DatePipe, private loader: NgxUiLoaderService, private service: purchaseServices, private modalService: NgbModal, private router: Router) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  ngOnInit(): void {
    this.getPOCosting();
  }
  getPOItems() {
    this.loader.start();
    this.service.getPOItemConsting(this.poid).subscribe(res => {
      this.loader.stop();
      this.costingItems = res;
      this.costingItems.forEach(item => {
        item.podate = this.datepipe.transform(item.podate, this.userdata.date_format);
      })
    }, err => {
      this.loader.stop();
    });
  }
  getPOCosting() {
    this.loader.start();
    this.service.getPOCosting(this.poid).subscribe(res => {
      this.loader.stop();
      this.poCostingDetails = res;
      this.poCostingDetails.invtotal = this.poCostingDetails.invtotal ? Number(this.poCostingDetails.invtotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.cf = this.poCostingDetails.cf ? Number(this.poCostingDetails.cf).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.duty = this.poCostingDetails.duty ? Number(this.poCostingDetails.duty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.warehousefee = this.poCostingDetails.warehousefee ? Number(this.poCostingDetails.warehousefee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.freight = this.poCostingDetails.freight ? Number(this.poCostingDetails.freight).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.haulfee = this.poCostingDetails.haulfee ? Number(this.poCostingDetails.haulfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.customsfee = this.poCostingDetails.customsfee ? Number(this.poCostingDetails.customsfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.docfee = this.poCostingDetails.docfee ? Number(this.poCostingDetails.docfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.cratefee = this.poCostingDetails.cratefee ? Number(this.poCostingDetails.cratefee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.tax = this.poCostingDetails.tax ? Number(this.poCostingDetails.tax).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.insurance = this.poCostingDetails.insurance ? Number(this.poCostingDetails.insurance).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.handlingfee = this.poCostingDetails.handlingfee ? Number(this.poCostingDetails.handlingfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.servicefee = this.poCostingDetails.servicefee ? Number(this.poCostingDetails.servicefee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.fdeliveryfee = this.poCostingDetails.fdeliveryfee ? Number(this.poCostingDetails.fdeliveryfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.otherfee = this.poCostingDetails.otherfee ? Number(this.poCostingDetails.otherfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.ldeliveryfee = this.poCostingDetails.ldeliveryfee ? Number(this.poCostingDetails.ldeliveryfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.hzmatfee = this.poCostingDetails.hzmatfee ? Number(this.poCostingDetails.hzmatfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.wfpercentage = this.poCostingDetails.wfpercentage ? Number(this.poCostingDetails.wfpercentage).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.demurragefee = this.poCostingDetails.demurragefee ? Number(this.poCostingDetails.demurragefee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.poCostingDetails.iscosted = this.poCostingDetails.iscosted ? (this.poCostingDetails.iscosted ? this.poCostingDetails.iscosted : false) : false;
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }

  recalculate() {
    this.costingItems.forEach(item => {
      item.podate = this.datepipe.transform(item.podate, this.userdata.postdatetimeformat);
    })
    this.loader.start();
    var obj = {
      "items": JSON.stringify(this.costingItems),
      "id": this.poid,
      "updateby": this.userdata.email,
      "updatedate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "companyid": this.userdata.companyid
    }
    this.service.postRecalculateItem(obj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.getPOItems();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading bol data ', err);
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  onSortClick(event) {

  }
  tabClick(event) {
    if (event.nextId == '2') {
      this.getPOItems();
    }
  }
  ProdctView(value) {
    var modalRef = this.modalService.open(ProductviewComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.item = value;
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
      this.getPOItems();
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  postPOCosting() {
    if (this.poCostingDetails.dor == undefined || this.poCostingDetails.dor == null) {
      this.toastr.Error('Please enter date of release');
      return;
    }
    if (this.poCostingDetails.doc == undefined || this.poCostingDetails.doc == null) {
      this.toastr.Error('Please enter date of calculation');
      return;
    }
    if (this.poCostingDetails.id == undefined || this.poCostingDetails.id == null || this.poCostingDetails.id == 0) {
      this.poCostingDetails.companyid = this.userdata.companyid;
      this.poCostingDetails.createby = this.userdata.email;
      this.poCostingDetails.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.poCostingDetails.updateby = this.userdata.email;
      this.poCostingDetails.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.poCostingDetails.approveddate = this.datepipe.transform(this.poCostingDetails.approveddate, this.userdata.postdateformat);
    this.poCostingDetails.dor = this.datepipe.transform(this.poCostingDetails.dor, this.userdata.postdateformat);
    this.poCostingDetails.doc = this.datepipe.transform(this.poCostingDetails.doc, this.userdata.postdateformat);

    this.loader.start();
    this.service.postPOCosting(this.poCostingDetails).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.getPOCosting();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }
  approvePOCosting() {
    if (this.costingItems.length == 0) {
      this.toastr.Error('Please check PO if received & try again')
      return;
    }
    this.loader.start();
    this.service.postApprovePOCosting(this.poid).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.redirectAction();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }

  redirectAction() {
    Swal.fire({
      title: 'PO costing submitted, Do you want to review pricing?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.closeModal();
        this.router.navigate(['/pricings'], { replaceUrl: true });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.closeModal();
      }
    })
  }
}
