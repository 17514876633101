import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrintElementService } from 'ngx-print-element';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { serviceManagerServices } from '../../servicemanager/servicemanager.service';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-composeemailwithattachment',
  templateUrl: './composeemailwithattachment.component.html',
  styleUrls: ['./composeemailwithattachment.component.scss']
})
export class ComposeemailwithattachmentComponent implements OnInit {
  fullScreen = false;
  @Input('flag') flag;
  @Input('id') id;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  ticketsBatchPrintList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  isShowCopanyLogo = false;
  compose: any = {
    "msg": "",
    "createby": null,
    "from": null,
    "sendername": null,
    "to": null,
    "cc": null,
    "bcc": null,
    "subject": "",
    "id": null,
    "template_code": false,
    "default_sender": false,
    "companyname": null,
    "logo": null,
    "currency": null,
    "contactno": null,
    "bankname": null,
    "bankaccname": null,
    "bankaccno": null,
    "bankbic": null,
    "duedate": null,
    "to_name": null,
    "amt": null,
    "refno": null,
    "companyid": null,
    "use_template": 'default',
    "attachtickettoinvoice": false,
    "multipleEmails": [
    ]
  }
  dcument: any;
  uploader: FileUploader;
  filename: any;
  templateList:any=[];
  constructor(private modalService: NgbModal,
    private toastr: NotificationService, private commonService:CommonServices,
    private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: CommonServices, public print: NgxPrintElementService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.companyLogo += this.userdata.logo;
  }

  ngOnInit(): void {
    this.getComposeEmail();
    this.getEmailTemplates();
  }
  getEmailTemplates() {
    this.loader.start();
    this.commonService.getEmailTemplateLists().subscribe(res => {
      this.loader.stop();
      this.templateList = res;
    }, err => {
      this.loader.stop();
    });
  }
  getComposeEmail() {
    this.loader.start()
    this.service.getComposeEmail(this.id, this.flag).subscribe(res => {
      this.loader.stop();
      if (res != null) {
        this.compose = res;
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading currency data', err);
    })
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  save() {
    if (this.compose.to_name == undefined || this.compose.to_name == null || this.compose.to_name == '') {
      this.toastr.Error('Please enter a recipient name')
      return;
    }
    if (this.compose.to == undefined || this.compose.to == null || this.compose.to == '') {
      this.toastr.Error('Please enter a recipient email')

      return;
    }
    if (this.compose.subject == undefined || this.compose.subject == null || this.compose.subject == '') {
      this.toastr.Error('Please enter subject')
      return;
    }
    this.compose.from_name = this.userdata.name;
    this.compose.createby = this.userdata.email;
    this.compose.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    this.compose.companyid = this.userdata.companyid;
    this.loader.start();
    this.service.postEmail(this.compose).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While post email', err);
    })
  }
  selectedFileOnChanged(event: any) {
    environment.production ? '' : console.log(event.target.value);
    this.filename = event.target.value.split(/(\\|\/)/g).pop()
  }
  viewDocument(doc) {
    const url = environment.DOC_URL + '/' + this.userdata.companyid + '/' + doc.name;
    window.open(url, '_blank');
  }

  initializeFileUploader() {
    this.uploader = new FileUploader({
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + this.userdata.token,
      url: environment.API_URL + 'api/Documents/UploadFiles',
      method: 'POST',
      removeAfterUpload: true,
      queueLimit: 100
    })
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('companyid', this.userdata.companyid);
      form.append('createby', this.userdata.email);
      form.append('createdate', this.datepipe.transform(new Date(), this.userdata.postdatetimeformat));
      form.append('dtype', 'emailattachment');
    };
    this.uploader.response.subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        res = JSON.parse(res)
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeFileUploader();
      }
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
  }
  uploadFile() {
    this.loader.start()
    this.uploader.queue[0].onSuccess = function (response, status, headers) {
      this.loader.stop();
      if (status == 200) {
        let tempRes = JSON.parse(response);
      } else {
        this.initializeFileUploader();
        this.toastr.success('File uploaded');
      }
    };
    this.uploader.queue[0].upload();
  }
}
