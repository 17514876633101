<resize-border [dragHolder]="header">
<div #header class="modal-header">
  <h5 class="modal-title f-w-600" id="productModalLabel">Bin Hold Details
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="table-responsive2">
    <table class="custom-table">
      <thead>
        <tr class="custom-tr">
          <th class="custom-th text-center">Customer</th>
          <th class="custom-th text-center">Count</th>
          <th class="custom-th text-center">Serial</th>
          <th class="custom-th text-center">Notes</th>
          <th class="custom-th text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr class="custom-tr" *ngFor="let item of listData">
          <td class="custom-td text-left">{{item.name}}</td>
          <td class="custom-td text-center">{{item.txnqty}}</td>
          <td class="custom-td text-center">{{item.serial}}</td>
          <td class="custom-td text-left">{{item.notes}}</td>
          <td class="custom-td text-center">
            <div class="modalActionBtn">
              <i class="eb-refresh" (click)=refresh(item)></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
</div>
</resize-border>
<ngx-ui-loader></ngx-ui-loader>
