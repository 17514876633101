<resize-border  [dragHolder]="header" >
  <div class="whole-modal" [class.full-screen]="fullScreen">
      <div #header class="modal-header">
        <h5 class="modal-title f-w-600" id="productModalLabel">Print
        </h5>
        <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
          <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
          <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
        </button>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <object [data]="printURL | safe" type="application/pdf" width="100%"></object>
      <div class="modal-footer justify-content-center">
        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
        <!-- <button class="btn btn-primary" type="button">Save</button> -->
      </div>
  </div>
</resize-border>