<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="paymentModalLabel">Purchase Invoice Transactions
            </h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
              </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="table-responsive2 mb-3">
                <table class="input-table">
                    <thead>
                        <tr class="custom-tr">
                            <th class="custom-th text-center">PI Total</th>
                            <th class="custom-th text-center">Transaction Total</th>
                            <th class="custom-th text-center">Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="custom-tr">
                            <td class="custom-td text-center">{{this.payment.amt}}</td>
                            <td class="custom-td text-center"></td>
                            <td class="custom-td text-center">{{this.payment.balance}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="">Method of payment</label>
                    <select name="mop" class="form-control" id="" (change)="getPaymentAccounts()"
                        [(ngModel)]="payment.mop">
                        <option value="">Select Method of Payment</option>
                        <option value="{{item.Value}}" *ngFor="let item of paymentMethodList">{{item.Text}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="">Cash/Bank</label>
                    <select name="bankid" class="form-control" id="" name="bankid" [(ngModel)]="payment.bankid">
                        <option value="">Select Cash/Bank</option>
                        <option [value]="item.Value" *ngFor="let item of companyBanks">{{item.Text}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="">Amount</label>
                    <input type="text" class="form-control" name="amount" [(ngModel)]="payment.amt">
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="">Balance</label>
                    <input type="text" class="form-control" name="balance" [(ngModel)]="payment.balance">
                </div>
                <div class="col-lg-12 col-md-12 form-group">
                    <label for="">Memo</label>
                    <textarea name="" class="form-control" name="memo" rows="2"></textarea>
                </div>
                <div class="col-lg-12 col-md-12 form-group">
                    <label for="">Notes</label>
                    <textarea name="" class="form-control" notes="notes" rows="2"></textarea>
                </div>
                <div class="col-lg-12 col-md-12 form-group">
                    <label for="">Select file</label>
                    <input type="file" class="form-control">
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
            <button class="btn btn-primary" type="button">Save</button>

            <div class="table-responsive2 mt-3">
                <table class="input-table">
                    <thead>
                        <tr class="custom-tr">
                            <th class="custom-th text-center" style="width:5% ;">Actions</th>
                            <th class="custom-th text-center" style="width:15% ;">#</th>
                            <th class="custom-th text-center">Method of payment</th>
                            <th class="custom-th text-center">Amount</th>
                            <th class="custom-th text-center">Date</th>
                            <th class="custom-th text-center">Memo</th>
                            <th class="custom-th text-center">TxnBy</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="custom-tr">
                            <td class="custom-td text-center">
                                <div class="ActionBtn">
                                    <a class="hoverBtn">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </div>
                            </td>
                            <td class="custom-td"></td>
                            <td class="custom-td"></td>
                            <td class="custom-td text-right"></td>
                            <td class="custom-td text-center text-noWrap"></td>
                            <td class="custom-td"></td>
                            <td class="custom-td"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</resize-border>