import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InventoryServices } from '../../inventory/inventory.service';

@Component({
  selector: 'app-quickaddproduct',
  templateUrl: './quickaddproduct.component.html',
  styleUrls: ['./quickaddproduct.component.scss']
})
export class QuickaddproductComponent implements OnInit {
  fullScreen = false;
  @Input('productdata') productdata;
  product: any = {
    ptype: 'inventory',
    dept: null,
    cat: null,
    supplierid: null,
    status: 'active'
  }
  userdata: any;
  appname = environment.APP_NAME;
  deptList: any = [];
  productType: any = [];
  supplierList: any = [];
  categoryList: any = [];
  statusList: any = [];
  form: NgForm;
  isSaved = false;
  refid: any;
  constructor(private modalService: NgbModal,
    private toastr: NotificationService,
    private activeModal: NgbActiveModal, private router: Router, private loader: NgxUiLoaderService, private service: InventoryServices, private datepipe: DatePipe, private commonService: CommonServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.commonService.getDepartmentList(false).subscribe(res => {
      this.deptList = res;
      environment.production ? '' : console.log('department data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading department data', err);
    });

    this.commonService.getComboEnums('prodtype').subscribe(res => {
      this.productType = res;
      environment.production ? '' : console.log('product type  ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading Sub category data', err);
    });
    this.service.getProductSupplier().subscribe(res => {
      this.supplierList = res;
      environment.production ? '' : console.log('supplierList type  ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading supplierList data', err);
    });
    this.commonService.getComboEnums('assemblystatus').subscribe(res => {
      this.statusList = res;
      environment.production ? '' : console.log('statusList  ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading statusList data', err);
    });

  }
  getCatData() {
    this.loader.start();
    this.commonService.getCategory(this.product.dept, false).subscribe(res => {
      this.categoryList = res;
      this.loader.stop();

      environment.production ? '' : console.log('Category data ', res);
    }, err => {
      this.loader.stop();

      environment.production ? '' : console.log('Error : While loading category data', err);
    });

  }

  ngOnInit(): void {
    if (this.productdata) {
      this.product = this.productdata;
    }
  }

  closeModal() {

    this.activeModal.close(this.isSaved ? this.refid : '');
  }

  postQuickItem() {
    if (this.product.ilc == undefined || this.product.ilc == null) {
      this.toastr.Error('Please enter ilc')
      return;
    }
    var t_prodbinsList = [];
    t_prodbinsList.push({
      prodid: null,
      active: true,
      companyid: this.userdata.companyid,
      cnt: 0,
      binid: this.userdata.default_inventory_bin_location,
      onhold: 0,
      sold: 0,
      minqty: 0,
      maxqty: 0,
      availableqty: 0,
      notes: '',
      createby: this.userdata.email,
      createdate: this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
    })
    var productObj = {
      "ilc": this.product.ilc,
      "description": this.product.description,
      "dept": this.product.dept,
      "supplierid": this.product.supplierid,
      "cat": this.product.cat,
      "ptype": this.product.ptype,
      "createby": this.userdata.email,
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "status": this.product.status,
      "companyid": this.userdata.companyid,
      "landedcost": this.product.landedcost,
      "store": this.product.store,
      "puom": this.product.puom ? this.product.puom : 'EA',
      "pocost": parseFloat(this.product.pocost),
      "t_prodbins": t_prodbinsList

    }
    environment.production ? '' : console.log('quick item', productObj);
    this.loader.start();
    this.service.postProduct(productObj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.refid = res.refid;
        this.isSaved = true;
        this.product = {};
        this.closeModal()
      }
      environment.production ? '' : console.log('post quick product response ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });
  }

  validateIlc() {
    this.loader.start();
    this.commonService.validateIlc(this.product.ilc).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          this.product.ilc = '';
          return;
        }
      }
    }, err => {
      environment.production ? '' : console.log('Error : While loading discountTypeList data', err);
    });

  }
}
