export class categoryDB {
    static category = [
        {
            img: "<img src='assets/images/dashboard/product/1.jpg' class='imgTable'>",
            product_name: "Headphones",
            price: "$20.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Electronics",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/2.jpg' class='imgTable'>",
            product_name: "Honor Mobile",
            price: "$462.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "Electronics",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/3.jpg' class='imgTable'>",
            product_name: "Samsung LED TV",
            price: "$652.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "Electronics",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/4.jpg' class='imgTable'>",
            product_name: "Motorola Bluetooth",
            price: "$25.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Electronics",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/5.jpg' class='imgTable'>",
            product_name: "Apple 6s",
            price: "$782.00",
            status: "<i class='fa fa-circle font-danger f-12'></i>",
            category: "Electronics",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/6.jpg' class='imgTable'>",
            product_name: "Printer",
            price: "$4825.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "Electronics",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/7.jpg' class='imgTable'>",
            product_name: "Canon Camera",
            price: "$2461.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Electronics",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/8.jpg' class='imgTable'>",
            product_name: "High Quality Headphones",
            price: "$761.00",
            status: "<i class='fa fa-circle font-danger f-12'></i>",
            category: "Electronics",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/9.jpg' class='imgTable'>",
            product_name: "Home Theater Speakers",
            price: "$672.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Electronics",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/10.jpg' class='imgTable'>",
            product_name: "Diamond Ring",
            price: "$237.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "Jewellery",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/11.jpg' class='imgTable'>",
            product_name: "Diamond Nacklace",
            price: "$3579.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "Jewellery",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/12.jpg' class='imgTable'>",
            product_name: "Diamond Earrings",
            price: "$3145.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Jewellery",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/13.jpg' class='imgTable'>",
            product_name: "Night lamp",
            price: "$84.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "furniture",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/14.jpg' class='imgTable'>",
            product_name: "men's shoes",
            price: "$67.00",
            status: "<i class='fa fa-circle font-danger f-12'></i>",
            category: "shoes",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/15.jpg' class='imgTable'>",
            product_name: "Ledi's red top",
            price: "$234.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "clothes",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/16.jpg' class='imgTable'>",
            product_name: "latest ledis shoes",
            price: "$357.00",
            status: "<i class='fa fa-circle font-danger f-12'></i>",
            category: "shoes",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/17.jpg' class='imgTable'>",
            product_name: "Woman one pis",
            price: "$682.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "clothes",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/18.jpg' class='imgTable'>",
            product_name: "Mouse",
            price: "$24.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "electronics",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/19.jpg' class='imgTable'>",
            product_name: "Coffee maker",
            price: "$9721.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "electronics",
            company:"sanmol software",
        },
        {
            img: "<img src='assets/images/dashboard/product/20.jpg' class='imgTable'>",
            product_name: "Diamond Nacklace",
            price: "$3579.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Jewellery",
            company:"sanmol software",
        },

    ]
}
