import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-projjoblistmodal',
  templateUrl: './projjoblistmodal.component.html',
  styleUrls: ['./projjoblistmodal.component.scss']
})
export class ProjjoblistmodalComponent implements OnInit {
  @Input('searchtxt') searchtxt;
  @Input('customerid') customerid;
  jobList: any = [];
  subscription: Subscription;
  @ViewChild('SearchElement') SearchElement: ElementRef;
  selectedJob: any = '';
  constructor(private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private commoanService: CommonServices,
    private toastr: NotificationService,
    private datePipe: DatePipe,
    private loader: NgxUiLoaderService) {
    this.getProjJobslist();
  }


  getProjJobslist() {
    if (this.searchtxt == undefined || this.searchtxt == null || this.searchtxt.length < 3) {
      return;
    }
    if (this.customerid == undefined || this.customerid == null) {
      this.customerid = '';
    }
    this.loader.start();
    this.commoanService.getProjJobslist(this.searchtxt, this.customerid).subscribe(res => {
      this.loader.stop();
      this.jobList = res;
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading expType list ', err);
    });
  }
  selectJob(item) {
    this.selectedJob = item;
    this.jobList = [];
  }

  ngOnInit(): void {

    // this.getProjJobslist();
  }
  // ngAfterViewInit() {

  //   const source = interval(500);
  //   this.subscription = source.subscribe(val =>  {
  //     if(!this.SearchElement.nativeElement.focus()){
  //       this.SearchElement.nativeElement.focus()
  //     }
  //   });
  // }

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  //   console.log('destroyed')
  // }

}
