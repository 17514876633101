import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable } from 'rxjs';
import { modelLogin, modelComp, TokenParams } from '../../components/auth/login/modelLogin';
import { catchError } from 'rxjs/operators';
import { Events } from 'src/app/shared/service/events.service';
import { DatePipe } from '@angular/common';

// import { ToastController } from '@angular/common';
// import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
    providedIn: 'root'
})
export class SchedulerServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    isLoading: boolean;
    objLogin: modelLogin;
    objComp: modelComp;
    objCompId: any;
    userprofile: any;
    public hero: ''
    public expenseData: ''
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }

    //Scheduler
    getSchedulerEvent(val1, val2) {
        return this.http.get(this.baseUrl + 'api/Scheduler/Gett_Events?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&startDate=' + val1 + '&endDate=' + val2)
    }

    getSchedulerResources() {
        return this.http.get(this.baseUrl + 'api/Scheduler/Gett_Resources?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSchedulerEventByID(id) {
        return this.http.get(this.baseUrl + 'api/Scheduler/Gett_EventInfo?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&technician=&start_date=&due_date=&mode=e&no_fix_customer_sites=')
    }

    postEvent(val) {
        return this.http.post(this.baseUrl + 'api/Scheduler/Postt_SaveEvent', val);
    }

    postUpdateEvent(val) {
        return this.http.post(this.baseUrl + 'api/Scheduler/Post_UpdateEvent', val);
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
