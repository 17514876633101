<resize-border [dragHolder]="header">
    <div #header class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">Serial</h5>
        <button type="button" class="close" aria-label="Close" (click)="closemodal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12 form-group">
                <label for="">Serial</label>
                <input type="text" class="form-control" placeholder="" name="serialno" [(ngModel)]="serial.serial">
            </div>
            <div class="col-lg-12 form-group">
                <label for="">Bin Location</label>
                <select class="form-control" required="" name="to bin location" [(ngModel)]="serial.binid">
                    <option value="">Select Bin</option>
                    <option *ngFor="let item of allBinList" [value]="item.Value">
                        {{item.Text}}</option>
                </select>
            </div>
            <div class="col-lg-12 form-group">
                <label for="">Purchase Order</label>
                <input type="text" class="form-control" placeholder="" name="po" [(ngModel)]="serial.po">

            </div>
            <div class="col-lg-12 form-group">
                <label for="">Purchase From</label>
                <input type="text" class="form-control" placeholder="" name="purchasefrom"
                    [(ngModel)]="serial.purchasefrom">
            </div>
            <div class="col-lg-12 form-group">
                <label for="">Purchase Date</label>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker280" placeholder="Choose a date"
                        [(ngModel)]="serial.purchasedate" name="purchasedate">
                    <mat-datepicker-toggle matSuffix [for]="picker280"></mat-datepicker-toggle>
                    <mat-datepicker #picker280></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-lg-12 form-group">
                <label for="">Manufacturer</label>
                <input type="text" class="form-control" placeholder="" name="manufacturer"
                    [(ngModel)]="serial.manufacturer">
            </div>
            <div class="col-lg-12 form-group">
                <label for="">Sale Date</label>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker281" placeholder="Choose a date"
                        [(ngModel)]="serial.saledate" name="saledate">
                    <mat-datepicker-toggle matSuffix [for]="picker281"></mat-datepicker-toggle>
                    <mat-datepicker #picker281></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-lg-12 form-group">
                <label for="">Order No</label>
                <input type="text" class="form-control" placeholder="" name="orderno" [(ngModel)]="serial.orderno">
            </div>
            <div class="col-lg-12 form-group">
                <label for="">Warranty Expiry Date</label>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker282" placeholder="Choose a date" [(ngModel)]="serial.wed"
                        name="wed">
                    <mat-datepicker-toggle matSuffix [for]="picker282"></mat-datepicker-toggle>
                    <mat-datepicker #picker282></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-lg-12 form-group">
                <label for="">Status</label>
                <select class="form-control" required="" name="status" [(ngModel)]="serial.status">
                    <option value="">Select Status</option>
                    <option *ngFor="let item of statusList" [value]="item.stringValue">{{item.Text}}</option>
                </select>
            </div>
            <div class="col-lg-12 form-group">
                <label for="">Notes</label>
                <textarea name="notes" rows="2" [(ngModel)]="serial.notes" class="form-control"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-center">
        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
            (click)="closemodal()">Close</button>
        <button type="button" class="btn btn-primary" *ngIf="!editData" (click)="postSerial()">Save</button>
    </div>
</resize-border>