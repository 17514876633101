import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountdetailsComponent } from './accountdetails/accountdetails.component';
import { ChooseIndustryComponent } from './chooseindustry/chooseindustry.component';
import { CreateAccountComponent } from './createaccount/createaccount.component';
import { FeaturesComponent } from './features/features.component';
import { HomepageComponent } from './homepage.component';
import { SystemmigrationComponent } from './systemmigration/systemmigration.component';
import { VerifyaccountComponent } from './verifyaccount/verifyaccount.component';

const routes: Routes = [
      {
        path: 'index',
        component: HomepageComponent, 
      },
      {
        path:'public/features',
        component:FeaturesComponent,
      },
      {
        path:'public/createaccount',
        component:CreateAccountComponent,
      },
      {
        path:'public/verifyaccount',
        component: VerifyaccountComponent,
      },
      {
        path:'public/accountdetails',
        component:AccountdetailsComponent,
      },
      {
        path:'public/chooseindustry',
        component:ChooseIndustryComponent,
      },
      {
        path:'public/systemmigration',
        component:SystemmigrationComponent,
      },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomepageRoutingModule { }
