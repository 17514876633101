import {NgModule} from '@angular/core';

import { CustomerslistComponent } from './customerlist.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    declarations: [CustomerslistComponent],
    imports:[CommonModule, FormsModule, NgbModule, NgbTooltipModule, NgSelectModule],
    exports: [CustomerslistComponent],
})
export class CustomerListModule {}