import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InventoryServices } from '../../../inventory.service';
import { ProductListComponent } from '../../products.component';
import { SerialComponent } from '../serial/serial.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-transfer-qty',
  templateUrl: './transfer-qty.component.html',
  styleUrls: ['./transfer-qty.component.scss']
})
export class TransferQtyComponent implements OnInit {
  @Input() fromParent;
  itemData: any = {}
  allBinList: any = []
  ownItemProdBinList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  closeResult: string;
  serial: any = {}
  serialList: any = [];
  filteredBins: any = [];
  private langChangeSubscription: Subscription; 
  constructor(private activeModal: NgbActiveModal,
    private service: InventoryServices,
    private datepipe: DatePipe,
    private modalService: NgbModal,
    private toastr: NotificationService,
    private loader: NgxUiLoaderService,
    private translate: TranslateService, 
private languageService: LanguageService, 
private http: HttpClient, ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    //get own item prod bin
  }


  ngOnInit(): void {
    environment.production ? '' : console.log('item data', this.fromParent);
    this.service.getTransferQty(this.fromParent.id).subscribe(res => {
      this.loader.stop();
      this.itemData = res;
      if (this.itemData.isserilized) {
        this.itemData.qtycount = 1;
      }
      this.itemData.tobinlocation = null;
      this.itemData.serial = null;
      this.getOwnBinLocation();
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });
    this.getBinLocation();
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
     
  }
private loadComponentTranslations(lang: string) { 
 const translationFilePath = `./assets/i18n/sales/customers/${lang}.json`; 
 this.http.get(translationFilePath).subscribe((translations:any) => { 
 this.translate.setTranslation(lang, translations, true); 
 this.translate.use(lang); 
 }); 
	} 
  setFromBinLocation() {
    this.itemData.frombinlocation = this.fromParent.binid ? '' + this.fromParent.binid : null;
    this.itemData.frombinlocation ? this.selectOwnBinLocation(this.itemData.frombinlocation) : '';
  }

  getOwnBinLocation() {
    this.service.getProductBin(this.fromParent.id).subscribe(res => {
      this.ownItemProdBinList = res;
      this.setFromBinLocation();
      environment.production ? '' : console.log(' bin list data ', this.ownItemProdBinList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }
  getBinLocation() {
    this.service.getBinLocationList().subscribe(res => {
      this.allBinList = res;
      environment.production ? '' : console.log(' bin list data ', this.allBinList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }
  filterBinLocations() {
    this.filteredBins = this.allBinList.filter(item => item.Value !== this.itemData.frombinlocation);
  }
  getSerial() {
    if (!this.itemData.frombinlocation || !this.itemData.isserilized) {
      return;
    }
    this.service.getSerialListUsingBin(this.itemData.frombinlocation, this.fromParent.id).subscribe(res => {
      this.serialList = res;
      environment.production ? '' : console.log('serial list data ', this.serialList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }
  closemodal() {
    this.activeModal.close('Modal Closed');
  }
  selectOwnBinLocation(id) {
    environment.production ? '' : console.log(id);
    let obj = this.ownItemProdBinList.find(x => x.Value == id);
    this.itemData.availableqty = obj.Value2;
    this.getSerial();
    this.filterBinLocations();
  }
  checkQty() {
    if (this.itemData.qtycount > this.itemData.availableqty) {
      this.toastr.Error('Transfer qty should not be greater than onhand qty.')
      this.itemData.qtycount = 0;
    }
  }

  postTransferRequest() {
    if (this.itemData.isserilized) {
      if (!this.itemData.serial || this.itemData.serial == undefined) {
        this.toastr.Error('Please select serial#')
        return false
      }
    }
    if (this.itemData.availableqty == 0) {
      this.toastr.Error('Transfer qty should not be greater than onhand qty.')
      return false
    }
    if (this.itemData.qtycount == 0) {
      return false
    }
    var data = {
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "companyid": this.userdata.companyid,
      "prodid": this.fromParent.id,
      "tiid": this.itemData.tiid,
      "module": this.itemData.module,
      "username": this.userdata.username,
      "createby": this.userdata.email,
      "tobinlocation": this.itemData.tobinlocation,
      "frombinlocation": this.itemData.frombinlocation,
      "qty": this.itemData.qty,
      "qtycount": this.itemData.qtycount ? this.itemData.qtycount : 1,
      "action": this.itemData.action,
      "msg": this.itemData.msg,
      "ttype": 4,//it means transfer
      "refid": this.itemData.refid,
      "serial": this.itemData.serial,
      "ilc": this.itemData.ilc,
      "description": this.itemData.description,
      "isserilized": this.itemData.isserilized,
      "customerid": this.itemData.customerid,
      "adjusttype": this.itemData.adjusttype,
      "qty2pick": this.itemData.qty2pick,
      "uom": this.itemData.uom,
    }
    this.loader.start();
    this.service.postTransferHoldAdjust(data).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
      if (!this.itemData.isserilized) {
        this.getOwnBinLocation()
        this.closemodal()

      } else {
        this.getOwnBinLocation()
        this.itemData.frombinlocation = '';
        this.itemData.tobinlocation = '';
        this.itemData.serial = '';
        this.itemData.availableqty = 0
      }
      environment.production ? '' : console.log('post product response ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });

  }
  updateQtyOnLocation() {

  }


  open() {
    var modalRef = null;
    this.fromParent.binid = this.itemData.frombinlocation;
    modalRef = this.modalService.open(SerialComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
    modalRef.componentInstance.fromParent = this.fromParent;
    modalRef.componentInstance.actoin = 'add';
    environment.production ? '' : console.log('id', modalRef.componentInstance.id);
    modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.getSerial();
      environment.production ? '' : console.log('closed');
    }, (reason) => {
    });
  }
}
