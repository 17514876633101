import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { HomepageComponent } from '../../homepage/homepage.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-accountdetails',
  templateUrl: './accountdetails.component.html',
  styleUrls: ['./accountdetails.component.scss']
})
export class AccountdetailsComponent implements OnInit {
  public isCollapsed = true;
  fullname:any;
  email:any
  password:any;
  public createAccount: FormGroup;
  private langChangeSubscription: Subscription; 
  constructor(private router: Router, private homepage:HomepageComponent,private formBuilder: FormBuilder,
    private translate: TranslateService, 
private languageService: LanguageService, 
private http: HttpClient, ) { 
    this.createAccountForm();
  }
  createAccountForm(){
    this.createAccount = this.formBuilder.group({
      fullname : [''],
      email : [''],
      password : [''],
      
    })
  }


  ngOnInit(): void {
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
     
  }
  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/sales/customers/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
  }
  isPasswordToggle = false
  PasswordToggle() {
    this.isPasswordToggle = !this.isPasswordToggle
  }
  gotochooseindustry() {
    this.router.navigateByUrl('/public/chooseindustry');
    // if(!this.fullname){
    //   Swal.fire('Please enter your full name');
    //   return false;
    // }
    // if(!this.email){
    //   Swal.fire('Please enter your email id');
    //   return false;
    // }
    // if(!this.password){
    //   Swal.fire('Please enter your password');
    //   return false;
    // }
    // var data = {
    //   fullname: this.fullname,
    //   email: this.email,
    //   password: this.password
    // }
    // environment.production ? '' : console.log(data)
    // localStorage.setItem('chooseIndustryData', JSON.stringify(data));
    // let formValue = JSON.parse(localStorage.getItem('chooseIndustryData'))
    // environment.production ? '' : console.log('choose Industry Data =' + formValue)
    // this.router.navigateByUrl('/public/chooseindustry');
  }
  gotoAbout() {
    this.router.navigate(['/public/index'], {queryParams: {action : 'aboutus'}});
  }
  gotoHome() {
    this.router.navigate(['/public/index']);
  }
  gotoOverview() {
    this.router.navigate(['/public/index'], {queryParams: {action : 'overview'}});
  }
  gotoFeature(){
    this.router.navigate(['/public/index'], {queryParams: {action : 'feature'}});
  }
  gotoPricing(){
    this.router.navigate(['/public/index'], {queryParams: {action : 'pricing'}});
  }
  gotoContact(){
    this.router.navigate(['/public/index'], {queryParams: {action : 'contact'}});
  }
}
