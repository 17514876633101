import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InventoryServices } from '../../../inventory.service';
import { SerialComponent } from '../serial/serial.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-product-bin',
  templateUrl: './product-bin.component.html',
  styleUrls: ['./product-bin.component.scss']
})
export class ProductBinComponent implements OnInit {
  @Input() params;

  itemData: any = {}
  binList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  ownItemProdBinList: any = [];
  actionType: any = []
  closeResult: string;
  serialList: any = [];
  isSaved = false;
  setReadonly = false;
  binData: any;
  isView = false;
  action: any;
  private langChangeSubscription: Subscription; 
  constructor(private activeModal: NgbActiveModal,
    private service: InventoryServices,
    private datepipe: DatePipe,
    private modalService: NgbModal,
    private commonService: CommonServices,
    private toastr: NotificationService,
    private loader: NgxUiLoaderService,
    private translate: TranslateService, 
private languageService: LanguageService, 
private http: HttpClient, 
 
    ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.service.getBinLocationList().subscribe(res => {
      this.binList = res;
    }, err => {
    });
  }

  ngOnInit(): void {
    this.action = this.params.action;
    this.isView = this.action == 'view' ? true : false;
    this.setReadonly = this.action == 'add' ? false : true;
    this.binData = this.params;
    this.getBinLocationById();
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
  }
  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/inventory/productitems/binlocations/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
  getBinLocationById() {
    if (!this.binData.id) {
      this.binData.active = true;
      this.binData.minqty=0;
      this.binData.maxqty=0;
      this.binData.reorderpoint=0;
      this.binData.reorderqty=0;
      return;
    }
    this.loader.start();
    this.service.getBinLocationById(this.binData.id).subscribe(res => {
      this.loader.stop();
      this.binData = res;
      this.binData.binid = '' + this.binData.binid;
    }, err => {
      this.loader.stop();
    })
  }

  closemodal() {
    this.activeModal.close(this.isSaved ? 'save' : '');
  }
  addBin() {
    this.binData.companyid = this.userdata.companyid;
    if (this.binData.id) {
      this.binData.createby = this.binData.createby;
      this.binData.createdate = this.datepipe.transform(this.binData.createdate, this.userdata.postdatetimeformat);
      this.binData.updateby = this.userdata.email;
      this.binData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    else {
      this.binData.prodid = this.binData.prodid;
      this.binData.createby = this.userdata.email;
      this.binData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.loader.start();
    this.service.postBinLocation(this.binData).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isSaved = true;
        this.closemodal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product bin location ', err);
    });
  }
}
