import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { purchaseServices } from '../../purchase/purchase.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-itemcodelist',
  templateUrl: './itemcodelist.component.html',
  styleUrls: ['./itemcodelist.component.scss']
})
export class ItemcodelistComponent implements OnInit {
  fullScreen = false;
  userdata: any;
  appname = environment.APP_NAME;
  codeList: any = [];
  serialno: any = '';
  cpcCodeLst: any = [];
  constructor(private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private service: purchaseServices,) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  getCodeList() {
    this.loader.start();
    this.service.getCodes(this.serialno).subscribe(res => {
      this.loader.stop();
      this.codeList = res;
      environment.production ? '' : console.log('codeList data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading codeList data', err);
    });
  }


  ngOnInit(): void {

  }

  closeModal(item) {
    var code = { code: item };
    this.activeModal.close(code);
  }
  select(item) {

  }
}
