import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { hrServices } from '../../hr/hr.services';
import { CommonServices } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationModalComponent implements OnInit {
  fullScreen = false;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  modalRef: any
  userdata: any;
  appname = environment.APP_NAME;
  authorizedby: any;
  authorizationnotes: any;
  employeeList: any = [];
  issaved = false;
  req: any = { authorizedby: null, authorizationnotes: '' };
  constructor(private activeModal: NgbActiveModal, private commonService: CommonServices, private hrService: hrServices,
    private toastr: NotificationService) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.companyLogo += this.userdata.logo;
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block
    this.getEmployees();
  }

  ngOnInit(): void {
  }
  getEmployees() {
    this.commonService.getGetSalesApprovers().subscribe(res => {
      this.employeeList = res;
    }, err => {
    });
  }
  submit() {
    if (!this.req.authorizedby) {
      this.toastr.Error('Please select authorizedby');
      return;
    }
    this.issaved = true;
    this.closeModal('save');
  }

  closeModal(action) {
    this.activeModal.close(action == 'save' ? { 'authorizedby': this.req.authorizedby, 'authorizationnotes': this.req.authorizationnotes } : action);
  }

}
