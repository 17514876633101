<div class="main-card">
    <div class="row">
      <div class="col-sm-12 position-relative">
        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="12">
          <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.latitude" [longitude]="m.longitude"
            [label]="m.user_code">
          </agm-marker>
        </agm-map>
      </div>
    </div>
  </div>