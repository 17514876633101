<div class="row">
  <div class="col-sm-12">
    <div class="clearfix"></div>
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                data-toggle="modal" data-original-title="Add" container="body" ngbTooltip="{{ 'Table_Columns.Compatibility_Tooltip' | translate }}"
                [class.disabled]="fieldsetDisabled" data-target="#exampleModal" (click)="open(content)"><i
                  class="fa fa-plus"></i>
              </a></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'name')">
              {{ 'Table_Columns.Name_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'model')" style="width: 8%;">
              {{ 'Table_Columns.Model_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'serial')" style="width: 8%;">
              {{ 'Table_Columns.Serial_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'make')" style="width: 8%;">
              {{ 'Table_Columns.Make_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'manufacturer')" style="width: 8%;">
              {{ 'Table_Columns.Manufacturer_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'description')" style="width: 15%;">
              {{ 'Table_Columns.Description_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'createby')">
              {{ 'Table_Columns.CreateBy_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'createdate')">
              {{ 'Table_Columns.CreateDate_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'updateby')">
              {{ 'Table_Columns.UpdateBy_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'updatedate')">
              {{ 'Table_Columns.UpdateDate_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
            </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of deviceList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled" (click)="delete(item.id)">{{ 'Table_Actions.Delete_Action' | translate }}</span>
                  </li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.name}}</td>
            <td class="custom-td text-left">{{item.model}}</td>
            <td class="custom-td text-left">{{item.serial}}</td>
            <td class="custom-td text-left">{{item.make}}</td>
            <td class="custom-td text-left">{{item.manufacturer}}</td>
            <td class="custom-td text-left">{{item.description}}</td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.createdate |
              date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.updatedate |
              date:userdata.displaydatetimeformat}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
      <div #header class="modal-header">
        <h4 class="modal-title" >{{ 'Modal_Title' | translate }}</h4>
        <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
          <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
          <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
        </button>
        <div>
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
          <button type="button" class="close"></button>
        </div>
      </div>
      <div class="modal-body">
        <div class="d-flex align-items-bottom">
          <div class="form-row">
            <div class="col-lg-3 col-md-6 form-group">
              <label for="">{{ 'Modal_Inputs.Name_Label' | translate }}</label>
              <input type="search" class="form-control" [(ngModel)]="filter.name" name="name" placeholder="{{ 'Modal_Inputs.Name_Placeholder' | translate }}">
            </div>
            <div class="col-lg-3 col-md-6 form-group">
              <label for="">{{ 'Modal_Inputs.ILC_Label' | translate }}</label>
              <input type="search" class="form-control" [(ngModel)]="filter.ilc" name="ilc" placeholder="{{ 'Modal_Inputs.ILC_Placeholder' | translate }}">
            </div>
            <div class="col-lg-3 col-md-6 form-group">
              <label>{{ 'Modal_Inputs.Type_Label' | translate }}</label>
              <select class="form-control" name="type" class="form-control" [(ngModel)]="filter.type"
                (change)="getCompanyDevices()">
                <option value="">{{ 'Modal_Inputs.ILC_Placeholder' | translate }}</option>
                <option *ngFor="let item of deviceTypes" [value]="item.Value">{{item.Text}}</option>
              </select>
            </div>
            <div class="col-lg-3 col-md-6 form-group">
              <label>{{ 'Modal_Inputs.Active_Label' | translate }}</label>
              <select class=" form-control" [(ngModel)]="filter.active" name="active" (change)="getCompanyDevices()">
                <option value="">{{ 'Modal_Inputs.Active_Placeholder' | translate }}</option>
                <option [value]="true">Yes</option>
                <option [value]="false">No</option>
              </select>
            </div>
          </div>
          <button class="btn btn-primary modal-button" (click)="getCompanyDevices()">
            <i class="fa fa-search"></i>
          </button>
        </div>
        
        <div class="category-table custom-datatable mb-3">
          <div class="table-responsive2">
            <table class="custom-table">
              <thead class="sticky-tab-head">
                <tr class="custom-tr">
                  <th class="custom-th text-center">
                    <!-- <input type="checkbox" name="maincheckbox" [(ngModel)]="maincheckbox"> -->
                  </th>
                  <th class="custom-th text-center">{{ 'Modal_Columns.ILCBarcode_Column' | translate }}</th>
                  <th class="custom-th text-center">{{ 'Modal_Columns.Serial_Column' | translate }}</th>
                  <th class="custom-th text-center">{{ 'Modal_Columns.Model_Column' | translate }}</th>
                  <th class="custom-th text-center">{{ 'Modal_Columns.Name_Column' | translate }}</th>
                  <th class="custom-th text-center">{{ 'Modal_Columns.Type_Column' | translate }}</th>
                  <th class="custom-th text-center">{{ 'Modal_Columns.Class_Column' | translate }}</th>
                  <th class="custom-th text-center">{{ 'Modal_Columns.Manufacturer_Column' | translate }}</th>
                  <th class="custom-th text-center">{{ 'Modal_Columns.ThirdPartyCode_Column' | translate }}</th>
                  <th class="custom-th text-center">{{ 'Modal_Columns.Active_Column' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="custom-tr" *ngFor="let item of companyDevices;let i=index">
                  <td class="custom-td text-center">
                    <input type="checkbox" name="checked-{{i}}" [(ngModel)]="item.checked" (change)="postDevice(item)">
                  </td>
                  <td class="custom-td text-left">
                    <span (click)="gotoEdit(item)" style="color:blue;cursor: pointer;">{{item.ilc}}</span>
                  </td>
                  <td class="custom-td text-left">{{item.serial}}</td>
                  <td class="custom-td text-left">{{item.model}}</td>
                  <td class="custom-td text-left">
                    <div class="csstooltip position-relative">
                      <div class="show-less position-relative" container="body">{{item.name ? item.name: ''}}
                      </div> <span class="tooltiptext">{{item.name}}</span>
                    </div>
                  </td>
                  <td class="custom-td text-left">{{item.type}}</td>
                  <td class="custom-td text-left">{{item.device_class}}</td>
                  <td class="custom-td text-left">{{item.manufacturer}}</td>
                  <td class="custom-td text-left">{{item.tpcode}}</td>
                  <td class="custom-td text-center"><span class="badge"
                      title="{{item.isactive}}">{{item.isactive}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center" *ngIf="companyDevices.length==0">
            <p style="color: gray;">No Devices Found</p>
          </div>
        </div>
      </div>
    </div>
  </resize-border>
</ng-template>