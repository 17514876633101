<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Service Log</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" [style.pointer-events]="action=='view'?'none':''">
      <form class="needs-validation" name="from" #f="ngForm">
        <!-- <h4 class="text-center">Action : {{lastaction}}</h4> -->
        <div class="form row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1">Description</label>
            <input type="text" class="form-control" id="description" name="description"
              [ngModelOptions]="{standalone: true}" [(ngModel)]="asset.description" #description="ngModel"
              [ngClass]="{ 'is-invalid': description.invalid && description.errors , 'is-valid': description.valid}"
              required>
            <div *ngIf="description.invalid && description.errors" class="invalid-feedback d-block">
              <div *ngIf="description.errors.required">
                Please enter description
              </div>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1">Type</label>
            <select class="form-control" [(ngModel)]="asset.type" [ngModelOptions]="{standalone: true}" #type="ngModel"
              [ngClass]="{ 'is-invalid': type.invalid && type.errors , 'is-valid': type.valid}" required>
              <option value="">Select type</option>
              <option *ngFor="let item of assetServiceTypeList" [value]="item.stringValue">{{item.Text}}</option>
            </select>
            <div *ngIf="type.invalid && type.errors" class="invalid-feedback d-block">
              <div *ngIf="type.errors.required">
                Please select type
              </div>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1">Labour</label>
            <input class="form-control" id="" type="number" [(ngModel)]="asset.labor"
              [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1">Parts</label>
            <input class="form-control" id="" type="number" [(ngModel)]="asset.parts"
              [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1">Date of service</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker8" name="dos" [(ngModel)]="asset.dos" #dos="ngModel"
                [ngClass]="{ 'is-invalid': dos.invalid && dos.errors , 'is-valid': dos.valid}" required>
              <mat-datepicker-toggle matSuffix [for]="picker8"></mat-datepicker-toggle>
              <mat-datepicker #picker8></mat-datepicker>
            </mat-form-field>
            <div *ngIf="dos.invalid && dos.errors" class="invalid-feedback d-block">
              <div *ngIf="dos.errors.required">
                Please enter date of service
              </div>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1">Next service date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker9" name="nextdue" [(ngModel)]="asset.nextdue">
              <mat-datepicker-toggle matSuffix [for]="picker9"></mat-datepicker-toggle>
              <mat-datepicker #picker9></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1">Serviceby</label>
            <input class="form-control" id="Serviceby" type="text" [(ngModel)]="asset.serviceby"
              [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1">To Location</label>
            <select class="form-control" [(ngModel)]="asset.locationid" name="locationid"
              [ngModelOptions]="{standalone: true}">
              <option value="">Select location</option>
              <option *ngFor="let item of locationList" [value]="item.Value">{{item.Text}}</option>
            </select>
          </div>


          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1">Condition</label>
            <select class="form-control" [(ngModel)]="asset.condition" [ngModelOptions]="{standalone: true}">
              <option value="">Select condition</option>
              <option *ngFor="let item of conditionList" [value]="item.stringValue">{{item.Text}}</option>
            </select>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="" class="mb-1">Status</label>
            <select class="form-control" [(ngModel)]="asset.status" name="status" [ngModelOptions]="{standalone: true}"
              #status="ngModel" [ngClass]="{ 'is-invalid': status.invalid && status.errors , 'is-valid': status.valid}"
              required>
              <option value="">Select status</option>
              <option *ngFor="let item of assetServiceStatusList" [value]="item.stringValue">{{item.Text}}</option>
            </select>
            <div *ngIf="status.invalid && status.errors" class="invalid-feedback d-block">
              <div *ngIf="status.errors.required">
                Please select status
              </div>
            </div>
          </div>
          <div class="form-group col-lg-12 col-md-6 col-sm-12">
            <label for="" class="mb-1">Notes</label>
            <textarea name="notes" [(ngModel)]="asset.notes" class="form-control" rows="3"></textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary" [disabled]="!f.valid || isView"
        (click)="postServiceLog()">Save</button>
    </div>
  </div>
</resize-border>