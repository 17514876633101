import { Component, ViewChild } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild("screen", { static: true }) screen: any;
  title = 'Ezybooks';
  constructor(private bnIdle: BnNgIdleService) {

  }

  ngOnInit() {
    this.bnIdle.startWatching(30).subscribe((res: boolean) => {
      if (res) {
        environment.production ? '' : console.log('session expired');
      }
    });
  }

}
