import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InventoryServices } from '../../../inventory.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/shared/service/language.service';

@Component({
  selector: 'app-transferserials',
  templateUrl: './transferserials.component.html',
  styleUrls: ['./transferserials.component.scss']
})
export class TransferserialsComponent implements OnInit {
  @Input() fromParent;
  itemData: any = {}
  allBinList: any = []
  ownItemProdBinList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  closeResult: string;
  serial: any = {}
  serialList: any = [];
  selectall: any = false;
  private langChangeSubscription: Subscription; 
  constructor(private activeModal: NgbActiveModal,
    private service: InventoryServices,
    private datepipe: DatePipe,
    private modalService: NgbModal,
    private toastr: NotificationService,
    private loader: NgxUiLoaderService,
    private translate: TranslateService, 
private languageService: LanguageService, 
private http: HttpClient, ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    //get own item prod bin
  }


  ngOnInit(): void {
    environment.production ? '' : console.log('item data', this.fromParent);
    this.service.getTransferQty(this.fromParent.id).subscribe(res => {
      this.loader.stop();
      this.itemData = res;
      if (this.itemData.isserilized) {
        this.itemData.qtycount = 1;
      }
      this.itemData.frombinlocation = null;
      this.itemData.tobinlocation = null;
      this.itemData.serial = ''
      environment.production ? '' : console.log('transfer response ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });
    this.getOwnBinLocation();
    this.getBinLocation();
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
  }
  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/sales/customers/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
  getOwnBinLocation() {
    this.service.getProductBin(this.fromParent.id).subscribe(res => {
      this.ownItemProdBinList = res;
      environment.production ? '' : console.log(' bin list data ', this.ownItemProdBinList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }
  getBinLocation() {
    this.service.getBinLocationList().subscribe(res => {
      this.allBinList = res;
      environment.production ? '' : console.log(' bin list data ', this.allBinList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }
  onAllChange() {
    if (this.selectall) {
      this.serialList.forEach(srlno => {
        srlno.checked = true;
      })
    } else {
      this.serialList.forEach(srlno => {
        srlno.checked = false;
      })
    }
  }
  onChange() {
    this.selectall = true;
    this.serialList.forEach(srlno => {
      if (!srlno.checked) {
        this.selectall = false;
      }
    })
  }
  getSerial() {
    this.service.getSerialListUsingBin(this.itemData.frombinlocation, this.fromParent.id).subscribe(res => {
      this.serialList = res;
      this.serialList.forEach(srlno => {
        srlno.checked = false;
      })
      environment.production ? '' : console.log('serial list data ', this.serialList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }
  closemodal() {
    this.activeModal.close('Modal Closed');
  }
  selectOwnBinLocation(id) {
    environment.production ? '' : console.log(id);
    let obj = this.ownItemProdBinList.find(x => x.Value == id);
    this.itemData.availableqty = obj.Value2;
    this.getSerial();
    environment.production ? '' : console.log(obj);
  }
  checkQty() {
    if (this.itemData.qtycount > this.itemData.availableqty) {
      this.toastr.Error('Transfer qty should not be greater than onhand qty.')
      this.itemData.qtycount = 0;
    }
  }

  postTransferRequest() {
    var serials = [];
    this.serialList.forEach(srlno => {
      if (srlno.checked) {
        serials.push(srlno.Value1);
      }
    })
    if (serials.length == 0) {
      this.toastr.Error('Please select serial no')
      return false
    }
    var data = {
      "createby": this.userdata.email,
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "companyid": this.userdata.companyid,
      "prodid": this.fromParent.id,
      "username": this.userdata.username,
      "tobinlocation": this.itemData.tobinlocation,
      "frombinlocation": this.itemData.frombinlocation,
      "action": this.itemData.action,
      "msg": this.itemData.msg,
      "ttype": 4,//it means transfer
      "ilc": this.itemData.ilc,
      "description": this.itemData.description,
      "isserilized": this.itemData.isserilized,
      "serialIds": serials,
    }
    this.loader.start();
    this.service.postProdMoveSerials(data).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
      this.closemodal();
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });
  }
}
