import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { InventoryServices } from '../../../inventory.service';
import { AddProductComponent } from '../add-product.component';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-tax',
	templateUrl: './tax.component.html',
	styleUrls: ['./tax.component.scss']
})
export class TaxComponent implements OnInit {
	@ViewChild('clearDateField') clearDateField: ElementRef;

	taxList: any;
	fieldsetDisabled: boolean;
	taxData: any = {
		taxid: '',
		active: true
	};
	userdata: any;
	appname = environment.APP_NAME;
	closeResult: string;
	format: any;
	taxtypeList: any;

	action: any = '';
	product: any;
	totalLeaves: number;
	lastaction: any;

	constructor(
		private modalService: NgbModal,
		private datepipe: DatePipe,
		private toastr: NotificationService,
		private service: InventoryServices,
		private commonService: CommonServices,
		private mainComponent: AddProductComponent,
		private loader: NgxUiLoaderService,
		private route: ActivatedRoute
	) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.format = this.userdata.date_format;
		MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();

		this.product = mainComponent.product;
		// this.taxData.todate = this.datepipe.transform(new Date(), 'yyyy-MM-dd')

		this.commonService.getTaxList('sales').subscribe(res => {
			this.taxtypeList = res;
			environment.production ? '' : console.log('type taxes list data ', this.taxtypeList);
		}, err => {
			environment.production ? '' : console.log('Error : While loading expType list ', err);
		});
		this.getInventoryTax(this.product.id);
		this.lastaction = this.route.snapshot.queryParamMap.get('action');

		if (this.lastaction == 'view') {
			this.fieldsetDisabled = true;
		} else if (this.lastaction == 'edit') {
			this.fieldsetDisabled = false;
		}
	}

	ngOnInit(): void {
	}

//#region Sorting Table Header
sortDir = 1;//1= 'ASE' -1= DSC
onSortClick(event,colum) {
	let target = event.currentTarget,
		childtarget = target.querySelector('i'),
		classList = childtarget.classList;

	if (classList.contains('fa-chevron-up')) {
		classList.remove('fa-chevron-up');
		classList.add('fa-chevron-down');
		target.classList.add('Sorted');
		this.sortDir = -1;
	} else {
		classList.add('fa-chevron-up');
		classList.remove('fa-chevron-down');
		target.classList.remove('Sorted');
		this.sortDir = 1;
	}
	this.sortArr(colum);
}

sortArr(colName: any) {
	this.taxList.sort((a, b) => {
		let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
		let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

if (!valueA && !valueB) {
	return 0; 
}
if (!valueA) {
	return -this.sortDir; 
}
if (!valueB) {
	return this.sortDir; 
}

if (valueA > valueB) {
	return this.sortDir;
}
if (valueA < valueB) {
	return -this.sortDir;
}
return 0;
});
}

//#endregion Sorting Table Header


	addTax(content, item, action) {
		if (action == 'view') {
			this.action = 'view';
		} else {
			this.action = '';
		}
		if (item != null) {
			this.getInventoryTaxByID(item.id)
		} else {
			// this.taxData = {
			//   taxid:'',
			//   active:true
			// };
		}
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			// this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
	closeModal() {
		this.modalService.dismissAll();
		this.taxData = {
			taxid: '',
			active: true
		};
	}
	saveTax() {
		if (this.taxData.id != undefined && this.taxData.id != null) {
			this.taxData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
			this.taxData.updateby = this.userdata.email;
			this.taxData.createby = this.taxData.createby;
			this.taxData.createdate = this.datepipe.transform(this.taxData.createdate, this.userdata.postdatetimeformat);
		} else {
			this.taxData.createby = this.userdata.email;
			this.taxData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		}
		this.taxData.companyid = this.userdata.companyid;
		this.taxData.prodid = this.product.id;
		this.taxData.effective_from = this.datepipe.transform(this.taxData.effective_from, 'yyyy-MM-dd')
		this.taxData.effective_to = this.datepipe.transform(this.taxData.effective_to, 'yyyy-MM-dd')
		environment.production ? '' : console.log('taxData post ' + this.taxData);
		// return false
		this.loader.start();
		this.service.postInventoryTax(this.taxData).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				this.getInventoryTax(this.product.id);
				this.taxData = {
					taxid: '',
					active: true
				};
				this.modalService.dismissAll();
			}
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While post taxData details ', err);
		});
	}
	getInventoryTax(id) {
		this.loader.start();
		this.service.getInventoryTax(id).subscribe(res => {
			this.loader.stop();
			this.taxList = res;
			environment.production ? '' : console.log('taxList index List ', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading taxList index list ', err);
		});
	}
	getInventoryTaxByID(id) {
		this.loader.start();
		this.service.getInventoryTaxByID(id).subscribe(res => {
			this.loader.stop();
			this.taxData = res;
			environment.production ? '' : console.log('taxData by id List ', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading taxData by id list ', err);
		});
	}

	updateCalcs() {
		var a = moment(this.taxData.effective_from, "YYYY-MM-DD");
		var b = moment(this.taxData.effective_to, "YYYY-MM-DD");
		this.totalLeaves = moment.duration(b.diff(a)).asDays();
		if (this.totalLeaves) {
			this.checkDate()

		}

	}
	checkDate() {
		var startDate = this.datepipe.transform(this.taxData.effective_from, ('dd/MM/yyyy'));
		var endDate = this.datepipe.transform(this.taxData.effective_to, ('dd/MM/yyyy'));
		var regExp = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/;
		if (parseInt(endDate.replace(regExp, "$3$2$1")) > parseInt(startDate.replace(regExp, "$3$2$1"))) {
			environment.production ? '' : console.log('start date is greater than end date');
		} else {
			this.taxData.effective_to = null
			this.totalLeaves = 0;
			this.toastr.Error("End date should be greater than start date")
			this.clearDateField.nativeElement.value = '';
			return false
		}
	}
	deleteTax(id) {
		Swal.fire({
			title: 'Do you want to delete item tax?',
			// text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				this.loader.start();
				this.service.deleteTemTax(id).subscribe((res: any) => {
					this.loader.stop();
					if (res != null) {
						if (res.status_code == "1") {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
						} else {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
							return;
						}
						this.getInventoryTax(this.product.id);
					}
				}, err => {
					this.loader.stop();
					environment.production ? '' : console.log('Error : While delete product ', err);
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}
}
