import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { InventoryServices } from 'src/app/components/inventory/inventory.service';
import { environment } from 'src/environments/environment';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AddProductComponent } from '../add-product.component';
import { purchaseServices } from 'src/app/components/purchase/purchase.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pohistory',
  templateUrl: './pohistory.component.html',
  styleUrls: ['./pohistory.component.scss']
})
export class PohistoryComponent implements OnInit {
  public closeResult: string;
  public categories = []
  product: any;
  historyList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  totalQty: any = 0;
  totalShippedQty: any = 0;
  totalReceivedqty: any = 0;
  totalDamageqty: any = 0;
  totalOverageqty: any = 0;
  filter: any = {
    searchtxt: '',
    fromdate: '',
    todate: ''
  };
  totalPOCost: any = 0;
  constructor(private modalService: NgbModal,
    private loader: NgxUiLoaderService,
    private datepipe: DatePipe,
    private maincomponent: AddProductComponent,
    private purchaseService: purchaseServices,
    private service: InventoryServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    this.categories = categoryDB.category;
    this.product = this.maincomponent.product;
    this.getProductOrders();
  }

  ngOnInit(): void {

  }
  sortDir = 1;//1= 'ASE' -1= DSC
	onSortClick(event,colum) {
		let target = event.currentTarget,
			childtarget = target.querySelector('i'),
			classList = childtarget.classList;

		if (classList.contains('fa-chevron-up')) {
			classList.remove('fa-chevron-up');
			classList.add('fa-chevron-down');
			target.classList.add('Sorted');
			this.sortDir = -1;
		} else {
			classList.add('fa-chevron-up');
			classList.remove('fa-chevron-down');
			target.classList.remove('Sorted');
			this.sortDir = 1;
		}
		this.sortArr(colum);
	}

	sortArr(colName: any) {
		this.historyList.sort((a, b) => {
			let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
			let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

	if (!valueA && !valueB) {
		return 0; 
	}
	if (!valueA) {
		return -this.sortDir; 
	}
	if (!valueB) {
		return this.sortDir; 
	}

	if (valueA > valueB) {
		return this.sortDir;
	}
	if (valueA < valueB) {
		return -this.sortDir;
	}
	return 0;
});
	}

	//#endregion Sorting Table Header

  getProductOrders() {
    this.loader.start();
    this.purchaseService.getPurchaseRequestItemHistory(this.product.id,
      this.filter.searchtxt,
      this.datepipe.transform(this.filter.fromdate, this.userdata.postdateformat),
      this.datepipe.transform(this.filter.todate, this.userdata.postdateformat),
    ).subscribe(res => {
      this.loader.stop();
      this.historyList = res;
      this.totalQty = 0;
      this.totalShippedQty = 0;
      this.totalReceivedqty = 0;
      this.totalPOCost = 0;
      this.historyList.forEach(po => {
        this.totalQty += po.qty;
        this.totalShippedQty += po.shippedqty ? Number(po.shippedqty) : 0;
        this.totalReceivedqty += po.receivedqty;
        this.totalPOCost += po.cost ? Number(po.cost) : 0;
        this.totalDamageqty += po.damageqty ? Number(po.damageqty) : 0;
        this.totalOverageqty += po.overageqty ? Number(po.overageqty) : 0;
        po.cost = po.cost ? Number(po.cost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        po.shippedqty = po.shippedqty ? Number(po.shippedqty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        po.receivedqty = po.receivedqty ? Number(po.receivedqty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        po.damageqty = po.damageqty ? Number(po.damageqty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        po.overageqty = po.overageqty ? Number(po.overageqty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        po.cost = po.cost ? Number(po.cost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      })
      this.totalQty = this.totalQty ? Number(this.totalQty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.totalPOCost = this.totalPOCost ? Number(this.totalPOCost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.totalShippedQty = this.totalShippedQty ? Number(this.totalShippedQty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.totalReceivedqty = this.totalReceivedqty ? Number(this.totalReceivedqty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.totalDamageqty = this.totalDamageqty ? Number(this.totalDamageqty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.totalOverageqty = this.totalOverageqty ? Number(this.totalOverageqty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    }, err => {
      this.loader.stop();
    });
  }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
