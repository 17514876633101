import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-serialsmodal',
  templateUrl: './serialsmodal.component.html',
  styleUrls: ['./serialsmodal.component.scss']
})
export class SerialsmodalComponent implements OnInit {
  fullScreen = false;
  @Input('serials') serials;
  @Input('selectedSerial') selectedSerial;
  searchTxt: any;
  serialList: any = [];
  filteredSerials: any = [];
  isSaved = false;
  constructor(private activemodal: NgbActiveModal) { }

  ngOnInit(): void {
    var selectedSrls = this.selectedSerial != '' ? this.selectedSerial.split(',') : [];
    var srls = this.serials.split(',');
    this.serialList = [];
    srls.forEach(item => {
      var isFound = false;
      selectedSrls.forEach(srlno => {
        if (item == srlno) {
          isFound = true;
        }
      })
      this.serialList.push({
        checked: isFound,
        srno: item
      })
    })
    this.filteredSerials = this.serialList;
  }
  onSearch() {
    if (this.searchTxt == undefined) {
      return;
    }
    this.filteredSerials = this.serialList.filter(item => item.srno.toLowerCase().includes(this.searchTxt.toLowerCase()));
  }
  closeModal() {
    var srls;
    this.filteredSerials.forEach(item => {
      if (item.checked) {
        if (srls == undefined) {
          srls = item.srno;
        } else {
          srls = srls + ',' + item.srno;
        }
      }
    })
    var finalSrls = this.isSaved ? srls : ''
    this.activemodal.close(finalSrls);
  }
}
