<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <form class="needs-validation" name="form" #f="ngForm" (keydown.enter)="$event.preventDefault()">

            <div #header class="modal-header">
                <h5 class="modal-title f-w-600" id="productModalLabel">{{ 'Offer_Details_Modal.Modal_Title' | translate
                    }} {{offerData.id}}
                </h5>
                <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                    <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                    <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
                </button>
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form row">
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ 'Modal_Inputs.Name_Label' | translate }}</label>
                            <input type="text" name="empname" [(ngModel)]="offerData.name" class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ 'Modal_Inputs.Email_Label' | translate }}</label>
                            <input type="email" name="email" [(ngModel)]="offerData.email" class="form-control">
                            <input class="input-check" type="checkbox" name="personalEmailAsWorkEmail"
                                (change)="onChangePersonalEmailAsWork()" [(ngModel)]="personalEmailAsWorkEmail" value=""
                                id="flexCheckDefault">
                            <label for="">{{ 'Modal_Inputs.Personal_Email_As_Work_Email_Label' | translate }}</label>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ 'Modal_Inputs.Work_Email_Label' | translate }}</label>
                            <input type="work_email" name="work_email" [(ngModel)]="offerData.work_email"
                                class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ 'Modal_Inputs.Gender_Label' | translate }}</label>
                            <select type="gender" name="gender" [(ngModel)]="offerData.gender" class="form-control">
                                <option value="">{{ 'Modal_Inputs.Gender_Placeholder' | translate }}</option>
                                <option value="male">{{ 'Modal_Inputs.male_gender' | translate }}</option>
                                <option value="female">{{ 'Modal_Inputs.female_gender' | translate }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ 'Modal_Inputs.Offer_Type_Label' | translate }}</label>
                            <select class="form-control" name="offertp" [(ngModel)]="offerData.offer_type"
                                #offertp="ngModel"
                                [ngClass]="{ 'is-invalid': offertp.invalid && offertp.errors , 'is-valid': offertp.valid}"
                                required="">
                                <option value="">{{ 'Modal_Inputs.Offer_Type_Placeholder' | translate }}</option>
                                <option *ngFor="let type of offertype" [value]="type.stringValue">
                                    {{type.Text}}</option>
                            </select>
                            <div *ngIf="offertp.invalid && offertp.errors" class="invalid-feedback d-block">
                                <div *ngIf="offertp.errors.required">
                                    {{ 'Modal_Inputs.offer_type_required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for=""> {{ 'Modal_Inputs.Expected_DOJ_Label' | translate }}</label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker260" placeholder="Choose a date" name="expected_doj"
                                [(ngModel)]="offerData.expected_doj" #doj="ngModel"
                                [ngClass]="{ 'is-invalid': doj.invalid && doj.errors , 'is-valid': doj.valid}"
                                required="">
                            <mat-datepicker-toggle matSuffix [for]="picker260"></mat-datepicker-toggle>
                            <mat-datepicker #picker260></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="doj.invalid && doj.errors" class="invalid-feedback d-block">
                            <div *ngIf="doj.errors.required">
                                {{ 'Modal_Inputs.expected_doj_required' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ 'Modal_Inputs.Contract_Label' | translate }}</label>
                            <select class="form-control" name="contractid" [(ngModel)]="offerData.contractid"
                                #contractid="ngModel"
                                [ngClass]="{ 'is-invalid': contractid.invalid && contractid.errors , 'is-valid': contractid.valid}"
                                required="">
                                <option value="">{{ 'Modal_Inputs.Contract_Placeholder' | translate }}</option>
                                <option *ngFor="let type of contractList" [value]="type.Value">
                                    {{type.Text}}</option>
                            </select>
                            <div *ngIf="contractid.invalid && contractid.errors" class="invalid-feedback d-block">
                                <div *ngIf="contractid.errors.required">
                                    {{ 'Modal_Inputs.contract_required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for=""> {{ 'Modal_Inputs.Pay_Type_Label' | translate }}</label>
                            <select class="form-control" name="paytype" [(ngModel)]="offerData.paytype"
                                #paytype="ngModel"
                                [ngClass]="{ 'is-invalid': paytype.invalid && paytype.errors , 'is-valid': paytype.valid}"
                                required="">
                                <option value="">{{ 'Modal_Inputs.Pay_Type_Placeholder' | translate }}</option>
                                <option *ngFor="let type of paytypeList" [value]="type.stringValue">
                                    {{type.Text}}</option>
                            </select>
                            <div *ngIf="paytype.invalid && paytype.errors" class="invalid-feedback d-block">
                                <div *ngIf="paytype.errors.required">
                                    {{ 'Modal_Inputs.pay_type_required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for=""> {{ 'Modal_Inputs.Annual_Salary_Label' | translate }}</label>
                        <input type="number" class="form-control"
                            placeholder="{{ 'Modal_Inputs.Annual_Salary_Placeholder' | translate }}" name="salary"
                            [(ngModel)]="offerData.salary" #salary="ngModel"
                            [ngClass]="{ 'is-invalid': salary.invalid && salary.errors , 'is-valid': salary.valid}"
                            required="">
                        <div *ngIf="salary.invalid && salary.errors" class="invalid-feedback d-block">
                            <div *ngIf="salary.errors.required">
                                {{ 'Modal_Inputs.Annual_pay_required' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for="">{{ 'Modal_Inputs.Monthly_Salary_Label' | translate }}</label>
                        <input type="number" class="form-control"
                            placeholder="{{ 'Modal_Inputs.Monthly_Salary_Label' | translate }}" name="basicsalary"
                            [(ngModel)]="offerData.basic_salary" #basicsalary="ngModel"
                            [ngClass]="{ 'is-invalid': basicsalary.invalid && basicsalary.errors , 'is-valid': basicsalary.valid}"
                            required="">
                        <div *ngIf="basicsalary.invalid && basicsalary.errors" class="invalid-feedback d-block">
                            <div *ngIf="basicsalary.errors.required">
                                {{ 'Modal_Inputs.monthlysalary_required' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for=""> {{ 'Modal_Inputs.Work_Shift_Label' | translate }}</label>
                            <select class="form-control" name="workshiftid" [(ngModel)]="offerData.workshiftid"
                                #workshiftid="ngModel">
                                <option value="">{{ 'Modal_Inputs.Work_Shift_Placeholder' | translate }}</option>
                                <option *ngFor="let type of shiftList" [value]="type.Value">
                                    {{type.Text}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for="">{{ 'Modal_Inputs.Hours_Week_Label' | translate }}</label>
                        <input type="number" class="form-control" placeholder="Hours/week" name="hrsperweek"
                            [(ngModel)]="offerData.hrsperweek" #hrsperweek="ngModel"
                            [ngClass]="{ 'is-invalid': hrsperweek.invalid && hrsperweek.errors , 'is-valid': hrsperweek.valid}"
                            required="">
                        <div *ngIf="hrsperweek.invalid && hrsperweek.errors" class="invalid-feedback d-block">
                            <div *ngIf="hrsperweek.errors.required">
                                {{ 'Modal_Inputs.Hours_Week_Placeholder' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for=""> {{ 'Modal_Inputs.Paid_Off_Days_Label' | translate }}</label>
                        <input type="number" class="form-control"
                            placeholder="{{ 'Modal_Inputs.Paid_Off_Days_Placeholder' | translate }}"
                            name="paidoffdaysperyr" [(ngModel)]="offerData.paidoffdaysperyr" #paidoffdaysperyr="ngModel"
                            [ngClass]="{ 'is-invalid': paidoffdaysperyr.invalid && paidoffdaysperyr.errors , 'is-valid': paidoffdaysperyr.valid}"
                            required="">
                        <div *ngIf="paidoffdaysperyr.invalid && paidoffdaysperyr.errors"
                            class="invalid-feedback d-block">
                            <div *ngIf="paidoffdaysperyr.errors.required">
                                {{ 'Modal_Inputs.Paid_off_days_required' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-sm-6">
                        <label for=""> {{ 'Modal_Inputs.Paid_Sick_Days_Label' | translate }}</label>
                        <input type="number" class="form-control"
                            placeholder=" {{ 'Modal_Inputs.Paid_Sick_Days_Placeholder' | translate }}"
                            name="paidsickdaysperyr" [(ngModel)]="offerData.paidsickdaysperyr"
                            #paidsickdaysperyr="ngModel"
                            [ngClass]="{ 'is-invalid': paidsickdaysperyr.invalid && paidsickdaysperyr.errors , 'is-valid': paidsickdaysperyr.valid}"
                            required="">
                        <div *ngIf="paidsickdaysperyr.invalid && paidsickdaysperyr.errors"
                            class="invalid-feedback d-block">
                            <div *ngIf="paidsickdaysperyr.errors.required">
                                {{ 'Modal_Inputs.paid_sick_days_required' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-sm-6">
                        <label for=""> {{ 'Modal_Inputs.Regular_Rate_Label' | translate }}</label>
                        <input type="number" class="form-control"
                            placeholder="{{ 'Modal_Inputs.Regular_Rate_Placeholder' | translate }}" name="payrateperhr"
                            [(ngModel)]="offerData.payrateperhr" #payrateperhr="ngModel"
                            [ngClass]="{ 'is-invalid': payrateperhr.invalid && payrateperhr.errors , 'is-valid': payrateperhr.valid}"
                            required="">
                        <div *ngIf="payrateperhr.invalid && payrateperhr.errors" class="invalid-feedback d-block">
                            <div *ngIf="payrateperhr.errors.required">
                                {{ 'Modal_Inputs.Regular_rate_required' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ 'Modal_Inputs.Cost_Center_Label' | translate }}</label>
                            <select class="form-control" name="locationid" [(ngModel)]="offerData.locationid"
                                #locationid="ngModel"
                                [ngClass]="{ 'is-invalid': locationid.invalid && locationid.errors , 'is-valid': locationid.valid}"
                                required="">
                                <option value="">{{ 'Modal_Inputs.Cost_Center_Placeholder' | translate }}</option>
                                <option *ngFor="let type of locationList" [value]="type.id">
                                    {{type.name}}</option>
                            </select>
                            <div *ngIf="locationid.invalid && locationid.errors" class="invalid-feedback d-block">
                                <div *ngIf="locationid.errors.required">
                                    {{ 'Modal_Inputs.Cost_center_required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for=""> {{ 'Modal_Inputs.Designation_Label' | translate }}</label>
                            <select class="form-control" name="Designation" [(ngModel)]="offerData.designationid"
                                #designationid="ngModel"
                                [ngClass]="{ 'is-invalid': designationid.invalid && designationid.errors , 'is-valid': designationid.valid}"
                                required="">
                                <option value="">{{ 'Modal_Inputs.Designation_Placeholder' | translate }}</option>
                                <option *ngFor="let type of designationList" [value]="type.Value">
                                    {{type.Text}}</option>
                            </select>
                            <div *ngIf="designationid.invalid && designationid.errors" class="invalid-feedback d-block">
                                <div *ngIf="designationid.errors.required">
                                    {{ 'Modal_Inputs.Designation_required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for=""> {{ 'Modal_Inputs.Status_Label' | translate }}</label>
                            <select class="form-control" name="status" [(ngModel)]="offerData.status" #status="ngModel"
                                [ngClass]="{ 'is-invalid': status.invalid && status.errors , 'is-valid': status.valid}"
                                required="">
                                <option value="">{{ 'Modal_Inputs.Status_Placeholder' | translate }}</option>
                                <option *ngFor="let type of offerStatusList" [value]="type.stringValue">
                                    {{type.Text}}</option>
                            </select>
                            <div *ngIf="status.invalid && status.errors" class="invalid-feedback d-block">
                                <div *ngIf="status.errors.required">
                                    {{ 'Modal_Inputs.Status_required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for="">{{ 'Modal_Inputs.Date_Accepted_Label' | translate }}</label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker261" placeholder="Choose a date" name="date_accepted"
                                [(ngModel)]="offerData.date_accepted">
                            <mat-datepicker-toggle matSuffix [for]="picker261"></mat-datepicker-toggle>
                            <mat-datepicker #picker261></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-xl-12 col-md-12 col-sm-12">
                        <label for="">{{ 'Modal_Inputs.Rejection_Reason_Label' | translate }}</label>
                        <textarea class="form-control" rows="4" name="Rejection reason" name="rejectionreason"
                            [(ngModel)]="offerData.rejection_reason"></textarea>
                    </div>
                    <div class="form-group col-xl-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ 'Modal_Inputs.Salary_Components_Label' | translate }}</label>
                            <ng-select placeholder="{{ 'Modal_Inputs.Salary_Components_Placeholder' | translate }}"
                                id="selectedComponent" [(ngModel)]="offerData.selectedComponent"
                                #selectedComponent="ngModel" name="selectedComponent"
                                [clearable]="offerData.selectedComponent"
                                (change)="selectComponent(offerData.selectedComponent)">
                                <ng-option *ngFor="let item of componentList" [value]="item">
                                    {{item.Text}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="custom-datatable col-sm-12  table-responsive2">
                    <table class="input-table">
                        <thead>
                            <tr class="custom-tr">
                                <th class="custom-th text-center">{{ 'Salary_Components_Table.actions_Column1' |
                                    translate }}</th>
                                <th class="custom-th text-center">{{ 'Salary_Components_Table.type_Column2' | translate
                                    }}</th>
                                <th class="custom-th text-center">{{ 'Salary_Components_Table.description_column3' |
                                    translate }}</th>
                                <th class="custom-th text-center">{{ 'Salary_Components_Table.fixamount_Column4' |
                                    translate }}</th>
                                <th class="custom-th text-center">{{ 'Salary_Components_Table.maxamount_Column5' |
                                    translate }}</th>
                                <th class="custom-th text-center">{{ 'Salary_Components_Table.monthlymount_Column6' |
                                    translate }}</th>
                                <th class="custom-th text-center">{{ 'Salary_Components_Table.annuallymount_Column7' |
                                    translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="custom-tr" *ngFor="let data of selectedList; let i = index;">
                                <td class="custom-td text-center">

                                    <span (click)="deleteOfferComponent(i,data)" [class.isdisabled]="lastaction=='view'"
                                        class="fa fa-trash ml-2 cursor-pointer"></span>
                                </td>

                                <td class="custom-td text-left">
                                    <input type="text" class="form-control" name="type-{{i}}" [(ngModel)]="data.type"
                                        readonly>
                                </td>
                                <td class="custom-td text-left"><input type="text" class="form-control"
                                        name="description-{{i}}" [(ngModel)]="data.description">
                                </td>
                                <td class="custom-td text-right"><input type="number" class="form-control"
                                        name="fix_value-{{i}}" [(ngModel)]="data.fix_value"
                                        (focusout)="calculateInDecimal(data)"></td>
                                <td class="custom-td text-right"><input type="number" class="form-control"
                                        name="max_value-{{i}}" [(ngModel)]="data.max_value"
                                        (focusout)="calculateInDecimal(data)"></td>
                                <td class="custom-td text-right"><input type="number" class="form-control"
                                        name="monthly_amt-{{i}}" [(ngModel)]="data.monthly_amt"></td>
                                <td class="custom-td text-right"><input type="number" class="form-control"
                                        name="annually_amt-{{i}}" [(ngModel)]="data.annually_amt"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn btn-outline-secondary" (click)="closeModal()">{{ 'Modal_Buttons.Close_Button' |
                    translate }}</button>
                <button type="button" class="btn btn-primary" [disabled]="!f.valid || lastaction=='view'"
                    (click)="saveOffer()">{{ 'Modal_Buttons.Save_Button' | translate }}</button>
            </div>
        </form>
    </div>
</resize-border>