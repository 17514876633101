import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';
import { InventoryServices } from '../../inventory/inventory.service';
import { SalesServices } from '../../Sales/sales.service';

@Component({
	selector: 'app-salesrequest',
	templateUrl: './salesrequest.component.html',
	styleUrls: ['./salesrequest.component.scss']
})
export class SalesrequestComponent implements OnInit {
	fullScreen = false;
	companyLogo: any = 'http://ezybooks.net/docs/logos/';
	format: any;
	userdata: any;
	appname = environment.APP_NAME;
	salesRequest: any = {
		status: '',
		searchtxt: '',
		fromdate: '',
		todate: '',
		type: ''
	}
	selectedSalesReqstatus: any = []
	salesRequestOrders: any = [];
	internalBinList: any = [];
	totalPages: any = 0;
	currentPage: any = 1;
	hideRightNav: boolean = true;
	hideLeftNav: boolean = false;
	totalRows: any = 0;
	constructor(private activeModal: NgbActiveModal,
		private loader: NgxUiLoaderService,
		private service: SalesServices,
		private inventoryService: InventoryServices,
		private commonService: CommonServices,
		private datepipe: DatePipe,) {
		//#region userdata block
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.format = this.userdata.date_format
		this.companyLogo += this.userdata.logo;
		MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
		//#endregion userdata block

		this.salesRequest.todate = this.userdata.enddate;
		this.salesRequest.fromdate = this.userdata.fromdate;
		environment.production ? '' : console.log('from date', this.salesRequest.fromdate);
		environment.production ? '' : console.log('end date', this.salesRequest.todate);
		// this.requestby = this.userdata.email

		this.getSalesRequests();
	}

	getSalesRequests() {
		this.loader.start();
		var editDataForHeader = '';
		this.commonService.headerEditItemData.next(editDataForHeader); //end '
		this.service.getSalesRequest(this.currentPage, this.salesRequest.type, this.salesRequest.status, this.datepipe.transform(this.salesRequest.fromdate, this.userdata.postdateformat), this.datepipe.transform(this.salesRequest.todate, this.userdata.postdateformat), this.salesRequest.searchtxt).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				this.salesRequestOrders = res.data;
				this.totalPages = res.total_pages;
			}
			this.salesRequestOrders.forEach(item => {
				item.ischecked = false;
			})
			this.totalRows = this.salesRequestOrders.length;
			environment.production ? '' : console.log('salesOrders list data ', res);

		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading product list ', err);
		});
	}

	ngOnInit(): void {
	}

	hideNavButtons(isright, isleft) {
		this.hideRightNav = isright;
		this.hideLeftNav = isleft;
	}
	gotoPage(page) {
		// this.hideNav = true
		if (page == 'first') {
			this.currentPage = 1;
			this.hideNavButtons(true, false);
		} else if (page == 'prev') {
			if (this.currentPage == '2') {
				this.currentPage = 1;
				this.hideNavButtons(true, false);
			} else {
				this.currentPage--;
				this.hideNavButtons(false, false);
			}
		} else if (page == 'next') {
			if (this.currentPage == this.totalPages - 1) {
				this.currentPage = this.totalPages;
				this.hideNavButtons(false, true);
			} else {
				this.currentPage++;
				this.hideNavButtons(false, false);
			}

		} else if (page == 'last') {
			this.currentPage = this.totalPages;
			this.hideRightNav = false
			this.hideLeftNav = true
		}
		this.getSalesRequests();
	}
	closeModal() {
		this.activeModal.close('Modal Closed');
	}

}
