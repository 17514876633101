import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { serviceManagerServices } from '../../servicemanager/servicemanager.service';
import { NgxPrintElementService } from 'ngx-print-element';
@Component({
  selector: 'app-techcopy',
  templateUrl: './techcopy.component.html',
  styleUrls: ['./techcopy.component.scss']
})
export class TechCopyComponent implements OnInit {
  fullScreen = false;
  public config = {
    printMode: 'template-popup',
    pageTitle: ' ',
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=no,top=0,left=0,fullscreen=yes',
    stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }],
    styles: ['#print-section {width:100%;display: block !important; font-size:16px !important; page-break-after: always;}', '.TaskPrint {width:100%; font-size:16px !important; page-break-after: always;}', 'body { width:100%; font-size:16px !important}', 'table {width:100%; font-size:16px !important;}', 'table tr td { height:50px; }','table tr th { height:50px;}', '@page {size: A4; font-size:16px !important}',' input[type=checkbox] {height: 25px;,width: 25px;}']
  }
  closeResult: string;
  @Input('fromdate') fromdate;
  @Input('todate') todate;
  @Input('status') status;
  @Input('id') id;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  ticketsBatchPrintList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  @ViewChild('content') public content: any;
  @ViewChild('clientnotes') clientNotes: ElementRef;
  isShowCopanyLogo = false;
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: serviceManagerServices, public print: NgxPrintElementService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.companyLogo += this.userdata.logo;
  }

  ngOnInit() {
    if (this.id != undefined && this.id != '' && this.id != null) {
      this.getTicketDetails(this.id);
    } else {
      this.getTicketListForBatchPrint();
    }
    setTimeout(() => {
      //document.getElementById("printButton").click();
    }, 2000)
  }

  getTicketDetails(ticketid) {
    this.service.getTicketDetails(ticketid).subscribe(res => {
      if (res != null) {
        this.ticketsBatchPrintList.push(res);
      }
    }, err => {
      environment.production ? '' : console.log('Error : While loading currency data', err);
    })
  }

  getTicketListForBatchPrint() {
    this.loader.start();
    this.service.getTicketsForBatchPrint(this.fromdate, this.todate, this.status).subscribe(res => {
      this.loader.stop();
      this.ticketsBatchPrintList = res;
      if (this.ticketsBatchPrintList != undefined && this.ticketsBatchPrintList != null) {
        this.ticketsBatchPrintList.forEach(ticket => {
          if (ticket.ticketSchedules.length > 0) {
            ticket.ticketSchedules.forEach(element => {
              element.category=ticket.category;
              if (element.schedule_date != null) {
                element.schedule_date = this.datepipe.transform(new Date(element.schedule_date),this.userdata.postdateformat);
              }
              if (element.schedule_time != null) {
                var dateTime = this.datepipe.transform(new Date(),this.userdata.postdateformat);
                dateTime = dateTime + 'T' + element.schedule_time;
                element.schedule_time = this.datepipe.transform(new Date(dateTime), 'hh:mm:ss a');
              }
              if (element.completeddate != null) {
                element.completeddate = this.datepipe.transform(new Date(element.completeddate),this.userdata.postdateformat);
              }
              if (element.completedtime != null) {
                var dateTime = this.datepipe.transform(new Date(),this.userdata.postdateformat);
                dateTime = dateTime + 'T' + element.completedtime;
                element.completedtime = this.datepipe.transform(new Date(dateTime), 'hh:mm:ss a');
              }
              if (element.enroutedate != null) {
                element.enroutedate = this.datepipe.transform(new Date(element.enroutedate),this.userdata.postdateformat);
              }
              if (element.enroutetime != null) {
                var dateTime = this.datepipe.transform(new Date(),this.userdata.postdateformat);
                dateTime = dateTime + 'T' + element.enroutetime;
                element.enroutetime = this.datepipe.transform(new Date(dateTime), 'hh:mm:ss a');
              }
            });
          }
        });
      }
      // this.open(this.content);
      environment.production ? '' : console.log('ticket batch list data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading ticket list ', err);
    });
  }
  getDismissReason(reason: any) {
    throw new Error('Method not implemented.');
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}
