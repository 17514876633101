import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { SalesServices } from '../../Sales/sales.service';
import { Subscription, interval } from 'rxjs';
@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent implements OnInit {
  header: any;
  pos: any = {};
  userdata: any;
  appname = environment.APP_NAME;
  setCustomerValue: any;
  keyword = 'name';
  validatecustomer: any;
  supplierList: any = [];
  searchTxt: any = '';
  islocal: any = false;
  @ViewChild('supplier') supplier;

  subscription: Subscription;
  @ViewChild('SearchElement') SearchElement: ElementRef;
  selectedSupplier: any = {};
  constructor(private activeModal: NgbActiveModal,
    private commonServices: CommonServices,
    private loader: NgxUiLoaderService,
    private datePipe: DatePipe,
    private toastr: NotificationService,
    private service: SalesServices,
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  toggle() {
    this.islocal != this.islocal;
    console.log('islocal '+this.islocal);
  }

  getSupplier() {
    if (this.searchTxt == undefined || this.searchTxt.length < 2) {
      return
    }
    this.loader.start();
    this.commonServices.getSuppliersList(this.islocal, this.searchTxt).subscribe(res => {
      this.loader.stop();
      this.supplierList = res;
      environment.production ? '' : console.log(' customer list data ', this.supplierList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
  selectCustomer(item) {
    this.selectedSupplier = item;
  }
  closeModal() {
    this.activeModal.close(this.selectedSupplier);
  }
  ngOnInit(): void {
    this.commonServices.aClickedEvent
      .subscribe((data: any) => {
        console.log('Event message from Component for islocal checked: ' + data);
        this.islocal = data;
      });
    this.islocal = this.islocal ? this.islocal : false;

    if (this.searchTxt) {
      this.getSupplier();
    }
  }

  preventEvent(event: Event): void {
    event.stopPropagation(); // Prevents event bubbling
    event.preventDefault();  // Prevents default action (if any)
  }

  ngAfterViewInit() {
    const source = interval(500);
    this.subscription = source.subscribe(val => {
      if (!this.SearchElement.nativeElement.focus()) {
        this.SearchElement.nativeElement.focus()
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    console.log('destroyed')
  }
}
