import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonServices } from 'src/app/shared/service/common.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { purchaseServices } from '../../purchase/purchase.service';
import { SalesServices } from '../../Sales/sales.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  fullScreen = false;
  @Input('params') params;
  userdata: any;
  appname = environment.APP_NAME;
  tracking: any = {
    title: 'Tracking',
    notes: '',
    action: '',
    status: '',
    refno: ''
  }
  format: any;
  trackingNotes: any = [];
  //Tags code
  tagName: any;
  private langChangeSubscription: Subscription;
  tagCtrl = new FormControl('');
  filteredTags: Observable<string[]>;
  tags: any = [];
  allTags: any = [];
  @ViewChild('tagInput') tagInput: any;
  addOnFocusOut = true;
  status: any = '';
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  statusList: any = [];
  disableSaveBtn: any = false;
  //end Tags code
  constructor(private activeModal: NgbActiveModal,
    private commoanService: CommonServices,
    private datepipe: DatePipe,
    private loader: NgxUiLoaderService,
    private salesService: SalesServices,
    private service: purchaseServices,
    private modalService: NgbModal,
     private translate: TranslateService, 
     private languageService: LanguageService, 
     private http: HttpClient,
    private toastr: NotificationService,) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.postdatetimeformat;
  }

  ngOnInit(): void {
    if (this.params.type == 'invoice') {
      this.tracking.title = 'Invoice Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('invstatus');
    } else if (this.params.type == 'salesorder') {
      this.tracking.title = 'Sales Order Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('orderstatus');
    } else if (this.params.type == 'quote') {
      this.tracking.title = 'Quote Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('quotestatus');
    } else if (this.params.type == 'estimate') {
      this.tracking.title = 'Estimate Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('estimatestatus');
    } else if (this.params.type == 'po') {
      this.tracking.title = 'Purchase Order Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('postatus');
    }
    //new
    else if (this.params.type == 'pi') {
      this.tracking.title = 'Purchase Invoice Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('pistatus');
    } 
    else if (this.params.type == 'customer') {
      this.tracking.title = 'Customer Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('customerstatus');
    } 
    else if (this.params.type == 'supplier') {
      this.tracking.title = 'Supplier Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('supplierstatus');
    } 
    else if (this.params.type == 'expense') {
      this.tracking.title = 'Expense Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('expensestatus');
    }
    else if (this.params.type == 'product') {
      this.tracking.title = 'Product Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('prodstatus');
    }
    else if (this.params.type == 'ticket') {
      this.tracking.title = 'ticket Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getTicketStatusList();
    }
    else if (this.params.type == 'contract') {
      this.tracking.title = 'Contract Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('contractstatus');
    }
    else if (this.params.type == 'asset') {
      this.tracking.title = 'Asset Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('assetstatus');
    }
    else if (this.params.type == 'rfq') {
      this.tracking.title = 'RFQ Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('rfqstatus');
    }
    else if (this.params.type == 'project') {
      this.tracking.title = 'Project Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('projectstatus');
    }
    else if (this.params.type == 'milestone') {
      this.tracking.title = 'Milestone Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('milestonestatus');
    }
    else if (this.params.type == 'jobapplication') {
      this.tracking.title = 'Job Application Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('jobapplicationstatus');
    }else if (this.params.type == 'expensereq') {
      this.tracking.title = 'Expense Request Tracking';
      this.tracking.refno = this.params.refno;
      this.tracking.status = this.params.status.toLowerCase()
      this.getStatusList('expreqstatus');
    }
    this.getTrackingNotes();
    this.getTags();

    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
  }

   
private loadComponentTranslations(lang: string) { 
  const translationFilePath = `./assets/i18n/sales/invoices/basic/${lang}.json`; 
  this.http.get(translationFilePath).subscribe((translations:any) => { 
  this.translate.setTranslation(lang, translations, true); 
  this.translate.use(lang); 
  }); 
   } 


  getStatusList(type) {
    this.loader.start();
    this.commoanService.getComboEnums(type).subscribe(res => {
      this.loader.stop();
      this.statusList = res;
      environment.production ? '' : console.log('status list data ', this.statusList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading status list ', err);
    });
  }
  getTicketStatusList() {
    this.loader.start();
    this.commoanService.getTicketStatuses().subscribe((res: any) => {
      this.loader.stop();
      this.statusList = [];
      res.forEach(item => {
        this.statusList.push({ 'Text': item.Text, 'Value': item.Value })
      })
      environment.production ? '' : console.log('status list data ', this.statusList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading status list ', err);
    });
  }

  //#region Tagsinput
  getTags() {
    this.commoanService.getTags(this.params.type).subscribe((res: any) => {
      if (res != null) {
        this.allTags = [];
        res.forEach(element => {
          this.allTags.push(element.Value);
        })
      }
      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith(null),
        map((tag: string | null) => (tag ? this._filter(tag) : this.allTags.slice())),
      );
      environment.production ? '' : console.log('Invoice tags data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading Invoice tags data', err);
    });
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our tag
    if (value) {
      var isExists = false;
      if (this.tags.length > 0) {
        this.tags.forEach(element => {
          if (element == value) {
            isExists = true;
          }
        })
      }
      if (!isExists) {
        this.tags.push(value);
      }
    }
    var isExists = false;
    this.allTags.forEach(element => {
      if (element == value) {
        isExists = true;
      }
    })
    if (!isExists) {
      var postTagObj = {
        "description": value,
        "active": true,
        "createby": this.userdata.email,
        "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
        "companyid": this.userdata.companyid,
        "color": "",
        "pg": this.params.type,
      }
      this.disableSaveBtn = true;
      this.loader.start();
      this.commoanService.postTags(postTagObj).subscribe((res: any) => {
        this.loader.stop();
        this.disableSaveBtn = false;
        if (res != null) {
          if (res.status_code == "1") {
            res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          } else {
            res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
            return;
          }
          this.getTags();
        }
      }, err => {
        this.loader.stop();
      });
    }
    // Clear the input value
    event.chipInput!.clear();
    this.tagCtrl.setValue(null);
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().includes(filterValue));
  }
  //#endregion filter mat-chips

  getTrackingNotes() {
    this.commoanService.getTrackingNotes(this.params.id, this.params.type).subscribe(res => {
      this.trackingNotes = res;
      environment.production ? '' : console.log('tracking notes data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading tracking notes data ', err);
    });
  }
  //#region post Tracking
  postTrackingNotes() {
    if (this.tracking.notes == undefined || this.tracking.notes == null || !this.tracking.notes || this.tracking.notes == '') {
      this.toastr.Error('Please enter notes');
      return;
    }
    // if (this.tags.length == 0) {
    //   this.toastr.Error('Please enter action');
    //   return;
    // }

    var tags = null;
    if (this.tags.length > 0) {
      this.tags.forEach((t, i) => {
        if (tags == undefined || tags == null) {
          tags = t;
        } else {
          tags += ',' + t;
        }
      });
    }
    var trackObj = {
      'id': null,
      'notes': this.tracking.notes,
      'tags': tags,
      'pg': this.params.type,
      'ref_id': this.params.id,
      'createby': this.userdata.email,
      'createdate': this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      'updateby': null,
      'updatedate': null,
      'companyid': this.userdata.companyid,
      'status': this.tracking.status,
    }
    environment.production ? '' : console.log('post req', JSON.stringify(trackObj));
    this.loader.start();
    this.commoanService.postTrackingNotes(trackObj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
      this.tracking.notes = null;
      this.tags = [];
      this.getTrackingNotes();
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading Tracking data', err);
    });
  }
  //#endregion post Tracking

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}
