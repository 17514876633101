import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  Success(title) {
    this.toastr.success(title)
  }

  Error(title) {
    this.toastr.error(title)
  }

  Info(title) {
    this.toastr.info(title)
  }

  Warning(title) {
    this.toastr.warning(title)
  }
}
