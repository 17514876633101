import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InventoryServices } from 'src/app/components/inventory/inventory.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { PickitemComponent } from '../pickitem/pickitem.component';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { CommonServices } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-serial',
  templateUrl: './serial.component.html',
  styleUrls: ['./serial.component.scss']
})
export class SerialComponent implements OnInit {
  serial: any = {
    status: '',
    binid: ''
  }
  @Input() fromParent;
  @Input() editData;
  @Input() prodid;
  @Input() orderid;
  @Input() customerid;

  binList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  ownItemProdBinList: any = [];
  actionType: any = []
  closeResult: string;
  allBinList: any = [];
  statusList: any = [];
  constructor(private activeModal: NgbActiveModal,
    private service: InventoryServices,
    private modalService: NgbModal,
    private toastr: NotificationService,
    private commonService: CommonServices,
    private datepipe: DatePipe,
    private loader: NgxUiLoaderService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    if (this.editData != null) {
      this.serial = this.editData;
    }
    this.commonService.getComboEnums('serialnostatus').subscribe(res => {
      this.statusList = res;
    })
    this.service.getBinLocationList().subscribe(res => {
      this.allBinList = res;
      environment.production ? '' : console.log(' bin list data ', this.allBinList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }


  closemodal() {
    this.activeModal.close('Modal Closed');
  }

  postSerial() {
    if (this.serial.binid) {
      this.serial.binid = parseFloat(this.serial.binid)
    }
    var data = {
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "createby": this.userdata.email,
      "companyid": this.userdata.companyid,
      "lastupdated": this.datepipe.transform(this.serial.lastupdated, this.userdata.postdatetimeformat),
      "serial": this.serial.serial,
      "updateby": this.serial.updateby,
      "id": this.serial.id,
      "prodid": this.fromParent.prodid,
      "pon": this.serial.pon,
      "purchasefrom": this.serial.purchasefrom,
      "purchasedate": this.datepipe.transform(this.serial.purchasedate, this.userdata.postdatetimeformat),
      "manufacturer": this.serial.manufacturer,
      "saledate": this.datepipe.transform(this.serial.saledate, this.userdata.postdatetimeformat),
      "orderno": this.serial.orderno,
      "notes": this.serial.notes,
      "warrantyexpirydate": this.datepipe.transform(this.serial.saledate, this.userdata.postdatetimeformat),
      "status": this.serial.status,
      "binid": this.serial.binid
    }

    environment.production ? '' : console.log('post transfer datais', data);

    this.loader.start();
    this.service.postSerial(data).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
      this.activeModal.close(this.customerid);
      environment.production ? '' : console.log('post product serial response ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });

  }


  ngOnInit(): void {

  }

}
