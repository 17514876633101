import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { purchaseServices } from '../../purchase/purchase.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-addsupplier',
  templateUrl: './addsupplier.component.html',
  styleUrls: ['./addsupplier.component.scss']
})
export class SupplierContactsModalComponent implements OnInit {
  fullScreen = false;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  userdata: any;
  appname = environment.APP_NAME;
  @ViewChild('supplierid') supplierid;
  contact: any = {}
  contactList: any = [];
  selectedContact: any;
  constructor(private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private modalService: NgbModal,
    private commonService: CommonServices,
    private toastr: NotificationService,
    private datepipe: DatePipe, private service: purchaseServices) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.companyLogo += this.userdata.logo;
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block
  }
  selectContact(item) {
    this.selectedContact = item;
    this.closeModal();
  }

  ngOnInit(): void {
  }

  saveContact() {
    if ((this.contact.id)) {
      this.contact.updateby = this.userdata.email;
      this.contact.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.contact.companyid = this.userdata.companyid;
      this.contact.supplierid = this.supplierid;
      this.contact.createby = this.userdata.email;
      this.contact.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.loader.start();
    this.service.postSupplierContact(this.contact).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading lead contact ', err);
    });
  }


  closeModal() {
    this.activeModal.close(this.selectedContact);
  }

}
