<form class="card" name="form" #f="ngForm">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Modal_Title' | translate }} {{product.id}}</h4>
    <div>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
      <button type="button" class="close"></button>
    </div>
  </div>
  <div class="modal-body" [style.pointer-events]="action=='view'?'none':''">
    <div class="row product-adding">
      <div class="col-lg-3">
        <div class="picture-div">
          <button class="capture-image-btn" (click)="file.click()"><i class="fa fa-edit"></i>
          </button>
          <img [src]="product.pic" onerror="this.src='assets/images/no-image.png';">
        </div>
        <div class="uid">{{ 'UID_Text' | translate }} {{product.uid}}</div>
        <input type="file" style="visibility: hidden;" ng2FileSelect [uploader]="uploader"
          (change)="selectedFileOnChanged($event)" accept="image/*" accept="image/jpeg, image/png" #file />
      </div>
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-3 col-md-6 form-group">
            <label class="required"> {{ 'Modal_Inputs.Lookupcode_Product_code_label' | translate }}</label>
            <input class="form-control" id="ilc" type="text" required maxlength="20" [(ngModel)]="product.ilc"
              (focusout)="validateIlc()" #ilc="ngModel" name="ilc"
              [ngClass]="{ 'is-invalid': ilc.invalid && ilc?.errors , 'is-valid': ilc.valid}" />
            <div *ngIf="ilc.invalid && ilc.errors" class="invalid-feedback d-block">
              <div *ngIf="ilc.errors.required">{{ 'Modal_Inputs.Lookupcode_required' | translate }}
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.Third_Party_Code_label' | translate }} <small>{{ 'Modal_Inputs.Third_Party_Code_Eg' | translate }}</small> </label>
            <input class="form-control" id="tpcode" type="text" [(ngModel)]="product.tpcode" #tpcode="ngModel"
              name="tpcode">
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label class="dept required">{{ 'Modal_Inputs.Department_label' | translate }}</label>
            <select class="form-control" [(ngModel)]="product.dept" (change)="
                                getCatData()" #dept="ngModel" required name="dept"
              [ngClass]="{ 'is-invalid': dept.invalid && dept.errors , 'is-valid': dept.valid}">
              <option value="">{{ 'Modal_Inputs.Department_placeholder' | translate }}</option>
              <option *ngFor="let item of deptList" [value]="item.Value">{{item.Text}}
              </option>
            </select>
            <div *ngIf="dept.invalid && dept.errors" class="invalid-feedback d-block">
              <div *ngIf="dept.errors.required">{{ 'Modal_Inputs.Department_Required' | translate }}</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label>{{ 'Modal_Inputs.Category_label' | translate }}</label>
            <select class="form-control" [(ngModel)]="product.cat" #cat="ngModel" name="cat">
              <option value="">{{ 'Modal_Inputs.Category_placeholder' | translate }}</option>
              <option *ngFor="let item of categoryList" [value]="item.Value">
                {{item.Text}}
              </option>
            </select>
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.Burden_Rate_label' | translate }}</label>
            <input type="number" class="form-control text-right" name="pocost" [(ngModel)]="product.pocost">
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.Avg_Burden_Rate_label' | translate }}</label>
            <input type="number" class="form-control text-right" name="avgcost" [(ngModel)]="product.avgcost">
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.Regular_Hours_Rate_label' | translate }}</label>
            <input type="number" class="form-control text-right" name="regularprice" [(ngModel)]="product.rhservice">
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.After_Hours_Rate_label' | translate }}</label>
            <input type="number" class="form-control text-right" name="afterhourprice" [(ngModel)]="product.ahservice">
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.Holiday_Hours_Rate_label' | translate }}</label>
            <input type="number" class="form-control text-right" name="holidayhourprice"
              [(ngModel)]="product.ohservice">
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.Agreement_Regular_Hours_Rate_label' | translate }}</label>
            <input type="number" class="form-control text-right" name="rhservice_agg"
              [(ngModel)]="product.rhservice_agg">
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.Agreement_After_Hours_Rate_label' | translate }}</label>
            <input type="number" class="form-control text-right" name="ahservice_agg"
              [(ngModel)]="product.ahservice_agg">
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.Agreement_Overtime_Hours_Rate_label' | translate }}</label>
            <input type="number" class="form-control text-right" name="ohservice_agg"
              [(ngModel)]="product.ohservice_agg">
          </div>

          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.Flat_Rate_label' | translate }}</label>
            <input type="number" class="form-control text-right" name="flatprice" [(ngModel)]="product.flatprice">
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label>{{ 'Modal_Inputs.Apply_Flat_Rate_label' | translate }}</label>
              <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                <label class="d-block">
                  <input class="radio_animated" id="flatpp" type="radio" [value]="true" [(ngModel)]="product.flatpp"
                    #flatpp="ngModel" name="flatpp">
                  {{ 'Modal_Inputs.Apply_Flat_Rate_yes' | translate }}
                </label>
                <label class="d-block">
                  <input class="radio_animated" id="flatpp1" [value]="false" [(ngModel)]="product.flatpp"
                    #flatpp="ngModel" name="flatpp" type="radio" name="rdo-applyflat1">
                  {{ 'Modal_Inputs.Apply_Flat_Rate_no' | translate }}
                </label>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <div class="form-group">
              <label>{{ 'Modal_Inputs.Sale_Online_label' | translate }}</label>
              <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                <label class="d-block">
                  <input class="radio_animated" [value]="true" [(ngModel)]="product.sale_online" #sale_online="ngModel"
                    name="sale_online" id="sale_online" type="radio">
                  {{ 'Modal_Inputs.Sale_Online_yes' | translate }}
                </label>
                <label class="d-block">
                  <input class="radio_animated" [value]="false" [(ngModel)]="product.sale_online" #sale_online="ngModel"
                    name="sale_online" id="sale_online1" type="radio">
                  {{ 'Modal_Inputs.Sale_Online_no' | translate }}
                </label>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.GL_Cost_of_Sales_label' | translate }}</label>
            <angular2-multiselect name="coa_costofsales" class="form-control input-sm" [data]="glAccountList"
              [(ngModel)]="coa_costofsales" [settings]="glCostofSalesSettings" (onSelect)="onCosSelect($event)"
              (onDeSelect)="OnCosDeSelect($event)">
            </angular2-multiselect>
          </div>

          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.GL_Income_label' | translate }}</label>
            <angular2-multiselect  name="coa_income" class="form-control input-sm" [data]="glAccountList"
              [(ngModel)]="coa_income" [settings]="glIncomeAccountSettings" (onSelect)="onItemSelect($event)"
              (onDeSelect)="OnItemDeSelect($event)">
            </angular2-multiselect>
          </div>

          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.Parent_Item_label' | translate }}</label>
            <ng-select placeholder="{{ 'Modal_Inputs.Parent_Item_placeholder' | translate }}" [(ngModel)]="product.parentid" name="parentid"
              [clearable]="product.parentid">
              <ng-option *ngFor="let item of parentProds" [value]="item.Value">
                {{item.Text}}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">{{ 'Modal_Inputs.Status_label' | translate }}</label>
            <select class="form-control" required="" [(ngModel)]="product.status" #status="ngModel" name="status"
              [ngClass]="{ 'is-invalid': status.invalid && status?.errors , 'is-valid': status.valid}">
              <option value="">{{ 'Modal_Inputs.Status_placeholder' | translate }}</option>
              <option *ngFor="let item of statusList" [value]="item.stringValue">
                {{item.Text}}
              </option>
            </select>
            <div *ngIf="status.invalid && status.errors" class="invalid-feedback d-block">
              <div *ngIf="status.errors.required">{{ 'Modal_Inputs.status_Required' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 form-group">
        <label class="required">{{ 'Modal_Inputs.Description_label' | translate }}</label>
        <textarea rows="6" class="form-control" id="description" required="true" [(ngModel)]="product.description"
          #description="ngModel" name="description"
          [ngClass]="{ 'is-invalid': description.invalid && description.errors , 'is-valid': description.valid}"></textarea>
        <div *ngIf="description.invalid && description.errors" class="invalid-feedback d-block">
          <div *ngIf="description.errors.required">{{ 'Modal_Inputs.Description_Required' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">{{ 'Modal_buttons.Close_button' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="saveItem()" [disabled]="!f.valid || !isedit">{{ 'Modal_buttons.Save_button' | translate }}</button>
  </div>

</form>