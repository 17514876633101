import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InventoryServices } from '../../../inventory.service';
import { AddProductComponent } from '../add-product.component';

@Component({
  selector: 'app-timeing',
  templateUrl: './timeing.component.html',
  styleUrls: ['./timeing.component.scss']
})
export class TimeingComponent implements OnInit {
  public closeResult: string;
  public categories = []
  product: any;
  timingList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  timingData: any = {
    "id": null,
    "description": '',
    "createby": '',
    "createdate": '',
    "updateby": '',
    "updatedate": '',
    "rating": '',
    "active": true,
    "approved": true,
    "prodid": '',
    "dayavail": ''
  };
  response: any;
  lastaction: any;
  fieldsetDisabled = false;
  isview: boolean;
  daysList: any = []
  constructor(private modalService: NgbModal,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private toastr: NotificationService,
    private maincomponent: AddProductComponent,
    private loader: NgxUiLoaderService,
    private commonService: CommonServices,
    private service: InventoryServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.product = maincomponent.product;
    this.lastaction = this.route.snapshot.queryParamMap.get('action');
    if (this.lastaction == 'view') {
      this.fieldsetDisabled = true;
    } else if (this.lastaction == 'edit') {
      this.fieldsetDisabled = false;
    }
    this.commonService.getComboEnums('dayavail').subscribe(res => {
      this.daysList = res;
      environment.production ? '' : console.log(' daysList list ', this.daysList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading daysList List ', err);
    });
    this.getTimingList();
  }

  ngOnInit(): void {

  }

  getTimingList() {
    this.loader.start();
    this.service.getTiming(this.product.id).subscribe(res => {
      this.loader.stop();
      console.log(res)
      this.timingList = res;
      this.timingList.forEach(rev => {
        rev.active = rev.active ? 'Yes' : 'No';
      })
    }, err => {
      this.loader.stop();
    })
  }
  getTimingByID(content, item, action) {
    this.loader.start();
    this.service.getTimingByID(item.id).subscribe(res => {
      this.loader.stop();
      this.timingData = res;
      if (action == 'view') {
        this.isview = true
      } else if (action == 'edit') {
        this.isview = false
      }
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }, err => {
      this.loader.stop();
    })
  }
  open(content) {
    this.timingData = {
      active: true,
      dayavail: ''
    };
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closemodal() {
    this.modalService.dismissAll()
  }
  postProductTiming() {
    this.timingData.companyid = this.userdata.companyid;
    if (this.timingData.id != undefined && this.timingData.id != null) {
      this.timingData.createby = this.timingData.createby;
      this.timingData.createdate = this.datepipe.transform(this.timingData.createdate, this.userdata.postdatetimeformat);
      this.timingData.updateby = this.userdata.email;
      this.timingData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    else {
      this.timingData.prodid = this.product.id;
      this.timingData.createby = this.userdata.email;
      this.timingData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.timingData.active = true;

    environment.production ? '' : console.log('post review data', this.timingData);
    this.loader.start();
    this.service.postTiming(this.timingData).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          this.getTimingList();

        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });

  }
  closeModal() {
    this.modalService.dismissAll();
  }
  deleteSupplier(item) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteTiming(item.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.getTimingList();
            this.modalService.dismissAll();
          }
        }, err => {
          this.loader.stop();
          environment.production ? '' : console.log('Error : While loading delete Currency', err);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
}
