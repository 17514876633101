import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { CommonServices } from 'src/app/shared/service/common.service';
import { ExcelService } from 'src/app/shared/service/excel.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NavService } from 'src/app/shared/service/nav.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { categoryDB } from '../../../shared/tables/category';
import { DocumentsComponent } from '../../modal/documents/documents.component';
import { PrintlabelComponent } from '../../modal/printlabel/printlabel.component';
import { InventoryServices } from '../inventory.service';
import { AdjustQtyComponent } from './modal/adjust-qty/adjust-qty.component';
import { BinlocationsListComponent } from './modal/binlocations/binlocations.component';
import { HoldqtyComponent } from './modal/holdqty/holdqty.component';
import { ItemanalysisComponent } from './modal/itemanalysis/itemanalysis.component';
import { ItemCompatibilityComponent } from './modal/itemcompatibility/itemcompatibility.component';
import { PurchasehistoryComponent } from './modal/purchasehistory/purchasehistory.component';
import { SerialsListComponent } from './modal/serials/serials.component';
import { TransferQtyComponent } from './modal/transfer-qty/transfer-qty.component';
import { TransferserialsComponent } from './modal/transferserials/transferserials.component';
import { ExportDataServices } from 'src/app/shared/service/exportdata.service';

@Component({
	selector: 'app-product-list',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss']
})
export class ProductListComponent implements OnInit {
	//#region userdata block
	appname = environment.APP_NAME;
	userdata: any;
	companyLogo: any = 'http://ezybooks.net/docs/logos/';
	format: any;
	totalRows: any = 0;
	totalPages: any = 0;
	currentPage: any = 1;
	//#endregion userdata block

	public isShow: boolean = false
	content: any;
	isCollapsed = true;
	filter: any = {
		name: '',
		searchtxt: '',
		status: '',
		serilized: '',
		supplier: '',
		deptid: '',
		type: '',
		tpsync: '',
		desc: '',
		category: ''
	}

	editSetDept: any;
	keyword: any = 'Text'
	public closeResult: string;
	public categories = []

	products: any;
	public arrowicon: boolean = true;
	statusList: any = [];
	prodList: any = []

	deptList: any = []
	categoryList: any = [];
	formula: string = 'Product List';
	reportColumnList: any = [];

	selectedProductstatus: any = []
	dropdownSettings: IDropdownSettings;
	stringData: string;
	hideRightNav: boolean = true;
	hideLeftNav: boolean = false;
	productType: any = []
	componentName: any;
	menuData: any;
	menuActions: any;
	private langChangeSubscription: Subscription;
	constructor(private modalService: NgbModal,
		private datetime: DatePipe,
		private router: Router,
		private service: InventoryServices,
		private loader: NgxUiLoaderService,
		private cd: ChangeDetectorRef,
		private toaster: NotificationService,
		private excel: ExcelService,
		private elementRef: ElementRef,
		private toastr: NotificationService,
		private commonservice: CommonServices,
		private translate: TranslateService,
		private languageService: LanguageService,
		private exportData: ExportDataServices,
		private http: HttpClient) {
		this.menuData = JSON.parse(localStorage.getItem('EzyBooks-menu'));
		setTimeout(() => {
			this.menuData.forEach(data => {
				if (data.title.toLowerCase() == 'inventory') {
					var module = data;
					module.children.forEach(data => {
						if (data.title.toLowerCase() == 'product items') {
							this.menuActions = data.menuActions;
						}
					})
				}
			})
			// 
		}, 1000);

		//#region userdata block
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.format = this.userdata.date_format
		this.companyLogo += this.userdata.logo;
		this.settings.pager.perPage = Number(this.userdata.grid_page_size ? this.userdata.grid_page_size : 10);
		MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
		//#endregion userdata block

		this.categories = categoryDB.category;

		var d = new Date()
		// alert(d.getTimezoneOffset());
		this.commonservice.getComboEnums('prodstatus').subscribe(res => {
			this.statusList = res;
			this.setStatus();
			environment.production ? '' : console.log('statusList  ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading statusList data', err);
		});
		// var date_time_format = "dd-MM-yyyy"
		this.componentName = this.elementRef.nativeElement.tagName.toLowerCase();

		this.filter.enddate = this.datetime.transform(new Date(), 'yyyy-MM-dd')
		this.filter.fromdate = this.userdata.fromdate;
		environment.production ? '' : console.log('from date', this.filter.fromdate);
		environment.production ? '' : console.log('end date', this.filter.enddate);

		var buttons = {
			foo: 'bar',
			fiz: 'buz'
		};

		for (var property in buttons) {
			environment.production ? '' : console.log(property); // Outputs: foo, fiz or fiz, foo
		}

		this.commonservice.getDepartmentList(false).subscribe(res => {
			this.deptList = res;
			const newFirstAllElement = { 'Text': 'All', 'Value': '' }
			this.deptList = [newFirstAllElement].concat(this.deptList)
			environment.production ? '' : console.log('department data ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading department data', err);
		});
		this.commonservice.getComboEnums('prodtype').subscribe(res => {
			this.productType = res;
			environment.production ? '' : console.log('product type  ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading product type data', err);
		});
		//setting for multiselect droddown.
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'stringValue',
			textField: 'Text',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 2,
			allowSearchFilter: true
		}
	}
	gotoEdit(data) {
		const encrptData = btoa(data.id);
		this.router.navigate(['products/add-product'], { queryParams: { id: encrptData, action: 'edit' }, replaceUrl: true })
		return;

	}

	sortDir = 1;//1= 'ASE' -1= DSC
	onSortClick(event, column) {
		let target = event.currentTarget,
			childtarget = target.querySelector('i'),
			classList = childtarget.classList;

		if (classList.contains('fa-chevron-up')) {
			classList.remove('fa-chevron-up');
			classList.add('fa-chevron-down');
			target.classList.add('Sorted');
			this.sortDir = -1;
		} else {
			classList.add('fa-chevron-up');
			classList.remove('fa-chevron-down');
			target.classList.remove('Sorted');
			this.sortDir = 1;
		}
		this.sortArr(column);
	}

	sortArr(colName: any) {
		this.products.sort((a, b) => {
			let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
			let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

			if (!valueA && !valueB) {
				return 0;
			}
			if (!valueA) {
				return -this.sortDir;
			}
			if (!valueB) {
				return this.sortDir;
			}

			if (valueA > valueB) {
				return this.sortDir;
			}
			if (valueA < valueB) {
				return -this.sortDir;
			}
			return 0;
		});
	}


	clicked(actionname, data) {
		var modalRef = null;
		if (actionname.toLowerCase() == 'view') {
			const encrptData = btoa(data.id); // encode a string        
			environment.production ? '' : console.log('enycrypted data', encrptData);
			//set edit data to header component

			this.router.navigate(['products/add-product'], { queryParams: { id: encrptData, action: 'view' }, replaceUrl: true })
		}
		if (actionname.toLowerCase() == 'edit') {
			const encrptData = btoa(data.id); // encode a string   
			//set edit data to header component

			environment.production ? '' : console.log('enycrypted data', encrptData);
			this.router.navigate(['products/add-product'], { queryParams: { id: encrptData, action: 'edit' }, replaceUrl: true })
		}
		if (actionname.toLowerCase() == 'clone') {
			const encrptData = btoa(data.id); // encode a string   
			environment.production ? '' : console.log('enycrypted data', encrptData);
			this.router.navigate(['products/add-product'], { queryParams: { id: encrptData, action: 'add' }, replaceUrl: true })
		}
		if (actionname.toLowerCase() == 'transfer') {
			modalRef = this.modalService.open(TransferQtyComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = data;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);

			modalRef.result.then((result) => {
				this.gotoPage('first');
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			});

		}
		if (actionname.toLowerCase() == 'hold') {

			modalRef = this.modalService.open(HoldqtyComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = data;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);

			modalRef.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
				this.gotoPage('first');
			}, (reason) => {
				this.gotoPage('first');
			});
		}
		if (actionname.toLowerCase() == 'adjust') {

			modalRef = this.modalService.open(AdjustQtyComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = data;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);
			modalRef.result.then((result) => {
				if (result == 'save') {
					this.gotoPage('first');
				}
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			});
		}
		if (actionname.toLowerCase() == 'item analysis') {
			modalRef = this.modalService.open(ItemanalysisComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = data;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);

			modalRef.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			});

		}
		if (actionname.toLowerCase() == 'purchase history') {
			modalRef = this.modalService.open(PurchasehistoryComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = data;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);

			modalRef.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			});

		}
		if (actionname.toLowerCase() == 'item compatibility') {
			modalRef = this.modalService.open(ItemCompatibilityComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = data;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);

			modalRef.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			});
		}

		if (actionname.toLowerCase() == 'print main ilc label') {
			modalRef = this.modalService.open(PrintlabelComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.id = data.id;
			modalRef.componentInstance.type = "p";
			modalRef.componentInstance.name = "1";
			modalRef.componentInstance.desc = "1";
			modalRef.componentInstance.alt = "0";
			// modalRef.componentInstance.name = 'printpo';
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});
		}
		if (actionname.toLowerCase() == 'print manufacturer ilc label') {
			modalRef = this.modalService.open(PrintlabelComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.id = data.id;
			modalRef.componentInstance.type = "p";
			modalRef.componentInstance.name = "1";
			modalRef.componentInstance.desc = "1";
			modalRef.componentInstance.alt = "2";
			// modalRef.componentInstance.name = 'printpo';
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});
		}
		if (actionname.toLowerCase() == 'print alt ilc label') {
			modalRef = this.modalService.open(PrintlabelComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.id = data.id;
			modalRef.componentInstance.type = "p";
			modalRef.componentInstance.name = "1";
			modalRef.componentInstance.desc = "1";
			modalRef.componentInstance.alt = "1";
			// modalRef.componentInstance.name = 'printpo';
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});
		}
		if (actionname.toLowerCase() == 'document manager') {
			modalRef = this.modalService.open(DocumentsComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'index-class', backdropClass: 'index-backdrop' });
			modalRef.componentInstance.refno = data.ilc;
			modalRef.componentInstance.id = data.id;
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});

		}
		if (actionname.toLowerCase() == 'delete') {
			this.deleteProduct(data.id)
		}

		if (actionname.toLowerCase() == 'move serials') {
			modalRef = this.modalService.open(TransferserialsComponent, { size: 'md' });
			modalRef.componentInstance.fromParent = data;
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});
		}

		environment.production ? '' : console.log(actionname);
	}


	onorderclick(data) {
		var modalRef = this.modalService.open(PurchasehistoryComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' });
		modalRef.componentInstance.fromParent = data;
		modalRef.result.then((result) => {
			environment.production ? '' : console.log(result);
		}).catch((error) => {
			environment.production ? '' : console.log(error);
		});

	}

	deleteProduct(id) {
		Swal.fire({
			title: 'Do you want to delete product?',
			// text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				this.loader.start();
				this.service.deleteProduct(id).subscribe(res => {
					environment.production ? '' : console.log('delete response ', res);
					this.loader.stop();
					this.toaster.Success('Product Deleted!');
					this.gotoPage('first')
				}, err => {
					this.loader.stop();
					environment.production ? '' : console.log('Error : While delete product ', err);
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}

	coloumclick(data) {
		setTimeout(() => {
			var modalRef = this.modalService.open(BinlocationsListComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' });
			modalRef.componentInstance.id = data.id;
			modalRef.componentInstance.isSerilzed = data.isserilized;
			modalRef.result.then((result) => {
				this.gotoPage('first')
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				this.gotoPage('first')

				environment.production ? '' : console.log(error);
			});
		}, 500)
	}


	onserialclick(data) {
		var modalRef = this.modalService.open(SerialsListComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' });
		modalRef.componentInstance.itemdata = {
			prodid: data.id,
			bincode: '',
			serialno: '',
			status: 'availableforsale'
		}
		modalRef.result.then((result) => {
			environment.production ? '' : console.log(result);
		}).catch((error) => {
			environment.production ? '' : console.log(error);
		});

	}
	export(type) {
		this.filter.typename = "InventoryIndex";
		this.filter.file_type = type;
		this.filter.pg = this.currentPage;
		this.exportData.params = this.filter;
		this.exportData.export();
	}
	getCatData(deptid) {
		this.loader.start();
		this.commonservice.getCategory(Number(deptid), false).subscribe(res => {
			this.categoryList = res;
			this.loader.stop();
			environment.production ? '' : console.log('Category data ', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading category data', err);
		});
	}
	goToAdd() {
		this.router.navigate(['products/add-product'], { queryParams: { id: null, action: 'add' }, replaceUrl: true })

	}
	dropdowntoggle() {
		this.isShow = !this.isShow;
	}
	clickEvent() {
		this.arrowicon = !this.arrowicon;
	}

	inputDeptCleared() {
		this.filter.deptid = ''
		this.filter.category = ''
	}

	open(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	refresh() {
		sessionStorage.removeItem(this.componentName + '-FILTER_LIST')
		this.filter.searchtxt = '';
		this.filter.desc = '';
		this.filter.type = '';
		this.filter.category = '';
		this.filter.deptid = '';
		this.selectedProductstatus = '';
		this.filter.status = '';
		this.filter.serilized = '';
		this.gotoPage('first');
	}

	public settings = {
		pager: {
			display: true,
			perPage: 0
		},
		hideSubHeader: false,
		actions: false,
		columns: {
			customColumn: {
				title: 'Actions',
				type: 'custom',
				filter: false,
				renderComponent: ProductActionColumn,
				onComponentInitFunction(instance) {
					//do stuff with component
					instance.id = 1;
					instance.click.subscribe(row => {
						console.debug('row', row, this.someMethod());
					});
					// instance.subscribe(data => environment.production ? '' : console.log(data))
				},
				valuePrepareFunction: (cell, row) => {
					return row.columnName;
				},
			},
			ilc: {
				title: 'ILC#',
				type: 'custom',
				width: '100px',
				valuePrepareFunction: (cell, row) => row,
				renderComponent: openproductcomponent,
			},
			description: {
				title: 'Description',
				type: 'html',
				valuePrepareFunction: (description) => {
					return `
					<div class="csstooltip position-relative"><div class="show-less position-relative" container="body" ngbTooltip="${description}">${description ? description : ''}
					</div> <span class="tooltiptext">${description}</span></div>`
				}
			},
			suppliername: {
				title: 'Supplier',
				type: 'html',
				valuePrepareFunction: (suppliername) => {
					return `
					<div class="csstooltip position-relative"><div class="show-less position-relative" container="body" ngbTooltip="${suppliername}">${suppliername ? suppliername : ''}
					</div> <span class="tooltiptext">${suppliername}</span></div>`
				}
			},
			dept_name: {
				title: 'Department',
			},
			category: {
				title: 'Category',
			},
			minqty: {
				title: 'Min',
			},
			maxqty: {
				title: 'Max',
			},
			qtyonhand: {
				title: 'OnHand',
				type: 'custom',
				renderComponent: OnHoldComponent,
				onComponentInitFunction(instance) {
					//do stuff with component
					instance.id = 1;
					instance.name = 'qtyonhand';
					instance.click.subscribe(row => {
						console.debug('row', row, this.someMethod());
					});
					// instance.subscribe(data => environment.production ? '' : console.log(data))
				},
				valuePrepareFunction: (cell, row) => {
					return row.columnName;
				},
			},
			qtyhold: {
				title: 'OnHold',
				type: 'custom',
				renderComponent: OnHoldComponent,
				onComponentInitFunction(instance) {
					//do stuff with component
					instance.name = 'qtyhold';
					instance.id = 1;
					instance.click.subscribe(row => {
						console.debug('row', row, this.someMethod());
					});
					// instance.subscribe(data => environment.production ? '' : console.log(data))
				},
				valuePrepareFunction: (cell, row) => {
					return row.columnName;
				},
			},
			qtyavail: {
				title: 'AvailQty',
				type: 'custom',
				renderComponent: OnHoldComponent,
				onComponentInitFunction(instance) {
					//do stuff with component
					instance.name = 'qtyavail';
					instance.id = 1;
					instance.click.subscribe(row => {
						console.debug('row', row, this.someMethod());
					});
					// instance.subscribe(data => environment.production ? '' : console.log(data))
				},
				valuePrepareFunction: (cell, row) => {
					return row.columnName;
				},
			},
			qtyonorder: {
				title: 'OnOrder',
				type: 'custom',
				renderComponent: OrderHistoryComponent,
				onComponentInitFunction(instance) {
					//do stuff with component
					instance.id = 1;
					instance.click.subscribe(row => {
						console.debug('row', row, this.someMethod());
					});
					// instance.subscribe(data => environment.production ? '' : console.log(data))
				},
				valuePrepareFunction: (cell, row) => {
					return row.columnName;
				},
			},
			nos: {
				title: 'SerialNos',
				type: 'custom',
				renderComponent: SerialNosComponent,
				onComponentInitFunction(instance) {
					instance.id = 1;
					instance.click.subscribe(row => {
						console.debug('row', row, this.someMethod());
					});
				},
				valuePrepareFunction: (cell, row) => {
					return row.columnName;
				},
			},
			retailprice: {
				title: 'Retail Price',
			},
			pocost: {
				title: 'PO Cost',
			},
			landedcost: {
				title: 'Landed Cost',
			},
			status: {
				title: 'Status',
				type: 'html',
				valuePrepareFunction: (status) => {
					return `<span class="badge" title="${status}">${status}</span>`
				}
			},
			filename: {
				title: 'Picture',
				type: 'html',
				valuePrepareFunction: (picture: string) => { return `<a href="${picture}" target="_blank"><img width="50px" src="${picture}" /></a>`; },
			},
			ptype: {
				title: 'Type',
				type: 'html',
			},
			tpcode: {
				title: 'TP Code',
				type: 'html',
			},
			tpcodeextra: {
				title: 'TP Code Extra',
			},
			isserilized: {
				title: 'Serilized',
			},
			tpsync: {
				title: 'TP Sync',
			},
			avgcost: {
				title: 'Avgcost',
			},
			color: {
				title: 'Color',
			},
			inventory_lead_time: {
				title: 'Inv. Lead Time',
				type: 'html',
				width: '100px',
			},
			production_lead_time: {
				title: 'Prod. Lead Time',
				type: 'html',
				width: '100px',
			},
			prefer_to_produce: {
				title: 'Prefer to Produce',
				type: 'html',
				width: '100px',
			},
			prefer_to_purchase: {
				title: 'Prefer to Purchase',
				type: 'html',
				width: '100px',
			},
			id: {
				title: 'Id',
				type: 'html',
				width: '100px',
			},


			createby: {
				title: 'CreateBy'
			},
			createdate: {
				title: 'CreateDate',
				valuePrepareFunction: (createdate: any) => {
					return this.datetime.transform(createdate, this.userdata.displaydatetimeformat)
				}
			},
			updateby: {
				title: 'UpdateBy'
			},
			updatedate: {
				title: 'UpdateDate',
				valuePrepareFunction: (updatedate: any) => {
					return this.datetime.transform(updatedate, this.userdata.displaydatetimeformat)
				}
			},
		},
	};
	ngOnInit() {
		if (sessionStorage.getItem(this.componentName + '-FILTER_LIST')) {
			this.filter = JSON.parse(sessionStorage.getItem(this.componentName + '-FILTER_LIST'));
		}
		//#region Grid Settings
		if (this.userdata.grid_inline_filter === false) {
			this.settings.hideSubHeader = true;
			environment.production ? '' : console.log('settings.hideSubHeader :', this.settings.hideSubHeader);
		} else {
			this.settings.hideSubHeader = false;
			environment.production ? '' : console.log('settings.hideSubHeader :', this.settings.hideSubHeader);
		}
		this.settings.pager.perPage = this.userdata.grid_page_size;
		environment.production ? '' : console.log('settings.perPage :', this.settings.pager.perPage);

		var editDataForHeader = '';
		this.commonservice.headerEditItemData.next(editDataForHeader); //end '
		//#region Grid Settings
		this.getItems();
		this.cd.detectChanges();
		var editDataForHeader = '';
		this.commonservice.headerEditItemData.next(editDataForHeader); //end '
		this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => {
			this.loadComponentTranslations(lang);
		});
	}
	private loadComponentTranslations(lang: string) {
		const translationFilePath = `./assets/i18n/inventory/productitems/${lang}.json`;
		this.http.get(translationFilePath).subscribe((translations: any) => {
			this.translate.setTranslation(lang, translations, true);
			this.translate.use(lang);
		});
	}
	selectSupplier(item) {
		this.filter.supplier = parseFloat(item.Value)
		this.gotoPage('first')

	}
	selectDepartment(item) {
		this.getCatData(this.filter.deptid);
		this.gotoPage('first')
	}
	hideNavButtons(isright, isleft) {
		this.hideRightNav = isright;
		this.hideLeftNav = isleft;
	}
	gotoPage(page) {
		// this.hideNav = true
		if (page == 'first') {
			this.currentPage = 1;
			this.hideNavButtons(true, false);
		} else if (page == 'prev') {
			if (this.currentPage == '2') {
				this.currentPage = 1;
				this.hideNavButtons(true, false);
			} else {
				this.currentPage--;
				this.hideNavButtons(false, false);
			}
		} else if (page == 'next') {
			if (this.currentPage == this.totalPages - 1) {
				this.currentPage = this.totalPages;
				this.hideNavButtons(false, true);
			} else {
				this.currentPage++;
				this.hideNavButtons(false, false);
			}

		} else if (page == 'last') {
			this.currentPage = this.totalPages;
			this.hideRightNav = false
			this.hideLeftNav = true
		}
		this.getItems();
	}
	getItems() {
		this.loader.start();
		this.service.getInventoryItems(this.currentPage, this.filter.serilized, this.filter.searchtxt, this.filter.supplier, this.filter.category, this.filter.deptid, this.filter.status, this.filter.tpsync).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				this.prodList = res.data;
				this.totalPages = res.total_pages;
			}
			this.prodList.forEach(element => {
				if (element.landedcost != null && element.landedcost != 0) {
					element.landedcost = element.landedcost.toFixed(this.userdata.decimals_allowed);
				}
				else {
					element.landedcost = parseFloat("0").toFixed(this.userdata.decimals_allowed);
				}
				if (element.retailprice != null && element.retailprice != 0) {
					element.retailprice = element.retailprice.toFixed(this.userdata.decimals_allowed);
				}
				else {
					element.retailprice = parseFloat("0").toFixed(this.userdata.decimals_allowed);
				}

				if (element.pocost != null && element.pocost != 0) {
					element.pocost = element.pocost.toFixed(this.userdata.decimals_allowed);
				}
				else {
					element.pocost = parseFloat("0").toFixed(this.userdata.decimals_allowed);
				}
				if (element.avgcost != null && element.avgcost != 0) {
					element.avgcost = element.avgcost.toFixed(this.userdata.decimals_allowed);
				}
				else {
					element.avgcost = parseFloat("0").toFixed(this.userdata.decimals_allowed);
				}
				if (element.pic == null) {
					element.filename = '/assets/images/no-image.png'

				} else {
					element.filename = environment.DOC_URL + '/' + this.userdata.companyid + '/' + element.pic
				}
				element.prefer_to_produce = element.prefer_to_produce ? 'Yes' : 'No';
				element.prefer_to_purchase = element.prefer_to_purchase ? 'Yes' : 'No';
			})

			this.products = this.prodList.reverse()
			//#region Get total rows
			this.totalRows = this.prodList.length;
			//#region Get total rows
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading product list ', err);
		});
	}

	onCustomAction(event): void {
		switch (event.action) {
			case 'view':
				this.modalService.open(this.content, { windowClass: 'index-class', backdropClass: 'index-backdrop' });
				this.printDiv('PrintTable')
				break;
			case 'edit':
				environment.production ? '' : console.log('edit', event);
				this.router.navigate(['/products/physical/add-product'])
				this.service.productDtForEdit = event.data;

				break
			case 'delete':
				alert('delete')
				break
		}
	}
	printDiv(divName) {
		var printContents = document.getElementById(divName).innerHTML;
		const originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();
		document.body.innerHTML = originalContents;
		window.location.reload();
	}
	setStatus() {
		var arrayData = []
		this.selectedProductstatus = [];
		if (this.filter.status) {
			environment.production ? '' : console.log('status list', this.filter.status.split());
			arrayData = this.filter.status.split(',');
		}
		if (arrayData.length == this.statusList.length) {
			this.selectedProductstatus = this.statusList;
		} else {
			arrayData.forEach(element => {
				this.statusList.forEach(mainStatus => {
					if (element == mainStatus.stringValue) {
						this.selectedProductstatus.push(mainStatus);
					}
				})
			})
		}
	}

	ngOnDestroy() {
		sessionStorage.setItem(this.componentName + '-FILTER_LIST', JSON.stringify(this.filter));
		this.modalService.dismissAll();

	}
	//#region NgAutocomplete
	onItemSelect(item: any) {
		let arrayData = [];
		this.selectedProductstatus.forEach(data => {
			arrayData.push('' + data.stringValue);
		})
		arrayData = arrayData.filter((c, index) => {
			return arrayData.indexOf(c) === index;
		});
		this.stringData = arrayData.toString();
		this.filter.status = this.stringData;
		environment.production ? '' : console.log(this.filter.status);
		this.gotoPage('first');
	}
	onSelectAll(items: any) {
		let arrayData = [];
		items.forEach(data => {
			arrayData.push('' + data.stringValue);
		});
		this.stringData = arrayData.toString();
		this.filter.status = this.stringData;
		this.gotoPage('first');
	}

	onItemDeSelect($event) {
		let arrayData = [];
		this.selectedProductstatus.forEach(data => {
			arrayData.push('' + data.stringValue);
		})
		arrayData = arrayData.filter((c, index) => {
			return arrayData.indexOf(c) === index;
		});
		this.stringData = arrayData.toString();
		this.filter.status = this.stringData;
		environment.production ? '' : console.log(this.filter.status);
		this.gotoPage('first');
	}
	//#endregion

}


@Component({
	selector: 'll-button-comp',
	template: ` 
	<div ngbDropdown class="d-inline-block ActionCell" container="body">
		<i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
		<ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
			<li *ngFor="let n of menuActions"><span ngbDropdownItem  (click)="clicked(n.title)">{{n.title}}</span></li>
		</ul> 
	</div>
	
`
})

export class ProductActionColumn implements OnInit {
	@Input() rowData: any;
	@Output() click: EventEmitter<any> = new EventEmitter(null);
	menuData: any;
	menuActions: any;
	closeResult: any;
	itemData: any;
	binList: any = []
	constructor(private loader: NgxUiLoaderService,
		private commonService: CommonServices,
		private router: Router,
		private modalService: NgbModal,
		private service: InventoryServices,
		private maincomponent: ProductListComponent,
		private navservice: NavService,
		private toaster: NotificationService) {
		this.menuData = JSON.parse(localStorage.getItem('EzyBooks-menu'));
		// 
		setTimeout(() => {
			this.menuData.forEach(data => {
				if (data.title.toLowerCase() == 'inventory') {
					var module = data;
					module.children.forEach(data => {
						if (data.title.toLowerCase() == 'product items') {
							this.menuActions = data.menuActions;
						}
					})
				}
			})
			// 
		}, 1000);
	}
	renderValue: string;
	ngOnInit() {
		this.renderValue = this.rowData.id;
		this.itemData = this.rowData;

	}
	clicked(actionname) {
		var modalRef = null;

		environment.production ? '' : console.log('itemdata', this.itemData);
		if (actionname.toLowerCase() == 'view') {
			const encrptData = btoa(this.rowData.id); // encode a string        
			environment.production ? '' : console.log('enycrypted data', encrptData);
			//set edit data to header component

			this.router.navigate(['products/add-product'], { queryParams: { id: encrptData, action: 'view' }, replaceUrl: true })
		}
		if (actionname.toLowerCase() == 'edit') {
			const encrptData = btoa(this.rowData.id); // encode a string   
			//set edit data to header component

			environment.production ? '' : console.log('enycrypted data', encrptData);
			this.router.navigate(['products/add-product'], { queryParams: { id: encrptData, action: 'edit' }, replaceUrl: true })
		}
		if (actionname.toLowerCase() == 'clone') {
			const encrptData = btoa(this.rowData.id); // encode a string   
			environment.production ? '' : console.log('enycrypted data', encrptData);
			this.router.navigate(['products/add-product'], { queryParams: { id: encrptData, action: 'add' }, replaceUrl: true })
		}
		if (actionname.toLowerCase() == 'transfer') {
			modalRef = this.modalService.open(TransferQtyComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = this.rowData;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);

			modalRef.result.then((result) => {
				this.maincomponent.gotoPage('first');
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			});

		}
		if (actionname.toLowerCase() == 'hold') {

			modalRef = this.modalService.open(HoldqtyComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = this.rowData;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);

			modalRef.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
				this.maincomponent.gotoPage('first');
			}, (reason) => {
				this.maincomponent.gotoPage('first');
			});
		}
		if (actionname.toLowerCase() == 'adjust') {
			modalRef = this.modalService.open(AdjustQtyComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = this.rowData;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);
			modalRef.result.then((result) => {
				if (result == 'save') {
					this.maincomponent.gotoPage('first');
				}
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			});
		}
		if (actionname.toLowerCase() == 'item analysis') {
			modalRef = this.modalService.open(ItemanalysisComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = this.rowData;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);

			modalRef.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			});

		}
		if (actionname.toLowerCase() == 'purchase history') {
			modalRef = this.modalService.open(PurchasehistoryComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = this.rowData;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);

			modalRef.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			});

		}
		if (actionname.toLowerCase() == 'item compatibility') {
			modalRef = this.modalService.open(ItemCompatibilityComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.fromParent = this.rowData;
			environment.production ? '' : console.log('id', modalRef.componentInstance.id);

			modalRef.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			});
		}

		if (actionname.toLowerCase() == 'print main ilc label') {
			modalRef = this.modalService.open(PrintlabelComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.id = this.rowData.id;
			modalRef.componentInstance.type = "p";
			modalRef.componentInstance.name = "1";
			modalRef.componentInstance.desc = "1";
			modalRef.componentInstance.alt = "0";
			// modalRef.componentInstance.name = 'printpo';
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});
		}
		if (actionname.toLowerCase() == 'print manufacturer ilc label') {
			modalRef = this.modalService.open(PrintlabelComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.id = this.rowData.id;
			modalRef.componentInstance.type = "p";
			modalRef.componentInstance.name = "1";
			modalRef.componentInstance.desc = "1";
			modalRef.componentInstance.alt = "2";
			// modalRef.componentInstance.name = 'printpo';
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});
		}
		if (actionname.toLowerCase() == 'print alt ilc label') {
			modalRef = this.modalService.open(PrintlabelComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'index-class', backdropClass: 'index-backdrop' })
			modalRef.componentInstance.id = this.rowData.id;
			modalRef.componentInstance.type = "p";
			modalRef.componentInstance.name = "1";
			modalRef.componentInstance.desc = "1";
			modalRef.componentInstance.alt = "1";
			// modalRef.componentInstance.name = 'printpo';
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});
		}
		if (actionname.toLowerCase() == 'document manager') {
			modalRef = this.modalService.open(DocumentsComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'index-class', backdropClass: 'index-backdrop' });
			modalRef.componentInstance.refno = this.rowData.ilc;
			modalRef.componentInstance.id = this.rowData.id;
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});

		}
		if (actionname.toLowerCase() == 'delete') {
			this.deleteProduct(this.rowData.id)
		}

		if (actionname.toLowerCase() == 'move serials') {
			modalRef = this.modalService.open(TransferserialsComponent, { size: 'md' });
			modalRef.componentInstance.fromParent = this.rowData;
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});
		}

		environment.production ? '' : console.log(actionname);
	}

	deleteProduct(id) {
		Swal.fire({
			title: 'Do you want to delete product?',
			// text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				this.loader.start();
				this.service.deleteProduct(id).subscribe(res => {
					environment.production ? '' : console.log('delete response ', res);
					this.loader.stop();
					this.toaster.Success('Product Deleted!');
					this.maincomponent.gotoPage('first')
				}, err => {
					this.loader.stop();
					environment.production ? '' : console.log('Error : While delete product ', err);
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}
	ngOnDestroy(): void {
		this.modalService.dismissAll();
	}
}


@Component({
	selector: 'll-button-comp',
	template: ` <div class="link cursor-pointer" data-toggle="modal" data-target="#exampleModal" (click)="open()">{{onHold}}</div>
`
})

export class OnHoldComponent implements OnInit {
	@Input() rowData: any;
	@Input() name: any;
	@Output() click: EventEmitter<any> = new EventEmitter(null);
	renderValue: string;
	appname = environment.APP_NAME;
	userdata: any;
	onHold: any;
	id: any;
	constructor(
		private modalService: NgbModal,
		private maincomponent: ProductListComponent
	) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
	}
	ngOnInit() {
		if (this.name == 'qtyonhand') {
			this.onHold = this.rowData.qtyonhand;
		}
		if (this.name == 'qtyhold') {
			this.onHold = this.rowData.qtyhold;
		}
		if (this.name == 'qtyavail') {
			this.onHold = this.rowData.qtyavail;
		}
		this.id = this.rowData.id;
	}

	open() {
		setTimeout(() => {
			var modalRef = this.modalService.open(BinlocationsListComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' });
			modalRef.componentInstance.id = this.rowData.id;
			modalRef.componentInstance.isSerilzed = this.rowData.isserilized;
			modalRef.result.then((result) => {
				this.maincomponent.gotoPage('first')
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				this.maincomponent.gotoPage('first')

				environment.production ? '' : console.log(error);
			});
		}, 500)
	}
}

@Component({
	selector: 'll-button-comp',
	template: ` <div class="link cursor-pointer" data-toggle="modal" data-target="#exampleModal" (click)="open()">{{onHold}}</div>
`
})

export class OrderHistoryComponent implements OnInit {
	@Input() rowData: any;
	@Output() click: EventEmitter<any> = new EventEmitter(null);
	renderValue: string;
	appname = environment.APP_NAME;
	userdata: any;
	onHold: any;
	id: any;
	constructor(
		private modalService: NgbModal,
	) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
	}
	ngOnInit() {
		this.onHold = this.rowData.qtyonorder;
		this.id = this.rowData.id;
		// console.log('rowData', this.rowData)

	}

	open() {
		var modalRef = this.modalService.open(PurchasehistoryComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' });
		modalRef.componentInstance.fromParent = this.rowData;
		modalRef.result.then((result) => {
			environment.production ? '' : console.log(result);
		}).catch((error) => {
			environment.production ? '' : console.log(error);
		});

	}

}
@Component({
	selector: 'll-button-comp',
	template: ` <div class="link cursor-pointer" data-toggle="modal" data-target="#exampleModal" (click)="open()">{{cnt}}</div>
`
})

export class SerialNosComponent implements OnInit {
	@Input() rowData: any;
	@Output() click: EventEmitter<any> = new EventEmitter(null);
	renderValue: string;
	appname = environment.APP_NAME;
	userdata: any;
	cnt: any;
	id: any;
	constructor(
		private modalService: NgbModal,
	) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
	}
	ngOnInit() {
		this.cnt = this.rowData.nos;
		this.id = this.rowData.id;
	}

	open() {
		var modalRef = this.modalService.open(SerialsListComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' });
		modalRef.componentInstance.itemdata = {
			prodid: this.rowData.id,
			bincode: '',
			serialno: '',
			status: 'availableforsale'
		}
		modalRef.result.then((result) => {
			environment.production ? '' : console.log(result);
		}).catch((error) => {
			environment.production ? '' : console.log(error);
		});

	}

}

@Component({
	selector: 'my-name-component',
	template: `<span (click)="gotoEdit()" style="color:blue;cursor: pointer;">{{name}}</span>`
})
export class openproductcomponent implements OnInit {
	@Input() value;
	@Input() rowData: any;

	name;
	constructor(private router: Router) { }

	ngOnInit() {
		this.name = this.value.ilc

	}
	gotoEdit() {
		const encrptData = btoa(this.rowData.id);
		this.router.navigate(['products/add-product'], { queryParams: { id: encrptData, action: 'edit' }, replaceUrl: true })
		return;
		const url = this.router.serializeUrl(this.router.createUrlTree(['products/add-product'], { queryParams: { id: encrptData, action: 'edit' } }));
		window.open(url, '_blank');
	}
}

