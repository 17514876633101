<resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="productModalLabel">Bin Locations
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="custom-row">
      <div class="form-row ">
        <div class="col-lg-1 col-md-1 form-group">
          <label>For Sale</label>
        </div>
        <div class="col-lg-2 col-md-2 form-group">
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="">
              <input class="radio_animated" id="" type="radio" [value]="true" name="forsale" [(ngModel)]="forsale"
                (change)="getProductBins()">
              Yes
            </label>
            <label class="d-block" for="">
              <input class="radio_animated" id="" type="radio" [value]="false" name="forsale" [(ngModel)]="forsale"
                (change)="getProductBins()">
              No
            </label>
          </div>
        </div>
        <div class="col-lg-9 col-md-9 form-group">
          <!-- <input type="text" class="form-control" placeholder="Search bin locations.."> -->
          <input type="search" class="form-control placehder" name="" placeholder="&#xF002; Search"
            [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" (input)="onSearchChange()">
        </div>
      </div>
      <!-- <div class="btn-group CsearchBtn1 ml-2" role="group">
      <button type="button" class="btn btn-primary right-radius">Search</button>
    </div> -->
    </div>
    <div class="table-responsive2">
      <table class="custom-table">
        <thead>
          <tr class="custom-tr">
            <th class="custom-th text-center">Bin</th>
            <th class="custom-th text-center">Description</th>
            <th class="custom-th text-center">On Hand</th>
            <th class="custom-th text-center">On Hold</th>
            <th class="custom-th text-center">Available</th>
            <th class="custom-th text-center">Active</th>
          </tr>
        </thead>
        <tbody>
          <tr class="custom-tr" *ngFor="let item of filteredItems">

            <td class="custom-td text-center">{{item.code}}</td>
            <td class="custom-td text-left">{{item.description}}</td>
            <td class="custom-td text-center">

              <span *ngIf="Serilzed" style="color:blue; cursor: pointer;"
                (click)="serialModal(item)">{{item.cnt}}</span>
              <span *ngIf="!Serilzed">{{item.cnt}}</span>

            </td>
            <td class="custom-td text-center" style="color: blue; cursor: pointer;"><span
                (click)="openBinHoldDetail(item)">{{item.onhold}}</span></td>
            <td class="custom-td text-center">{{item.availableqty}}</td>
            <td class="custom-td text-center">{{item.active}}</td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
  </div>
</resize-border>
<ngx-ui-loader></ngx-ui-loader>