import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/shared/service/language.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SalesServices } from '../../Sales/sales.service';

@Component({
  selector: 'app-customersite',
  templateUrl: './customersite.component.html',
  styleUrls: ['./customersite.component.scss']
})
export class CustomersiteComponent implements OnInit {
  fullScreen = false;
  @Input('customerid') customerid;
  appname = environment.APP_NAME;
  userdata: any;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  site: any = {
    isprimary: false,
    specmap: false
  }
  filename: any;
  uploader: FileUploader;
  private langChangeSubscription: Subscription; 
  constructor(
    private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient, 
    private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private datepipe: DatePipe,
    private saleService: SalesServices,
    private modalService: NgbModal,
    private toastr: NotificationService,
  ) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
  }

  addSites() {
    if (this.site.name == undefined || this.site.name == null) {
      this.toastr.Error('Enter site name')
      return;
    }
    if (this.site.id != undefined && this.site.id != null) {
      this.site.updateby = this.userdata.email;
      this.site.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.site.createby = this.userdata.email;
      this.site.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.site.active = true;
    }
    this.site.customerid = this.customerid;
    this.site.companyid = this.userdata.companyid;
    this.site.pic = this.filename ? this.filename : null;

    this.loader.start();
    this.saleService.postSites(this.site).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          if (this.filename != undefined) {
            this.uploadFile();
          }
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal('saved');
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While post customer sites ', err);
    });
  }
  selectedFileOnChanged(event: any) {
    environment.production ? '' : console.log(event.target.value);
    this.filename = event.target.value.split(/(\\|\/)/g).pop()
  }

  initializeFileUploader() {
    this.uploader = new FileUploader({
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + this.userdata.token,
      url: environment.API_URL + 'api/Documents/UploadFiles',
      method: 'POST',
      removeAfterUpload: true,
      queueLimit: 100
    })
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('companyid', this.userdata.companyid);
      form.append('createby', this.userdata.email);
      form.append('refno', this.customerid);
      form.append('createdate', this.datepipe.transform(new Date(), this.userdata.postdatetimeformat));
      form.append('dtype', 'cust-site-file');
    };
    // this.response = '';
    this.uploader.response.subscribe(res => {
      environment.production ? '' : console.log('res ' + res);
      // this.toastr.Success("File uploaded sucessfully")
      // this.response = res
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
  }
  uploadFile() {
    this.uploader.queue[0].onSuccess = function (response, status, headers) {
      if (status == 200) {
        let tempRes = JSON.parse(response);
      } else {
        // Swal.fire('', 'File uploaded', 'success')
      }
    };
    this.uploader.queue[0].upload();
  }

  closeModal(value) {
    this.activeModal.close(value);
  }
  ngOnInit(): void {
    console.log('selected customer id', this.customerid);
 
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
  }
   
private loadComponentTranslations(lang: string) { 
  const translationFilePath = `./assets/i18n/servicemanager/servicecontracts/basicinfo/${lang}.json`; 
  this.http.get(translationFilePath).subscribe((translations:any) => { 
  this.translate.setTranslation(lang, translations, true); 
  this.translate.use(lang); 
  }); 
   } 
  
 

}
