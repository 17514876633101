<div class="custom-row">
  <div class="form-row">
    <div class="col-lg-1 col-md-6 form-group pl-0">
      <label for="fromdate">OnHand Qty</label><br>
      <p class="form-control text-center badge">{{product.qtyonhand}}</p>
    </div>
    <div class="col-lg-1 col-md-6 form-group pl-0">
      <label for="fromdate"> Onorder Qty</label><br>
      <p class="form-control text-center badge">{{product.qtyonorder}}</p>
    </div>
    <div class="col-lg-1 col-md-6 form-group pl-0">
      <label for="fromdate">Available Qty</label><br>
      <p class="form-control text-center badge">{{product.qtyavailable}}</p>
    </div>
    <div class="col-lg-1 col-md-6 form-group pl-0">
      <label for="fromdate">Hold Qty</label><br>
      <p class="form-control text-center badge">{{product.qtyonhold}}</p>
    </div>
    <div class="col-lg-4 col-md-6 form-group pl-0">
      <label for="fromdate">Search by Ref#, Ref Details</label>
      <input class="form-control" type="text" [(ngModel)]="searchtext" name="searchtext"
        placeholder="Ref#, Ref Details" />
    </div>
    <div class="col-lg-2 col-md-6 form-group pl-0">
      <label for="fromdate">From Date</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker465" [(ngModel)]="fromdate" placeholder="Choose a date" name="fromdate">
        <mat-datepicker-toggle matSuffix [for]="picker465"></mat-datepicker-toggle>
        <mat-datepicker #picker465></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-2 col-md-6 form-group pl-0">
      <label for="fromdate">To Date</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker466" [(ngModel)]="todate" placeholder="Choose a date" name="todate">
        <mat-datepicker-toggle matSuffix [for]="picker466"></mat-datepicker-toggle>
        <mat-datepicker #picker466></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="btn-group CsearchBtn" role="group">
    <button type="button" (click)="getProductTransactions()" class="btn btn-primary">Search</button>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable  inventorytransactionhistorytable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center" >Action</th>
            <th class="custom-th text-center" (click)="onSortClick($event,'id')">TxnID<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'trandate')">TxnDate<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'from_bin_location')">From Location<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'to_bin_location')">To Location<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'txnqty')">TxnQty<i class="Sorting fa fa-chevron-up"></i></th>
            <!-- <th class="custom-th text-center">Balance</th> -->
            <th class="custom-th text-center"(click)="onSortClick($event,'uom')">UOM<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'pocost')">PO Cost<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'avgcost_weighted')">AVG Cost(Weighted)<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'avgcost_fifo')">AVG Cost(FIFO)<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'saleprice')">Price<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'trantype')">TxnType<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'serial')">Serial<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'tranby')">TxnBy<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'receipt')">Customer<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'receipt')">Receipt<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'refno')">Refno#<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'ref_details')">Ref Details<i class="Sorting fa fa-chevron-up"></i></th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of transactionList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="undoProductTrans(item)">Undo</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.id}}</td>
            <td class="custom-td text-left">{{item.trandate| date:userdata.date_format}}</td>
            <td class="custom-td text-left">{{item.from_bin_location}}</td>
            <td class="custom-td text-left">{{item.to_bin_location}}</td>
            <td class="custom-td text-center">{{item.txnqty}}</td>
            <!-- <td class="custom-td text-right">{{item.balance}}</td> -->
            <td class="custom-td text-left">{{item.uom}}</td>
            <td class="custom-td text-left">{{item.pocost}}</td>
            <td class="custom-td text-right">{{item.avgcost_weighted}}</td>
            <td class="custom-td text-right">{{item.avgcost_fifo}}</td>
            <td class="custom-td text-right">{{item.saleprice}}</td>
            <td class="custom-td text-left">{{item.trantype}}</td>
            <td class="custom-td text-left">{{item.serial}}</td>
            <td class="custom-td text-left">{{item.tranby}}</td>
            <td class="custom-td text-left">{{item.customer}}</td>
            <td class="custom-td text-left">{{item.receipt}}</td>
            <td class="custom-td text-left">{{item.refno}}</td>
            <td class="custom-td text-left">{{item.ref_details}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>