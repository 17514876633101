import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { purchaseServices } from '../../purchase/purchase.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { SupplierListComponent } from '../supplier-list/supplier-list.component';
import { PartysComponent } from '../partysmodal/partysmodal.component';
import { ProjjoblistmodalComponent } from '../projjoblistmodal/projjoblistmodal.component';
import { CommonServices } from 'src/app/shared/service/common.service';
import { param } from 'jquery';

@Component({
  selector: 'app-expensereqmodal',
  templateUrl: './expensereq.component.html',
  styleUrls: ['./expensereq.component.scss']
})
export class ExpenseReqModalComponent implements OnInit {
  fullScreen = false;
  @Input() params;
  poDetails: any = {
    costingItems: []
  };
  itemStatus: any = ''
  userdata: any;
  fieldsetDisabled = false;
  expRequestData: any = {
    "id": null,
    "submittedby": null,
    "expensedate": null,
    "description": "",
    "notes": "",
    "seq": null,
    "reqno": "",
    "companyid": null,
    "projectid": null,
    "approvedby": "",
    "receiptno": "",
    "expno": "",
    "status": "pending",
    "currency": "",
    "ticketid": null,
    "supplierid": null,
    "supplier": "",
    "tags": "",
    "roe": 0,
    "tpcode": "",
    "reimbursable": false,
    "projref": "",
    "payment_mode": "",
    "cheque_no": "",
    "amount": 0,
    "createby": "",
    "createdate": null,
    "updateby": "",
    "updatedate": null
  }
  private langChangeSubscription: Subscription;
  appname = environment.APP_NAME;
  reimbursTypes: any = [];
  partyTypeList: any = [];
  totalTaxableAmount = 0;
  employeeList: any = [];
  statusList: any = [];
  paymentModes: any = [];
  currencyList: any = [];
  isSaved = false;
  @ViewChild(SupplierListComponent, { static: false }) SupplierComponent: SupplierListComponent;
  @ViewChild(PartysComponent, { static: false }) PartyComponent: PartysComponent;
  @ViewChild(ProjjoblistmodalComponent, { static: false }) ProjectsComponent: ProjjoblistmodalComponent;
  constructor(private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private http: HttpClient,
    private commonService: CommonServices,
    private toaster: NotificationService,
    private languageService: LanguageService, private loader: NgxUiLoaderService, private toastr: NotificationService, private datepipe: DatePipe, private service: purchaseServices, private modalService: NgbModal) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.getEmployeeList(); this.service.getCurrency().subscribe(res => {
      this.currencyList = res;
      this.selectROE();
    }, err => {
    });
    this.commonService.getComboEnums('expreqpaymentmode').subscribe(res => {
      this.paymentModes = res;
    }, err => {
    });
    this.commonService.getComboEnums('expreqstatus').subscribe(res => {
      this.statusList = res;
    }, err => {
    });
  }

  addExpenseReq() {
    if (this.expRequestData.id) {
      this.expRequestData.updateby = this.userdata.email;
      this.expRequestData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.expRequestData.t_expensetracking = this.expRequestData.expenseTrackings;
      this.expRequestData.t_exptransactions = this.expRequestData.expenseTransactions;
    }
    else {
      this.expRequestData.companyid = this.userdata.companyid;
      this.expRequestData.createby = this.userdata.email;
      this.expRequestData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.expRequestData.expensedate = this.datepipe.transform((this.expRequestData.expensedate), this.userdata.postdatetimeformat);
    this.loader.start();
    this.service.postExpenseReuest(this.expRequestData).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isSaved = true;
      }
      this.closeModal();
    }, err => {
      this.loader.stop()
      environment.production ? '' : console.log('Error : While loading post expense ', err);
    });
  }

  ngOnInit(): void {
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => {
      this.loadComponentTranslations(lang);
    });
    if (this.params.ticketid) {
      this.expRequestData.projref = this.params.projref;
      this.expRequestData.ticketid = this.params.ticketid;
    }
    if (this.params.id) {
      this.getExpReqDetails(this.params.id);
    } else {
      this.expRequestData.expensedate = this.datepipe.transform(new Date(), this.userdata.date_format);
    }
  }
  getExpReqDetails(id) {
    this.loader.start();
    this.service.getExpenseReuestByID(id).subscribe(res => {
      this.loader.stop();
      this.expRequestData = res;
      this.expRequestData.submittedby = this.expRequestData.submittedby ? '' + this.expRequestData.submittedby : null;
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
  getEmployeeList() {
    this.loader.start();
    this.commonService.getEmployees().subscribe(data => {
      this.loader.stop();
      this.employeeList = data;
    }, err => {
    });
  }

  getCurrentROE() {
    if (!this.expRequestData.currency) {
      this.toaster.Error('Please select currency');
      return;
    }
    this.loader.start();
    this.commonService.getExchangeRate(this.userdata.currency, this.expRequestData.currency).subscribe(res => {
      this.loader.stop();
      this.expRequestData.roe = res ? Number(res).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    }, err => {
      this.loader.stop();
    });
  }

  private loadComponentTranslations(lang: string) {
    const translationFilePath = `./assets/i18n/purchase/expenserequest/basic/${lang}.json`;
    this.http.get(translationFilePath).subscribe((translations: any) => {
      this.translate.setTranslation(lang, translations, true);
      this.translate.use(lang);
    });
  }

  SelectedSupplier() {
    this.SupplierComponent.islocal = this.expRequestData.supplierid ? false : true;
    if (this.SupplierComponent.selectedSupplier) {
      if (this.SupplierComponent.selectedSupplier != null) {
        if (this.SupplierComponent.selectedSupplier.id == undefined) {
          return;
        }
        this.expRequestData.supplierid = this.SupplierComponent.selectedSupplier.id
        this.expRequestData.supplier = this.SupplierComponent.selectedSupplier.name;
        this.expRequestData.payment_terms = this.SupplierComponent.selectedSupplier.paymentterms;
        this.expRequestData.currency = this.SupplierComponent.selectedSupplier.currency;
        this.selectROE();
      }
    }
  }
  selectedJob() {
    this.ProjectsComponent.searchtxt = this.expRequestData.projref ? this.expRequestData.projref : '';
    this.ProjectsComponent.customerid = '';
    if (this.ProjectsComponent.selectedJob) {
      if (this.ProjectsComponent.selectedJob == null || this.ProjectsComponent.selectedJob == '') {
        return;
      }
      this.expRequestData.projref = this.ProjectsComponent.selectedJob.tpcode ? this.ProjectsComponent.selectedJob.tpcode : this.ProjectsComponent.selectedJob.proj;
      if (this.ProjectsComponent.selectedJob.type == 'project') {
        this.expRequestData.projid = this.ProjectsComponent.selectedJob.refid;
      } else if (this.ProjectsComponent.selectedJob.type == 'ticket') {
        this.expRequestData.ticketid = this.ProjectsComponent.selectedJob.refid;
      }
    }
  }

  selectROE() {
    this.currencyList.forEach(element => {
      if (this.expRequestData.currency == element.Value) {
        this.expRequestData.roe = Number(element.Value2).toFixed(this.userdata.decimals_allowed) ? Number(element.Value2).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      }
      else if (!this.expRequestData.currency) {
        this.expRequestData.roe = '';
      }
    })
  }
  closeModal() {
    this.activeModal.close(this.isSaved ? 'saved' : 'modal closed');
  }


}
