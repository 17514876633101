<div class="pictureTab">
  <div class="w-100 text-right">
    <div class="file-input" [class.disabled]="fieldsetDisabled">
      <input type="file" name="file-input" id="file-input" class="file-input__input" ng2FileSelect [uploader]="uploader"
        (change)="selectedFileOnChanged($event)" accept="application/pdf, image/gif, image/jpeg, image/png" />
      <label class="file-input__label btn btn-primary" for="file-input">
        <span>Add Picture</span></label>
    </div>
  </div>
  <div class="pictures-flex row">
    <div class="col-xxl-2 col-lg-3 col-md-4 col-sm-6" *ngFor="let item of picList">
      <div class="pictures-div">
        <img [src]="filepath + item.filename" type="_blank">{{item.file_name}}
        <div class="actions">
          <div class="check-div" ng-if="!this.showMain"><input type="checkbox" (change)="changeStatus(item)"
              [(ngModel)]="item.mainpic" [disabled]="fieldsetDisabled"> Main Pic </div>
          <div class="buttons">
            <div class="action-button">
              <i class="fa fa-eye cursor-pointer" (click)="viewDocument(item)"></i>
            </div>
            <div class="action-button">
              <i class="fa fa-trash cursor-pointer" (click)="deletePic(item)" [class.disabled]="fieldsetDisabled"></i>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- <div class="pictures-flex">
    <div class="pictures-div" *ngFor="let item of picList">
      <img [src]="filepath + item.filename" type="_blank">{{item.file_name}}
      <div class="actions">
        <div class="check-div" ng-if="!this.showMain"><input type="checkbox" (change)="changeStatus(item)" [(ngModel)]="item.mainpic"> Main Pic </div>
        <div class="buttons">
          <div class="action-button">
            <i class="fa fa-eye cursor-pointer" (click)="viewDocument(item)"></i>
          </div>
          <div class="action-button">
            <i class="fa fa-trash cursor-pointer" (click)="deletePic(item)" [class.disabled]="fieldsetDisabled"></i>
          </div>
        </div>
      </div>
    </div>

  </div> -->
</div>

<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Add Product review</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation">
      <div class="form">
        <div class="form-group">
          <label for="" class="mb-1">Select File :</label>
          <input class="form-control" type="file" ng2FileSelect [uploader]="uploader"
            (change)="selectedFileOnChanged($event)" accept=".xlsx,.xls,image/*" accept="image/jpeg, image/png" />
        </div>
        <div class="form-group">
          <label for="" class="mb-1">Is main picture :</label>
          <select class="form-control" required="" name="supplier" [(ngModel)]="pictureData.mainpic">
            <option value="">Select</option>
            <option [value]="true">Yes</option>
            <option [value]="false">No</option>
          </select>
        </div>

      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
      (click)="modal.dismiss('Cross click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="postPictureData()">Save</button>
  </div>
  </resize-border>
</ng-template>
