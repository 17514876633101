import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { serviceManagerServices } from '../../servicemanager/servicemanager.service';
import { NgxPrintElementService } from 'ngx-print-element';
@Component({
  selector: 'app-ticketcopy',
  templateUrl: './ticketcopy.component.html',
  styleUrls: ['./ticketcopy.component.scss']
})
export class TicketCopyComponent implements OnInit {
  fullScreen = false;
  @ViewChild('printButton') printButton: ElementRef;
  closeResult: string;
  @Input('fromdate') fromdate;
  @Input('todate') todate;
  @Input('status') status;
  @Input('id') id;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  ticketsBatchPrintList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  isShowCopanyLogo=false;
  public config = {
    printMode: 'template-popup',
    pageTitle: ' ',
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    styles: ['#print-section {width:100%;display: block !important; page-break-after: always; font-size:16px !important}','.TaskPrint {width:100%;}', 'body { width:100%; font-size:16px !important}', 'table {width:100%;  font-size:16px !important}', '@page {size: A4;  font-size:16px !important}','table tr th, table tr td {font-size:16px !important}']
  }
  @ViewChild('content') public content: any;
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: serviceManagerServices,public print: NgxPrintElementService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.companyLogo += this.userdata.logo;
  }

  ngOnInit() {
    if (this.id != undefined && this.id != '' && this.id != null) {
      this.getTicketDetails(this.id);
    } else {
      this.getTicketListForBatchPrint();
    }
    setTimeout(() => {
      //document.getElementById("printButton").click();
    }, 2000)
  }


  getTicketDetails(ticketid) {
    this.service.getTicketDetails(ticketid).subscribe(res => {
      if (res != null) {
        this.ticketsBatchPrintList.push(res);
      }
    }, err => {
      environment.production ? '' : console.log('Error : While loading currency data', err);
    })
  }
  getTicketListForBatchPrint() {
    this.loader.start();
    this.service.getTicketsForBatchPrint(this.fromdate, this.todate, this.status).subscribe(res => {
      this.loader.stop();
      this.ticketsBatchPrintList = res;
      // this.open(this.content);
      environment.production ? '' : console.log('ticket batch list data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading ticket list ', err);
    });
  }
  getDismissReason(reason: any) {
    throw new Error('Method not implemented.');
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}
