import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'binLocatFilter',
  pure: false
})
export class BinLocationFilterPipe implements PipeTransform {

  transform(items: any[], term): any {
    if (items?.length === 0 || term == '') {
      return items;
    }
    const data = [];
     for(const item of items) {
            if( item['binid']?.toLowerCase().includes(term.toLowerCase()) 
            || item.description?.toLowerCase().includes(term.toLowerCase()) 
           ) {
                data.push(item);
            }
        }
        return data;
  }

}
