import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AppServices } from 'src/app/shared/service/app.services';
import { InventoryServices } from 'src/app/components/inventory/inventory.service';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { AddProductComponent } from '../add-product.component';
import Swal from 'sweetalert2';
import { I } from '@angular/cdk/keycodes';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { AdjustQtyComponent } from '../../modal/adjust-qty/adjust-qty.component';
import { ProductBinComponent } from '../../modal/product-bin/product-bin.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-binlocations',
	templateUrl: './binlocations.component.html',
	styleUrls: ['./binlocations.component.scss']
})
export class BinlocationsComponent implements OnInit {
	public closeResult: string;
	public categories = []
	bin
	searchText: string = '';
	bins: any;
	totalOnhandQty: any = 0
	totalMax: any = 0;
	totalMin: any = 0;
	totalSold: any = 0;
	totalAvail: any = 0;
	totalOnHold: any = 0;
	binLocationList: any = [];
	binData: any = {
		"active": false,
		"companyid": '',
		"cnt": 0,
		"binid": '',
		"onhold": 0,
		"sold": 0,
		"minqty": 0,
		"maxqty": 0,
		"availableqty": 0,
		"reorderpoint": 0,
		'reorderqty': 0,
		"notes": '',
		"createby": '',
		"createdate": '',
		"updateby": '',
		"updatedate": '',
	}
	binList: any = [];
	userdata: any;
	appname = environment.APP_NAME;
	isView: boolean = false;
	response: any;
	lastaction: any;
	fieldsetDisabled = false;
	product: any;
	forsale = true;
	private langChangeSubscription: Subscription;
	constructor(private modalService: NgbModal,
		private service: InventoryServices,
		private datepipe: DatePipe,
		private toastr: NotificationService,
		private translate: TranslateService,
		private languageService: LanguageService,
		private http: HttpClient,
		private route: ActivatedRoute,
		private maincomponent: AddProductComponent,
		private loader: NgxUiLoaderService,
		private commonService: AppServices) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
		this.product = maincomponent.product;
		this.lastaction = this.route.snapshot.queryParamMap.get('action');
		if (this.lastaction == 'view') {
			this.fieldsetDisabled = true;
		} else if (this.lastaction == 'edit') {
			this.fieldsetDisabled = false;
		}
		this.getProductBins();
	}
	getProductBins() {
		this.loader.start();
		this.service.getProductBins(this.product.id, this.forsale ? 1 : 0).subscribe(res => {
			this.loader.stop();
			this.binLocationList = res;
			this.setProductBins();
		}, err => {
			this.loader.stop();
		});
	}
	setProductBins() {
		this.totalOnhandQty = 0;
		this.totalMax = 0;
		this.totalMin = 0;
		this.totalAvail = 0;
		// this.totalSold = 0
		this.totalOnHold = 0
		this.binLocationList.forEach(element => {
			element.cnt = element.cnt ? element.cnt : 0
			element.maxqty = element.maxqty ? element.maxqty : 0;
			element.minqty = element.minqty ? element.minqty : 0;
			element.availableqty = element.availableqty ? element.availableqty : 0;
			element.sold = element.sold ? element.sold : 0;
			element.onhold = element.onhold ? element.onhold : 0;
			if (element.active == true) {
				this.totalOnhandQty += element.cnt;
				this.totalMax += element.maxqty;
				this.totalMin += element.minqty;
				this.totalAvail += element.availableqty;
				// this.totalSold += element.sold;
				this.totalOnHold += element.onhold;
			}
			element.binid = element.binid.toString();
		});
	}

	ngOnInit(): void {
		this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => {
			this.loadComponentTranslations(lang);
		});
	}
	private loadComponentTranslations(lang: string) {
		const translationFilePath = `./assets/i18n/inventory/productitems/binlocations/${lang}.json`;
		this.http.get(translationFilePath).subscribe((translations: any) => {
			this.translate.setTranslation(lang, translations, true);
			this.translate.use(lang);
		});
	}
	deleteProdBin(item) {
		Swal.fire({
			title: 'Do you want to delete bin location?',
			// text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				if (item.id == undefined || item.id == null) {
					return;
				}
				this.service.deleteProdBin(item.id).subscribe((res: any) => {
					environment.production ? '' : console.log('delete response ', res);
					this.loader.stop();
					if (res != null) {
						if (res.status_code == "1") {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
						} else {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
							return;
						}
						this.getProductBins();
					}
				}, err => {
					this.loader.stop();
					environment.production ? '' : console.log('Error : While loading get purchase req action ', err);
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}
	changeForsale(item) {
		Swal.fire({
			title: 'Are you sure want to update bin forsale?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				this.loader.start();
				this.service.getToggleBinForSale(item.binid).subscribe((res: any) => {
					environment.production ? '' : console.log('delete response ', res);
					this.loader.stop();
					if (res != null) {
						if (res.status_code == "1") {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
						} else {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
							return;
						}
						this.getProductBins();
					}
				}, err => {
					this.loader.stop();
					environment.production ? '' : console.log('Error : While loading get purchase req action ', err);
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {

			}
		})
	}
	changeIncludeOnhand(item) {
		Swal.fire({
			title: 'Are you sure want to update bin include onhand?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				this.loader.start();
				this.service.getBinIncludeOnHand(item.binid).subscribe((res: any) => {
					environment.production ? '' : console.log('delete response ', res);
					this.loader.stop();
					if (res != null) {
						if (res.status_code == "1") {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
						} else {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
							return;
						}
						this.getProductBins();
					}
				}, err => {
					this.loader.stop();
					environment.production ? '' : console.log('Error : While loading get purchase req action ', err);
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {

			}
		})
	}
	adjustQty(item) {
		var modalRef = this.modalService.open(AdjustQtyComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
		modalRef.componentInstance.fromParent = { id: this.maincomponent.product.id, frombinlocation: item.binid };
		environment.production ? '' : console.log('id', modalRef.componentInstance.id);
		modalRef.result.then((result) => {
			if (result == 'save') {
				this.getProductBins();
			}
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		});
	}
	openProductBin(id, action) {
		var modalRef = this.modalService.open(ProductBinComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' })
		modalRef.componentInstance.params = id ? { id: id, action: action } : { prodid: this.product.id, action: action };
		environment.production ? '' : console.log('id', modalRef.componentInstance.id);
		modalRef.result.then((result) => {
			if (result == 'save') {
				this.getProductBins();
			}
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		});
	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	//#region Sorting Table Header
	sortDir = 1;//1= 'ASE' -1= DSC
	onSortClick(event) {
		let target = event.currentTarget,
			childtarget = target.querySelector('i'),
			classList = childtarget.classList;

		if (classList.contains('fa-chevron-up')) {
			classList.remove('fa-chevron-up');
			classList.add('fa-chevron-down');
			target.classList.add('Sorted');
			this.sortDir = -1;
		} else {
			classList.add('fa-chevron-up');
			classList.remove('fa-chevron-down');
			target.classList.remove('Sorted');
			this.sortDir = 1;
		}
		this.sortArr('description');
	}

	sortArr(colName: any) {
		this.binLocationList.sort((a, b) => {
			let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
			let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

			if (!valueA && !valueB) {
				return 0;
			}
			if (!valueA) {
				return -this.sortDir;
			}
			if (!valueB) {
				return this.sortDir;
			}

			if (valueA > valueB) {
				return this.sortDir;
			}
			if (valueA < valueB) {
				return -this.sortDir;
			}
			return 0;
		});
	}
	//#endregion Sorting Table Header

	closeModal() {
		this.modalService.dismissAll();
	}

}
