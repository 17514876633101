<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="productModalLabel">Sites
            </h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
              </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="custom-row">
                <div class="form-row ">
                    <div class="offset-6 col-lg-6 col-md-6 form-group">
                        <label for="">Name</label>
                        <input type="search" class="form-control" [(ngModel)]="site.name" name="name"
                            placeholder="Name">
                    </div>
                </div>
                <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
                    <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary right-radius"
                        (click)="getSite()">Search</button>
                </div>
            </div>
            <div class="category-table custom-datatable">
                <div class="table-responsive">
                    <table class="custom-table">
                        <thead class="sticky-tab-head">
                            <tr class="custom-tr">
                                <th class="custom-th text-center">Name</th>
                                <th class="custom-th text-center">Address1</th>
                                <th class="custom-th text-center">Address2</th>
                                <th class="custom-th text-center">Address3</th>
                                <th class="custom-th text-center">Address4</th>
                                <th class="custom-th text-center">Directions</th>
                                <th class="custom-th text-center">East</th>
                                <th class="custom-th text-center">North</th>
                                <th class="custom-th text-center">South</th>
                                <th class="custom-th text-center">West</th>
                                <th class="custom-th text-center">Lat</th>
                                <th class="custom-th text-center">Lng</th>
                                <th class="custom-th text-center">Pic</th>
                                <th class="custom-th text-center">ShortName</th>
                                <th class="custom-th text-center">Zip</th>
                                <th class="custom-th text-center">Specmap</th>
                                <th class="custom-th text-center">Type</th>
                                <th class="custom-th text-center">Active</th>
                                <th class="custom-th text-center">CreateBy</th>
                                <th class="custom-th text-center">CreateDate</th>
                                <th class="custom-th text-center">UpdateBy</th>
                                <th class="custom-th text-center">UpdateDate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="custom-tr" *ngFor="let item of siteList">
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.name}}</span>
                                </td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.address1}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.address2}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.address3}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.address4}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.directions}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.east}}</span>
                                </td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.north}}</span>
                                </td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.south}}</span>
                                </td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.west}}</span>
                                </td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.lat}}</span>
                                </td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.lng}}</span>
                                </td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.pic}}</span>
                                </td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.shortname}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.zip}}</span>
                                </td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectSite(item)">{{item.specmap}}</span></td>
                                <td class="custom-td text-left hyperlink">{{item.type}}</td>
                                <td class="custom-td text-center"><span class="badge"
                                        title="{{item.active}}">{{item.active}}</span>
                                </td>
                                <td class="custom-td text-left">{{item.createby}}</td>
                                <td class="custom-td text-center text-nowrap">{{item.createdate |
                                    date:userdata.displaydatetimeformat}}
                                </td>
                                <td class="custom-td text-left">{{item.updateby}}</td>
                                <td class="custom-td text-center text-nowrap">{{item.updatedate |
                                    date:userdata.displaydatetimeformat}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
        </div>
    </div>
</resize-border>