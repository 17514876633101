<div class="custom-row">
  <div class="form-row">
    <div class="col-lg-1 col-md-6 form-group pl-0">
      <label for="fromdate">{{ 'Form_Inputs.OnHandQty_Label' | translate }}</label><br>
      <p class="form-control text-center badge">{{product.qtyonhand}}</p>
    </div>
    <div class="col-lg-1 col-md-6 form-group pl-0">
      <label for="fromdate"> {{ 'Form_Inputs.OnOrderQty_Label' | translate }}</label><br>
      <p class="form-control text-center badge">{{product.qtyonorder}}</p>
    </div>
    <div class="col-lg-1 col-md-6 form-group pl-0">
      <label for="fromdate">{{ 'Form_Inputs.AvailableQty_Label' | translate }}</label><br>
      <p class="form-control text-center badge">{{product.qtyavailable}}</p>
    </div>
    <div class="col-lg-1 col-md-6 form-group pl-0">
      <label for="fromdate">{{ 'Form_Inputs.HoldQty_Label' | translate }}</label><br>
      <p class="form-control text-center badge">{{product.qtyonhold}}</p>
    </div>
    <div class="col-lg-4 col-md-6 form-group pl-0">
      <label for="fromdate">{{ 'Form_Inputs.SearchByRef_Label' | translate }}</label>
      <input class="form-control" type="text" [(ngModel)]="searchtext" name="searchtext"
        placeholder="{{ 'Form_Inputs.SearchByRef_Label' | translate }}" />
    </div>
    <div class="col-lg-2 col-md-6 form-group pl-0">
      <label for="fromdate">{{ 'Form_Inputs.FromDate_Label' | translate }}</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker465" [(ngModel)]="fromdate" placeholder="{{ 'Form_Inputs.FromDate_Placeholder' | translate }}" name="fromdate">
        <mat-datepicker-toggle matSuffix [for]="picker465"></mat-datepicker-toggle>
        <mat-datepicker #picker465></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-2 col-md-6 form-group pl-0">
      <label for="fromdate">{{ 'Form_Inputs.ToDate_Label' | translate }}</label>
      <mat-form-field>
        <input matInput [matDatepicker]="picker466" [(ngModel)]="todate" placeholder="{{ 'Form_Inputs.ToDate_Placeholder' | translate }}" name="todate">
        <mat-datepicker-toggle matSuffix [for]="picker466"></mat-datepicker-toggle>
        <mat-datepicker #picker466></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="btn-group CsearchBtn" role="group">
    <button type="button" (click)="getProductTransactions()" class="btn btn-primary">{{ 'Form_Inputs.Search_Button' | translate }}</button>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable  inventorytransactionhistorytable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center" >{{ 'Table_Columns.Action_Column' | translate }}</th>
            <th class="custom-th text-center" (click)="onSortClick($event,'id')">{{ 'Table_Columns.TxnID_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'trandate')">{{ 'Table_Columns.TxnDate_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'from_bin_location')">{{ 'Table_Columns.FromLocation_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'to_bin_location')">{{ 'Table_Columns.ToLocation_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'txnqty')">{{ 'Table_Columns.TxnQty_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <!-- <th class="custom-th text-center">Balance</th> -->
            <th class="custom-th text-center"(click)="onSortClick($event,'uom')">{{ 'Table_Columns.UOM_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'pocost')">{{ 'Table_Columns.POCost_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'avgcost_weighted')">{{ 'Table_Columns.AvgCostWeighted_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'avgcost_fifo')">{{ 'Table_Columns.AvgCostFIFO_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'saleprice')">{{ 'Table_Columns.Price_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'trantype')">{{ 'Table_Columns.TxnType_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'serial')">{{ 'Table_Columns.Serial_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'tranby')">{{ 'Table_Columns.TxnBy_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'receipt')">{{ 'Table_Columns.Customer_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'receipt')">{{ 'Table_Columns.Receipt_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'refno')">{{ 'Table_Columns.RefNo_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'ref_details')">{{ 'Table_Columns.RefDetails_Column' | translate }}<i class="Sorting fa fa-chevron-up"></i></th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of transactionList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="undoProductTrans(item)">{{ 'Table_Actions.Undo_Action' | translate }}</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.id}}</td>
            <td class="custom-td text-left">{{item.trandate| date:userdata.date_format}}</td>
            <td class="custom-td text-left">{{item.from_bin_location}}</td>
            <td class="custom-td text-left">{{item.to_bin_location}}</td>
            <td class="custom-td text-center">{{item.txnqty}}</td>
            <!-- <td class="custom-td text-right">{{item.balance}}</td> -->
            <td class="custom-td text-left">{{item.uom}}</td>
            <td class="custom-td text-left">{{item.pocost}}</td>
            <td class="custom-td text-right">{{item.avgcost_weighted}}</td>
            <td class="custom-td text-right">{{item.avgcost_fifo}}</td>
            <td class="custom-td text-right">{{item.saleprice}}</td>
            <td class="custom-td text-left">{{item.trantype}}</td>
            <td class="custom-td text-left">{{item.serial}}</td>
            <td class="custom-td text-left">{{item.tranby}}</td>
            <td class="custom-td text-left">{{item.customer}}</td>
            <td class="custom-td text-left">{{item.receipt}}</td>
            <td class="custom-td text-left">{{item.refno}}</td>
            <td class="custom-td text-left">{{item.ref_details}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>