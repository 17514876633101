<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="exampleModalLabel">Issued Cheque Tracking</h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal('close')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="needs-validation">
                <div class="form row">
                    <div class="form-group col-sm-6">
                        <label for="">Clear Date</label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker421" placeholder="Choose a date"
                                [(ngModel)]="tracking.cleardate" name="cleardate">
                            <mat-datepicker-toggle matSuffix [for]="picker421"></mat-datepicker-toggle>
                            <mat-datepicker #picker421></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Status</label>
                        <select class="form-control" [(ngModel)]="tracking.status" name="status">
                            <option>Select Status</option>
                            <option *ngFor="let item of issuedStatus" [value]="item.stringValue">{{item.Text}}</option>
                        </select>
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="">Notes</label>
                        <textarea type="text" class="form-control" rows="5" [(ngModel)]="tracking.notes"></textarea>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal('close')">Close</button>
            <button type="button" class="btn btn-primary" (click)="saveTracking()">Save</button>
        </div>
    </div>
</resize-border>