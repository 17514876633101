import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Type } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { purchaseServices } from '../../purchase/purchase.service';
import { SalesServices } from '../../Sales/sales.service';
import { IntegrationServices } from 'src/app/components/integrations/integration.service'
import { InventoryServices } from '../../inventory/inventory.service';
@Component({
  selector: 'app-qbonlinemodal',
  templateUrl: './qbonlinemodal.component.html',
  styleUrls: ['./qbonlinemodal.component.scss']
})
export class QbonlinemodalComponent implements OnInit {
  fullScreen = false;
  @Input('type') type;
  userdata: any;
  appname = environment.APP_NAME;
  printURL: any;
  listRes: any = [];
  showtitle: boolean;
  modalTitle: any;
  customer: any = {
    id: null,
    name: '',
    accountno: '',
    shortname: '',
    status: '',
    tpaccno: '',
  }
  supplier: any = {
    id: null,
    name: '',
    accno: '',
    shortname: '',
    status: '',
    tpcode: '',
  }
  invoiceData: any = {
    tax: 0,
    discount: 0,
    salesrep: '',
    invno: '',
    project: '',
    projectloc: '',
    customer: '',
    tpsync: '',
    fdate: '',
    status: ''
  }
  filter: any = {
    name: '',
    ilc: '',
    whatyouarethinking: '',
    status: '',
    serialized: '',
    supplier: '',
    deptid: '',
    type: '',
    tpsync: '',
    desc: '',
    category: ''
  }
  currentPage:any=1;
  constructor(
    private activeModal: NgbActiveModal,
    private datepipe: DatePipe,
    private loader: NgxUiLoaderService,
    private SalesServices: SalesServices,
    private service: InventoryServices,
    private purchaseServices: purchaseServices,
    private IntegrationServices: IntegrationServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  ngOnInit(): void {
    this.showtitle = true;
    if (this.type == 'customer') {
      this.modalTitle = 'Customer List';
      this.getCustomersList();
    }
    if (this.type == 'supplier') {
      this.modalTitle = 'Supplier List';
      this.getSupplierList();
    }
    if (this.type == 'invoices') {
      this.modalTitle = 'Invoice List';
      this.getInvoices();
    }
    if (this.type == 'inventory') {
      this.modalTitle = 'Inventory List';
      this.getItems();
    }
  }

  //Get Customer List==============
  getCustomersList() {
    this.listRes = [];
    this.loader.start();
    this.SalesServices.getCustomers(1, this.customer.searchtxt, '', '', '', this.customer.status).subscribe(res => {
      this.loader.stop();
      this.listRes = res;
      environment.production ? '' : console.log('customers list data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customers list ', err);
    });
  }

  //Get Supplier List==============
  getSupplierList() {
    this.listRes = [];
    this.loader.start();
    this.purchaseServices.getsuppliers(1,'', '', this.supplier.searchtxt, this.supplier.status
    ).subscribe(res => {
      this.loader.stop();
      this.listRes = res;
      environment.production ? '' : console.log('supplier list data', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading supplier list ', err);
    });
  }

  //Get Invoice List==============
  getInvoices() {
    this.loader.start();
    this.listRes = [];
    this.SalesServices.getInvoices(
      this.currentPage,
      '',
      this.datepipe.transform(this.invoiceData.fdate, this.userdata.postdateformat),
      this.datepipe.transform(this.invoiceData.edate, this.userdata.postdateformat),
      this.invoiceData.status, ''
    ).subscribe(res => {
      this.loader.stop();
      this.listRes = res;
      this.listRes.forEach(element => {
        element.name = element.invno;
        element.description = element.customer;
        // element.duedate = this.datepipe.transform(element.duedate, this.format);

        environment.production ? '' : console.log('invoices list data ', res);
      });

    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading invoice list ', err);
    });
  }

  //Get Product List==============
  getItems() {
    this.loader.start();
    this.service.getInventoryItems(1, this.filter.serialized, this.filter.searchtext, this.filter.supplier, this.filter.category, this.filter.deptid, this.filter.status, this.filter.tpsync).subscribe((res: any) => {
      this.loader.stop();
      if (res == null) {
        return;
      }
      this.listRes = res.data;
      this.listRes.forEach(element => {
        element.name = element.ilc;
      })
      environment.production ? '' : console.log('product list data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading product list ', err);
    });
  }

}
